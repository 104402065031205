import { useEffect, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  SettleBillButton,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import "./NightAudit.css";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { Tooltip } from "chart.js";
import {
  formatIndianCurrency,
  getCurrentPropertyDate,
  getCurrentPropertyDateHandler,
  useCurrentTime,
} from "../../utils/utils";
import html2canvas from "html2canvas";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import { useNavigate } from "react-router-dom";
import SettlementPopup from "../CustomComponents/SettlementPopup/SettlementPopup";
import NoDataFound from "../CustomComponents/NoDataFound";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import { Button, Icon } from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import Loader from "../UI/Loader/Loader";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import RoundOffNumber from "../CustomComponents/RoundOffNumber/RoundOffNumber";
import IFrameBillUi from "../Folio/IFrameBillUi";
import PrintFolioRoomBill from "../Folio/PrintFolioRoomBill";
import { getDecryptedData } from "../../utils/encryptStorage";
import jsPDF from "jspdf";
import AssignRoomPopup from "../CustomComponents/AssignRoomsPopup/AssignRoomPopup";
import { SecondaryButton } from "../NewCustomComp/Buttons/Buttons";
import AssignRoomPopupNew from "../CustomComponents/AssignRoomPopupNew/AssignRoomPopupNew";
import AddMultiplePaymentsPopup from "../CustomComponents/AddMultiplePaymentsPopup/AddMultiplePaymentsPopup";
// import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

const NightAudit = ({ setNightAuditPopup, nightAuditPopup }) => {
  const navigate = useNavigate();
  const [selectedStep, setSelectedStep] = useState(1);
  const [openTabs, setOpenTabs] = useState(1);
  const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);
  const [dayTypeFilter, setDayTypeFilter] = useState("");

  const [dueCheckOutData, setDueCheckOutData] = useState([]);
  const [checkedInData, setCheckedInData] = useState([]);
  const [checkOutData, setCheckOutData] = useState([]);
  const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);
  const [nextDayPopup, setNextDayPopup] = useState(false);
  const [inHouseGuests, setInHouseGuests] = useState([]);
  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);
  const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
    useState(false);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [showAllSettlement, setShowAllSettlement] = useState(false);
  const [allSettlementsData, setAllSettlementsData] = useState([]);
  const [bookingId, setBookingId] = useState("");
  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);
  const [bookingAccordianActive, setBookingAccordianActive] = useState("");
  const [openBookingDetails, setOpenBookingDetails] = useState(true);

  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData("encryptToken");

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [roundedValue, setRoundedValue] = useState(0);
  const [roundOffValue, setRoundOffValue] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));
  const currentTimeToBackend = useCurrentTime("24");

  const [iframeContent, setIframeContent] = useState("");
  const [showBillPreview, setShowBillPreview] = useState(false);
  const [billGenerated, setBillGenerated] = useState(false);
  const [invoiceGenerated, setInvoiceGenerated] = useState(null);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const [generateInvoice, setGenerateInvoice] = useState(false);
  // UseState for forms
  const [kotSettlementData, setKotSettlementData] = useState({
    amount: "",
    payment_mode: "",
  });

  const [assignRoom, setAssignRoom] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const setAddItemDataHandleChange = (event) => {
    setKotSettlementData({
      ...kotSettlementData,
      [event.target.name]: event.target.value,
    });
  };

  const stepFormData = [
    {
      id: 1,
      content: "Today’s Due Check Out",
    },
    {
      id: 2,
      content: "Today’s No Show",
    },
    // {
    //   id: 3,
    //   content: " Today’s Checked Out",
    // },
    {
      id: 3,
      content: "Today’s In House Guests",
    },
    {
      id: 4,
      content: "Today’s Unsettled KOT's",
    },
    {
      id: 5,
      content: "Today’s Unsettled Folios",
    },
    {
      id: 6,
      content: "Today’s Collected Amount",
    },
    // {
    //   id: 7,
    //   content: "Today’s Collected Amount",
    // },
  ];

  useEffect(() => {
    const storedStep = localStorage.getItem("selectedStep");
    if (storedStep) {
      setSelectedStep(parseInt(storedStep, 10));
    }
    setLoading(false);
  }, []);

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  useEffect(() => {
    if (!loading) {
      localStorage.setItem("selectedStep", selectedStep.toString());
    }
  }, [selectedStep, loading]);

  const fetchDueCheckOut = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/pending/checkin/checkout/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setDueCheckOutData(data.checkout);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchCheckedIn = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/pending/checkin/checkout/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setCheckedInData(data.checkin);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const noShowBookings = checkedInData.filter(
    (booking) => !booking?.assigned_rooms
  );

  const fetchCheckedOut = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/pending/checkin/checkout/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setCheckOutData(data.departures);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchInHouseGuests = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/pending/checkin/checkout/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setInHouseGuests(data.checkin);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [folioBookings, setFolioBookings] = useState([]);

  const fetchFolioDetails = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/pending/checkin/checkout/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setFolioBookings(data?.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  // for folio settlements

  const [agentLists, setAgentLists] = useState([]);

  const fetchAgentLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      if (data?.data.length > 0) {
        setAgentLists(data.data);
      } else setAgentLists([]);
    } catch (error) {
      console.error(error);
    }
  };

  const agentListOptions = [
    {
      label: "Select option",
      value: "",
    },
    ...agentLists?.map((agent) => ({
      label: agent?.company_name,
      value: agent?.agent_information,
    })),
  ];
  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    // { label: "Other", value: "other" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "8%", value: "8" },
    { label: "12%", value: "12" },
    { label: "15%", value: "15" },
  ];
  const paymentTypeOptions = [
    { label: "Select Option", value: "" },
    { label: "Cash", value: "cash" },
    { label: "City Ledger", value: "city_ledger" },
  ];

  const currentDate = new Date().toISOString().split("T")[0];

  const [addChargesData, setAddChargesData] = useState({
    date: currentDate,
    booking_id: "",
    folio_id: "",
    property_id: "",
    notes: "",
    notes: "",
    payment_mode: "",
    payment_type: "",
    amount: "",
  });

  useEffect(() => {
    setAddChargesData({
      ...addChargesData,
      amount: "",
      date: currentDate,
    });
  }, [addChargesData.payment_type]);

  const [roomDetails, setRoomDetails] = useState([]);

  const [agentCreditLimit, setAgentCreditLimit] = useState("");
  useEffect(() => {
    const selectedAgent = agentLists?.find(
      (agent) =>
        Number(agent?.agent_information) ===
        Number(addChargesData?.company_name)
    );


    if (selectedAgent && selectedAgent?.credit.length > 0) {
      const creditLimit = selectedAgent?.credit?.reduce(
        (acc, agent) => Number(acc) + Number(agent.balance_amount),
        0
      );
      setAgentCreditLimit(creditLimit);
    } else {
      setAgentCreditLimit(null);
    }

    setAddChargesData({
      ...addChargesData,
      amount: null,
      date: addChargesData.date || currentDate,
    });
  }, [addChargesData.company_name, agentLists]);
  useEffect(() => {
    // Convert input values to numbers
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    // Apply GST
    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);
    }

    let totalAmount = priceAfterDiscount + gstAmount;

    setAddChargesData({
      ...addChargesData,
      // subtotal: subtotal.toFixed(2),
      // net_total: totalAmount.toFixed(2),
      // discount_amount: discountAmount.toFixed(2),
      // tax_amount: gstAmount.toFixed(2),
    });
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  const setAddChargesHandleChange = (event) => {
    if (
      event.target.name === "amount" &&
      addChargesData.payment_type === "city_ledger"
    ) {
      if (
        agentCreditLimit > 0 &&
        parseFloat(event.target.value) > agentCreditLimit
      ) {
        showErrorToast(
          "Amount should not be greater than Agent Balance Credit Limit"
        );
        return;
      }
    }
    if (event.target.name === "company_name") {
      setAddChargesData({
        // ...addChargesData,
        amount: 0,
      });
    }

    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (property_id) {
      //   fetchDueCheckOut(property_id?.id);
      //   fetchCheckedIn(property_id?.id);
      //   fetchCheckedOut(property_id?.id);
      //   fetchInHouseGuests(property_id?.id);
      fetchAgentLists();
      fetchFolioDetails(property_id?.id);
    }
  }, [property_id]);

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const [masterFolioDetails, setMasterFolioDetails] = useState([]);
  const [allFolios, setAllFolios] = useState([]);

  const [totalBalanceAmount, setTotalBalanceAmount] = useState(0);
  // console.log(totalBalanceAmount);
  useEffect(() => {
    const balance =
      allFolios &&
      allFolios.length > 0 &&
      allFolios.reduce(
        (prev, curr, index, array) => +prev + +curr.balance_amount,
        0
      );

    setTotalBalanceAmount(balance);
    // setTotalBalanceAmount(100.2);
  }, [allFolios]);

  const handleNext = () => {
    const nextStep =
      selectedStep < stepFormData.length ? selectedStep + 1 : selectedStep;
    setSelectedStep(nextStep);
    // setNextDayPopup(true)
  };
  const handleLastClick = () => {
    setNextDayPopup(true);
  };

  const handlePrevious = () => {
    const previousStep = selectedStep > 1 ? selectedStep - 1 : selectedStep;
    setSelectedStep(previousStep);
  };

  const submitCheckOutBookingDataWithBookingId = (unique_booking_id) => {
    setBookingId(unique_booking_id);
   

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${unique_booking_id}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCheckOutBookingDetail(data.booking);
          setShowCheckOutBookingDetails(true);
          // setShowDashBoardCheckOut(false);
          setAllFolios(data.allFolios);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleShowAllSettlement = (propertyId, bookingId) => {
    setShowCheckOutBookingDetails(false);
    setShowAllSettlement(!showAllSettlement);
    fetchAllSettlements(propertyId, bookingId);
  };

  const fetchAllSettlements = async (propertyId, bookingId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/settlements/${bookingId}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllSettlementsData(data.settlements);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const completeCheckOut = (e) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/checkout/${checkOutBookingDetail.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setBookingDetails(data.booking);
          showSuccessToast(data.message);
          // setShowDashBoardCheckIn(false);
          setSettlementDoneCheckOut(false);
          // fetchDashboardInfo(property_id?.id, dayTypeFilter);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleOpenBookigDetails = (idx) => {
    setOpenBookingIndex(idx);
    if (bookingOpenIndex === idx) {
      setBookingAccordianActive("active");
    } else {
      setBookingAccordianActive("");
    }
  };

  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const getCurrentTime = () => {
        let date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        hours = hours < 10 ? "0" + hours : hours; // Add leading zero for single-digit hours
        minutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero for single-digit minutes
        let strTime = hours + ":" + minutes;
        return strTime;
      };
      setCurrentTime(getCurrentTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const startNextDayHandle = (data, totalPaidAmount, balanceAmount) => {
    // console.log(propertyCurrentDate);
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/end/night/audit/${propertyCurrentDate}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          action: "day_close",
          closed_by: user?.name,
          day_close_time: currentTime,
          day_close_date: propertyCurrentDate,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowCheckOutSettlementDetails(false);
          setNightAuditPopup(false);

          setNextDayPopup(false);

          setSelectedStep(1);
          localStorage.setItem("selectedStep", "1");

          // setSettlementDoneCheckOut(true);

          // setShowCheckOutBookingDetails(true);
          window.location.reload();
        } else {
          setNightAuditPopup(false);

          setNextDayPopup(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [settlement_details, setSettlement_details] = useState([]);
  const [totalPaidAmount, setTotalPaidAmount] = useState("");
  const [balanceAmount, setBalanceAmount] = useState("");
  const [selectedKot, setSelectedKot] = useState("");
  const [selectedFolio, setSelectedFolio] = useState("");
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [kotSettlementPopup, setKotSettlementPopup] = useState(false);
  const [folioSettlementPopup, setFolioSettlementPopup] = useState(false);
  const [settlementAmount, setSettlementAmount] = useState(false);

  const [paymentSelected, setPaymentSelected] = useState("cash");

  const settlementDataFromComponent = (
    data,
    totalPaidAmount,
    balanceAmount
  ) => {
    setSettlement_details(data);
    setTotalPaidAmount(totalPaidAmount);
    setBalanceAmount(balanceAmount);
  };

  const submitCheckOutBookingData = (e, unique_booking_id) => {
    setBookingId(unique_booking_id);
    if (e) {
      e.preventDefault();
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCheckOutBookingDetail(data.booking);
          // showSuccessToast(data.message);
          setShowCheckOutBookingDetails(true);
          // setShowDashBoardCheckOut(false);

          setAllFolios(data.allFolios);
          // setShowBookingDetails(true);
          // setBookingDetails(data.booking);
          // // showSuccessToast(data.message);
          // setShowArrivalConfirmed(false);
          // const updatedData = {
          //   guest_name: data?.booking?.guest[0]?.guest_name,
          //   guest_phone: data?.booking?.guest[0]?.guest_phone,
          //   guest_city: data?.booking?.guest[0]?.guest_city,
          //   guest_state: data?.booking?.guest[0]?.guest_state,
          //   guest_pincode: data?.booking?.guest[0]?.guest_pincode,
          //   guest_address: data?.booking?.guest[0]?.guest_address,
          //   guest_email: data?.booking?.guest[0]?.guest_email,
          //   identity_proof_type: data?.booking?.guest[0]?.identity_proof_type,
          //   identity_proof_no: data?.booking?.guest[0]?.identity_proof_no,
          //   guest_country: "India",
          // };
          // setCheckInData(updatedData);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleNoShowData = (status, bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/update/booking_status/${bookingId}/${status}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          fetchFolioDetails(property_id?.id);
          // window.location.reload()
        } else {
          showErrorToast(data.message);
        }
      });
  };

  const settlementHandleKOT = (event) => {
    event.preventDefault();

    if (
      Number(kotSettlementData?.amount) !== Number(selectedKot?.balance_amount)
    ) {
      showErrorToast("Amount should be less than total amount");
      return;
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/payment/add/${selectedKot?.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          payment_method: kotSettlementData.payment_mode,
          tip: 0,
          amount: kotSettlementData.amount,
          change: 0,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchFolioDetails(property_id?.id);
          setSettlementAmount("");
          setKotSettlementData({});
          showSuccessToast(data.message);
          setKotSettlementPopup(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const settlementHandleFolio = (event) => {
    event.preventDefault();

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/payments/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: selectedFolio.id,
          property_id: property_id?.id,
          booking_id: selectedFolio.booking_id
            ? selectedFolio.booking_id
            : selectedBookingId.id,
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchFolioDetails(property_id?.id);
          showSuccessToast(data.message);
          setAddChargesData({});
          setFolioSettlementPopup(false);
          setShowCheckOutSettlementDetails(false);
          // yha pe
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const checkOutSettlementHandleFolio = (event) => {
    event.preventDefault();
    // console.log({addChargesData, round_off: roundOffValue});
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/checkout/all/folio/settlement/${checkOutBookingDetail.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: selectedFolio.id,
          property_id: property_id?.id,
          booking_id: selectedFolio.booking_id
            ? selectedFolio.booking_id
            : selectedBookingId.id,
          round_off: roundOffValue,
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchFolioDetails(property_id?.id);
          showSuccessToast(data.message);
          setAddChargesData({});
          setFolioSettlementPopup(false);
          setShowCheckOutSettlementDetails(false);

          submitCheckOutBookingDataWithBookingId(
            checkOutBookingDetail.unique_booking_id
          );

          setAddChargesData({});
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // useEffect(() => {
  //   const disableRightClick = (e) => {
  //     e.preventDefault();
  //   };

  //   const disableShortcuts = (e) => {
  //     console.log(e);
  //     if (e.shiftKey) {
  //       console.log("hi");
  //     }
  //     if (
  //       e.key === "F12" ||
  //       (e.metaKey && e.shiftKey && (e.key === "j" || e.key === "c")) ||
  //       (e.metaKey && e.key === "U")
  //     ) {
  //       e.preventDefault();
  //     }
  //   };

  //   if (nightAuditPopup) {
  //     document.addEventListener("contextmenu", disableRightClick);
  //     document.addEventListener("keydown", disableShortcuts);
  //   } else {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //     document.removeEventListener("keydown", disableShortcuts);
  //   }

  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //     document.removeEventListener("keydown", disableShortcuts);
  //   };
  // }, [nightAuditPopup]);
  const handleRoundOffChange = (rounded, roundOffValue) => {
    setRoundedValue(rounded);
    setRoundOffValue(roundOffValue);
  };

  const isDecimal = totalBalanceAmount % 1 !== 0;
  // ...............................................................................................
  const bookings = [
    {
      bookingID: 1,
      guestName: "John Doe",
      phone: "1234567890",
      email: "john@example.com",
      checkInOut: "2024-05-01 - 2024-05-05",
      roomType: "Standard",
      roomNo: "101",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    {
      bookingID: 2,
      guestName: "Jane Smith",
      phone: "0987654321",
      email: "jane@example.com",
      checkInOut: "2024-05-02 - 2024-05-06",
      roomType: "Deluxe",
      roomNo: "202",
    },
    // Add more bookings as needed
  ];

  // download pdf

  const getBillIframe = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${checkOutBookingDetail?.folioId}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        setIframeContent(data);
        setShowBillPreview(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const getBillIframeDownload = () => {
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${checkOutBookingDetail?.folioId}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        // setIframeContent(data);
        handleDownloadPdf(data); // Call handleDownloadPdf here
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleDownloadPdf = (data) => {
    // Create a temporary container for the HTML content
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = `
      <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;">
        <div style="border: 1px solid #ccc; padding: 10px; max-width: 800px; font-family: Arial, sans-serif;">
          ${data}
        </div>
      </div>
    `;

    // Add the temporary container to the body (hidden)
    document.body.appendChild(tempContainer);

    // Use html2canvas to capture the container as an image
    html2canvas(tempContainer, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Initialize jsPDF and add the image
      const pdf = new jsPDF("p", "pt", "a4");
      const imgWidth = 595.28;
      const pageHeight = 841.89;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = 0;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

      // Save the PDF
      pdf.save("bill.pdf");

      // Remove the temporary container
      document.body.removeChild(tempContainer);
      setLoader(false);
    });
  };

  // console.log("checkOutBookingDetail", checkOutBookingDetail);

  const handleSubmitGenerateBillHandle = (regenerate) => {
    fetch(
      // Route::get(‘generate/folio/bill/{folio_id}/{property_id}’, ‘generateBill’);
      `${process.env.REACT_APP_BASE_URL}/api/v1/generate/folio/bill/${checkOutBookingDetail?.folioId}/${generateInvoice}/${property_id?.id}?regenerateBill=${regenerate}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          submitCheckOutBookingDataWithBookingId(
            checkOutBookingDetail?.unique_booking_id
          );
          showSuccessToast(data.message);
          setShowCheckOutBookingDetails(false);
          // setAddChargesData(false);
        } else {
          showErrorToast("Please add Bill To details in folio");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleAssignRoom = (allData) => {
    setAssignRoom(true);
    if (allData) {
      setSelectedId(allData);
    }
  };

  
  return (
    <>
      <div
        className="night_audit_page"
        style={{ position: "relative", borderRadius: "6px" }}
      >
        <div className="night_audit_page_heading flex justify_content_between">
          <h2>
            Night Audit (
            {propertyCurrentDate && (
              <TextDateFormatter
                as={"span"}
                date={propertyCurrentDate}
                showYear={true}
              />
            )}
            )
          </h2>
          {/* <button
onClick={() => [
setNightAuditPopup(false),
localStorage.setItem("selectedStep", "1"),
]}
>
Close
</button> */}
          <div
            onClick={() => [
              setNightAuditPopup(false),
              localStorage.setItem("selectedStep", "1"),
            ]}
            className="close_popup"
          >
            <svg
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
              <path
                d="M13 12.5L0.999999 0.500001"
                stroke="#AAAAAA"
                strokeWidth="1.2"
              />
            </svg>
          </div>
        </div>

        <div className="night_audit_top_step_form flex align_items_center justify_content_center">
          {stepFormData.map((item) => (
            <div
              className={`night_audit_top_step_form_child flex align_items_center flex_gap_10 ${
                item.id <= selectedStep ? "selected" : ""
              }`}
              key={item.id}
              style={{ flexBasis: `calc(100% / ${stepFormData.length})` }}
            >
              <div className="step_form_index flex align_items_center justify_content_center">
                {item.id}
              </div>
              <div className="step_form_content">
                <p>{item.content}</p>
              </div>
            </div>
          ))}
        </div>

        {loader ? (
          <Loader />
        ) : (
          <>
            {selectedStep === 1 && (
              <>
                {folioBookings?.dueCheckouts?.length > 0 ? (
                  <div style={{ position: "relative", height: "270px" }}>
                    <table className="hoteltable_table_full_folio">
                      <thead style={{ position: "sticky", top: "0" }}>
                        <tr>
                          <th className="hoteltable_th">Booking ID</th>
                          <th className="hoteltable_th">Guest Name</th>
                          <th className="hoteltable_th">Phone</th>
                          <th className="hoteltable_th">Check In - Out</th>
                          <th className="hoteltable_th">Room Type</th>
                          <th className="hoteltable_th">Room No</th>
                          {/* <th className="hoteltable_th">Total Amt.</th>  */}
                          {/* <th className="hoteltable_th">Balance Amt.</th> */}
                          <th className="hoteltable_th"> </th>
                        </tr>
                      </thead>
                      <div style={{ maxHeight: "240px", overflowY: "auto" }}>
                        <tbody className="hoteltable_tbody">
                          {folioBookings?.dueCheckouts &&
                            folioBookings?.dueCheckouts.map((item) => (
                              <tr className="hoteltable_tr">
                                <td className="hoteltable_td">
                                  <div className="">
                                    #{item?.unique_booking_id}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    {item.guest[0]?.guest_name}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    {item.guest[0]?.guest_phone}
                                  </div>
                                </td>

                                <td className="hoteltable_td">
                                  <div className="">
                                    <TextDateFormatter
                                      as={"span"}
                                      date={item?.check_in}
                                      showYear={true}
                                    />
                                  </div>
                                  <div className="">
                                    <TextDateFormatter
                                      as={"span"}
                                      date={item?.check_out}
                                      showYear={true}
                                    />
                                  </div>
                                </td>

                                <td className="hoteltable_td">
                                  {item.items.length > 0 ? (
                                    <div className="">
                                      {item.items.map((room, index) => (
                                        <span key={index}>
                                          {room.room_type_name}
                                        </span>
                                      ))}
                                    </div>
                                  ) : (
                                    "NA"
                                  )}
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    {item.assigned_rooms
                                      ? item.assigned_rooms
                                      : "NA"}
                                  </div>
                                </td>
                                {/* <td className="hoteltable_td">
                <div className="">{item.grand_total}</div>
              </td> */}
                                {/* <td className="hoteltable_td">
                <div className="">{item.balance_amount}</div>
              </td> */}
                                <td className="hoteltable_td">
                                  <CheckWithoutBgUnderlineBtn
                                    onClick={() => [
                                      submitCheckOutBookingDataWithBookingId(
                                        item.unique_booking_id
                                      ),
                                      setSelectedBookingId(item),
                                    ]}
                                    children={"Check Out"}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </div>
                    </table>

                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-58px",
                        right: "0",
                      }}
                    >
                      <div></div>
                      {folioBookings &&
                      folioBookings?.dueCheckouts?.length === 0 ? (
                        <BgThemeButton onClick={handleNext} children={"Next"} />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ position: "relative" }}>
                    <NoDataFoundSmall />
                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-73px",
                        right: "0",
                      }}
                    >
                      <div></div>

                      {folioBookings?.dueCheckouts?.length === 0 ? (
                        <BgThemeButton onClick={handleNext} children={"Next"} />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            {selectedStep === 2 && (
              <>
                {folioBookings && folioBookings?.noShow?.length > 0 ? (
                  <>
                    <div style={{ position: "relative", height: "270px" }}>
                      <table className="hoteltable_table_full_folio">
                        <thead style={{ position: "sticky", top: "0" }}>
                          <tr>
                            <th className="hoteltable_th">Booking ID</th>
                            <th className="hoteltable_th">Guest Name</th>
                            <th className="hoteltable_th">Phone</th>
                            <th className="hoteltable_th">Check In - Out</th>
                            <th className="hoteltable_th">Room Type</th>
                            <th className="hoteltable_th">Room No</th>
                            <th className="hoteltable_th"></th>
                          </tr>
                        </thead>
                        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                          <tbody className="hoteltable_tbody">
                            {folioBookings &&
                              folioBookings?.noShow?.map((item) => (
                                <tr className="hoteltable_tr">
                                  <td className="hoteltable_td">
                                    <div className="">
                                      #{item?.unique_booking_id}
                                    </div>
                                  </td>
                                  <td className="hoteltable_td">
                                    <div className="">
                                      {item.guest[0]?.guest_name}
                                    </div>
                                  </td>
                                  <td className="hoteltable_td">
                                    <div className="">
                                      {item.guest[0]?.guest_phone}646465
                                    </div>
                                  </td>

                                  <td className="hoteltable_td">
                                    <div className="">
                                      <TextDateFormatter
                                        as={"span"}
                                        date={item?.check_in}
                                        showYear={true}
                                      />
                                    </div>
                                    <div className="">
                                      <TextDateFormatter
                                        as={"span"}
                                        date={item?.check_out}
                                        showYear={true}
                                      />
                                    </div>
                                  </td>
                                  <td className="hoteltable_td">
                                    {item.items.length > 0 ? (
                                      <div className="">
                                        {item.items.map((room, index) => (
                                          <span key={index}>
                                            {room.room_type_name}
                                          </span>
                                        ))}
                                      </div>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                  <td className="hoteltable_td">
                                    <div className="">
                                      {item.assigned_rooms
                                        ? item.assigned_rooms
                                        : "NA"}
                                    </div>
                                  </td>
                                  <td className="hoteltable_td">
                                    <CheckWithoutBgUnderlineBtn
                                      onClick={() =>
                                        handleNoShowData("no_show", item?.id)
                                      }
                                      children={"Move To No Show"}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </div>
                      </table>
                    </div>

                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-58px",
                        right: "0",
                      }}
                    >
                      {folioBookings?.noShow?.length === 0 ? (
                        <BgThemeButton onClick={handleNext} children={"Next"} />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} />
                      )}
                    </div>
                  </>
                ) : (
                  <div style={{ position: "relative" }}>
                    <NoDataFoundSmall />
                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-73px",
                        right: "0",
                      }}
                    >
                      {folioBookings?.noShow?.length === 0 ? (
                        <BgThemeButton onClick={handleNext} children={"Next"} />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            {selectedStep === 3 && (
              <>
                {folioBookings?.inHouse?.length > 0 ? (
                  <div style={{ position: "relative", height: "270px" }}>
                    <table className="hoteltable_table_full_folio">
                      <thead style={{ position: "sticky", top: "0" }}>
                        <tr>
                          <th className="hoteltable_th">Booking ID</th>
                          <th className="hoteltable_th">Guest Name</th>
                          <th className="hoteltable_th">Phone</th>
                          <th className="hoteltable_th">Email</th>
                          <th className="hoteltable_th">Check In - Out</th>
                          <th className="hoteltable_th">Room Type</th>
                          <th className="hoteltable_th">Room No.</th>
                        </tr>
                      </thead>
                      <div style={{ maxHeight: "240px", overflowY: "auto" }}>
                        <tbody className="hoteltable_tbody">
                          {folioBookings &&
                            folioBookings?.inHouse?.map((item) => (
                              <tr className="hoteltable_tr">
                                <td className="hoteltable_td">
                                  <div className="">
                                    #{item?.unique_booking_id}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    {item.guest[0]?.guest_name}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    {item.guest[0]?.guest_phone}
                                  </div>
                                </td>

                                <td className="hoteltable_td">
                                  <div className="">
                                    {item.guest[0]?.guest_email}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    <TextDateFormatter
                                      as={"span"}
                                      date={item?.check_in}
                                      showYear={true}
                                    />
                                  </div>
                                  <div className="">
                                    <TextDateFormatter
                                      as={"span"}
                                      date={item?.check_out}
                                      showYear={true}
                                    />
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    {item.items.length > 0 ? (
                                      <div className="">
                                        {item.items.map((room, index) => (
                                          <span key={index}>
                                            {room?.room_type_name}
                                          </span>
                                        ))}
                                      </div>
                                    ) : (
                                      "NA"
                                    )}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    {item.assigned_rooms ? (
                                      item.assigned_rooms
                                    ) : (
                                      <SecondaryButton
                                        onClick={() => handleAssignRoom(item)}
                                        title="Assign Rooms"
                                        size="medium"
                                      />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </div>
                    </table>

                    {assignRoom && (
                      <AssignRoomPopupNew
                        setShowAssignRoomPopup={setAssignRoom}
                        mainData={selectedId}
                        bookingId={selectedId?.id}
                      />
                    )}

                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-58px",
                        right: "0",
                      }}
                    >
                      <div></div>
                      {folioBookings?.noShow?.length === 0 ? (
                        <>
                          {folioBookings?.inHouse.some((item)=>item.assigned_rooms==="") ? (
                            <BgThemeButtonDisabled
                              onClick={handleNext}
                              children={"Next"}
                            />
                          ) : (
                            <BgThemeButton
                              onClick={handleNext}
                              children={"Next"}
                              style={{ textAlign: "end" }}
                            />
                          )}
                        </>
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} style={{ textAlign: 'end' }} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ position: "relative" }}>
                    <NoDataFoundSmall />
                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-73px",
                        right: "0",
                      }}
                    >
                      <div></div>
                      {folioBookings?.noShow?.length === 0 ? (
                        <BgThemeButton onClick={handleNext} children={"Next"} />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} style={{ textAlign: 'end' }} />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {selectedStep === 4 && (
              <>
                {folioBookings?.unsettledKots?.length > 0 ? (
                  <div style={{ position: "relative", height: "270px" }}>
                    <table
                      className="hoteltable_table_full_folio"
                      // style={{ height: "600px" }}
                    >
                      <thead style={{ position: "sticky", top: "0" }}>
                        <tr>
                          <th className="hoteltable_th">Customer Name</th>
                          <th className="hoteltable_th">Phone</th>
                          {/* <th className="hoteltable_th">Folio No.</th> */}
                          <th className="hoteltable_th">Disc. Amt.</th>
                          <th className="hoteltable_th">Subtotal</th>
                          <th className="hoteltable_th">Tax Amt. </th>
                          <th className="hoteltable_th">Net Total </th>
                          <th className="hoteltable_th">Paid Amount </th>
                          <th className="hoteltable_th">Balance Amount </th>
                          <th className="hoteltable_th"> </th>
                        </tr>
                      </thead>
                      <div style={{ maxHeight: "240px", overflowY: "auto" }}>
                        <tbody className="hoteltable_tbody">
                          {folioBookings &&
                            folioBookings?.unsettledKots?.map((item) => (
                              <tr className="hoteltable_tr">
                                <td className="hoteltable_td">
                                  <div className="">{item.customer_name}</div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">{item.customer_phone}</div>
                                </td>

                                <td className="hoteltable_td">
                                  <div className="">
                                    {formatIndianCurrency(item.discount_amount)}
                                  </div>
                                </td>

                                <td className="hoteltable_td">
                                  <div className="">
                                    {formatIndianCurrency(item.total_amount)}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  {formatIndianCurrency(item.tax_amount)}
                                </td>
                                <td className="hoteltable_td">
                                  {formatIndianCurrency(item.net_total)}
                                </td>
                                <td className="hoteltable_td">
                                  {formatIndianCurrency(item.paid_amount)}
                                </td>
                                <td className="hoteltable_td">
                                  {formatIndianCurrency(item.balance_amount)}
                                </td>
                                <td className="hoteltable_td">
                                  <SettleBillButton
                                    onClick={() => [
                                      setKotSettlementPopup(true),
                                      setSelectedKot(item),
                                    ]}
                                  >
                                    Settle
                                  </SettleBillButton>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </div>
                    </table>
                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-58px",
                        right: "0",
                      }}
                    >
                      <div></div>
                      {folioBookings?.unsettledKots?.length === 0 ? (
                        <BgThemeButton onClick={handleNext} children={"Next"} />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ position: "relative" }}>
                    <NoDataFoundSmall />
                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-73px",
                        right: "0",
                      }}
                    >
                      {/* <BgThemeButton onClick={handlePrevious} children={"Previous"} /> */}
                      <div></div>
                      {folioBookings?.unsettledKots?.length === 0 ? (
                        <BgThemeButton onClick={handleNext} children={"Next"} />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {selectedStep === 5 && (
              <>
                {folioBookings?.unsettledFolios?.length > 0 ? (
                  <div style={{ position: "relative", height: "270px" }}>
                    <table className="hoteltable_table_full_folio">
                      <thead style={{ position: "sticky", top: "0" }}>
                        <tr>
                          <th className="hoteltable_th">Bill To</th>
                          <th className="hoteltable_th">Folio No.</th>
                          {/* <th className="hoteltable_th">Phone</th> */}
                          {/* <th className="hoteltable_th">Folio No.</th> */}
                          <th className="hoteltable_th">Disc. Amt.</th>
                          <th className="hoteltable_th">Subtotal</th>
                          <th className="hoteltable_th">Tax Amt. </th>
                          <th className="hoteltable_th">Net Total </th>
                          <th className="hoteltable_th">Paid Amount </th>
                          <th className="hoteltable_th">Balance Amount </th>
                          <th className="hoteltable_th"> </th>
                        </tr>
                      </thead>
                      <div style={{ maxHeight: "240px", overflowY: "auto" }}>
                        <tbody className="hoteltable_tbody">
                          {folioBookings &&
                            folioBookings?.unsettledFolios?.map((item) => (
                              <tr className="hoteltable_tr">
                                <td className="hoteltable_td">
                                  <div className="">{item.bill_to}</div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">{item.folio_no}</div>
                                </td>

                                <td className="hoteltable_td">
                                  <div className="">
                                    {formatIndianCurrency(item.discount_amount)}
                                  </div>
                                </td>

                                <td className="hoteltable_td">
                                  <div className="">
                                    {formatIndianCurrency(item.sub_total)}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  {formatIndianCurrency(item.tax_amount)}
                                </td>
                                <td className="hoteltable_td">
                                  {formatIndianCurrency(item.grand_total)}
                                </td>
                                <td className="hoteltable_td">
                                  {formatIndianCurrency(item.paid_amount)}
                                </td>
                                <td className="hoteltable_td">
                                  {formatIndianCurrency(item.balance_amount)}
                                </td>
                                <td className="hoteltable_td">
                                  <SettleBillButton
                                    onClick={() => [
                                      setFolioSettlementPopup(true),
                                      setSelectedFolio(item),
                                    ]}
                                  >
                                    Settle
                                  </SettleBillButton>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </div>
                    </table>
                    {/* <div className="flex justify_content_end mt-20 mb-20">
  <p>
    <strong>Grand Total: ₹12,000.00</strong>
  </p>
</div> */}
                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-58px",
                        right: "0",
                      }}
                    >
                      {folioBookings?.unsettledFolios?.length === 0 ? (
                        <BgThemeButton onClick={handleNext} children={"Next"} />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ position: "relative" }}>
                    <NoDataFoundSmall />
                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-73px",
                        right: "0",
                      }}
                    >
                      {folioBookings?.unsettledFolios?.length === 0 ? (
                        <BgThemeButton onClick={handleNext} children={"Next"} />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleNext}
                          children={"Next"}
                        />
                        // <BgThemeButton onClick={handleNext} children={"Next"} />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {selectedStep === 6 && (
              <>
                {folioBookings?.collectedSettlements?.length > 0 ? (
                  <div style={{ position: "relative", height: "270px" }}>
                    <table className="hoteltable_table_full_folio">
                      <thead style={{ position: "sticky", top: "0" }}>
                        <tr>
                          <th className="hoteltable_th">Booking ID</th>
                          <th className="hoteltable_th">Folio ID</th>
                          <th className="hoteltable_th">Agent Name</th>
                          <th className="hoteltable_th">Payment Mode</th>
                          <th className="hoteltable_th">Payment Type</th>
                          {/* <th className="hoteltable_th">Phone</th> */}
                          {/* <th className="hoteltable_th">Folio No.</th> */}
                          <th className="hoteltable_th">Notes</th>
                          <th className="hoteltable_th">Amount</th>
                          <th className="hoteltable_th"> </th>
                        </tr>
                      </thead>
                      <div style={{ maxHeight: "240px", overflowY: "auto" }}>
                        <tbody className="hoteltable_tbody">
                          {folioBookings &&
                            folioBookings?.collectedSettlements?.map((item) => (
                              <tr className="hoteltable_tr">
                                <td className="hoteltable_td">
                                  <div className="">
                                    {item?.unique_booking_id
                                      ? item?.unique_booking_id
                                      : "N/A"}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">{item?.folio_id}</div>
                                </td>
                                <td className="hoteltable_td">
                                  {/* <div className="">{item?.agent_name}</div> */}
                                  <div>
                                    {item?.agent_name
                                      ? item?.agent_name
                                      : "N/A"}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    {
                                      <UnderscoreRemoveCapitalize
                                        text={
                                          item.payment_mode
                                            ? item.payment_mode
                                            : "N/A"
                                        }
                                      />
                                    }
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  <div className="">
                                    <UnderscoreRemoveCapitalize
                                      text={
                                        item.payment_type
                                          ? item.payment_type
                                          : "N/A"
                                      }
                                    />
                                  </div>
                                </td>

                                <td className="hoteltable_td">
                                  <div className="">{item.notes}</div>
                                </td>

                                <td className="hoteltable_td">
                                  <div className="">
                                    {formatIndianCurrency(item.amount)}
                                  </div>
                                </td>
                                <td className="hoteltable_td">
                                  {/* <SettleBillButton
              onClick={() => [
                setFolioSettlementPopup(true),
                setSelectedFolio(item),
              ]}
            >
              Settle
            </SettleBillButton> */}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </div>
                    </table>

                    {/* <div className="flex justify_content_end mt-20 mb-20">
  <p>
    <strong>Grand Total: ₹12,000.00</strong>
  </p>
</div> */}
                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-58px",
                        right: "0",
                      }}
                    >
                      <div></div>

                      {folioBookings?.unsettledFolios?.length === 0 ? (
                        <BgThemeButton
                          onClick={handleLastClick}
                          children={"Next"}
                        />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleLastClick}
                          children={"Next"}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ position: "relative" }}>
                    <NoDataFoundSmall />
                    <div
                      className="flex justify_content_between mt-20"
                      style={{
                        position: "absolute",
                        bottom: "-73px",
                        right: "0",
                      }}
                    >
                      {folioBookings?.unsettledFolios?.length === 0 ? (
                        <BgThemeButton
                          onClick={handleLastClick}
                          children={"Next"}
                        />
                      ) : (
                        <BgThemeButtonDisabled
                          onClick={handleLastClick}
                          children={"Next"}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {/* Due Check Out */}
      {showCheckOutBookingDetails && (
        <div className="bg-backdrop">
          <div className="dashboard_checkin_modal_container">
            {/* { checkOutBookingDetail.length > 0 && ( */}
            <>
              <div className="booking_details_child">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <GoBackButtonCustom
                      buttonNextText={`Booking Details`}
                      onClick={() => [
                        setShowCheckOutBookingDetails(false),
                        // setShowDepartureCheckOut(true),
                      ]}
                    />
                    {/* <Page
                            backAction={{
                              content: "Products",
                              url: `/dashboard`,
                            }}
                            title="Check-Out Details"
                            compactTitle
                          ></Page> */}
                  </div>
                  <div>
                    <h2>
                      <strong>
                        ID: #{checkOutBookingDetail?.unique_booking_id}
                      </strong>
                    </h2>
                  </div>
                </div>
                <div
                  style={{ cursor: "default" }}
                  className="booking_details_child_main"
                >
                  <div
                    className="booking_details_child_heading"
                    onClick={() => handleOpenBookigDetails(1)}
                  >
                    <h5>
                      <strong>General Information</strong>
                    </h5>
                    {/* {openBookingDetails ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="7"
                              viewBox="0 0 10 7"
                              fill="none"
                            >
                              <path
                                d="M9 6L5 2L1 6"
                                stroke="#333333"
                                strokeWidth="1.5"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="7"
                              viewBox="0 0 10 7"
                              fill="none"
                            >
                              <path
                                d="M1 1L5 5L9 1"
                                stroke="#333333"
                                strokeWidth="1.5"
                              />
                            </svg>
                          )} */}
                  </div>
                  {openBookingDetails && (
                    <div className="booking_details_child_list">
                      <li className="booking_list_view">
                        <h4>Customer Name</h4>
                        <h5>{checkOutBookingDetail?.guest[0]?.guest_name}</h5>
                      </li>
                      <li className="booking_list_view">
                        <h4>Phone Number</h4>
                        <h5>{checkOutBookingDetail?.guest[0]?.guest_phone}</h5>
                      </li>
                      <li className="booking_list_view">
                        <h4>Email</h4>
                        <h5>{checkOutBookingDetail?.guest[0]?.guest_email}</h5>
                      </li>
                    </div>
                  )}
                </div>

                <div
                  style={{ cursor: "default" }}
                  className="booking_details_child_main"
                >
                  <div
                    className="booking_details_child_heading"
                    onClick={() => handleOpenBookigDetails(2)}
                  >
                    <h5>
                      <strong>Room Details </strong>
                    </h5>
                    {/* {openBookingDetails ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="7"
                              viewBox="0 0 10 7"
                              fill="none"
                            >
                              <path
                                d="M9 6L5 2L1 6"
                                stroke="#333333"
                                strokeWidth="1.5"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="7"
                              viewBox="0 0 10 7"
                              fill="none"
                            >
                              <path
                                d="M1 1L5 5L9 1"
                                stroke="#333333"
                                strokeWidth="1.5"
                              />
                            </svg>
                          )} */}
                  </div>
                  {openBookingDetails && (
                    <div className="booking_details_child_list">
                      {checkOutBookingDetail?.assigned_rooms && (
                        <li className="booking_list_view">
                          <h4>Assigned Rooms</h4>
                          <h5>
                            <span>{checkOutBookingDetail?.assigned_rooms}</span>
                          </h5>
                        </li>
                      )}

                      <li className="booking_list_view">
                        <h4>Rooms & Meal Plan</h4>
                        <h5>
                          {(checkOutBookingDetail &&
                            checkOutBookingDetail.items &&
                            checkOutBookingDetail?.items.map((room) => {
                              return (
                                <>
                                  <div>
                                    {room.no_of_rooms} X {room.room_type_name}{" "}
                                    <span className="meal_plan_with_background">
                                      {room.room_plan}
                                    </span>
                                  </div>{" "}
                                </>
                              );
                            })) ||
                            ""}
                        </h5>
                      </li>
                      <li className="booking_list_view">
                        <h4>Booking Status</h4>
                        <h5>
                          <BookingStatusUpdate
                            status={
                              (checkOutBookingDetail &&
                                checkOutBookingDetail?.status) ||
                              ""
                            }
                          />
                        </h5>
                      </li>
                    </div>
                  )}
                </div>

                <div
                  style={{ cursor: "default" }}
                  className="booking_details_child_main"
                >
                  <div
                    className="booking_details_child_heading"
                    // onClick={() => handleOpenBookigDetails(2)}
                  >
                    {/* THIS */}
                    <h5>
                      <strong>Price Break-Up </strong>
                    </h5>
                  </div>
                  <div className="flex flex_gap_20 ">
                    {/* {checkOutBookingDetail &&
                          checkOutBookingDetail?.grand_total && (
                            <div
                              className={
                                openTabs === 1
                                  ? "priceBreakUpInnerTabs active"
                                  : "priceBreakUpInnerTabs"
                              }
                              onClick={() => setOpenTabs(1)}
                            >
                              Room
                            </div>
                          )} */}

                    {/*     {checkOutBookingDetail?.total_of_all_kots
                          ?.total_grand_total &&
                        checkOutBookingDetail?.total_of_all_kots
                          ?.total_grand_total != "0" ? (
                          <div
                            className={
                              openTabs === 2
                                ? "priceBreakUpInnerTabs active"
                                : "priceBreakUpInnerTabs"
                            }
                            onClick={() => setOpenTabs(2)}
                          >
                            F&B
                          </div>
                        ) : null}

                        {checkOutBookingDetail?.total_housekeeping_data
                          ?.total_grand_total != "0" &&
                        checkOutBookingDetail?.total_housekeeping_data
                          ?.total_grand_total ? (
                          <div
                            className={
                              openTabs === 3
                                ? "priceBreakUpInnerTabs active"
                                : "priceBreakUpInnerTabs"
                            }
                            onClick={() => setOpenTabs(3)}
                          >
                            Housekeeping
                          </div>
                        ) : null}
                        {checkOutBookingDetail?.total_room_service_data
                          ?.total_grand_total != "0" &&
                        checkOutBookingDetail?.total_room_service_data
                          ?.total_grand_total ? (
                          <div
                            className={
                              openTabs === 4
                                ? "priceBreakUpInnerTabs active"
                                : "priceBreakUpInnerTabs"
                            }
                            onClick={() => setOpenTabs(4)}
                          >
                            Room Service
                          </div>
                        ) : null}
                        {checkOutBookingDetail?.total_misc_data
                          ?.total_grand_total != "0" &&
                        checkOutBookingDetail?.total_misc_data
                          ?.total_grand_total ? (
                          <div
                            className={
                              openTabs === 5
                                ? "priceBreakUpInnerTabs active"
                                : "priceBreakUpInnerTabs"
                            }
                            onClick={() => setOpenTabs(5)}
                          >
                            Misc
                          </div>
                        ) : null} */}
                  </div>

                  {/* {openTabs === 1 && ( */}
                  <div className="booking_details_child_list">
                    <li className="booking_list_view">
                      <h4>Paid Amount</h4>
                      <h5>
                        {formatIndianCurrency(
                          allFolios &&
                            allFolios.length > 0 &&
                            allFolios.reduce(
                              (prev, curr, index, array) =>
                                +prev + +curr.paid_amount,
                              0
                            )
                        ) || 0}
                      </h5>
                    </li>

                    <li className="booking_list_view">
                      <h4>Grand Total</h4>
                      <h5>
                        {formatIndianCurrency(
                          allFolios &&
                            allFolios.length > 0 &&
                            allFolios.reduce(
                              (prev, curr, index, array) =>
                                +prev + +curr.grand_total,
                              0
                            )
                        ) || 0}
                      </h5>
                    </li>
                    <li className="booking_list_view">
                      <h4>Due Amount</h4>
                      <h5 style={{ color: "#E8B41A" }}>
                        {formatIndianCurrency(
                          allFolios &&
                            allFolios.length > 0 &&
                            allFolios.reduce(
                              (prev, curr, index, array) =>
                                +prev + +curr.balance_amount,
                              0
                            )
                        ) || 0}
                      </h5>
                    </li>
                  </div>
                  {/* )} */}
                  {/* {openTabs === 2 && (
                        <div className="booking_details_child_list">
                      
                          <li className="booking_list_view">
                            <h4>Subtotal</h4>
                            <h5>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_of_all_kots
                                    ?.total_sub_total
                              ) || 0}
                            </h5>
                          </li>
                       
                          <li className="booking_list_view">
                            <h4>Paid Amount</h4>
                            <h5>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_of_all_kots
                                    ?.total_paid_amount
                              ) || 0}
                            </h5>
                          </li>
                        
                          <li className="booking_list_view">
                            <h4>Grand Total</h4>
                            <h5>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_of_all_kots
                                    ?.total_grand_total
                              ) || 0}
                            </h5>
                          </li>
                          <li className="booking_list_view">
                            <h4>Due Amount</h4>
                            <h5 style={{ color: "#E8B41A" }}>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_of_all_kots
                                    ?.total_balance_amount
                              ) || 0}
                            </h5>
                          </li>
                        </div>
                      )}
                      {openTabs === 3 && (
                        <div className="booking_details_child_list">
                         
                          <li className="booking_list_view">
                            <h4>Subtotal</h4>
                            <h5>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_housekeeping_data
                                    ?.total_sub_total
                              ) || 0}
                            </h5>
                          </li>
                     
                          <li className="booking_list_view">
                            <h4>Paid Amount</h4>
                            <h5>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_housekeeping_data
                                    ?.total_paid_amount
                              ) || 0}
                            </h5>
                          </li>
                        
                          <li className="booking_list_view">
                            <h4>Grand Total</h4>
                            <h5>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_housekeeping_data
                                    ?.total_grand_total
                              ) || 0}
                            </h5>
                          </li>
                          <li className="booking_list_view">
                            <h4>Due Amount</h4>
                            <h5 style={{ color: "#E8B41A" }}>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_housekeeping_data
                                    ?.total_balance_amount
                              ) || 0}
                            </h5>
                          </li>
                        </div>
                      )}
                      {openTabs === 4 && (
                        <div className="booking_details_child_list">
                         
                          <li className="booking_list_view">
                            <h4>Subtotal</h4>
                            <h5>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_room_service_data
                                    ?.total_sub_total
                              ) || 0}
                            </h5>
                          </li>
                         
                          <li className="booking_list_view">
                            <h4>Paid Amount</h4>
                            <h5>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_room_service_data
                                    ?.total_paid_amount
                              ) || 0}
                            </h5>
                          </li>
                         
                          <li className="booking_list_view">
                            <h4>Grand Total</h4>
                            <h5>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_room_service_data
                                    ?.total_grand_total
                              ) || 0}
                            </h5>
                          </li>
                          <li className="booking_list_view">
                            <h4>Due Amount</h4>
                            <h5 style={{ color: "#E8B41A" }}>
                              {formatIndianCurrency(
                                checkOutBookingDetail &&
                                  checkOutBookingDetail?.total_room_service_data
                                    ?.total_balance_amount
                              ) || 0}
                            </h5>
                          </li>
                        </div>
                      )} */}
                </div>
              </div>

              {checkOutBookingDetail &&
                !checkOutBookingDetail?.assigned_rooms && (
                  <div style={{ textAlign: "center", color: "red" }}>
                    Booking is not ready for Check-Out
                  </div>
                )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                {checkOutBookingDetail &&
                // && checkOutBookingDetail?.status !== "checked_out"
                !checkOutBookingDetail?.assigned_rooms ? (
                  <>
                    <BgThemeButton
                      onClick={() => [setShowCheckOutBookingDetails(false)]}
                      style={{ margin: "auto" }}
                      children={"Close"}
                    />
                  </>
                ) : (
                  <>
                    {checkOutBookingDetail && totalBalanceAmount < 1 ? (
                      <>
                        <BgThemeButtonDisabled
                          onClick={() => {
                            setShowCheckOutSettlementDetails(true);
                            setShowCheckOutBookingDetails(false);
                          }}
                          children={"Settlement"}
                        />
                        {checkOutBookingDetail?.status === "checked_out" ? (
                          <Tooltip
                            content={"User has already been checked out."}
                          >
                            <BgThemeButtonDisabled
                              onClick={() => completeCheckOut(bookingId)}
                              style={{
                                backgroundColor: "#EEE",
                                color: "black",
                              }}
                              children={"Check-Out"}
                            />
                          </Tooltip>
                        ) : (
                          <BgThemeButton
                            onClick={() => completeCheckOut(bookingId)}
                            children={"Check-Out"}
                          />
                        )}

                        {/* <BgThemeButton
                          onClick={() =>
                            handleShowAllSettlement(
                              property_id?.id,
                              checkOutBookingDetail.id
                            )
                          }
                          children={"Show All Settlement"}
                        /> */}
                      </>
                    ) : (
                      <>
                        <BgThemeButton
                          onClick={() => {
                            setShowCheckOutSettlementDetails(true);
                            setShowCheckOutBookingDetails(false);
                            setBookingId(
                              checkOutBookingDetail?.unique_booking_id
                            );
                          }}
                          children={"Settlement"}
                        />
                        <BgThemeButtonDisabled children={"Check-Out"} />
                        {/* <BgThemeButton
                          onClick={() =>
                            handleShowAllSettlement(
                              property_id?.id,
                              checkOutBookingDetail.id
                            )
                          }
                          children={"Show All Settlement"}
                        /> */}
                      </>
                    )}
                  </>
                )}
              </div>
              {checkOutBookingDetail &&
              totalBalanceAmount < 1 &&
              checkOutBookingDetail?.bill_generated ? (
                <div className="flex justify_content_between align_items_center">
                  <div className="flex flex_gap_10">
                    <WithoutBgButtonBlue
                      style={{ height: "30px", marginTop: "10px" }}
                      onClick={() => handleSubmitGenerateBillHandle(true)}
                      children={"Re-Generate Bill"}
                      loader={loader}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="18"
                          viewBox="0 0 12 18"
                          fill="none"
                        >
                          <path
                            d="M0.825 12.0375C0.55 11.5625 0.34375 11.075 0.20625 10.575C0.06875 10.075 0 9.5625 0 9.0375C0 7.3625 0.58125 5.9375 1.74375 4.7625C2.90625 3.5875 4.325 3 6 3H6.13125L4.93125 1.8L5.98125 0.75L8.98125 3.75L5.98125 6.75L4.93125 5.7L6.13125 4.5H6C4.75 4.5 3.6875 4.94063 2.8125 5.82188C1.9375 6.70313 1.5 7.775 1.5 9.0375C1.5 9.3625 1.5375 9.68125 1.6125 9.99375C1.6875 10.3062 1.8 10.6125 1.95 10.9125L0.825 12.0375ZM6.01875 17.25L3.01875 14.25L6.01875 11.25L7.06875 12.3L5.86875 13.5H6C7.25 13.5 8.3125 13.0594 9.1875 12.1781C10.0625 11.2969 10.5 10.225 10.5 8.9625C10.5 8.6375 10.4625 8.31875 10.3875 8.00625C10.3125 7.69375 10.2 7.3875 10.05 7.0875L11.175 5.9625C11.45 6.4375 11.6562 6.925 11.7938 7.425C11.9313 7.925 12 8.4375 12 8.9625C12 10.6375 11.4187 12.0625 10.2563 13.2375C9.09375 14.4125 7.675 15 6 15H5.86875L7.06875 16.2L6.01875 17.25Z"
                            fill="#3968ED"
                          />
                        </svg>
                      }
                    >
                      Re-Generate Bill
                    </WithoutBgButtonBlue>
                    <WithoutBgButtonBlue
                      style={{ height: "30px", marginTop: "10px" }}
                      onClick={() => [
                        window.open(
                          `/bookings/folio-bills/${checkOutBookingDetail?.id}/${checkOutBookingDetail?.folioId}`,
                          "_blank"
                        ),
                        localStorage.removeItem("selectedFolioValue", ""),
                        localStorage.removeItem("selectedFolioLabel", ""),
                      ]}
                      children={"Bill Details"}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <mask
                            id="mask0_8958_3368"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="18"
                            height="18"
                          >
                            <rect width="18" height="18" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_8958_3368)">
                            <path
                              d="M3.75 14.25H4.81875L12.15 6.91875L11.0813 5.85L3.75 13.1812V14.25ZM2.25 15.75V12.5625L12.15 2.68125C12.3 2.54375 12.4656 2.4375 12.6469 2.3625C12.8281 2.2875 13.0187 2.25 13.2188 2.25C13.4188 2.25 13.6125 2.2875 13.8 2.3625C13.9875 2.4375 14.15 2.55 14.2875 2.7L15.3188 3.75C15.4688 3.8875 15.5781 4.05 15.6469 4.2375C15.7156 4.425 15.75 4.6125 15.75 4.8C15.75 5 15.7156 5.19062 15.6469 5.37187C15.5781 5.55312 15.4688 5.71875 15.3188 5.86875L5.4375 15.75H2.25ZM11.6063 6.39375L11.0813 5.85L12.15 6.91875L11.6063 6.39375Z"
                              fill="#3968ED"
                            />
                          </g>
                        </svg>
                      }
                      loader={loader}
                    >
                      Bill Details
                    </WithoutBgButtonBlue>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      onClick={() => [
                        getBillIframe(),
                        // setShowCheckOutBookingDetails(false),
                      ]}
                      className="pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75Z"
                          fill="#3968ED"
                        />
                        <path
                          d="M13.5361 6.85125C13.0215 5.52022 12.1282 4.36914 10.9665 3.54029C9.80489 2.71143 8.42584 2.24113 6.99981 2.1875C5.57379 2.24113 4.19473 2.71143 3.03309 3.54029C1.87145 4.36914 0.978134 5.52022 0.463564 6.85125C0.428812 6.94737 0.428812 7.05263 0.463564 7.14875C0.978134 8.47978 1.87145 9.63086 3.03309 10.4597C4.19473 11.2886 5.57379 11.7589 6.99981 11.8125C8.42584 11.7589 9.80489 11.2886 10.9665 10.4597C12.1282 9.63086 13.0215 8.47978 13.5361 7.14875C13.5708 7.05263 13.5708 6.94737 13.5361 6.85125ZM6.99981 9.84375C6.43737 9.84375 5.88756 9.67697 5.41991 9.36449C4.95226 9.05202 4.58777 8.60788 4.37253 8.08826C4.15729 7.56863 4.10098 6.99685 4.21071 6.44521C4.32043 5.89358 4.59127 5.38687 4.98898 4.98917C5.38668 4.59146 5.89339 4.32062 6.44503 4.21089C6.99666 4.10117 7.56844 4.15748 8.08807 4.37272C8.6077 4.58795 9.05183 4.95245 9.36431 5.4201C9.67678 5.88775 9.84356 6.43756 9.84356 7C9.84241 7.75385 9.54243 8.4765 9.00937 9.00956C8.47631 9.54261 7.75367 9.84259 6.99981 9.84375Z"
                          fill="#3968ED"
                        />
                      </svg>
                    </div>

                    {/* <BgThemeButton
                      style={{ height: "30px" }}
                      onClick={handlePrintButtonClick}
                      children={"Print"}
                    /> */}

                    <div
                      onClick={() => getBillIframeDownload()}
                      className="pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
                          fill="#3968ED"
                        />
                      </svg>
                    </div>

                    <PrintFolioRoomBill
                      folioId={checkOutBookingDetail?.folioId}
                      htmlContent={iframeContent}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {checkOutBookingDetail && totalBalanceAmount < 1 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <WithoutBgButtonBlue
                        style={{ height: "30px", marginTop: "10px" }}
                        onClick={() => handleSubmitGenerateBillHandle(true)}
                        children={"Re-Generate Bill"}
                        loader={loader}
                      >
                        Generate Bill
                      </WithoutBgButtonBlue>
                    </div>
                  )}
                </>
              )}
            </>
            {/* )} */}
          </div>
        </div>
      )}

      <>
        {showBillPreview && (
          <div
            style={{ zIndex: "999" }}
            className="editFolioSummeryBillContainer"
            onClick={() => setShowBillPreview(false)}
          >
            {/* <BillUi
                downloadPdf={downloadPdf}
                viewFolioBillsData={viewFolioBillsData}
              /> */}
            <IFrameBillUi
              downloadPdf={downloadPdf}
              // viewFolioBillsData={viewFolioBillsData}
              iframeContent={iframeContent}
            />
            {/* <NewIframe
                folioData={folioData?.id}
                htmlContent={iframeContent}
              /> */}
            {/* <ViewFolioRoomBill
                folioData={folioData?.id}
                htmlContent={iframeContent}
              /> */}
            {/* <PopupPreview htmlContent={htmlContent} onClose={handleClosePreview} /> */}
          </div>
        )}

        <iframe
          srcDoc={iframeContent}
          id="receipt"
          style={{ display: "none" }}
          title="Receipt"
        />
      </>

      {/* Settlement */}
      {/* {showCheckOutSettlementDetails && (
<SettlementPopup
bookingId={bookingId}
settlementDataFromComponent={settlementDataFromComponent}
handleSubmitAddMeals={handleSubmitAddMeals}
balanceAmount={checkOutBookingDetail?.balance_amount}
totalRoomsAmount={checkOutBookingDetail?.balance_amount}
setShowCheckOutSettlementDetails={setShowCheckOutSettlementDetails}
totalHousekeepingAmount={
checkOutBookingDetail?.total_housekeeping_data?.total_balance_amount
}
totalFAndBAmount={
checkOutBookingDetail?.total_of_all_kots?.total_balance_amount
}
totalMiscAmount={
checkOutBookingDetail?.total_misc_data?.total_balance_amount
}
totalRoomServiceAmount={
checkOutBookingDetail?.total_room_service_data?.total_balance_amount
}
/>
)} */}
      {showCheckOutSettlementDetails && (
        <>
        {/* <div className="bg-backdrop">
          <form
            onSubmit={checkOutSettlementHandleFolio}
            style={{ width: "600px" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="add-ota-model-container-heading">
                Add Payments
              </div>
              <div
                onClick={() => [
                  setShowCheckOutSettlementDetails(false),
                  setAddChargesData({}),
                ]}
                style={{ cursor: "pointer" }}
              >
                <Icon source={CancelMajor} color="base" />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  focused={false}
                  name="date"
                  type="date"
                  titleName="Date"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.date || currentDate}
                />
              </div>
              <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  options={paymentTypeOptions}
                  type="text"
                  required={true}
                  focused={false}
                  name="payment_type"
                  titleName="Payment Type"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.payment_type}
                />
              </div>
              <div className="form-container-div">
                {addChargesData.payment_type === "cash" && (
                  <VerticalInputSelectCustomCopy
                    options={paymentModeOptions}
                    type="text"
                    required={true}
                    focused={false}
                    name="payment_mode"
                    titleName="Payment Mode"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.payment_mode}
                  />
                )}
                {addChargesData.payment_type === "city_ledger" && (
                  <VerticalInputSelectCustomCopy
                    options={agentListOptions}
                    type="text"
                    required={true}
                    focused={false}
                    name="company_name"
                    titleName="Company Name"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.company_name}
                  />
                )}
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  focused={false}
                  name="notes"
                  titleName="Notes"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.notes}
                />
              </div>
              <div className="form-container-div">
                {agentCreditLimit < 1 &&
                addChargesData.payment_type === "city_ledger" ? (
                  <VerticalInputFieldCustom
                    
                    required={true}
                    focused={false}
                    disabled={true}
                    name="amount"
                    titleName="Amount"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.amount}
                  />
                ) : (
                  <VerticalInputFieldCustom
                    
                    required={true}
                    focused={false}
                    
                    name="amount"
                    titleName="Amount"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.amount}
                  />
                )}
              </div>
            </div>
            {addChargesData.payment_type === "city_ledger" && (
              <div className="form-container-div-full">
                <>
                  Balance Credit Limit:
                  <span>
                    {agentCreditLimit
                      ? formatIndianCurrency(agentCreditLimit)
                      : formatIndianCurrency(agentCreditLimit)}
                  </span>
                </>
              </div>
            )}

            <div className=" flex">
              <div className="w-half">
                Pending Balance:
               
                <span>
                  {formatIndianCurrency(
                    roundedValue ? roundedValue : totalBalanceAmount
                  )}
                </span>
              </div>

              {isDecimal && (
                <div className="w-half flex flex_gap_10">
                  <div className="">
                    <div>Round Off</div>
                  </div>
                  <div style={{ height: "28px" }}>
                    <RoundOffNumber
                      totalAmount={totalBalanceAmount}
                      onRoundOffChange={handleRoundOffChange}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex_gap_10">
              {Number(roundedValue ? roundedValue : totalBalanceAmount) ===
              Number(addChargesData?.amount) ? (
                <BgThemeButton children={"Add"} type={"submit"} />
              ) : (
                <BgThemeButtonDisabled children={"Add"} />
              )}

              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => [
                  setShowCheckOutSettlementDetails(false),
                  setAddChargesData({}),
                ]}
              />
            </div>
          </form>
        </div> */}
        <AddMultiplePaymentsPopup
          propertyCurrentDate={propertyCurrentDate}
          setShow={setShowCheckOutSettlementDetails}
          checkout={true}
          folios={allFolios}
          balanceAmount={allFolios.length > 0 &&
            allFolios.reduce(
              (prev, curr, index, array) =>
                +prev + +curr.balance_amount,
              0
            )}
          bookingId={checkOutBookingDetail.id}
          uniqueBookingId={checkOutBookingDetail?.unique_booking_id}
          propertyId={property_id?.id}
          refreshPageApi={submitCheckOutBookingDataWithBookingId}
        
        />
        </>
        
      )}
      {nextDayPopup && (
        <div className="bg-backdrop">
          <div className="dashboard_checkin_modal_container">
            <div
              className="dashboard_checkin_modal_close_btn"
              style={{ cursor: "pointer" }}
              onClick={() => setNextDayPopup(false)}
            >
              close
            </div>
            <div className="dashboard_checkin_modal_box">
              <div className="dashboard_checkin_modal_content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 25C0 38.785 11.215 50 25 50C38.785 50 50 38.785 50 25C50 11.215 38.785 0 25 0C11.215 0 0 11.215 0 25ZM38.0178 20.5178C38.9941 19.5415 38.9941 17.9585 38.0178 16.9822C37.0415 16.0059 35.4585 16.0059 34.4822 16.9822L22.5 28.9645L16.7678 23.2322C15.7915 22.2559 14.2085 22.2559 13.2322 23.2322C12.2559 24.2085 12.2559 25.7915 13.2322 26.7678L20.7322 34.2678C21.7085 35.2441 23.2915 35.2441 24.2678 34.2678L38.0178 20.5178Z"
                    fill="#15AA12"
                  />
                </svg>
                <h4>Switch to Next Day</h4>
                <p>
                  Congratulations on completing the night audit! It's time to
                  transition to the next day. Please click the button below to
                  proceed.
                </p>
                <div className="dashboard_checkin_modal_btn">
                  <BgThemeButton
                    loader={loader}
                    onClick={() => startNextDayHandle()}
                    children={"Switch to Next Day"}
                  />
                </div>
                <span className="theme_color block"></span>
              </div>
            </div>
          </div>
        </div>
      )}
      {kotSettlementPopup && (
        <>
          <div className="bg-backdrop">
            <form
              onSubmit={(e) => settlementHandleKOT(e)}
              style={{ width: "700px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Settlement For KOT
                </div>
                <div
                  onClick={() => (
                    setShowAddModelMenuActive(false),
                    setKotSettlementPopup(false),
                    setKotSettlementData({})
                  )}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <div style={{ marginBottom: "5px" }}>
                <strong>Balance Amount</strong> :{" "}
                {formatIndianCurrency(selectedKot?.balance_amount)}
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    required={true}
                    options={paymentModeOptions}
                    titleName="Payment Mode"
                    name="payment_mode"
                    value={kotSettlementData.payment_mode}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Amount"
                    name="amount"
                    // type="number"
                    value={kotSettlementData.amount}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  {Number(kotSettlementData.amount) ===
                  Number(selectedKot?.balance_amount) ? (
                    <BgThemeButton
                      //  onClick={(e) => handleSubmitAddRooms(e)}
                      type="submit"
                      children={"Add"}
                    />
                  ) : (
                    <BgThemeButtonDisabled
                      //  onClick={(e) => handleSubmitAddRooms(e)}
                      type="submit"
                      children={"Add"}
                    />
                  )}

                  <Button
                    onClick={() => [
                      setKotSettlementPopup(false),
                      setKotSettlementData({}),
                    ]}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      {folioSettlementPopup && (
        <div className="bg-backdrop">
          <form
            onSubmit={settlementHandleFolio}
            style={{ width: "600px" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="add-ota-model-container-heading">
                Add Payments
              </div>
              <div
                onClick={() => setFolioSettlementPopup(!folioSettlementPopup)}
                style={{ cursor: "pointer" }}
              >
                <Icon source={CancelMajor} color="base" />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  focused={false}
                  name="date"
                  type="date"
                  titleName="Date"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.date || currentDate}
                />
              </div>
              <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  options={paymentTypeOptions}
                  type="text"
                  required={true}
                  focused={false}
                  name="payment_type"
                  titleName="Payment Type"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.payment_type}
                />
              </div>
              <div className="form-container-div">
                {addChargesData.payment_type === "cash" && (
                  <VerticalInputSelectCustomCopy
                    options={paymentModeOptions}
                    type="text"
                    required={true}
                    focused={false}
                    name="payment_mode"
                    titleName="Payment Mode"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.payment_mode}
                  />
                )}
                {addChargesData.payment_type === "city_ledger" && (
                  <VerticalInputSelectCustomCopy
                    options={agentListOptions}
                    type="text"
                    required={true}
                    focused={false}
                    name="company_name"
                    titleName="Company Name"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.company_name}
                  />
                )}
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  focused={false}
                  name="notes"
                  titleName="Notes"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.notes}
                />
              </div>
              <div className="form-container-div">
                {agentCreditLimit < 1 &&
                addChargesData.payment_type === "city_ledger" ? (
                  <VerticalInputFieldCustom
                    type="number"
                    required={true}
                    focused={false}
                    disabled={true}
                    name="amount"
                    titleName="Amount"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.amount}
                  />
                ) : (
                  <VerticalInputFieldCustom
                    // type="number"
                    required={true}
                    focused={false}
                    // disabled={true}
                    name="amount"
                    titleName="Amount"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.amount}
                  />
                )}
              </div>
            </div>
            {/* {addChargesData.payment_type === "city_ledger" && (
<div className="form-container-div-full">
<>
  Balance Credit Limit:
  <span>
    {agentCreditLimit
      ? formatIndianCurrency(agentCreditLimit)
      : formatIndianCurrency(agentCreditLimit)}
  </span>
</>
</div>
)} */}

            <div className="form-container-div-full">
              <>
                Pending Balance:
                <span>
                  {formatIndianCurrency(selectedFolio?.balance_amount)}
                </span>
              </>
            </div>

            <div className="flex flex_gap_10">
              {Number(selectedFolio?.balance_amount) ===
              Number(addChargesData.amount) ? (
                <BgThemeButton children={"Add"} type={"submit"} />
              ) : (
                <BgThemeButtonDisabled children={"Add"} />
              )}

              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => setFolioSettlementPopup(!folioSettlementPopup)}
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};
export default NightAudit;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stay_reservation_views } from "../../assets/constant";
import React from "react";
import "./reservationView.css";
import { calculateToDateMinus, currentDateSingle } from "../../utils/utils";
import { showErrorToast } from "../../assets/toastUtils";
import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";
import { Link, useLocation } from "react-router-dom";
import ReservationTooltip from "./ReservationTooltip";
import Loader from "../UI/Loader/Loader";
import NoDataFound from "../CustomComponents/NoDataFound";
import { getDecryptedData } from "../../utils/encryptStorage";
import SidebarInnerListWithMultiplePermissions from "../CustomComponents/SidebarInnerListWithMultiplePermissions";
import { SvgLeftArrow, SvgRightArrow } from "../../assets/svgIcons";

const ReservationViewNew = () => {
  const getDateByMonthWeekName = (dateStr) => {
    const date = new Date(dateStr);

    // Get day, month, and weekday in required format
    const day = date.toLocaleDateString("en-GB", { day: "2-digit" });
    const month = date.toLocaleDateString("en-GB", { month: "short" });
    const weekday = date
      .toLocaleDateString("en-GB", { weekday: "short" })
      .toUpperCase();

    return `${day} ${month} ${weekday}`;
  };

  const location = useLocation();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const [data, setData] = useState([]);
  const fetchInfo = async (id, date, filterType) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/view/fetch/${id}/reservation-view?currentDate=${date}&filterType=${filterType}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(
        property_id?.id,
        calculateToDateMinus(currentDateSingle, 7),
        "next"
      );
    }
  }, [property_id]);

  const [bookingData, setBookingData] = useState({
    dates: [],
    all_bookings: [],
  });

  // const countConfirmed = bookings.filter(booking => booking.status === "confirmed").length;
  const flatMap = bookingData?.all_bookings?.flatMap((item) =>
    item.category_bookings.filter((booking) => booking.status === "confirmed")
  );

  // console.log(flatMap);
  useEffect(() => {
    const mapBookings = () => {
      if (!data || !Array.isArray(data) || data.length === 0) {
        return [];
      }

      let mappedBookings = [];

      data.forEach((room) => {
        const { room_category, bookings } = room;

        if (!bookings || !Array.isArray(bookings) || bookings.length === 0) {
          return;
        }

        const categoryBookings = [];
        const uniqueBookingIds = new Set(); // To track unique booking IDs

        bookings.forEach((booking) => {
          const { booking_date, bookings: innerBookings } = booking;

          if (
            !innerBookings ||
            !Array.isArray(innerBookings) ||
            innerBookings.length === 0
          ) {
            return;
          }

          innerBookings.forEach((innerBooking) => {
            const {
              booking_id,
              unique_booking_id,
              check_in_date,
              check_out_date,
              status,
              booking_guest,
            } = innerBooking;

            if (
              !check_in_date ||
              !check_out_date ||
              uniqueBookingIds.has(unique_booking_id)
            ) {
              return;
            }

            uniqueBookingIds.add(unique_booking_id); // Add to the set to ensure uniqueness

            categoryBookings.push({
              booking_date: booking_date,
              booking_guest: booking_guest,
              booking_id: booking_id,
              check_in: check_in_date,
              check_out: check_out_date,
              room_type: room_category,
              status: status,
              unique_booking_id: unique_booking_id,
            });
          });
        });

        if (categoryBookings.length > 0) {
          mappedBookings.push({
            room_category: room_category,
            category_bookings: categoryBookings,
          });
        }
      });

      return mappedBookings;
    };

    const getDateRange = (data) => {
      if (!data || !Array.isArray(data) || data.length === 0) {
        return [];
      }

      let dates = [];

      data.forEach((room) => {
        const { bookings } = room;

        if (!bookings || !Array.isArray(bookings) || bookings.length === 0) {
          return;
        }

        bookings.forEach((booking) => {
          const { booking_date } = booking;

          if (!dates.includes(booking_date)) {
            dates.push(booking_date);
          }
        });
      });

      return dates;
    };

    const mappedData = {
      all_bookings: mapBookings(),
      dates: getDateRange(data),
    };

    setBookingData(mappedData);
  }, [data]);

  const [masterFolioDetails, setMasterFolioDetails] = useState(null);

  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);

  const [chargesDetails, setChargesDetails] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);

  const getSingleBookingDataHandler = (bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          setLoader(false);
          setBookingDetails(data?.booking);
          setChargesDetails(data?.charges);
          setMasterFolioDetails(data?.masterFolio);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  //======================my code handlers============================

  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    setExpandedCategories(
      bookingData.all_bookings.reduce((acc, category) => {
        acc[category.room_category] = true; // By default, all categories are expanded
        return acc;
      }, {})
    );
  }, [bookingData]);
  // status colors
  const getStatusColor = (status) => {
    switch (status) {
      case "hold":
        return "#BD941B";
      case "confirmed":
        return "#3968ED";
      case "checked_in":
        return "#15AA12";
      case "cancelled":
        return "#E03838";
      default:
        return "#ff8040";
    }
  };

  // Function to create a row for bookings
  // const createBookingRow = (bookings, allDates, index) => {
  //   const tr = Array(allDates.length).fill(<td></td>);
  //   bookings.forEach((booking) => {
  //     const checkInIndex = allDates.indexOf(booking.check_in);
  //     const checkOutIndex = allDates.indexOf(booking.check_out);

  //     if (checkInIndex !== -1) {
  //       const colSpan =
  //         checkOutIndex === -1
  //           ? allDates.length - checkInIndex
  //           : checkOutIndex - checkInIndex + 1;
  //       const td = (
  //         <td
  //           colSpan={colSpan}
  //           key={`${booking.unique_booking_id}-${booking.check_in}`}
  //           style={{
  //             paddingLeft: allDates[0] <= booking.check_in ? "25px" : "0px",
  //             paddingRight:
  //               allDates[allDates.length - 1] <= booking.check_out
  //                 ? "0px"
  //                 : "25px",
  //           }}
  //         >
  //           <div
  //             className="unique_id_paragraph_bar"
  //             style={{
  //               background: getStatusColor(booking.status),
  //             }}
  //             onClick={() =>
  //               getSingleBookingDataHandler(booking.unique_booking_id)
  //             }
  //           >
  //             <span className="reservationViewGuestTruncated">
  //               {booking.booking_guest.length > 20
  //                 ? `${booking.booking_guest.slice(0, 20)}...`
  //                 : booking.booking_guest}
  //             </span>

  //             <ReservationTooltip booking={booking} />
  //           </div>
  //         </td>
  //       );

  //       // Insert the booking cell into the appropriate position
  //       tr[checkInIndex] = td;

  //       // Replace spanned-over cells with null
  //       for (let i = checkInIndex + 1; i < checkInIndex + colSpan; i++) {
  //         tr[i] = null;
  //       }
  //     } else if (checkInIndex === -1) {
  //       // Calculate colSpan to include the check-out date
  //       const colSpan = checkOutIndex - 0 + 1;
  //       // console.log(colSpan);

  //       // console.log(allDates[index]);

  //       // Create a cell that spans the booking duration
  //       const td = (
  //         <td
  //           colSpan={colSpan}
  //           key={`${booking.unique_booking_id}-${booking.check_in}`}
  //           style={{
  //             paddingLeft: allDates[0] <= booking.check_in ? "25px" : "0px",
  //             paddingRight:
  //               allDates[allDates.length - 1] <= booking.check_out
  //                 ? "0px"
  //                 : "25px",
  //           }}
  //         >
  //           <div
  //             className="unique_id_paragraph_bar"
  //             style={{
  //               background: getStatusColor(booking.status),
  //             }}
  //             onClick={() =>
  //               getSingleBookingDataHandler(booking.unique_booking_id)
  //             }
  //           >
  //             <span className="reservationViewGuestTruncated">
  //               {booking.booking_guest.length > 20
  //                 ? `${booking.booking_guest.slice(0, 20)}...`
  //                 : booking.booking_guest}
  //             </span>

  //             <ReservationTooltip booking={booking} />
  //           </div>
  //         </td>
  //       );

  //       // Insert the booking cell into the appropriate position
  //       tr[0] = td;

  //       // Replace spanned-over cells with null
  //       for (let i = 0 + 1; i < 0 + colSpan; i++) {
  //         tr[i] = null;
  //       }
  //     }
  //   });

  //   return (
  //     <tr
  //       key={bookings.map((b) => b.booking_id).join("-")}
  //       style={
  //         index % 2 !== 0
  //           ? { background: "white", borderColor: "white" }
  //           : { background: "#F5F5F5", borderColor: "#F5F5F5" }
  //       }
  //     >
  //       <td></td> {/* Empty cell at the start */}
  //       {tr.filter((cell) => cell !== null)}
  //       <td></td> {/* Empty cell at the end */}
  //     </tr>
  //   );
  // };

  

  // Process bookings to ensure no overlap
  // const processBookings = (categoryBookings) => {
  //   const rows = [];

  //   categoryBookings.forEach((booking) => {
  //     let placed = false;

  //     for (const row of rows) {
  //       let overlap = false;
  //       for (const b of row) {
  //         if (
  //           (b.check_in < booking.check_out &&
  //             b.check_out > booking.check_in) ||
  //           b.check_out === booking.check_in
  //         ) {
  //           overlap = true;
  //           break;
  //         }
  //       }
  //       if (!overlap) {
  //         row.push(booking);
  //         placed = true;
  //         break;
  //       }
  //     }

  //     if (!placed) {
  //       rows.push([booking]);
  //     }
  //   });

  //   return rows;
  // };
  const createBookingRow = (bookings, allDates, index) => {
    const normalizedDates = allDates.map((date) =>
      new Date(date).toISOString().split("T")[0]
    );
    const tr = Array(allDates.length).fill(<td></td>);
  
    bookings.forEach((booking) => {
      const checkInDate = new Date(booking.check_in).toISOString().split("T")[0];
      const checkOutDate = new Date(booking.check_out).toISOString().split("T")[0];
  
      const checkInIndex = normalizedDates.indexOf(checkInDate);
      const checkOutIndex = normalizedDates.indexOf(checkOutDate);
  
      if (checkInIndex !== -1) {
        const colSpan =
          checkOutIndex === -1
            ? allDates.length - checkInIndex
            : checkOutIndex - checkInIndex + 1;
  
        const td = (
          <td
            colSpan={colSpan}
            key={`${booking.unique_booking_id}-${booking.check_in}`}
            style={{
              paddingLeft: checkInIndex === new Date(allDates[0]).toISOString().split("T")[0]  ? "0px" : "25px",
              paddingRight:
                checkOutIndex ===  new Date(allDates.length - 1).toISOString().split("T")[0] ? "0px" : "25px",
            }}
          >
            <div
              className="unique_id_paragraph_bar"
              style={{
                background: getStatusColor(booking.status),
              }}
              onClick={() =>
                getSingleBookingDataHandler(booking.unique_booking_id)
              }
            >
              <span className="reservationViewGuestTruncated">
                {booking.booking_guest.length > 20
                  ? `${booking.booking_guest.slice(0, 20)}...`
                  : booking.booking_guest}
              </span>
              <ReservationTooltip booking={booking} />
            </div>
          </td>
        );
  
        tr[checkInIndex] = td;
  
        for (let i = checkInIndex + 1; i < checkInIndex + colSpan; i++) {
          tr[i] = null;
        }
      }
    });
  
    return (
      <tr
        key={bookings.map((b) => b.booking_id).join("-")}
        style={
          index % 2 !== 0
            ? { background: "white", borderColor: "white" }
            : { background: "#F5F5F5", borderColor: "#F5F5F5" }
        }
      >
        <td></td> {/* Empty cell at the start */}
        {tr.filter((cell) => cell !== null)}
        <td></td> {/* Empty cell at the end */}
      </tr>
    );
  };
  
  // const processBookings = (categoryBookings) => {
  //   const rows = [];
  
  //   categoryBookings.forEach((booking) => {
  //     let placed = false;
  
  //     for (const row of rows) {
  //       let overlap = false;
  //       for (const b of row) {
  //         if (
  //           (b.check_in < booking.check_out &&
  //             b.check_out > booking.check_in) ||
  //           b.check_out === booking.check_in
  //         ) {
  //           overlap = true;
  //           break;
  //         }
  //       }
  //       if (!overlap) {
  //         row.push(booking);
  //         placed = true;
  //         break;
  //       }
  //     }
  
  //     if (!placed) {
  //       rows.push([booking]);
  //     }
  //   });
  
  //   return rows;
  // };

  // Toggle expand/collapse for a category
 
 const processBookings = (categoryBookings, allDates) => {
  const rows = [];

  const isDateInRange = (date) => allDates.includes(date);

  categoryBookings.forEach((booking) => {
    let placed = false;

    // Skip bookings completely outside the date range
    if (
      !isDateInRange(booking.check_in) &&
      !isDateInRange(booking.check_out) &&
      booking.check_out < allDates[0]
    ) {
      return;
    }

    for (const row of rows) {
      const hasOverlap = row.some(
        (existingBooking) =>
          (existingBooking.check_in < booking.check_out &&
            existingBooking.check_out > booking.check_in) ||
          existingBooking.check_out === booking.check_in
      );

      if (!hasOverlap) {
        row.push(booking);
        placed = true;
        break;
      }
    }

    if (!placed) {
      rows.push([booking]);
    }
  });

  return rows;
};
 
  const toggleExpandCollapse = (category) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  // api calling function for buttons nest and previous

  const handleNextClick = async () => {
    await fetchInfo(
      property_id?.id,
      bookingData?.dates[bookingData?.dates.length - 1],
      "next"
    );
  };

  const handlePreviousClick = async () => {
    await fetchInfo(property_id?.id, bookingData?.dates[0], "previous");
  };

  const handleTableHeaderDateClick = async (date) => {
    await fetchInfo(property_id?.id, date, "next");
  };
  
  console.log("bookingData", bookingData);
 
  return (
    <>
      {/* <div
        style={{ display: "flex", zIndex: 20, justifyContent: "space-between" }}
        className="footer_inner_lists"
      >
        <ul className="flex flex_gap_10">
          {permissions &&
            stay_reservation_views &&
            stay_reservation_views?.map((item, index) => (
              <React.Fragment key={index}>
                {permissions && permissions.includes(item.permission) && (
                  <li style={{ listStyle: "none" }}>
                    <Link
                      to={item.link}
                      className={`inner_list_item ${
                        location.pathname === item.link ? "active" : ""
                      }`}
                      key={index}
                    >
                      {item.name}
                    </Link>
                  </li>
                )}
              </React.Fragment>
            ))}
        </ul>
        
      </div> */}
      <div style={{ position: "relative" }}>
        <SidebarInnerListWithMultiplePermissions
          innerLists={stay_reservation_views}
        />
        <div
          className="flex flex_gap_15"
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            zIndex: "20",
          }}
        >
          <button
            className="reservation_view_next_prev_button"
            onClick={handlePreviousClick}
          >
           <SvgLeftArrow/>
          </button>

          <button
            className="reservation_view_next_prev_button"
            onClick={handleNextClick}
          >
            <SvgRightArrow/>
          </button>
        </div>
      </div>

      <div className="booking-choice-full-parent-container-right-heading-new">
        <div
          style={{
            width: "100%",
            backgroundColor: "#EFF0F2",
            marginTop: "10px",
          }}
        >
          <div className="reservation_bar_container" style={{ width: "100%" }}>
            <div className="reservation_total">
              <div className="reservation_total confirmed">
                <h5 className="reservation_total_heading">
                  {bookingData?.all_bookings?.flatMap((item) =>
                    item.category_bookings.filter(
                      (booking) => booking.status === "checked_in"
                    )
                  ).length || 0}
                </h5>
                <p className="reservation_total_paragraph">Checked-In</p>
              </div>
              <div className="reservation_total confirmed">
                <h5 className="reservation_total_heading">
                  {bookingData?.all_bookings?.flatMap((item) =>
                    item.category_bookings.filter(
                      (booking) => booking.status === "confirmed"
                    )
                  ).length || 0}
                </h5>
                <p className="reservation_total_paragraph">Confirmed</p>
              </div>
              <div className="reservation_total pending confirmed">
                <h5 className="reservation_total_heading">
                  {/* {bookingOuterDetails?.pending_bookings} */}{" "}
                  {bookingData?.all_bookings?.flatMap((item) =>
                    item.category_bookings.filter(
                      (booking) => booking.status === "hold"
                    )
                  ).length || 0}
                </h5>
                <p className="reservation_total_paragraph">Pending</p>
              </div>
              <div className="reservation_total pending confirmed">
                <h5 className="reservation_total_heading">
                  {/* {bookingOuterDetails?.pending_bookings} */}{" "}
                  {bookingData?.all_bookings?.flatMap((item) =>
                    item.category_bookings.filter(
                      (booking) => booking.status === "cancelled"
                    )
                  ).length || 0}
                </h5>
                <p className="reservation_total_paragraph">Cancelled</p>
              </div>
              <div className="reservation_total pending confirmed">
                <h5 className="reservation_total_heading">
                  {/* {bookingOuterDetails?.pending_bookings} */}{" "}
                  {bookingData?.all_bookings?.flatMap((item) =>
                    item.category_bookings.filter(
                      (booking) => booking.status === "checked_out"
                    )
                  ).length || 0}
                </h5>
                <p className="reservation_total_paragraph">Checked-Out</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container-stay-view" style={{ marginTop: "10px" }}>
        {/* adi code */}

        {/* ==========my code start==================== */}

        {loader ? (
          <Loader />
        ) : (
          <div className="reservation_view_container overflowContainer ">
            <table className="booking-table">
              <thead>
                <tr>
                  <th className="reservation_date_headers   button_align_right"></th>
                  {bookingData?.dates?.map((date) => (
                    <th key={date} className="reservation_date_headers">
                      <div
                        onClick={() => handleTableHeaderDateClick(date)}
                        className={
                          new Date(date)?.toDateString() ===
                          new Date()?.toDateString()
                            ? "reservation_date_container_current_date"
                            : "reservation_date_container"
                        }
                      >
                        {getDateByMonthWeekName(date)}
                      </div>
                    </th>
                  ))}
                  <th className="reservation_date_headers button_align_left"></th>
                </tr>
              </thead>
              {bookingData &&
              bookingData.all_bookings &&
              bookingData.all_bookings.length > 0 ? (
                <tbody>
                  {bookingData?.all_bookings?.map((category) => (
                    <React.Fragment key={category?.room_category}>
                      {/* Room Category Row */}
                      <tr>
                        <td
                          colSpan={bookingData?.dates.length + 2}
                          className="room-category"
                        >
                          <button
                            className="reservation_view_room-category-button flex flex_gap_5 align_center"
                            onClick={() =>
                              toggleExpandCollapse(category?.room_category)
                            }
                          >
                            {expandedCategories[category?.room_category] ? (
                              <img
                                src="/reservationViewButtonImages/reservationViewCollapse.svg"
                                alt="arrow"
                                className="arrow"
                              />
                            ) : (
                              <img
                                src="/reservationViewButtonImages/reservationViewExpand.svg"
                                alt="arrow"
                                className="arrow"
                              />
                            )}

                            <span> {category.room_category}</span>
                          </button>
                        </td>
                      </tr>
                      {/* Booking Rows */}
                      {expandedCategories[category.room_category] &&
                        processBookings(category.category_bookings ,bookingData?.dates).map(
                          (bookings, index) =>
                            createBookingRow(bookings, bookingData.dates, index)
                        )}
                    </React.Fragment>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <td colSpan={bookingData?.dates.length + 2}>
                    <NoDataFound />
                  </td>
                </tbody>
              )}
            </table>
          </div>
        )}

        {/* ========================my code end============================ */}

        {openSingleBookingDetails && (
          <BookingDetailSliderNew
            data={bookingDetails}
            chargesDetails={chargesDetails}
            masterFolioDetails={masterFolioDetails}
            setShowBookingDetails={setOpenSingleBookingDetails}
            getSingleBookingDataHandler={getSingleBookingDataHandler}
          />
        )}
      </div>
    </>
  );
};
export default ReservationViewNew;

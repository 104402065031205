import React, { useState, useEffect } from "react";
import styles from "./uploadComponent.module.scss";
import {
  FaPlus,
  FaTrashAlt,
  FaEye,
  FaClose,
  FaPdf,
  FaCsv,
  FaDoc,
  FaFileAlt,
} from "./icons/uploadIcons";

const UploadFileComponent = ({
  fileType = "image",
  defaultFileName = "",
  defaultFile = null,
  onFileChange, // callback to send data to the parent
  width,
  deleteImageApiCall,
}) => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);

  // Update the internal state when new defaults come from the parent
  useEffect(() => {
    if (!file) {
      setFile(defaultFile);
      setFileName(defaultFileName);
    }
  }, [defaultFile, defaultFileName]);

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFileName(uploadedFile.name);
      if (fileType === "image") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFile(reader.result);
          onFileChange && onFileChange(uploadedFile, uploadedFile.name); // Send the file and name back to parent
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        setFile(uploadedFile);
        onFileChange && onFileChange(uploadedFile, uploadedFile.name); // Send the file and name back to parent
      }
    }
  };

  const handleDelete = () => {
    if (deleteImageApiCall) {
      deleteImageApiCall();
      setFile(null);
      setFileName("");
      onFileChange && onFileChange(null, "");
      return;
    } else {
      setFile(null);
      setFileName("");
      onFileChange && onFileChange(null, ""); // Inform the parent that the file was deleted
      return;
    }
  };

  const handlePreview = () => {
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const getFileIcon = () => {
    switch (fileType) {
      case "pdf":
        return <FaPdf />;
      case "csv":
        return <FaCsv />;
      case "doc":
        return <FaDoc />;
      case "txt":
        return <FaFileAlt />;
      default:
        return <FaFileAlt />;
    }
  };

  if (!fileType) return null;

  return (
    <>
      <div
        className="flex flex_direction_col align_items_center"
        style={{ width: width ? width : "160px", height: "fit-content" }}
      >
        <div className={styles.container}>
          {!file ? (
            <div className={styles.uploadContainer}>
              <label htmlFor="upload" className={styles.uploadLabel}>
                <FaPlus className={styles.icon} />
                <p>Upload</p>
                <input
                  id="upload"
                  type="file"
                  accept={
                    fileType === "image"
                      ? "image/jpeg, image/png"
                      : fileType === "pdf"
                      ? "application/pdf"
                      : fileType === "csv"
                      ? ".csv"
                      : fileType === "doc"
                      ? ".odt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      : fileType === "txt"
                      ? "text/plain"
                      : "*/*"
                  }
                  onChange={handleFileChange}
                  className={styles.uploadInput}
                />
              </label>
            </div>
          ) : (
            <>
              <div className={styles.imageContainer}>
                {fileType === "image" ? (
                  <img
                    src={file}
                    alt="Uploaded preview"
                    className={styles.imagePreview}
                    height={"100%"}
                    width={"100%"}
                  />
                ) : (
                  <div className={styles.fileIcon}>{getFileIcon()}</div>
                )}

                <div className={styles.buttonContainerOverlay}>
                  <button onClick={handleDelete}>
                    <FaTrashAlt />
                  </button>
                  {fileType === "image" && (
                    <button onClick={handlePreview}>
                      <FaEye />
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        {!file ? (
          <p className={styles.uploadingText}>
            {fileName
              ? fileName
              : `or drop ${
                  fileType.toUpperCase() === "IMAGE"
                    ? "JPG, PNG"
                    : fileType.toUpperCase()
                } file here`}
          </p>
        ) : (
          <p className={styles.uploadingText}>{fileName}</p>
        )}
      </div>

      {fileType === "image" && previewOpen && (
        <div className={styles.previewPopup} onClick={handleClosePreview}>
          <div className={styles.previewCloseButton}>
            <FaClose />
          </div>
          <img src={file} alt="Image preview" className={styles.previewImage} />
        </div>
      )}
    </>
  );
};

export default UploadFileComponent;
import React, { useEffect, useState } from "react";
import { SvgCancel } from "../../../../assets/svgIcons";
import { PrimaryInput } from "../../../NewCustomComp/Inputs/Inputs";
import { SelectBasicWithSelectTag } from "../../../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { Textarea } from "../../../NewCustomComp/Testarea/Textarea";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../NewCustomComp/Buttons/Buttons";
import { showErrorToast, showSuccessToast } from "../../../../assets/toastUtils";
import { calculateTotal } from "../../../CustomComponents/ModifyReservationPopup/calculationFunctions/CalculateKeyTotalFromArrayOfObjects";

const EditDiscountPopup = ({
  selectedKot,
  setEditDiscountPopup,
  property_id,
  userToken,
  getOneData
}) => {
  const [discountDetails, setDiscountDetails] = useState({
    discountRate: "",
    discountAmount: "",
    discountType: "",
    subTotal: "",
    taxAmount: "",
    totalAmt: "",
    balanceAmt: "",
    additional_discount_reason: "",
  });

  useEffect(() => {
    const subTotal = calculateTotal(selectedKot.items, "item_price");
    const discountedSubTotal = subTotal;
    const taxAmount = (+discountedSubTotal * +selectedKot.tax_rate) / 100;
    const totalAmt = +discountedSubTotal + +taxAmount;

    setDiscountDetails({
      discountRate: 0,
      discountAmount: 0,
      discountType: +selectedKot.discount_rate ? "percent" : "fixed",
      subTotal,
      taxRate: selectedKot.tax_rate,
      taxAmount,
      totalAmt,
    });
  }, [selectedKot]);

  const handleDiscountTypeChange = (e) => {
    const subTotal = calculateTotal(selectedKot.items, "item_price");
    const taxAmount = (+subTotal * +selectedKot.tax_rate) / 100;
    setDiscountDetails({
      ...discountDetails,
      discountType: e.target.value,
      discountAmount: 0,
      discountRate: 0,
      subTotal: subTotal,
      taxRate: selectedKot.tax_rate,
      taxAmount: taxAmount,
      totalAmt: +subTotal + +taxAmount,
    });
  };

  const handleInputChange = (key, value) => {
    if (key === "additional_discount_reason") {
      setDiscountDetails({
        ...discountDetails,
        [key]: value,
      });
      return;
    } else {
      const updatedDetails = { ...discountDetails, [key]: value };
      const subTotal = calculateTotal(selectedKot.items, "item_price");
      const discountValue =
        updatedDetails.discountType === "percent"
          ? (+updatedDetails.discountRate / 100) * +selectedKot.total_amount
          : +updatedDetails.discountAmount;
      const newSubTotal = subTotal - discountValue;
      const newTaxAmount = (+newSubTotal * +selectedKot.tax_rate) / 100;
      const newTotalAmt = newSubTotal + newTaxAmount;
     
      setDiscountDetails({
        ...updatedDetails,
        discountAmount: discountValue,
        subTotal: newSubTotal,
        taxAmount: newTaxAmount,
        totalAmt: newTotalAmt,
      });
    }
  };

  const postDiscountData = async () => {
    const {
      discountRate,
      discountAmount,
      discountType,
      subTotal,
      taxAmount,
      totalAmt,
      additional_discount_reason,
    } = discountDetails;

    const bodyData = {
      id: selectedKot.id,
      discount_amount: discountAmount,
      discount_rate: discountRate,
      discount_type: discountType,
      tax_amount: taxAmount,
      subTotal: subTotal,
      totalAmt: totalAmt,
      balanceAmt: +totalAmt - +selectedKot.paid_amount,
      additional_discount_reason: additional_discount_reason,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/update/discount/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(bodyData),
        }
      );
      const data = await response.json();

      if (data.success) {
        showSuccessToast(data.message);
        handlePopupClose();
        getOneData(selectedKot.id);
      } else{
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error updating discount:", error);
      showErrorToast("Error updating discount");
    }
  };

  const handlePopupClose = () => {
    setEditDiscountPopup(false);
    setDiscountDetails({
      discountRate: "",
      discountAmount: "",
      discountType: "",
      subTotal: "",
      taxAmount: "",
      totalAmt: "",
      balanceAmt: "",
      additional_discount_reason: "",
    });
  };

  return (
    <div className="bg-backdrop">
      <div className="global_popup_container" style={{ width: "500px" }}>
        <div className="flex justify_content_between mb-10">
          <h3 className="add-ota-model-container-heading">Edit Discount</h3>
          <span onClick={handlePopupClose}>
            <SvgCancel color={"#333"} width={"20px"} height={"20px"} />
          </span>
        </div>
        <form>
          {/* Form fields */}
          <div className="flex flex_gap_10 mb-10">
            <div className="w-50">
              <SelectBasicWithSelectTag
                size={"medium"}
                label={"Discount Type:"}
                name="discountType"
                onChange={handleDiscountTypeChange}
                value={discountDetails.discountType}
                options={[
                  { label: "Fixed", value: "fixed" },
                  { label: "Percent", value: "percent" },
                ]}
              />
            </div>
            <div className="w-50">
              <PrimaryInput
                size={"medium"}
                label={
                  discountDetails.discountType === "fixed"
                    ? "Discount Amount:"
                    : "Discount Rate:"
                }
                type="number"
                value={
                  discountDetails.discountType === "fixed"
                    ? discountDetails.discountAmount
                    : discountDetails.discountRate
                }
                onChange={(e) =>
                  handleInputChange(
                    discountDetails.discountType === "fixed"
                      ? "discountAmount"
                      : "discountRate",
                    e.target.value || 0
                  )
                }
              />
            </div>
          </div>
          {/* Read-only fields */}
          <div className="flex flex_gap_10 mb-10">
            <div className="w-50">
              <PrimaryInput
                disabled={true}
                size={"medium"}
                label={"Sub Total:"}
                type="text"
                value={discountDetails?.subTotal}
              />
            </div>
            <div className="w-50">
              <PrimaryInput
                disabled={true}
                size={"medium"}
                label={"Tax Rate:"}
                type="text"
                value={selectedKot?.tax_rate}
              />
            </div>
          </div>

          <div className="flex flex_gap_10 mb-10">
            <div className="w-50">
              <PrimaryInput
                disabled={true}
                size={"medium"}
                label={"Tax Amount:"}
                type="text"
                value={discountDetails?.taxAmount}
              />
            </div>
            <div className="w-50">
              <PrimaryInput
                disabled={true}
                size={"medium"}
                label={"Total Amount:"}
                type="text"
                value={discountDetails?.totalAmt}
              />
            </div>
          </div>
          <div className="flex flex_gap_10 mb-10">
            <div className="w-100">
              <Textarea
                size={"medium"}
                label={"Reason:"}
                type="text"
                value={discountDetails?.additional_discount_reason}
                onChange={(e) =>
                  handleInputChange(
                    "additional_discount_reason",
                    e.target.value
                  )
                }
              />
            </div>
          </div>

          <div className="flex flex_gap_10 ">
            <PrimaryButton
              size="medium"
              type="button"
              onClick={postDiscountData}
              className="btn-submit"
              title={"Save"}
            />

            <SecondaryButton
              size="medium"
              type="button"
              onClick={handlePopupClose}
              className="btn-cancel"
              title={" Cancel"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDiscountPopup;

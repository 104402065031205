import { useNavigate, useParams } from "react-router-dom";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import "./Folio.css";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { useEffect, useRef, useState } from "react";
import DashboardFilter from "../Dashboard/DashboardFiltersComponent/DashboardFilter";

import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Icon,
} from "@shopify/polaris";
import { CancelMajor } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import HandleNaN from "../CustomComponents/HandleNaN/HandleNaN";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import FolioTopBarandNavigation from "./FolioTopBarandNavigation";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import BottomChargeAmounts from "./BottomChargeAmounts";
import Loader from "../UI/Loader/Loader";
import CustomCountryStateSelector from "../CustomComponents/CustomCountryStateSelector";
import { getDecryptedData } from "../../utils/encryptStorage";
import { SvgPlusWithoutBorder } from "../../assets/svgIcons";

const FolioGuestDetails = () => {
  const navigate = useNavigate();
  const clickOutSideRef = useRef(null);

  const [propertyLogoLoader, setPropertyLogoLoader] = useState(false);
  const [fetchedPropertyLogo, setFetchedPropertyLogo] = useState([]);

  const [editBillTo, setEditBillTo] = useState(false);
  const [showFolioFeature, setShowFolioFeature] = useState(false);
  const [createNewFolio, setCreateNewFolio] = useState(false);
  const [addGuestPopup, setAddGuestPopup] = useState(false);
  const [createNewFolioPopup, setCreateNewFolioPopup] = useState(false);
  const [addChargesPopup, setAddChargesPopup] = useState(false);

  const [chargesList, setChargesList] = useState([]);
  const [settlements, setSettlements] = useState([]);
  const [folioSummery, setFolioSummery] = useState([]);
  const [folioData, setFolioData] = useState([]);
  const [folioBookings, setFolioBookings] = useState([]);
  const [allFolioArray, setAllFolioArray] = useState([]);

  const [guest, setGuest] = useState([]);
  const [roomDetails, setRoomDetails] = useState([]);
  const { folioId, folioUniqueId } = useParams();

  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);
  const userToken = getDecryptedData("encryptToken");

  const folioFilterOptions = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "mtd",
      label: "MTD",
    },
    {
      value: "qtd",
      label: "QTD",
    },
    {
      value: "ytd",
      label: "YTD",
    },
    {
      value: "future 1 month",
      label: "Future 1 Month",
    },
    {
      value: "future 3 months",
      label: "Future 3 Months",
    },
  ];

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    { label: "Other", value: "other" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "8%", value: "8" },
    { label: "12%", value: "12" },
    { label: "15%", value: "15" },
  ];

  const [addChargesData, setAddChargesData] = useState({
    charge_type: "",
    description: "",
    rate: "",
    quantity: "",
    net_total: "",
    subtotal: "",
    discount_amount: "",
    gst_type: "inclusive",
    discount_rate: "",
    tax_rate: "",
    tax_amount: "",
  });

  const [guestDetail, setGuestDetail] = useState({
    property_id: "",
    booking_id: "",
    folio_id: "",
    folio_no: "",
    is_primary: "",
    room_no: "",
    guest_name: "",
    guest_email: "",
    guest_phone: "",
    guest_city: "",
    guest_state: "",
    guest_country: "",
    guest_dob: "",
    guest_address: "",
    guest_pincode: "",
    identity_proof_type: null,
    other_proof_type: "",
    identity_proof_no: "",
  });

  const handleSelectCountryState = (data) => {
    setGuestDetail((prevState) => ({
      ...prevState,
      guest_country: data.country,
      guest_state: data.state,
    }));
  };
  const setAddItemDataHandleChange = (event) => {
    setGuestDetail({
      ...guestDetail,
      [event.target.name]: event.target.value,
    });
  };

  const [selectedDateFilterValue, setSelectedDateFilterValue] = useState("MTD");
  const [dayTypeFilter, setDayTypeFilter] = useState("");

  const handleSelect = (label, value) => {
    setSelectedDateFilterValue(label);
    setDayTypeFilter(value);
  };

  useEffect(() => {
    // Convert input values to numbers
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    // Apply GST
    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);
    }

    let totalAmount = priceAfterDiscount + gstAmount;

    setAddChargesData({
      ...addChargesData,
      subtotal: subtotal.toFixed(2),
      net_total: totalAmount.toFixed(2),
      discount_amount: discountAmount.toFixed(2),
      tax_amount: gstAmount.toFixed(2),
    });
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  const setAddChargesHandleChange = (event) => {
    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchFolioInfo = async (propertyId, selectedFolioId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/folios/${folioId}/${propertyId}?folio_id=${
          selectedFolioId ? selectedFolioId : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setFolioData(data?.data);
      setChargesList(data?.data?.charges);
      setSettlements(data?.data?.payments);
      setFolioSummery(data?.data?.folio_summary);
      setGuest(data?.data?.guest);
      setRoomDetails(data?.data?.roomDetails);
      setFolioBookings(data?.data?.booking);
      setLoader(false);
      setActiveRoom(data?.data?.roomDetails[0]);
      setGuestDetail(data?.data?.roomDetails[0].guest_details[0]);
      setSelectedGuestArray(data?.data?.roomDetails[0].guest_details);

      setAllFolioArray(data?.allFolios);
    } catch (error) {
      setLoader(false);
    }
  };

  const [roomDetailsArray, setRoomDetailsArray] = useState([]);

  useEffect(() => {
    const roomDetailsArray = Object.keys(roomDetails).map((key) => {
      return {
        roomNo: key,
        guest_details: roomDetails[key],
      };
    });

    setRoomDetailsArray(roomDetails);
    const roomDetailsFiltered = [...roomDetails].sort(
      (a, b) => b.guest_details.length - a.guest_details.length
    );

    // console.log(roomDetailsFiltered[0]?.guest_details);

    if (roomDetailsFiltered.length > 0) {
      // setActiveRoom(roomDetailsFiltered[0]);
      // setSelectedGuestArray(roomDetailsFiltered[0]?.guest_details);
      // setSelectedGuest(roomDetailsFiltered[0]?.guest_details[0]);
    }

    fetchPropertyLogo(
      property_id?.id,
      roomDetailsFiltered[0]?.guest_details[0]?.id
    );
  }, [roomDetails]);

  useEffect(() => {
    if (property_id) {
      fetchFolioInfo(property_id?.id, folioUniqueId);
    }
  }, [property_id]);

  const handleSubmitCreateFolio = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/charges/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addChargesData, folio_id: folioData.id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesData(false);
          fetchFolioInfo(property_id?.id, folioUniqueId);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleClickOutside = (event) => {
    if (
      clickOutSideRef.current &&
      !clickOutSideRef.current.contains(event.target)
    ) {
      setShowFolioFeature(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogoFileUploadChange = (e) => {
    e.stopPropagation();
    setUploadedLogoFile(e.target.files[0]);
  };

  const [fetchedImages, setFetchedImages] = useState([]);

  const [uploadedLogoFile, setUploadedLogoFile] = useState(null);

  const fetchPropertyImages = (property_id) => {
    const formData = new FormData();
    formData.append("media_type", "property");
    formData.append("associated_id", property_id);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/store/identity/proof/${property_id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setFetchedImages(data?.media?.identity_proof);
      });
  };

  const deleteFetchedImages = async (propertyImage) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/delete/${propertyImage}/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          fetchPropertyImages(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const addPropertyLogo = () => {
    setPropertyLogoLoader(true);
    const formData = new FormData();
    // formData.append("media_type", "identity_proof");
    formData.append("associated_id", selectedGuest?.id);
    formData.append("identity_proof", uploadedLogoFile);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/store/identity/proof/${property_id.id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setPropertyLogoLoader(false);
        } else {
          setPropertyLogoLoader(false);
          showErrorToast("Something Went Wrong.. Please try again...");
        }
      });
  };

  const deletePropertyLogo = async (propertyImage) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/delete/${propertyImage}/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          fetchPropertyLogo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [activeRoom, setActiveRoom] = useState(null);
  const [selectedGuestArray, setSelectedGuestArray] = useState([]);

  const [selectedGuest, setSelectedGuest] = useState([]);
  // console.log(guestDetail);
  const activeRoomHandler = (room) => {
   
    setActiveRoom(room);
    setAddGuestButton(false);
    setSelectedGuestArray(room?.guest_details);
    setSelectedGuest();
    if (room?.guest_details?.length > 0) {
      setGuestDetail(room?.guest_details[0]);
      return
    } else {
      setGuestDetail({
        property_id: "",
        booking_id: "",
        folio_id: "",
        folio_no: "",
        is_primary: "",
        room_no: "",
        guest_name: "",
        guest_email: "",
        guest_phone: "",
        guest_city: "",
        guest_state: "",
        guest_country: "",
        guest_dob: "",
        guest_address: "",
        guest_pincode: "",
        identity_proof_type: null,
        other_proof_type: "",
        identity_proof_no: "",
      })
      return
    }
    

  };

  const [addGuestButton, setAddGuestButton] = useState(false);

  const activeGuestHandler = (room) => {
    // console.log(room);
    setSelectedGuest(room);
    setAddGuestButton(false);
    setGuestDetail(room);
  };

  const addGuestClickHandler = () => {
    if (folioData?.booking?.status === "checked_out") {
      showErrorToast("Can't add guest in checked out booking");
      return;
    }

    if (activeRoom?.guest_details?.length >= activeRoom?.guest_limit) {
      showErrorToast(
        `Can't add more than ${activeRoom?.guest_limit} guests in ${activeRoom?.roomNo}`
      );
      return;
    }
    setSelectedGuest(null);
    setAddGuestButton(true);
    setGuestDetail({
      guest_name: "",
      age: "",
      gender: "",
      property_id: "",
      booking_id: "",
      folio_id: "",
      folio_no: "",
      is_primary: "",
      room_no: "",
      guest_name: "",
      guest_email: "",
      guest_phone: "",
      guest_city: "",
      guest_state: "",
      guest_country: "",
      guest_dob: "",
      guest_address: "",
      guest_pincode: "",
      identity_proof_type: "",
      identity_proof_no: "",
    });
  };

  const addGuestHandler = (event) => {
    event.preventDefault();

    if (!folioData?.folio_no) {
      showErrorToast("Please generate the Folio Series in settings");
      return;
    }

    fetch(
      // add/folios/guests/{property_id}’, ‘addPropertyCustomers
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/guests/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...guestDetail,
          folio_id: folioData.id,
          room_no: activeRoom?.roomNo,
          is_primary: 0,
          folio_no: folioData?.folio_no,
          booking_id: folioData?.booking_id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesData(false);
          fetchFolioInfo(property_id?.id, folioUniqueId);
          //   window.location.reload()
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const updateGuestHandler = (event) => {
    event.preventDefault();
    // console.log(guestDetail);
    if (!folioData?.folio_no) {
      showErrorToast("Please generate the Folio Series in settings");
      return;
    }

    if (guestDetail?.identity_proof_type === "other") {
      if (!guestDetail.other_proof_type) {
        showErrorToast("Please enter other proof name");
        return;
      }
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/update/guest/${selectedGuest?.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...guestDetail,
          folio_id: folioData.id,
          room_no: activeRoom?.roomNo,
          is_primary: 0,
          folio_no: folioData?.folio_no,
          booking_id: folioData?.booking_id,
          other_proof_type:
            guestDetail?.identity_proof_type === "other"
              ? guestDetail?.other_proof_type
              : null,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesData(false);
          fetchFolioInfo(property_id?.id, folioUniqueId);
          //   window.location.reload()
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const roomNumberArray = [
    {
      label: "Select option",
      value: null,
    },
    ...(roomDetails?.map((room) => ({
      label: room?.roomNo?.toString(),
      value: room?.roomNo?.toString(),
    })) || []),
  ];
  const guestsArray = [
    {
      label: "Select option",
      value: null,
    },
    ...(roomDetails?.flatMap(
      (room) =>
        room?.guest_details?.map((guest) => ({
          label: guest?.guest_name,
          value: guest?.id?.toString(),
        })) || []
    ) || []),
  ];

  const fetchPropertyLogo = (property_id, guestId) => {
    const formData = new FormData();
    formData.append("media_type", "identity_proof");
    formData.append("associated_id", guestId);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/fetch/identity/proof/${guestId}/${property_id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setFetchedPropertyLogo(data?.identity_proof);
      });
  };

  const dataFromChild = (id) => {
    fetchFolioInfo(property_id?.id, id);
    navigate(`/bookings/folio/${folioId}/${id}`);
    // const currentUrl = window.location.href;
    // console.log(currentUrl);
    // const parts = currentUrl.split('/');
    // parts[parts.length - 1] = folioId; // Replace the last part with '38'
    // const newUrl = parts.join('/');
    // console.log(newUrl);
    // // window.location.replace(newUrl);
  };

  const [chargeAddedTransactions, setChargeAddedTransactions] = useState({
    discountAmountSum: "",
    subTotalSum: "",
    taxAmountSum: "",
    grandTotalSum: "",
    paidAmountSum: "",
    dueAmountSum: "",
  });

  useEffect(() => {
    const discountAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.discount_amount || 0),
      0
    );
    const subTotalSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.sub_total),
      0
    );
    const taxAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.tax_amount),
      0
    );
    const grandTotalSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.net_total),
      0
    );
    const paidAmountSum = folioData?.payments?.reduce(
      (sum, transaction) => sum + parseFloat(transaction.amount || 0),
      0
    );
    const dueAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.due_amount || 0),
      0
    );

    const transactions = {
      discountAmountSum,
      subTotalSum,
      taxAmountSum,
      grandTotalSum,
      paidAmountSum,
      dueAmountSum,
    };
    setChargeAddedTransactions(transactions);
  }, [chargesList]);

  const roomDetailsFiltered = [...roomDetails].sort(
    (a, b) => b.guest_details.length - a.guest_details.length
  );

  console.log("selectedGuestArray",guestDetail);

  return (
    <>
      <FolioTopBarandNavigation
        guestsArray={guestsArray}
        roomNumberArray={roomNumberArray}
        // handleSelect={handleSelect}
        // fetchFolioInfoParent = {fetchFolioInfo(property_id?.id)}
        dataFromChild={dataFromChild}
      />

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="folio_guest_details_div flex flex_gap_20">
            <div
              className="folio_guest_details_div_left"
              style={{ width: "15%" }}
            >
              <div className="folio_summery flex flex_gap_20 align_items_center justify_content_center mt-10">
                <div className="folio_summery_child" style={{ width: "100%" }}>
                  <div className="folio_summery_child_top flex justify_content_between align_items_center mb-10">
                    <h3>Rooms</h3>
                  </div>
                  {roomDetailsFiltered?.length > 0 ? (
                    <>
                      {/* {roomDetailsFiltered?.map((room) => {
                        return (
                          <div
                            className={
                              activeRoom?.roomNo === room?.roomNo
                                ? "active_room active"
                                : "active_room"
                            }
                            onClick={() => activeRoomHandler(room)}
                          >
                            {room?.roomNo}
                          </div>
                        );
                      })} */}

                      <div
                        className={
                          roomDetails.length > 1 ? "folio_room_wrapper" : ""
                        }
                      >
                        <>
                          {roomDetails.map((room) => {
                            return (
                              <div
                                style={{ cursor: "pointer" }}
                                className={`${
                                  activeRoom?.roomNo === room?.roomNo
                                    ? "active_room active"
                                    : "active_room"
                                } folio_rooms`}
                                onClick={() => activeRoomHandler(room)}
                              >
                                <div className="folio_room_number">
                                  {room?.roomNo}
                                </div>
                                <div className="folio_room_guest">
                                  {/* XY Guests */}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      </div>
                    </>
                  ) : (
                    <div>No rooms found</div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-10 folio_guest_details_wrapper">
              <div className="folio_guest_details">
                <h3>Guests</h3>

                <div
                  className={`booking-first-row-head-divs ${
                    addGuestButton ? "active booking-first-row-head-divs " : ""
                  } folio_add_button mt-10 mb-10`}
                  onClick={() => addGuestClickHandler()}
                >
                  <SvgPlusWithoutBorder />
                  Add
                </div>

                {selectedGuestArray &&
                  selectedGuestArray?.map((guest) => {
                    return (
                      <div
                        className={`booking-first-row-head-divs folio_add_guest_name mt-5 ${
                          guestDetail?.id === guest.id ? "active" : ""
                        }`}
                        onClick={() => activeGuestHandler(guest)}
                      >
                        {guest.guest_name}
                      </div>
                    );
                  })}
              </div>
            </div>

            <div
              className="folio_guest_details_div_right w-100 mt-10"
              style={{ width: "70%" }}
            >
              <div className="folio_guest_details_div_right_child folio_guest_form">
                <h3 className="folio_guest_details_div_right_child_title">
                  Guests Details
                </h3>
                {/* <div
                  className="flex flex_gap_10"
                  style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
                >
                  {selectedGuestArray &&
                    selectedGuestArray?.map((guest) => {
                      return (
                        <div
                          className={`booking-first-row-head-divs ${
                            guestDetail?.id === guest.id ? "active" : ""
                          }`}
                          onClick={() => activeGuestHandler(guest)}
                        >
                          {guest.guest_name}
                        </div>
                      );
                    })}
                  <div
                    className={`booking-first-row-head-divs ${
                      addGuestButton
                        ? "active booking-first-row-head-divs "
                        : ""
                    }`}
                    onClick={() => addGuestClickHandler()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1270_978)">
                        <path
                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                          stroke="#3968ED"
                        />
                        <path
                          d="M10.5866 7H3.41333"
                          stroke="#3968ED"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M7 3.41344L7 10.5867"
                          stroke="#3968ED"
                          strokeMiterlimit="10"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1270_978">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div> */}

                <form
                  onSubmit={
                    addGuestButton ? addGuestHandler : updateGuestHandler
                  }
                >
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Name"
                        name="guest_name"
                        value={guestDetail?.guest_name}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"number"}
                        titleName="Phone"
                        name="guest_phone"
                        value={guestDetail?.guest_phone}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        // required={true}
                        type={"email"}
                        titleName="Email"
                        name="guest_email"
                        value={guestDetail?.guest_email}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    {/* <div className="form-container-div">
                      <VerticalInputFieldCustom
                        // required={true}
                        titleName="State"
                        name="guest_state"
                        value={guestDetail.guest_state}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        // required={true}
                        titleName="Country"
                        name="guest_country"
                        value={guestDetail.guest_country}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div> */}
                    <CustomCountryStateSelector
                      getCountryState={handleSelectCountryState}
                      defaultCountry={guestDetail?.guest_country}
                      defaultState={guestDetail?.guest_state}
                    />
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        // required={true}
                        titleName="City"
                        name="guest_city"
                        value={guestDetail?.guest_city}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        // required={true}
                        type={"number"}
                        titleName="PIN Code / ZIP Code"
                        name="guest_pincode"
                        value={guestDetail?.guest_pincode}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        // required={true}
                        options={proofOptions}
                        name="identity_proof_type"
                        focused={false}
                        titleName="Customer Identity"
                        value={guestDetail?.identity_proof_type}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    {guestDetail?.identity_proof_type === "other" && (
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          // required={true}
                          options={proofOptions}
                          name="other_proof_type"
                          focused={false}
                          titleName="Enter Name"
                          value={guestDetail?.other_proof_type}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                    )}

                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        // required={true}
                        type={"text"}
                        name="identity_proof_no"
                        focused={false}
                        titleName="Identity No."
                        value={guestDetail?.identity_proof_no}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  {guestDetail && (
                    <div
                      className="folio_guest_details_div_right_child"
                      style={{ padding: "10px" }}
                    >
                      <h3 className="folio_guest_details_div_right_child_title">
                        Upload ID
                      </h3>
                      <div className="flex justify_content_center">
                        <div
                          className="status_sec"
                          style={{
                            justifyContent: "center",
                            width: "50%",
                            padding: "0px",
                          }}
                        >
                          {/* <h3>Property Logo</h3> */}
                          <div
                            className="edit-rooms-form-wrapper-container"
                            style={{ width: "100%" }}
                          >
                            {propertyLogoLoader ? (
                              <LoaderSmall />
                            ) : (
                              <div style={{ position: "relative" }}>
                                {fetchedPropertyLogo ? (
                                  <>
                                    <div className="logoPreview_container">
                                      <div style={{ width: "100%" }}>
                                        <img
                                          style={{ objectFit: "contain" }}
                                          src={fetchedPropertyLogo.src}
                                          alt="Property Logo"
                                          height="200px"
                                          width="100%"
                                        />
                                      </div>
                                      <div className="featureImages_btnContainer">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            deletePropertyLogo(
                                              fetchedPropertyLogo.id
                                            )
                                          }
                                          className="featureImages_removeLogobtn"
                                        >
                                          <svg
                                            xmlns="http:www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 8 8"
                                            fill="none"
                                          >
                                            <path
                                              d="M1 6.94752L7 1"
                                              stroke="black"
                                            />
                                            <path
                                              d="M7 6.94775L1 1.00023"
                                              stroke="black"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {uploadedLogoFile ? (
                                      <>
                                        <div className="logoPreview_container">
                                          <div style={{ width: "100%" }}>
                                            <img
                                              src={
                                                uploadedLogoFile instanceof File
                                                  ? URL.createObjectURL(
                                                      uploadedLogoFile
                                                    )
                                                  : uploadedLogoFile.src
                                              }
                                              alt="Blog Image"
                                              height="200px"
                                              width="100%"
                                            />
                                          </div>
                                          <div className="featureImages_btnContainer">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                deleteFetchedImages(
                                                  uploadedLogoFile.id
                                                )
                                              }
                                              className="featureImages_removeLogobtn"
                                            >
                                              <svg
                                                xmlns="http:www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 8 8"
                                                fill="none"
                                              >
                                                <path
                                                  d="M1 6.94752L7 1"
                                                  stroke="black"
                                                />
                                                <path
                                                  d="M7 6.94775L1 1.00023"
                                                  stroke="black"
                                                />
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="featureImages_uplaodFile_displaytext_container">
                                        <p className="featured_image_text">
                                          Add Files
                                        </p>
                                        <p className="featured_image_text_dragdrop">
                                          or drop JPG, PNG images here
                                        </p>
                                        <input
                                          accept="image/*"
                                          type="file"
                                          id="select-image"
                                          onChange={handleLogoFileUploadChange}
                                          className="featureImages_upload_file_input"
                                        />
                                      </div>
                                    )}
                                    {uploadedLogoFile && (
                                      <BgThemeButton
                                        onClick={addPropertyLogo}
                                        style={{ marginTop: "10px" }}
                                      >
                                        Upload
                                      </BgThemeButton>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <BgThemeButton children={"Update"} /> */}
                    </div>
                  )}

                  <div className="flex justify_content_center">
                    {addGuestButton ? (
                      <BgThemeButton children={"Add"} type={"submit"} />
                    ) : (
                      <>
                        {folioData?.booking?.status === "checked_out" ? (
                          <BgThemeButtonDisabled
                            children={"Update"}
                            type={"submit"}
                            onClick={updateGuestHandler}
                          />
                        ) : (
                          <BgThemeButton children={"Update"} type={"submit"} />
                        )}
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <BottomChargeAmounts
            chargesList={chargesList}
            folioData={folioData}
          />

          {addGuestPopup && (
            <div className="bg-backdrop">
              <form
                onSubmit={(e) => e.preventDefault()}
                style={{ width: "600px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">
                    Add Guest
                  </div>
                  <div
                    onClick={() => setAddGuestPopup(!addGuestPopup)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Name"
                      name="name"
                      // value={addItemData.floor_no}
                      // onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Phone"
                      name="phone"
                      // value={addItemData.floor_no}
                      // onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>
                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Email"
                      name="name"
                      // value={addItemData.floor_no}
                      // onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Guest Id"
                      name="phone"
                      // value={addItemData.floor_no}
                      // onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div className="flex flex_gap_10">
                  <BgThemeButton children={"Add"} type={"submit"} />
                  <BgOutlineThemeButton
                    children={"Cancel"}
                    type={"button"}
                    onClick={() => setAddGuestPopup(!addGuestPopup)}
                  />
                </div>
              </form>
            </div>
          )}
          {createNewFolioPopup && (
            <div className="bg-backdrop">
              <form
                onSubmit={(e) => e.preventDefault()}
                style={{ width: "600px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">
                    Create New Folio
                  </div>
                  <div
                    onClick={() => setCreateNewFolio(!createNewFolioPopup)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Name"
                      name="name"
                      // value={addItemData.floor_no}
                      // onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Phone"
                      name="phone"
                      // value={addItemData.floor_no}
                      // onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>
                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Email"
                      name="name"
                      // value={addItemData.floor_no}
                      // onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Guest Id"
                      name="phone"
                      // value={addItemData.floor_no}
                      // onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div className="flex flex_gap_10">
                  <BgThemeButton children={"Add"} type={"submit"} />
                  <BgOutlineThemeButton
                    children={"Cancel"}
                    type={"button"}
                    onClick={() => setCreateNewFolio(!createNewFolioPopup)}
                  />
                </div>
              </form>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FolioGuestDetails;

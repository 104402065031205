import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Checkbox,
  Page,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link, useNavigate } from "react-router-dom";
import AddCategoryAgent from "./UI/AddCategoryAgent";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import AddCategoryItem from "./UI/AddCategoryItem";
import AddDepartment from "./UI/AddDepartment";
import AddUnits from "./UI/AddUnits";
import { useSelector } from "react-redux";
import AddItems from "./UI/AddItems";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import NoDataFound from "./CustomComponents/NoDataFound";
import Loader from "./UI/Loader/Loader";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { pms_inner_lists } from "../assets/constant";
import CustomPagination from "./CustomComponents/CustomPagination/CustomPagination";
import { getDecryptedData } from "../utils/encryptStorage";
import SidebarInnerListWithMultiplePermissions from "./CustomComponents/SidebarInnerListWithMultiplePermissions";
import { capitalizeFirstLetter } from "../utils/utils";

const Items = () => {
  const permissions = localStorage.getItem("permissions");
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState([]);

  const [allOrders, setAllOrders] = useState([]);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders.slice(startIndex, endIndex);

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showAddDepartmentMenuActive, setShowAddDepartmentMenuActive] =
    useState(false);
  const [showAddUnitsMenuActive, setShowAddUnitsMenuActive] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);
  const [showAddItemsActive, setShowAddItemsActive] = useState(false);

  const taxSlabOptions = [
    { label: "5%", value: "5" },
    { label: "10%", value: "10" },
    { label: "18%", value: "18" },
    { label: "25%", value: "25" },
  ];

  const [itemsData, setItemsData] = useState({
    item_name: "",
    unit_name: "",
    category_name: "",
    hsn_code: "",
    department_name: "",
    tax_slab: "",
  });

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const departmentArray = [
    { department_name: "All", id: "all" },
    ...departments.map((department) => {
      return { department_name: department.department_name, id: department.id };
    }),
  ];

  const fetchChannelInfo = async (channelId, propertyId, index) => {
    setUniqueChannelId(channelId);
    // setLoader(true);
  };

  const [uniqueChannelId, setUniqueChannelId] = useState("all");

  useEffect(() => {
    setUniqueChannelId(uniqueChannelId);
    if (uniqueChannelId === "all") {
      setOrders(allOrders);
    } else {
      const copyOrders = [...orders];
      const filteredOrders = allOrders.filter(
        (order) => order.department_id === uniqueChannelId
      );
      setOrders(filteredOrders);
    }
  }, [uniqueChannelId]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const rowMarkup = currentOrders?.map(
    ({
      id,
      item_name,
      category_name,
      unit_name,
      department_name,
      tax_slab,
      hsn_code,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{item_name}</IndexTable.Cell>
        <IndexTable.Cell>{category_name}</IndexTable.Cell>
        <IndexTable.Cell>{department_name}</IndexTable.Cell>
        <IndexTable.Cell>{unit_name}</IndexTable.Cell>
        <IndexTable.Cell>{tax_slab}</IndexTable.Cell>
        <IndexTable.Cell>{hsn_code}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const setItemsDataHandleChange = (event) => {
    setItemsData({
      ...itemsData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.Item);

      setAllOrders(data.Item);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchUnits = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unit/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setUnits(data.Unit);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/category/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setCategories(data.department);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchDepartment = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/department/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setDepartments(data.Department);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchUnits(property_id?.id);
      fetchCategories(property_id?.id);
      fetchDepartment(property_id?.id);
    }
  }, [property_id]);

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/items/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        department_name: data.category.department_name,
        item_name: data.category.item_name,
        unit_name: data.category.unit_name,
        category_name: data.category.category_name,
        hsn_code: data.category.hsn_code,
        tax_slab: data.category.tax_slab,
      };
      setItemsData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateItem = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/items/update/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...itemsData }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowEditModelMenuActive(false);
        clearSelection();
        showSuccessToast(data.message);
        setItemsData({
          item_name: "",
          unit_name: "",
          category_name: "",
          hsn_code: "",
          department_name: "",
          tax_slab: "",
        });
        // window.location.reload()
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    // clearSelection();
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/items/delete/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIds),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowLowerMenuMultipleSelect(false);
        setShowModelMenuActive(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  return (
    <>
      <SidebarInnerListWithMultiplePermissions innerLists={pms_inner_lists} />

      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Items</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div>
              {permissions &&
              permissions?.includes("full_access_stores_management") ? (
                <BgOutlineThemeButton
                  children={"Units"}
                  onClick={() =>
                    setShowAddUnitsMenuActive(!showAddUnitsMenuActive)
                  }
                />
              ) : (
                <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                  <BgOutlineThemeButton children={"Units"} disabled />
                </Tooltip>
              )}
            </div>

            <div>
              {permissions &&
              permissions?.includes("full_access_stores_management") ? (
                <BgOutlineThemeButton
                  children={"Departments"}
                  onClick={() =>
                    setShowAddDepartmentMenuActive(!showAddDepartmentMenuActive)
                  }
                />
              ) : (
                <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                  <BgOutlineThemeButton children={"Departments"} disabled />
                </Tooltip>
              )}
            </div>

            <div>
              {permissions &&
              permissions?.includes("full_access_stores_management") ? (
                <BgOutlineThemeButton
                  children={"Categories"}
                  onClick={() =>
                    setShowAddModelMenuActive(!showModelMenuActive)
                  }
                />
              ) : (
                <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                  <BgOutlineThemeButton children={"Categories"} disabled />
                </Tooltip>
              )}
            </div>

            <div>
              {permissions &&
              permissions?.includes("full_access_stores_management") ? (
                <BgThemeButton
                  children={"Add Items"}
                  onClick={() => setShowAddItemsActive(!showAddItemsActive)}
                />
              ) : (
                <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                  <BgThemeButtonDisabled children={"Add Items"} />
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="flex flex_gap_20">
              <div
                style={{
                  backgroundColor: "#FFF",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  width: "15%",
                  height: "fit-content",
                  borderRadius: "10px",
                }}
              >
                <div className="itemsFilters">
                  {departmentArray &&
                    departmentArray.map((department, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            fetchChannelInfo(
                              department.id,
                              property_id.id,
                              index
                            )
                          }
                          className={`booking-first-row-head-divs itemsFiltersCard ${
                            uniqueChannelId === department.id ? "active" : ""
                          }`}
                        >
                          {capitalizeFirstLetter(department.department_name)}
                        </div>
                      );
                    })}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div>
                      <BgThemeButton
                        onClick={() =>
                          setInventoriesOptionShowAddModelMenuActive(true)
                        }
                        children={"Inventory Options"}
                      />
                    </div> */}
                  {/* <div>
                      <BgOutlineThemeButton
                        onClick={unholdAddModalButtonClickHandler}
                        children={"Unhold Inventory"}
                      />
                    </div>
                    <div>
                      <BgOutlineThemeButton
                        onClick={unblockAddModalButtonClickHandler}
                        children={"Unblock Inventory"}
                      />
                    </div>
                    <div>
                      <BgOutlineThemeButton
                        onClick={blockAddModalButtonClickHandler}
                        children={"Block Inventory"}
                      />
                    </div>
                    <div>
                      <BgThemeButton
                        onClick={addModalButtonClickHandler}
                        children={"Bulk Updates"}
                      />
                    </div> */}
                </div>
              </div>
              <div style={{ width: "85%" }}>
                {orders && orders.length > 0 ? (
                  <>
                    <div className="itemsLength">
                      {orders.length} <span className="itemsLengthTxt"> Total Items</span>
                    </div>
                    <LegacyCard>
                      <IndexTable
                        resourceName={resourceName}
                        itemCount={currentOrders.length}
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources.length
                        }
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Item Name" },
                          { title: "Item Category" },
                          { title: "Department" },
                          { title: "Unit" },
                          { title: "Tax Rate (%)" },
                          { title: "HSN Code" },
                        ]}
                      >
                        {rowMarkup}
                      </IndexTable>

                      {/* Single select */}
                      {showLowerMenuSingleSelect && (
                        <div className=" room-type-bottom-container">
                          <div className="room-type-bottom-container-div">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                gap: "20px",
                              }}
                            >
                              {permissions &&
                              permissions?.includes(
                                "full_access_stores_management"
                              ) ? (
                                <Button
                                  plain
                                  destructive
                                  onClick={showModelMenu}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      <Icon source={DeleteMajor} color="base" />
                                    </span>
                                    <span>Remove</span>
                                  </div>
                                </Button>
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      opacity: "0.4",
                                      cursor: "no-drop",
                                    }}
                                  >
                                    <span>
                                      <Icon source={DeleteMajor} color="base" />
                                    </span>
                                    <span>Remove</span>
                                  </div>
                                </Tooltip>
                              )}

                              {permissions &&
                              permissions?.includes(
                                "full_access_stores_management"
                              ) ? (
                                <BgOutlineThemeButton
                                  children={"Edit"}
                                  loader={loader}
                                  onClick={() =>
                                    setShowEditModelMenuActive(
                                      !showEditModelMenuActive
                                    )
                                  }
                                />
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <BgThemeButtonDisabled children={"Edit"} />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Multiple select */}
                      {showLowerMenuMultipleSelect && (
                        <div className="room-type-bottom-container">
                          <div className="room-type-bottom-container-div">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {permissions &&
                              permissions?.includes(
                                "full_access_stores_management"
                              ) ? (
                                <Button
                                  plain
                                  destructive
                                  onClick={showModelMenu}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      <Icon source={DeleteMajor} color="base" />
                                    </span>
                                    <span>Remove</span>
                                  </div>
                                </Button>
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      opacity: "0.4",
                                      cursor: "no-drop",
                                    }}
                                  >
                                    <span>
                                      <Icon source={DeleteMajor} color="base" />
                                    </span>
                                    <span>Remove</span>
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </LegacyCard>
                    <CustomPagination
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      totalItems={orders.length}
                      onPageChange={handlePageChange}
                      clearSelection={clearSelection}
                    />
                  </>
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {/* Add Button Click */}
      {showAddModelMenuActive && (
        <AddCategoryItem
          setShowAddUnitsMenuActive={setShowAddUnitsMenuActive}
          setShowAddDepartmentMenuActive={setShowAddDepartmentMenuActive}
          setShowAddModelMenuActive={setShowAddModelMenuActive}
        />
      )}

      {/* Add Department Click */}
      {showAddDepartmentMenuActive && (
        <AddDepartment
          setShowAddUnitsMenuActive={setShowAddUnitsMenuActive}
          setShowAddDepartmentMenuActive={setShowAddDepartmentMenuActive}
          setShowAddModelMenuActive={setShowAddModelMenuActive}
        />
      )}

      {/* Add Units Click */}
      {showAddUnitsMenuActive && (
        <AddUnits
          setShowAddUnitsMenuActive={setShowAddUnitsMenuActive}
          setShowAddDepartmentMenuActive={setShowAddDepartmentMenuActive}
          setShowAddModelMenuActive={setShowAddModelMenuActive}
        />
      )}

      {/* Add Items Click */}
      {showAddItemsActive && (
        <AddItems
          setShowAddUnitsMenuActive={setShowAddUnitsMenuActive}
          setShowAddDepartmentMenuActive={setShowAddDepartmentMenuActive}
          setShowAddModelMenuActive={setShowAddModelMenuActive}
          setShowAddItemsActive={setShowAddItemsActive}
          fetchInfo={fetchInfo}
        />
      )}

      {/* Edit Button Click */}
      {showEditModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="agent-add-categories-model-container">
              <LegacyCard>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "10px",
                  }}
                >
                  <div>
                    <Page
                      backAction={{
                        content: "Edit Item",
                        url: `/hotelier/items`,
                      }}
                      title="Edit Item"
                      compactTitle
                    ></Page>
                  </div>
                  <div></div>
                </div>
                <div style={{ padding: "20px" }}>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="edit-rooms-form-wrapper-container">
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            name={"item_name"}
                            className="textfield"
                            focused={false}
                            titleName="Item Name"
                            onChange={setItemsDataHandleChange}
                            autoComplete="off"
                            value={itemsData.item_name}
                          />
                        </div>

                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Unit
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            required="true"
                            name="unit_name"
                            onChange={setItemsDataHandleChange}
                            value={itemsData.unit_name}
                          >
                            <option value="">Select Option</option>
                            {units?.map((x) => {
                              return (
                                <>
                                  <option
                                    key={x.units_name}
                                    value={x.units_name}
                                  >
                                    {x.units_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Category Item
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            required="true"
                            name="category_name"
                            onChange={setItemsDataHandleChange}
                            value={itemsData.category_name}
                          >
                            <option value="">Select Option</option>
                            {categories?.map((x) => {
                              return (
                                <>
                                  <option key={x.id} value={x.category_name}>
                                    {x.category_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Department
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            required="true"
                            name="department_name"
                            onChange={setItemsDataHandleChange}
                            value={itemsData.department_name}
                          >
                            <option value="">Select Option</option>
                            {departments?.map((x) => {
                              return (
                                <>
                                  <option key={x.id} value={x.department_name}>
                                    {x.department_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            name="hsn_code"
                            className="textfield"
                            titleName="HSN Code"
                            focused={false}
                            onChange={setItemsDataHandleChange}
                            autoComplete="off"
                            value={itemsData.hsn_code}
                          />
                        </div>
                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Tax Slab
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            required="true"
                            name="tax_slab"
                            onChange={setItemsDataHandleChange}
                            value={itemsData.tax_slab}
                          >
                            <option value="">Select Option</option>
                            {taxSlabOptions?.map((x) => {
                              return (
                                <>
                                  <option key={x.label} value={x.value}>
                                    {x.label}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        color: "#3968ED",
                        display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        children={"Update"}
                        loader={loader}
                        type="submit"
                        onClick={() => handleUpdateItem(selectedResources[0])}
                      />
                      <BgOutlineThemeButton
                        onClick={() => setShowEditModelMenuActive(false)}
                        children={"Cancel"}
                      />
                    </div>
                  </form>
                </div>
              </LegacyCard>
            </section>
          </div>
        </>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={deleteData} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};
export default Items;

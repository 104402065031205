import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/syncBooking.webp";
import { SvgCancel, SvgSync, SvgSyncBooking } from "../../../assets/svgIcons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { BgThemeButton } from "../../UI/Buttons";
import { SelectBasicWithSelectTag } from "../../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { PrimaryInput } from "../../NewCustomComp/Inputs/Inputs";
import { PrimaryIconButtonWithText } from "../../NewCustomComp/Buttons/Buttons";

const SyncBookingPopup = ({ setOpenSyncPopup, property_id, userToken }) => {
  const [loader, setLoader] = useState(false);
  const [syncPopupData, setSyncPopupData] = useState({
    channel: [],
    bookingId: "",
    date: "",
    selectedChannelId: "",
  });
  const fetchChannelsList = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channels/list`,
        requestOptions
      );
      const result = await response.json();
      console.log(result);
      setSyncPopupData((prevData) => ({
        ...prevData,
        channel: result?.data?.data || [],
      }));
    } catch (error) {
      console.error("Error fetching channels list:", error);
    }
  };

  const channelsOption = [
    { label: "select Channel", value: "" },
    ...syncPopupData.channel.map((item) => ({
      label: item.channel_name,
      value: item.id,
    })),
  ];
  useEffect(() => {
    if (userToken) {
      fetchChannelsList();
    }
  }, [userToken]);

  const handleSyncBooking = async () => {
    setLoader(true);
    try {
      if (!syncPopupData?.bookingId) {
        showErrorToast("Please select booking");
        return;
      }
  
      if (!syncPopupData?.date) {
        showErrorToast("Please select date");
        return;
      }
  
      if (!syncPopupData?.selectedChannelId) {
        showErrorToast("Please select channel");
        return;
      }
  
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/sync/single/booking/${property_id?.id}?channel_id=${syncPopupData?.selectedChannelId}&booking_id=${syncPopupData?.bookingId}&booking_date=${syncPopupData?.date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      const data = await response.json();
  
      if (response.ok && data.success) {
        showSuccessToast(data.message);
        setSyncPopupData({
          bookingId: "",
          date: "",
          selectedChannelId: "",
          channel: [],
        });
        setOpenSyncPopup(false);
      } else {
        showErrorToast(data.message || "Failed to sync booking.");
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      setLoader(false);
    }
  };
  
  return (
    <div>
      <div className="bg-backdrop">
        <div className="bg-white p-20 br-8" style={{ width: "600px" }}>
          <span 
            className="pointer"
            style={{float:"right"}}
            onClick={() => setOpenSyncPopup(false)}
          >
            <SvgCancel color={"#ddd"} height={"20"} width={"20"} />
          </span>
          <div className="w-100">
            <div className="dashboard_checkin_modal_content">
              <SvgSyncBooking/>
              <h4>
                Sync your <br /> External Booking
              </h4>
              <div className="flex flex_gap_10 mt-10">
                <div className="w-30">
                  <SelectBasicWithSelectTag
                    label={"Select Channel"}
                    options={channelsOption}
                    value={syncPopupData.selectedChannelId}
                    size={"medium"}
                    name={"selectedChannelId"}
                    onChange={(e) =>
                      setSyncPopupData({
                        ...syncPopupData,
                        selectedChannelId: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="w-30">
                  <PrimaryInput
                    label={"Date"}
                    type="date"
                    size={"medium"}
                    value={syncPopupData.date}
                    onChange={(e) =>
                      setSyncPopupData({
                        ...syncPopupData,
                        date: e.target.value,
                      })
                    }
                    name={"date"}
                  />
                </div>

                <div className="w-30">
                  <PrimaryInput
                    label={"Booking ID"}
                    size={"medium"}
                    value={syncPopupData.bookingId}
                    type="number"
                    placeholder="Enter Booking ID"
                    onChange={(e) =>
                      setSyncPopupData({
                        ...syncPopupData,
                        bookingId: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="w-10 flex align_items_end">
                  <PrimaryIconButtonWithText
                    icon={<SvgSync />}
                    size={"medium"}
                    title={"Sync"}
                    onClick={() => handleSyncBooking()}
                    loader={loader}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyncBookingPopup;

import { Icon, IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency, getBaseUrl } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";
import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";
import { showErrorToast } from "../../assets/toastUtils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CancelMajor } from "@shopify/polaris-icons";

const ReportSaleSummeryReport = ({
  departureReport,
  // loader,
  fullData,
  donShowTitle,
  userToken,
  property_id,
}) => {
  const tableHeadings = [
    "INV Date",
    "INV No.",
    "Folio No.",
    "Booking ID",
    "Booking Source",
    "Guest Name",
    "Arrival Date",
    "Departure Date",
    "No. Of Nights",
    "Adults",
    "Child",
    "No. Of Rooms",
    "Room Rate/Night",
    "Taxable Amt.",
    "Tax %",
    "Tax Amt.",
    "Tax Type",
    "Net Room Sale",
    "F&B Sale Amt.",
    "Taxable Amt.",
    "Tax %",
    "Tax Amt.",
    "Tax Type",
    "Net F&B Sale",
    "Add Ons Sale",
    "Taxable Amt.",
    "Tax %",
    "Tax Amt.",
    "Tax Type",
    "Net Add-On Sale",
    "Total Bill Amt.",
    "Advance",
    "Cash",
    "Upi/Debit",
    "Guest Credit",
    "Company Credit",
    "Balance",
  ];
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const styles = {
    tableData: {
      verticalAlign: "top",
    },
  };
  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkup =
    fullData &&
    fullData?.data?.length > 0 &&
    fullData?.data?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.invoice_date ? (
            <TextDateFormatter date={order.invoice_date} as={"span"} />
          ) : (
            "NA"
          )}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}

          {order.invoice_no ? (
            <span
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => handleSubmitPreview(order?.master_folio_id)}
            >
              {" "}
              #{order.invoice_no}
            </span>
          ) : (
            "N/A"
          )}

          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {order.folio_no ? order.folio_no : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.booking_id ? (
            <span
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() =>
                getSingleBookingDataHandler(order.unique_booking_id)
              }
            >
              {" "}
              #{order.unique_booking_id}
            </span>
          ) : (
            "N/A"
          )}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.booking_source ? order.booking_source : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.guest_name ? order.guest_name : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.arrival_date ? (
            <TextDateFormatter date={order.arrival_date} as={"span"} />
          ) : (
            "NA"
          )}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.departure_date ? (
            <TextDateFormatter date={order.departure_date} as={"span"} />
          ) : (
            "NA"
          )}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.no_nights ? <>{order.no_nights}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        {/* <CustomReportTableCell styles={styles.tableData}>
        
          {order.mealPlan ? <>{order.mealPlan}</> : "N/A"}
         
        </CustomReportTableCell> */}
        {/* <CustomReportTableCell styles={styles.tableData}>
         
          {order.room_type ? <>{order.room_type}</> : "N/A"}
          
        </CustomReportTableCell> */}
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.no_adult ? <>{order.no_adult}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.no_child ? <>{order.no_child}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.no_of_rooms ? <>{order.no_of_rooms}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)}
                  <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div  style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {item.tax_rate}% <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.tax_amount)} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {item.tax_type === "inclusive" ? "incl" : "Excl"} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.net_total)} <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)} <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)}
                  <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {item.tax_rate}% <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.tax_amount)}
                  <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {item.tax_type === "inclusive" ? "incl" : "Excl"}
                  <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.net_total)} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)}
                  <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)}
                  <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {item.tax_rate}% <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.tax_amount)}
                  <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {item.tax_type === "inclusive" ? "incl" : "Excl"} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.net_total)} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.total_bill_amount &&
            formatIndianCurrency(order?.total_bill_amount)}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.advance_payment &&
            formatIndianCurrency(order?.payment_summary?.advance_payment)}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.cash &&
            formatIndianCurrency(order?.payment_summary?.cash)}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.upi
            ? formatIndianCurrency(order?.payment_summary?.upi)
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.upi
            ? formatIndianCurrency(order?.payment_summary?.upi)
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.city_ledger
            ? formatIndianCurrency(order?.payment_summary?.city_ledger)
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.balance_amount
            ? formatIndianCurrency(order?.balance_amount)
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  const downloadData = departureReport.map((report) => {
    return {
      date: report.date,
      guest_name: report.guest_name ? report.guest_name : "N/A",
      reference: report.guest_name
        ? `#${report.unique_booking_id} Folio-${report.folio_no} (${report.room_no})`
        : "Paid Credit",
      credits: report.credit_amount,
      debits: report.debit_amount,
      available_credit_limit: report.available_credit_limit,
    };
  });

  const names = [
    {
      Invoice_No: "INV No.",
      Inv_Date: "Date",
      Booking_Ref: "Booking Ref",
      Primary_Guest: "Guest",
      GSTIN: "GST IN",
      Account_Details: "Account",
      Tax_Rate: "Tax Rate",
      Taxable_Amount: "Taxable Amt.",
      SGST_Amount: "SGST",
      CGST_Amount: "CGST",
      Invoice_Value: "INV Value",
    },
  ];

  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [folios, setFolios] = useState([]);
  const [chargesDetails, setChargesDetails] = useState(null);
  const [masterFolioDetails, setMasterFolioDetails] = useState(null);
  const [loader, setLoader] = useState(false);

  const getSingleBookingDataHandler = (bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          setLoader(false);
          setBookingDetails(data?.booking);
          setChargesDetails(data?.charges);
          setMasterFolioDetails(data?.masterFolio);
          setFolios(data?.allFolios);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const [iframeSrc, setIframeSrc] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [showBillPreview,setShowBillPreview] = useState(false);
  const [iframeContent, setIframeContent] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const handleSubmitPreview = async (booking_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${booking_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const iframeContent = await response.text(); // API response as text (iframe content)
      
      // Save iframe content and control states
      setIframeSrc(iframeContent); 
      setIframeContent(iframeContent); 
      setShowIframePopup(true);
      setShowBillPreview(true);
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };
  
  const printIframe = (id) => {
    // setIsLoading(false);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };
  const handleDownload = () => {
    const iframe = document.getElementById("receipt");
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;

    html2canvas(iframeDocument.body).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addFont("FontAwesome", "FontAwesome", "normal");
      pdf.setFont("FontAwesome");
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("receipt.pdf");
    });
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container" id="sales_table">
          <div className="room-types-full-container-div">
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">Detailed Business Report</div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
                id="ignoreFromPrint"
              >
                <SvgGetInfo />
              </div>
            </div>
            {fullData?.data?.length > 0 && (
              <div className="flex flex_gap_15" id="ignoreFromPrint">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"sales_table"}
                  downloadFileName={"sales-summary-report"}
                  ignoreFromPrint={"ignoreFromPrint"}
                  pdfOrientation={"landscape"}
                  pageFormat={"A1"}
                  longPdf={true}
                />
                <DownloadCSVButton
                  itemToDownloadID={"sales_table"}
                  downloadFileName={"sales-summary-report"}
                />
              </div>
            )}
          </div>
          {fullData && fullData?.data?.length > 0 ? (
            <>
              <CustomReportTable
                headings={tableHeadings}
                commonHeadings={[
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "Room Sale",
                  "Room Sale",
                  "Room Sale",
                  "Room Sale",
                  "Room Sale",
                  "Room Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "",
                  "Payment Details",
                  "Payment Details",
                  "Payment Details",
                  "Payment Details",
                  "Payment Details",
                  "Payment Details",
                ]}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  textAlign: "center",
                  width: "max-content",
                }}
                tableStyles={{ textAlign: "left", width: "max-content" }}
              >
                {rowMarkup}
                {/* {calculateTotals} */}
                <CustomReportTableRow>
                  <CustomReportTableCell
                    groupBackgroundColors={"#ddd"}
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length}
                  >
                    <b>Summery</b>
                  </CustomReportTableCell>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Number of Bookings
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length - 1}
                  >
                    {fullData?.overall_totals?.overall_bookings || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>Total Room Sale</CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length - 1}
                  >
                    {fullData?.overall_totals?.overall_room_sale || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>Total F&B Sale</CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length - 1}
                  >
                    {fullData?.overall_totals?.net_revenue || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Add Ons Sale
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length - 1}
                  >
                    {fullData?.overall_totals?.overall_addon_sale || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>
                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Combined Sale
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length - 1}
                  >
                    {fullData?.overall_totals?.overall_combined_sale || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>
                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Balance Amount
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length - 1}
                  >
                    {fullData?.overall_totals?.overall_balance_amount || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>
                <CustomReportTableRow>
                  <CustomReportTableCell>Net Revenue</CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length - 1}
                  >
                    {fullData?.overall_totals?.net_revenue || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Detailed Business Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Detailed Business Report?",
                  answer: (
                    <p>
                      The <b>Detailed Business Report</b> is a detailed
                      financial document that tracks all payments received over
                      a specific date range, categorized by payment methods such
                      as cash, bank transfers, UPI, credit card, debit card, and
                      city ledger. This report provides a consolidated view of
                      all payment transactions, making it easier to reconcile
                      finances.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Detailed Business Report?",
                  answer: (
                    <div>
                      <p>The Detailed Business Report is essential for:</p>

                      <ul>
                        <li>
                          <b>Financial Accuracy: </b>Ensures all payments are
                          accurately recorded and matched with corresponding
                          transactions, preventing discrepancies.
                        </li>
                        <li>
                          <b>Cash Flow Management: </b>Helps in tracking cash
                          inflows from different payment channels, providing a
                          clear picture of daily revenue.
                        </li>
                        <li>
                          <b>Audit Preparedness: </b> Provides a transparent
                          record of payment methods used, simplifying audits and
                          financial reporting.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b>Helps in identifying
                          any pending or unmatched payments, ensuring smoother
                          financial operations.
                        </li>
                        <li>
                          <b>Fraud Prevention: </b>By reconciling payments
                          against bookings and invoices, this report helps
                          identify potential fraud or errors in financial
                          transactions.
                        </li>
                      </ul>
                      <p>
                        This report is a key tool for maintaining accurate and
                        organized financial records.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
      {openSingleBookingDetails && (
        <BookingDetailSliderNew
          folios={folios}
          data={bookingDetails}
          chargesDetails={chargesDetails}
          masterFolioDetails={masterFolioDetails}
          setShowBookingDetails={setOpenSingleBookingDetails}
          getSingleBookingDataHandler={getSingleBookingDataHandler}
        />
      )}

      {showIframePopup && (
        <>
          <div className="bg-backdrop">
            <section
              className="communication_iframe_container"
              style={{ width: "795px", overflow: "hidden" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                  padding: "0 25px",
                }}
              >
                <div
                  className="add-ota-model-container-heading"
                  style={{ display: "flex", gap: "10px" }}
                >
                  Booking Voucher
                  <div
                    onClick={() => printIframe("receipt")}
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                    >
                      <path
                        d="M11.0007 2.66667H3.00065V0H11.0007V2.66667ZM11.0007 6.33333C11.1895 6.33333 11.348 6.26933 11.476 6.14133C11.604 6.01333 11.6678 5.85511 11.6673 5.66667C11.6673 5.47778 11.6033 5.31933 11.4753 5.19133C11.3473 5.06333 11.1891 4.99956 11.0007 5C10.8118 5 10.6533 5.064 10.5253 5.192C10.3973 5.32 10.3335 5.47822 10.334 5.66667C10.334 5.85556 10.398 6.014 10.526 6.142C10.654 6.27 10.8122 6.33378 11.0007 6.33333ZM9.66732 10.6667V8H4.33398V10.6667H9.66732ZM11.0007 12H3.00065V9.33333H0.333984V5.33333C0.333984 4.76667 0.528429 4.29156 0.917318 3.908C1.30621 3.52444 1.77843 3.33289 2.33398 3.33333H11.6673C12.234 3.33333 12.7091 3.52511 13.0927 3.90867C13.4762 4.29222 13.6678 4.76711 13.6673 5.33333V9.33333H11.0007V12Z"
                        fill="#3968ED"
                      />
                    </svg>
                  </div>
                  <div onClick={handleDownload} style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
                        fill="#3968ED"
                      />
                    </svg>
                  </div>
                  {downloadUrl && (
                    <a href={downloadUrl} download="receipt.html">
                      Download
                    </a>
                  )}
                </div>
                <div
                  onClick={() => setShowIframePopup(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>

              <iframe srcDoc={iframeContent} id="receipt" title="Receipt" />
            </section>
          </div>
        </>
      )}
    </>
  );
};
export default ReportSaleSummeryReport;

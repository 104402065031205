import React, { useState } from "react";
import NoDataFoundSmall from "../NoDataFoundSmall";
import { getDateByMonth } from "../../../utils/utils";
import { SvgCompareArrows, SvgReceipt } from "../../../assets/svgIcons";
import styles from "./customPageSliderComponents.module.scss";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../../assets/toastUtils";
import BookingVoucher from "../BookingBillsVouchers/BookingVoucher";

const SingleAgentBookings = ({ data }) => {
  const [iframeContent, setIframeContent] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const [openSettlement, setOpenSettlement] = useState(null); // Track the open settlement by its index

  const handleShowSettlementsDetails = (index) => {
    setOpenSettlement(openSettlement === index ? null : index);
  };

  const handleSubmitPreviewVoucher = async (booking_id) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomVoucher&reservationId=${booking_id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          setIframeContent(data);
          setShowIframePopup(true);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const  getSingleBooking = () =>{
    
  }

  return (
    <div className="p-20">
      {data && data.length > 0 ? (
        <div>
          {data.map((item, index) => (
            <div key={index}>
              <div className="flex justify_content_between pt-10 pb-10">
                <span onClick = {() => getSingleBooking(item.unique_booking_id)}># {item.unique_booking_id}</span>
                <span>{item.date ? getDateByMonth(item.date) : "N/A"}</span>
                <span>{item.amount ? `₹${item.amount}` : "N/A"}</span>

                <span
                  className="flex flex_gap_10 justify_content_end"
                  style={{ width: "50px" }}
                >
                  {item.status === "checked_out" && (
                    <span
                      className="pointer"
                      onClick={(e) =>
                        handleSubmitPreviewVoucher(item?.unique_booking_id)
                      }
                    >
                      <SvgReceipt />
                    </span>
                  )}
                  <span
                    className="pointer"
                    onClick={() => handleShowSettlementsDetails(index)}
                  >
                    <SvgCompareArrows />
                  </span>
                </span>
              </div>

              <hr />

              {/* Render settlement details only if openSettlement is equal to the current index */}
              {openSettlement === index &&
                item.settlements &&
                item.settlements.length > 0 && (
                  <div>
                    <table className={styles.settlementTable}>
                      <thead>
                        <tr>
                          <th>Payment Type</th>
                          <th>Mode/Company</th>
                          <th>Amt.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.settlements.map((settlement, settlementIndex) => (
                          <tr key={settlementIndex}>
                            <td>{settlement?.payment_type}</td>
                            <td>{settlement?.payment_mode}</td>
                            <td>{settlement?.paid_amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          ))}
        </div>
      ) : (
        <NoDataFoundSmall />
      )}

      {iframeContent && showIframePopup && (
        <BookingVoucher
          setShowIframePopup={setShowIframePopup}
          iframeContent={iframeContent}
        />
      )}
    </div>
  );
};

export default SingleAgentBookings;

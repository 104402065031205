import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import Loader from "./UI/Loader/Loader";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { useSelector } from "react-redux";
import {
  other_select_nav_items_inventory,
  rooms_inner_lists,
} from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import NoDataFound from "./CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import CustomPagination from "./CustomComponents/CustomPagination/CustomPagination";
import DragDropListComponent from "./CustomComponents/DragDropListComponent/DragDropListComponent";
import { getDecryptedData } from "../utils/encryptStorage";
import SidebarInnerListWithMultiplePermissions from "./CustomComponents/SidebarInnerListWithMultiplePermissions";

const ToggleActiveDisable = (props) => {
  const [isToggled, setIsToggled] = useState(props.status === "enable");
  const [beddingFinalValue, setBeddingFinalValue] = useState("");

  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    const token = getDecryptedData("encryptToken");
    setUserToken(token);
  }, []);
  const user = JSON.parse(localStorage.getItem("user"));
  const property_id = useSelector((state) => state.property.value);

  const handleToggle = useCallback(() => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  }, []);

  const handleToggleStatus = async () => {
    if (isToggled) {
      // setBeddingFinalValue("enabled");
      await handleDisable();
    } else {
      await handleEnable();
      // setBeddingFinalValue("enabled");
    }
  };
  const [loader, setLoader] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];
  const handleDisable = async () => {
    const userId = props.userId;
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/assign/base/category/${props.userId}/${property_id?.id}`,
        {
          method: "GET",
          // body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setIsToggled(false);
        window.location.reload();
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleEnable = async () => {
    const userId = props.userId;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/assign/base/category/${props.userId}/${property_id?.id}`,
        {
          method: "GET",
          // body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setIsToggled(1);
        window.location.reload();
      } else {
        showErrorToast(data.message);
        setIsToggled(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    if (isToggled === false) {
      setBeddingFinalValue("Disabled");
    } else {
      setBeddingFinalValue("Enabled");
    }
  }, [isToggled]);

  return (
    <div className="flex align_items_center">
      {/* <span className="toggle_text">{beddingFinalValue} Base Category:</span> */}
      <label className="toggle-container">
        <input
          name="extra_bedding"
          type="checkbox"
          checked={isToggled}
          onChange={handleToggle}
          className="toggle-checkbox-custom"
        />
        <span
          className="toggle-slider"
          onClick={() => handleToggleStatus()}
        ></span>
      </label>
    </div>
  );
};

const RoomTypes = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  // const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [syncOrderPopup, setSyncOrderPopup] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    const token = getDecryptedData("encryptToken");
    setUserToken(token);
  }, []);

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders.slice(startIndex, endIndex);

  const [addRoomTypeData, setAddRoomTypeData] = useState({
    room_total: 0,
    rack_price: 0,
    max_child: 0,
    room_name: "",
    max_adults: 0,
    base_child: 0,
    base_adult: 0,
    infants: 0,
    extra_bed_limit: 0,
    room_size: 0,
    room_size_unit: "",
    room_bed_type: "",
    room_description: "",
    amenities: "",
    property_id: "99f12663-7d6d-4d28-8f08-786b7daa73f1",
    breakfast: 0,
    lunch: 100,
    dinner: 100,
    extra_adults: 0,
    child_with_Bed: 0,
    child_without_Bed: 0,
    extra_bedding: "",
    page_title: "",
    meta_description: "",
    url_handle: "",
    room_view_type: "",
    // room_images: "",
    status: "active",
  });

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);

      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const getItemChecked = (id) => {};

  getItemChecked(selectedResources[0]);

  // drag and drop
  const handleDragStart = (e, index) => {
    // e.stopPropogation
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData("index"), 10);
    const reorderedItems = [...orders];
    const draggedItem = reorderedItems[draggedIndex];
    reorderedItems.splice(draggedIndex, 1);
    reorderedItems.splice(newIndex, 0, draggedItem);
    const orderList = reorderedItems?.map((order, index) => {
      return {
        ...order,
        order: index + 1,
      };
    });
    syncOrdersHandle(orderList);
    setOrders(orderList);
    // onDataChange(reorderedItems);
  };

  const syncOrdersHandle = (order) => {
    fetch(
      // dev.revcatalyst.in/api/v1/update/bulk/room/category/order/{property_id}
      `${process.env.REACT_APP_BASE_URL}/api/v1/update/bulk/room/category/order/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          rooms: order,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // fetchInfo(property_id.id);
          // clearSelection();
          // showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const sortedOrders = currentOrders
    .slice()
    .sort((a, b) => a?.order - b?.order);

  const rowMarkup = sortedOrders?.map((room, index) => (
    <IndexTable.Row
      id={room?.id}
      key={room?.id}
      selected={selectedResources.includes(room?.id)}
      position={index}
    >
      <IndexTable.Cell>
        <div
          style={{ cursor: "move" }}
          key={room?.id}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="22"
            viewBox="0 0 9 12"
            fill="none"
          >
            <g clipPath="url(#clip0_1_20365)">
              <path
                d="M1.44742 2.89485C2.24681 2.89485 2.89485 2.24681 2.89485 1.44742C2.89485 0.648033 2.24681 0 1.44742 0C0.648033 0 0 0.648033 0 1.44742C0 2.24681 0.648033 2.89485 1.44742 2.89485Z"
                fill="#AAAAAA"
              />
              <path
                d="M6.74845 2.89485C7.54784 2.89485 8.19587 2.24681 8.19587 1.44742C8.19587 0.648033 7.54784 0 6.74845 0C5.94906 0 5.30103 0.648033 5.30103 1.44742C5.30103 2.24681 5.94906 2.89485 6.74845 2.89485Z"
                fill="#AAAAAA"
              />
              <path
                d="M1.44742 7.44758C2.24681 7.44758 2.89485 6.79955 2.89485 6.00016C2.89485 5.20077 2.24681 4.55273 1.44742 4.55273C0.648033 4.55273 0 5.20077 0 6.00016C0 6.79955 0.648033 7.44758 1.44742 7.44758Z"
                fill="#AAAAAA"
              />
              <path
                d="M6.74845 7.44758C7.54784 7.44758 8.19587 6.79955 8.19587 6.00016C8.19587 5.20077 7.54784 4.55273 6.74845 4.55273C5.94906 4.55273 5.30103 5.20077 5.30103 6.00016C5.30103 6.79955 5.94906 7.44758 6.74845 7.44758Z"
                fill="#AAAAAA"
              />
              <path
                d="M1.44742 12.0062C2.24681 12.0062 2.89485 11.3581 2.89485 10.5588C2.89485 9.75936 2.24681 9.11133 1.44742 9.11133C0.648033 9.11133 0 9.75936 0 10.5588C0 11.3581 0.648033 12.0062 1.44742 12.0062Z"
                fill="#AAAAAA"
              />
              <path
                d="M6.74845 12.0062C7.54784 12.0062 8.19587 11.3581 8.19587 10.5588C8.19587 9.75936 7.54784 9.11133 6.74845 9.11133C5.94906 9.11133 5.30103 9.75936 5.30103 10.5588C5.30103 11.3581 5.94906 12.0062 6.74845 12.0062Z"
                fill="#AAAAAA"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_20365">
                <rect width="8.19588" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {/* <Icon

  source={DragHandleIcon}
  tone="base"
/> */}
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <CustomCssComponentTableText
          innerText={room.room_name}
        ></CustomCssComponentTableText>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <CustomCssComponentTableText
          innerText={room.room_total}
        ></CustomCssComponentTableText>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <CustomCssComponentTableText
          innerText={room.base_adult}
        ></CustomCssComponentTableText>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <CustomCssComponentTableText
          innerText={room.max_adults}
        ></CustomCssComponentTableText>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <CustomCssComponentTableText
          innerText={room.max_child}
        ></CustomCssComponentTableText>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <CustomCssComponentTableText
          innerText={room.room_size + " " + room.room_size_unit}
        ></CustomCssComponentTableText>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <CustomCssComponentTableText
          subScript={<span>&#8377;</span>}
          innerText={room.rack_price}
        ></CustomCssComponentTableText>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {/* {is_base_category} */}

        <ToggleActiveDisable
          positive={"enable"}
          negative={"disable"}
          status={room.is_base_category === 0 ? "disable" : "enable"}
          userId={room.id}
        />
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  // FETCH DATA
  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.rooms);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id && userToken) {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  // delete data
  const deletedIds = {
    ids: selectedResources,
  };
  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          clearSelection();
          fetchInfo(property_id.id);
          setShowModelMenuActive(!showModelMenuActive);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // Update Data
  const [updatedData, setUpdatedData] = useState();

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        id: data.room.id,
        room_total: data.room.room_total,
        rack_price: data.room.rack_price,
        max_child: data.room.max_child,
        room_name: data.room.room_name,
        max_adults: data.room.max_adults,
        base_child: data.room.base_child,
        base_adult: data.room.base_adult,
        infants: data.room.infants,
        extra_bed_limit: data.room.extra_bed_limit,
        room_size: data.room.room_size,
        room_size_unit: data.room.room_size_unit,
        room_bed_type: data.room.room_bed_type,
        room_description: data.room.room_description,
        room_view_type: data.room.room_view_type,
        amenities: data.room.amenities,
        property_id: data.room.property_id,
        breakfast: data.room.room_total,
        lunch: data.room.lunch,
        dinner: data.room.dinner,
        extra_adults: data.room.extra_adults,
        child_with_Bed: data.room.child_with_Bed,
        child_without_Bed: data.room.child_without_Bed,
        extra_bedding: data.room.extra_bedding,
        page_title: data.room.page_title,
        meta_description: data.room.meta_description,
        url_handle: data.room.url_handle,
        max_occupancy: data.room.max_occupancy,
        min_los: data.room.min_los,
      };
      setAddRoomTypeData(updatedData);
      setUpdatedData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddRoomTypeData({
      ...addRoomTypeData,
      [event.target.name]: event.target.value,
    });
  };

  const [buttonLoader, setButtonLoader] = useState(false);

  const updateFunction = (id) => {
    setButtonLoader(true);

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/update/${id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addRoomTypeData,
          status: "active",
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id.id);
          clearSelection();
          showSuccessToast(data.message);
          setLoader(false);
          setButtonLoader(false);
        } else {
          showErrorToast(data.message);
          setLoader(false);
          setButtonLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setLoader(false);
        setButtonLoader(false);
      });
  };
  const params = useParams();


  return (
    <>
      <SidebarInnerListWithMultiplePermissions
        innerLists={rooms_inner_lists}
        selectAtLast={true}
        other_select_nav_items_inventory={other_select_nav_items_inventory}
        firstSelected={"Inventory"}
        firstSelectedPermission={"read_inventory"}
        firstSelectedLink={"/inventory"}
      />

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Room Types</div>
              <div className="flex flex_gap_20">
                {permissions &&
                permissions.includes("full_access_ari_module") ? (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/roomstype/add`}
                  >
                    <BgThemeButton children={"Add Room Type"} />
                  </Link>
                ) : (
                  <Tooltip
                    content="Please Contact Your Admin"
                    dismissOnMouseOut
                  >
                    <BgThemeButtonDisabled children={"Add Room Type"} />
                  </Tooltip>
                )}
                {/* <BgThemeButton
                  onClick={() => setSyncOrderPopup(true)}
                  children={"Sync Order"}
                /> */}
              </div>
            </div>

            {showLowerMenuSingleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div>
                    {permissions &&
                    permissions.includes("full_access_ari_module") ? (
                      <Button
                        plain
                        destructive
                        onClick={() => setShowModelMenuActive(true)}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                    ) : (
                      <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity:"0.7",
                            color:"red"
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div>Base Adults</div>
                      <div style={{ width: "70px" }}>
                        <VerticalInputFieldCustom
                          name="base_adult"
                          onChange={(e) => setAddItemDataHandleChange(e)}
                          value={addRoomTypeData.base_adult}
                          className="textfield"
                          focused={false}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="room-type-bottom-div">
                      <div>Max Adults</div>
                      <div style={{ width: "70px" }}>
                        <VerticalInputFieldCustom
                          name="max_adults"
                          className="textfield"
                          onChange={(e) => setAddItemDataHandleChange(e)}
                          focused={false}
                          value={addRoomTypeData.max_adults}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="room-type-bottom-div">
                      <div>Max Child</div>
                      <div style={{ width: "70px" }}>
                        <VerticalInputFieldCustom
                          name="max_child"
                          className="textfield"
                          onChange={(e) => setAddItemDataHandleChange(e)}
                          focused={false}
                          value={addRoomTypeData.max_child}
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    {permissions &&
                    permissions.includes("full_access_ari_module") ? (
                      <>
                        <BgThemeButton
                          onClick={(e) => updateFunction(selectedResources[0])}
                          children={"Update"}
                          loader={buttonLoader}
                        />
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/roomstype/edit/${selectedResources[0]}`}
                          state={updatedData}
                        >
                          <BgOutlineThemeButton children={"Edit More"} />
                        </Link>
                      </>
                    ) : (
                      <Tooltip
                        content="Please Contact Your Admin"
                        dismissOnMouseOut
                      >
                        <div className="flex flex_gap_10">
                          <BgThemeButtonDisabled
                            onClick={(e) =>
                              updateFunction(selectedResources[0])
                            }
                            children={"Update"}
                          />

                          <BgThemeButtonDisabled children={"Edit More"} />
                        </div>
                      </Tooltip>
                    )}
                  </form>
                </div>
              </div>
            )}

            {showLowerMenuMultipleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div>
                    <Button
                      plain
                      destructive
                      onClick={() => setShowModelMenuActive(true)}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {orders && orders.length > 0 ? (
              <>
                <LegacyCard>
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={currentOrders?.length}
                    selectedItemsCount={
                      allResourcesSelected ? "All" : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={[
                      // { title: "" },
                      // { title: "" },
                      // { title: "images" },
                      { title: "" },
                      { title: "Room Type" },
                      { title: "Total Rooms" },
                      { title: "Base Adults" },
                      { title: "Max Adults" },
                      { title: "Max Child" },
                      { title: "Room Size" },
                      { title: "Rack Price" },
                      { title: "Base Category" },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>
                </LegacyCard>
                <CustomPagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={orders.length}
                  onPageChange={handlePageChange}
                  clearSelection={clearSelection}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    {permissions &&
                    permissions.includes("full_access_ari_module") ? (
                      <>
                        <Button onClick={() => deleteData()} destructive>
                          Yes, Remove
                        </Button>
                      </>
                    ) : (
                      <Tooltip
                        content="Please Contact Your Admin"
                        dismissOnMouseOut
                      >
                        <div className="flex flex_gap_10">
                          <Button destructive>Yes, Remove</Button>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </section>
              </div>
            </>
          )}
          {syncOrderPopup && (
            <>
              <div className="bg-backdrop">
                <DragDropListComponent data={orders} onDataChange={setOrders} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default RoomTypes;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import { PrimaryInput } from "../../NewCustomComp/Inputs/Inputs";
import { SelectBasicWithSelectTag } from "../../NewCustomComp/SelectMultiSelect/SelectMultiSelect";

const AddNewAgentPopup = ({ setShow, setBookingData, fetchAgentList }) => {
  // setBookingData({
  //     ...bookingData,
  //     [name]: value,
  //     booking_source_name: agentName,
  //   });
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const navigate = useNavigate();

  const [addCategories, setAddCategories] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchAgentCategories = async (id) => {
    // /api/v1/agent/categories/1
    if (!id) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/categories/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAddCategories(data.data, "fetchAgentCategories");
    } catch (error) {
      console.error(error);
    }
  };
  const agentCategoriesOptions = [
    { value: "", label: "select" }, // Initial object
    ...addCategories.map((item) => ({
      value: item.id,
      label: item.agent_cat_name,
    })),
  ];
  setTimeout(() => {
    setLoader(false);
  }, 1000);

  useEffect(() => {
    if (property_id) {
      fetchAgentCategories(property_id?.id);
    }
  }, [property_id]);

  const [addAgentData, setAddAgentData] = useState({
    agent_name: "",
    agent_email: "",
    agent_phone: "",
    agent_category: "",
    company_name: "",
    business_type: "",
  });

  const handleChangeCategory = (e) => {
    const { name, value } = e.target;
    setAddAgentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const setAddItemDataHandleChange = (event) => {
    setAddAgentData({
      ...addAgentData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitAddRoomType = (event) => {
    event.preventDefault();

    if (
      addAgentData.agent_name === "" ||
      addAgentData.agent_email === "" ||
      addAgentData.agent_phone === "" ||
      addAgentData.agent_category === "" ||
      addAgentData.company_name === "" ||
      addAgentData.business_type === ""
    ) {
      showErrorToast("All fields are required");
      return;
    }

    const data = JSON.stringify({ ...addAgentData });

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/agent/${property_id?.id}`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast("Agent Created Successfully!");

          fetchAgentList(property_id?.id);
          setBookingData((prev) => ({
            ...prev,
            agent_id: data.agent_id,
            booking_source_name: addAgentData.agent_name,
          }));

          handlePopupClose();
        } else {
          showErrorToast(data.error || data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast(data.error || data.message);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handlePopupClose = () => {
    setShow(false);
    setAddAgentData({
      agent_name: "",
      agent_email: "",
      agent_phone: "",
      agent_category: "",
      company_name: "",
      business_type: "",
    });
  };
  return (
    <>
      <>
        <div className="flex flex_gap_10 align_items_center">
          <GoBackButtonCustom onClick={handlePopupClose} />
          <div className="popup_heading">Add Agent</div>
        </div>
        <div className="w-100">
          <div>
            <div>
              <div className="mt-20">
                <h1 className="popup_heading_small mb-10"> Contact Person</h1>
                <div>
                  <PrimaryInput
                    required={true}
                    name={"agent_name"}
                    className="textfield"
                    focused={false}
                    label="Name"
                    size={"medium"}
                    onChange={setAddItemDataHandleChange}
                    autoComplete="off"
                    value={addAgentData.agent_name}
                  />
                </div>
                <div className="flex flex_gap_10 mt-10">
                  <div className="w-50">
                    <PrimaryInput
                      required={true}
                      size={"medium"}
                      type={"number"}
                      name={"agent_phone"}
                      className="textfield"
                      focused={false}
                      label="Phone Number"
                      onChange={setAddItemDataHandleChange}
                      autoComplete="off"
                      value={addAgentData.agent_phone}
                    />
                  </div>
                  <div className="w-50">
                    <PrimaryInput
                      type={"email"}
                      required={true}
                      name={"agent_email"}
                      className="textfield"
                      focused={false}
                      label="Email"
                      size={"medium"}
                      onChange={setAddItemDataHandleChange}
                      autoComplete="off"
                      value={addAgentData.agent_email}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <div className="popup_heading_small mb-10">
                  Business Details
                </div>
                <div></div>
                <div className="flex flex_gap_10 mt-10">
                  <div className="w-50">
                    <PrimaryInput
                      required={true}
                      size={"medium"}
                      name={"company_name"}
                      className="textfield"
                      focused={false}
                      label="Company Name"
                      onChange={setAddItemDataHandleChange}
                      autoComplete="off"
                      value={addAgentData.company_name}
                    />
                  </div>
                  <div className="w-50">
                    <PrimaryInput
                      size={"medium"}
                      required={true}
                      name={"business_type"}
                      className="textfield"
                      focused={false}
                      label="Business Type"
                      onChange={setAddItemDataHandleChange}
                      autoComplete="off"
                      value={addAgentData.business_type}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-20">
                <h2 className="popup_heading_small mb-10">Agent Category</h2>
                <div className="form-container-div-half">
                  <SelectBasicWithSelectTag
                    required={true}
                    size={"medium"}
                    name={"agent_category"}
                    value={addAgentData.agent_category}
                    onChange={handleChangeCategory}
                    label="Select Agent Category"
                    options={agentCategoriesOptions}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex_gap_10 mt-20">
              <div>
                <BgThemeButton
                  children={"Add"}
                  onClick={handleSubmitAddRoomType}
                />
              </div>
              <div>
                <BgOutlineThemeButton
                  children={"Discard"}
                  onClick={handlePopupClose}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AddNewAgentPopup;

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import "./Folio.css";
import React, { useEffect, useRef, useState } from "react";
import DashboardFilter from "../Dashboard/DashboardFiltersComponent/DashboardFilter";
import HandleNaN from "../CustomComponents/HandleNaN/HandleNaN";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useSelector } from "react-redux";
import { Icon } from "@shopify/polaris";
import { CancelMajor } from "@shopify/polaris-icons";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Tooltip,
} from "@shopify/polaris";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import NoDataFound from "../CustomComponents/NoDataFound";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import {
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
  useCurrentTime,
} from "../../utils/utils";
import { getDecryptedData } from "../../utils/encryptStorage";

const FolioTopBarandNavigation = ({
  guestsArray,
  roomNumberArray,
  dataFromChild,
  showFolioSelect,
}) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const navigate = useNavigate();
  const location = useLocation();
  const { folioId, folioUniqueId } = useParams();

  const [chargesList, setChargesList] = useState([]);
  const [settlements, setSettlements] = useState([]);
  const [folioSummery, setFolioSummery] = useState([]);
  const [folioData, setFolioData] = useState([]);
  const [folioBookings, setFolioBookings] = useState([]);
  const [showFolioFeature, setShowFolioFeature] = useState(false);
  const [createNewFolio, setCreateNewFolio] = useState(false);
  const [addChargesPopup, setAddChargesPopup] = useState(false);
  const [allFolioArray, setAllFolioArray] = useState([]);
  const [addGuestPopup, setAddGuestPopup] = useState(false);
  const clickOutSideRef = useRef(null);
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const userToken = getDecryptedData("encryptToken");
  const [guest, setGuest] = useState([]);

  const [transferCharges, setTransferCharges] = useState(false);
  const [transferPayments, setTransferPayments] = useState(false);
  const [mergeFolio, setMergeFolio] = useState(false);

  const [selectedDateFilterValue, setSelectedDateFilterValue] = useState("");
  const [selectedDateFilterValueLeft, setSelectedDateFilterValueLeft] =
    useState("Select Option");
  const [selectedDateFilterValueRight, setSelectedDateFilterValueRight] =
    useState("Select Option");

  const [leftFolioId, setLeftFolioId] = useState("");
  const [rightFolioId, setRightFolioId] = useState("");

  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [table1DataPayments, setTable1DataPayments] = useState([]);
  const [table2DataPayments, setTable2DataPayments] = useState([]);

  const [selectedTransferCharges, setSelectedTransferCharges] = useState(null);

  const folio_inner_lists = [
    {
      name: "Guest Details",
      link: `/bookings/folio/${folioId}/${folioUniqueId}`,
      check_permission: false,
    },

    {
      name: "Charges",
      link: `/bookings/charges/${folioId}/${folioUniqueId}`,
      check_permission: false,
    },
    {
      name: "Payments",
      link: `/bookings/payment/${folioId}/${folioUniqueId}`,
      check_permission: false,
    },
    {
      name: "Folio Logs",
      link: `/bookings/folio-logs/${folioId}/${folioUniqueId}`,
      permission: ["folio_management" ,"full_access_front_office_management"],
      check_permission: true,
    },
    {
      name: "Billings",
      link: `/bookings/folio-bills/${folioId}/${folioUniqueId}`,
      permission: ["billing_management" ,"full_access_front_office_management"],
      check_permission: true,
    },
  ];
  const serviceOptions = [
    { label: "Select Option", value: "" },
    { label: "Room-Charge", value: "Room-Charge" },
    { label: "Food-Charge", value: "Food-Charge" },
    { label: "Housekeeping-Charge ", value: "Housekeeping-Charge" },
    { label: "Miscellaneous-Charge", value: "Miscellaneous-Charge" },
    { label: "Laundry-Charge", value: "Laundry-Charge" },
    // { label: "MISC", value: "misc" },
  ];
  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const folioFilterOptions = [
    ...allFolioArray?.map((room) => ({
      label: room.folio_no,
      value: room.id,
    })),
  ];

  const filteredFolioOptions = [
    ...folioFilterOptions?.filter((room) => room.value !== leftFolioId),
  ];

  const [addChargesData, setAddChargesData] = useState({
    charge_type: "",
    description: "",
    rate: "",
    quantity: "",
    net_total: "",
    subtotal: "",
    discount_amount: "",
    gst_type: "inclusive",
    discount_rate: "",
    tax_rate: "",
    tax_amount: "",
  });
  const [addNewFolioData, setAddNewFolioData] = useState({
    room_no: "",
    guest_id: "",
  });

  const setAddChargesHandleChange = (event) => {
    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
    });
  };

  const setAddFolioHandleChange = (event) => {
    setAddNewFolioData({
      ...addNewFolioData,
      [event.target.name]: event.target.value,
    });
  };

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();
  const currentTimeToBackend = useCurrentTime("24");
  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  const handleSubmitCreateFolio = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/charges/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: folioData.id,
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesData(false);
          fetchFolioInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSubmitTransferFolio = (ids) => {
    if (selectedResources.length < 1) {
      showErrorToast("Please select Charge to be moved");
      return;
    }
    if (leftFolioId === "" || rightFolioId === "") {
      showErrorToast("Please select both the Folios");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transfer/folio/charges/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          charges: ids,
          folio_id: folioData.id,
          moveToFolioId: rightFolioId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchChargeListsLeft(property_id?.id, leftFolioId);
          fetchChargeListsRight(property_id?.id, rightFolioId);
          showSuccessToast(data.message);
          clearSelection();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const handleSubmitPaymentTransferFolio = (ids) => {
    if (selectedResources.length < 1) {
      showErrorToast("Please select Payments to be moved");
      return;
    }

    if (leftFolioId === "" || rightFolioId === "") {
      showErrorToast("Please select both the Folios");
      return;
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transfer/folio/payments/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          payments: ids,
          folio_id: folioData.id,
          moveToFolioId: rightFolioId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchChargeListsLeft(property_id?.id, leftFolioId);
          fetchChargeListsRight(property_id?.id, rightFolioId);
          showSuccessToast(data.message);
          clearSelection();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const handleCreateNewFolio = (event) => {
    event.preventDefault();
    fetch(
      // Route::post(‘create/folios/{property_id}’, ‘createFolio’);
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/folios/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addNewFolioData,
          masterFolio_id: folioData.master_folio_id
            ? folioData.master_folio_id
            : folioData.id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesData(false);
          fetchFolioInfo(property_id?.id);
          setAddNewFolioData({
            room_no: "",
            guest_id: "",
          });
          setCreateNewFolio(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const fetchFolioInfo = async (propertyId, uniqueFolioId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/folios/${folioId}/${propertyId}?folio_id=${
          uniqueFolioId ? uniqueFolioId : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setFolioData(data?.data);
      setChargesList(data?.data?.charges);
      setSettlements(data?.data?.payments);
      setFolioSummery(data?.data?.folio_summary);
      setGuest(data?.data?.guests);
      setFolioBookings(data?.data?.booking);
      setLoader(false);

      setAllFolioArray(data?.allFolios);
      const firstFolioNo = data?.allFolios[0]?.folio_no;
      const firstFolioId = data?.allFolios[0]?.id;
      setSelectedDateFilterValue(firstFolioNo);
      // if (
      //   localStorage.getItem("selectedFolioValue") &&
      //   localStorage.getItem("selectedFolioLabel")
      // ) {
      //   setSelectedDateFilterValue(localStorage.getItem("selectedFolioLabel"));
      // } else {
      //   const firstFolioNo = data?.allFolios[0]?.folio_no;
      //   const firstFolioId = data?.allFolios[0]?.id;

      //   localStorage.setItem("selectedFolioValue", firstFolioId);
      //   localStorage.setItem("selectedFolioLabel", firstFolioNo);
      //   setSelectedDateFilterValue(firstFolioNo);
      // }
    } catch (error) {
      setLoader(false);
    }
  };

  const fetchChargeListsLeft = async (propertyId, selectedFolioId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/folios/${folioId}/${propertyId}?folio_id=${
          selectedFolioId ? selectedFolioId : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      // setFolioData(data?.data);
      // setChargesList(data?.data?.charges);
      // setSettlements(data?.data?.payments);
      // setFolioSummery(data?.data?.folio_summary);
      // setGuest(data?.data?.guests);
      // setFolioBookings(data?.data?.booking);
      // setLoader(false);

      // setAllFolioArray(data?.allFolios);
      // const firstFolioId = data?.allFolios[0]?.folio_no;
      // setSelectedDateFilterValue(firstFolioId);
      setTable1Data(data?.data?.charges);
      setTable1DataPayments(data?.data?.payments);
    } catch (error) {
      setLoader(false);
    }
  };
  const fetchChargeListsRight = async (propertyId, selectedFolioId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/folios/${folioId}/${propertyId}?folio_id=${
          selectedFolioId ? selectedFolioId : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      // setFolioData(data?.data);
      // setChargesList(data?.data?.charges);
      // setSettlements(data?.data?.payments);
      // setFolioSummery(data?.data?.folio_summary);
      // setGuest(data?.data?.guests);
      // setFolioBookings(data?.data?.booking);
      // setLoader(false);

      // setAllFolioArray(data?.allFolios);
      // const firstFolioId = data?.allFolios[0]?.folio_no;
      // setSelectedDateFilterValue(firstFolioId);
      setTable2Data(data?.data?.charges);
      setTable2DataPayments(data?.data?.payments);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchFolioInfo(property_id?.id, folioUniqueId);
    }
  }, [property_id, folioUniqueId]);
  const [dayTypeFilter, setDayTypeFilter] = useState("");

  const handleSelect = (label, value, option) => {
    setSelectedDateFilterValue(label);
    setDayTypeFilter(value);
    dataFromChild(value);

    localStorage.setItem("selectedFolioValue", value);
    localStorage.setItem("selectedFolioLabel", label);
  };
  const handleSelectFolioLeft = (label, value) => {
    setSelectedDateFilterValueLeft(label);
    setLeftFolioId(value);

    fetchChargeListsLeft(property_id?.id, value);
  };

  const handleSelectFolioRight = (label, value) => {
    setSelectedDateFilterValueRight(label);
    setRightFolioId(value);

    fetchChargeListsRight(property_id?.id, value);
  };

  const handleClickOutside = (event) => {
    if (
      clickOutSideRef.current &&
      !clickOutSideRef.current.contains(event.target)
    ) {
      setShowFolioFeature(false);
    }
  };
  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "8%", value: "8" },
    { label: "12%", value: "12" },
    { label: "15%", value: "15" },
  ];

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [addItemData, setAddItemData] = useState({
    service: "",
    description: "",
    rate: "",
    quantity: "",
    total_amount: "",
    sub_total: "",
    discount: "",
    gst_type: "",
    gst: "",
  });

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);
  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        room_name: data.room.room_name,
        room_no: data.room.room_no,
        floor_no: data.room.floor_no,
        status: data.room.status,
        room_id: data.room.room_id,
        property_id: data.room.property_id,
      };

      // setAddItemData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState([]);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const rowMarkupTable1 = table1Data?.map(
    ({ id, charge_type, net_total, paid_amt, due_amt, index }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <UnderscoreRemoveCapitalize text={charge_type} />
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={net_total}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          {/* <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={due_amt}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={paid_amt}
            ></CustomCssComponentTableText>
          </IndexTable.Cell> */}
          {/* <IndexTable.Cell>
                    <StatusUpdate status={status}></StatusUpdate>
                </IndexTable.Cell> */}
        </IndexTable.Row>
      );
    }
  );
  const rowMarkupTable2 = table2Data?.map(
    ({ charge_type, net_total, paid_amt, due_amt, index }) => {
      return (
        <IndexTable.Row
          key={index}
          // selected={selectedResources.includes(index)}
          position={index}
        >
          <IndexTable.Cell>
            <UnderscoreRemoveCapitalize text={charge_type} />
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={net_total}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          {/* <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={due_amt}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={paid_amt}
            ></CustomCssComponentTableText>
          </IndexTable.Cell> */}
          {/* <IndexTable.Cell>
                    <StatusUpdate status={status}></StatusUpdate>
                </IndexTable.Cell> */}
        </IndexTable.Row>
      );
    }
  );
  const rowMarkupTable1Payments = table1DataPayments?.map(
    ({ id, notes, payment_mode, settlement_name, amount, date, index }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <UnderscoreRemoveCapitalize text={payment_mode} />
          </IndexTable.Cell>

          <IndexTable.Cell>{date}</IndexTable.Cell>

          {/* <IndexTable.Cell>{notes}</IndexTable.Cell> */}
          <IndexTable.Cell>{formatIndianCurrency(amount)}</IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const bedTypeOptions = [
    {
      label: "",
      value: "",
    },
    {
      label: "",
      value: "",
    },
    {
      label: "",
      value: "",
    },
  ];

  const rowMarkupTable2Payments = table2DataPayments?.map(
    ({ id, notes, payment_mode, settlement_name, amount, date, index }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <UnderscoreRemoveCapitalize text={payment_mode} />
          </IndexTable.Cell>

          <IndexTable.Cell>{date}</IndexTable.Cell>

          {/* <IndexTable.Cell>{notes}</IndexTable.Cell> */}
          <IndexTable.Cell>{formatIndianCurrency(amount)}</IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );
  const itemsPerPage = 10;

  const sortMethods = {
    none: { method: (a, b) => null },
    ascendingRooms: { method: undefined },
    sortedRooms: {
      method: (a, b) => {
        const nameComparison = a.room_name.localeCompare(b.room_name);

        if (nameComparison !== 0) {
          return nameComparison;
        }
        return a.room_no - b.room_no;
      },
    },
  };

  // popup states

  const splitChargesAddPopup = () => {
    if (folioData?.booking?.status === "checked_out") {
      showErrorToast("Can't split Folio in checked out booking.");
      return;
    }
    if (allFolioArray.length < 2) {
      showErrorToast("Need more than one Folio to split Charges.");
      return;
    }
    setTransferCharges(!transferCharges);
  };

  const splitPaymentsAddPopup = () => {
    if (folioData?.booking?.status === "checked_out") {
      showErrorToast("Can't split Folio in checked out booking.");
      return;
    }
    if (allFolioArray.length < 2) {
      showErrorToast("Need more than Folio to split Payments.");
      return;
    }
    setTransferPayments(!transferPayments);
  };

  const createNewFolioPopup = () => {
    if (folioData?.booking?.status === "checked_out") {
      showErrorToast("Can't create new Folio in checked out booking");
      return;
    }
    if (guestsArray.length <= allFolioArray.length) {
      if (guestsArray.length - 1 <= allFolioArray.length) {
        showErrorToast(
          "All Folios are already created. Please add the new guest."
        );
        return;
      }
    }
    setCreateNewFolio(!createNewFolio);
  };

  const mergeNewFolioPopup = () => {
    if (folioData?.booking?.status === "checked_out") {
      showErrorToast("Can't split Folio in checked out booking.");
      return;
    }
    if (allFolioArray.length < 2) {
      showErrorToast("Need more than one folio to merge folio.");
      return;
    }
    setMergeFolio(!mergeFolio);
  };

  const allFolioArrayOptions = [
    {
      label: "Select Option",
      value: "",
    },
    ...allFolioArray?.map((item) => ({
      label: item.folio_no,
      value: item.id,
    })),
    {
      label: "New Folio",
      value: "new_folio",
    },
  ];

  const [selectedFolioFilter, setSelectedFolioFilter] = useState("");
  const handleSelectFolioFilter = (label, value) => {
    setSelectedFolioFilter(label);
  };

  const allFolioArrayFilters = allFolioArray.filter(
    (item) => +item.id !== +selectedFolioFilter
  );

  const rowMarkupAllFolios = allFolioArrayFilters?.map(
    ({ id, folio_no, amount, date, index }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>{id}</IndexTable.Cell>

          <IndexTable.Cell>{folio_no}</IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const handleFolioMerge = (event) => {
    //     Route::post(‘merge/folios/{booking_id}/{property_id}’, ‘mergeFolioCharges’);
    // 10:16
    // $selectedFolios     = $request->selectedFolios;  // it consist of selected folio ids
    //         $mergeWithFolioId   = $request->mergeWithFolioId; //here only one folio id into which we are merging

    event.preventDefault();
    fetch(
      // Route::post(‘create/folios/{property_id}’, ‘createFolio’);
      `${process.env.REACT_APP_BASE_URL}/api/v1/merge/folios/${folioData?.booking?.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          selectedFolios: selectedResources,
          mergeWithFolioId: selectedFolioFilter,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesData(false);
          fetchFolioInfo(property_id?.id);
          setAddNewFolioData({
            room_no: "",
            guest_id: "",
          });
          setCreateNewFolio(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  return (
    <>
      <div className="folio_top_bar folio_top_bar_and_navigation_main">
        <div className="w-100 folio_top_bar_left flex justify_content_between align_items_center">
          <div
            className="folio_top_bar_left_item folio_top_bar_and_navigation_child"
            style={{ width: "10%", borderRight: "0.5px solid #333333" }}
          >
            <p className="folio_top_title">{guest && guest[0]?.guest_name}</p>
            <div className="flex flex_gap_10">
              <div className="flex align_items_center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    d="M4.9998 4.29998C6.1596 4.29998 7.0998 3.35977 7.0998 2.19998C7.0998 1.04018 6.1596 0.0999756 4.9998 0.0999756C3.84001 0.0999756 2.8998 1.04018 2.8998 2.19998C2.8998 3.35977 3.84001 4.29998 4.9998 4.29998Z"
                    fill="#888888"
                  />
                  <path
                    d="M2.5498 9.90011C2.10194 9.90011 1.65407 9.72925 1.31237 9.38755C0.62895 8.70413 0.62895 7.59609 1.31237 6.91267C1.72485 6.50844 3.0398 5.69998 4.9998 5.69998C6.9598 5.69998 8.27476 6.50844 8.68723 6.91267C9.37065 7.59609 9.37065 8.70413 8.68723 9.38755C8.34553 9.72925 7.89767 9.90011 7.4498 9.90011H2.5498Z"
                    fill="#888888"
                  />
                </svg>
                <p className="folio_top_bar_and_navigation_child_guests">
                  {folioBookings?.items?.reduce(
                    (acc, item) => Number(acc) + Number(item.no_adult),
                    0
                  )}
                </p>
              </div>
              <div className="flex align_items_center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                >
                  <path
                    d="M2.62505 1.75C2.62505 1.28587 2.80942 0.840752 3.13761 0.512563C3.4658 0.184374 3.91092 0 4.37505 0C4.83917 0 5.28429 0.184374 5.61248 0.512563C5.94067 0.840752 6.12505 1.28587 6.12505 1.75C6.12505 2.21413 5.94067 2.65925 5.61248 2.98744C5.28429 3.31563 4.83917 3.5 4.37505 3.5C3.91092 3.5 3.4658 3.31563 3.13761 2.98744C2.80942 2.65925 2.62505 2.21413 2.62505 1.75ZM3.93755 10.5V13.125C3.93755 13.609 3.54653 14 3.06255 14C2.57856 14 2.18755 13.609 2.18755 13.125V7.86953L1.61606 8.77734C1.35903 9.1875 0.817624 9.30781 0.410202 9.05078C0.00277983 8.79375 -0.123001 8.25508 0.13403 7.84766L1.22505 6.1168C1.9059 5.03125 3.09536 4.375 4.37505 4.375C5.65473 4.375 6.84419 5.03125 7.52505 6.11406L8.61606 7.84766C8.87309 8.25781 8.75005 8.79648 8.34262 9.05352C7.9352 9.31055 7.3938 9.1875 7.13676 8.78008L6.56255 7.86953V13.125C6.56255 13.609 6.17153 14 5.68755 14C5.20356 14 4.81255 13.609 4.81255 13.125V10.5H3.93755Z"
                    fill="#888888"
                  />
                </svg>
                <p className="folio_top_bar_and_navigation_child_guests">
                  {Math.abs(
                    folioBookings?.items?.reduce(
                      (acc, item) => Number(acc) + Number(item.no_child),
                      0
                    )
                  ) || 0}
                </p>
              </div>
            </div>
          </div>
          <div
            className="folio_top_bar_left_item folio_top_bar_and_navigation_child"
            style={{ width: "25%", borderRight: "0.5px solid #333333" }}
          >
            <p className="folio_top_title">Arrival Date</p>
            <h5 className="folio_top_bar_and_navigation_child_dates">
              {folioData?.actual_check_in
                ? folioData?.actual_check_in
                : folioData?.expected_check_in}
              ,
              <span>
                {folioData?.actual_check_in_time
                  ? folioData?.actual_check_in_time
                  : folioData?.expected_check_in_time}
              </span>
            </h5>
          </div>
          <div
            className="folio_top_bar_left_item folio_top_bar_and_navigation_child"
            style={{ width: "25%", borderRight: "0.5px solid #333333" }}
          >
            <p className="folio_top_title">Departure Date</p>
            <h5 className="folio_top_bar_and_navigation_child_dates">
              {folioData?.actual_check_out
                ? folioData?.actual_check_out
                : folioData?.expected_check_out}

              <span>
                {folioData?.actual_check_out_time
                  ? folioData?.actual_check_out_time
                  : folioData?.expected_check_out_time}
              </span>
            </h5>
          </div>
          <div
            className="folio_top_bar_left_item folio_top_bar_and_navigation_child"
            style={{ width: "15%", borderRight: "0.5px solid #333333" }}
          >
            <p className="folio_top_title">Nights</p>
            <div className="flex flex_gap_10">
              <div className="flex align_items_center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="13"
                  viewBox="0 0 10 13"
                  fill="none"
                >
                  <path
                    d="M3.54167 0.666626C4.34861 0.666626 5.10694 0.819848 5.81667 1.12629C6.52639 1.43274 7.14375 1.84826 7.66875 2.37288C8.19375 2.89788 8.60947 3.51524 8.91592 4.22496C9.22236 4.93468 9.37539 5.69301 9.375 6.49996C9.375 7.3069 9.22178 8.06524 8.91533 8.77496C8.60889 9.48468 8.19336 10.102 7.66875 10.627C7.14375 11.152 6.52639 11.5678 5.81667 11.8742C5.10694 12.1807 4.34861 12.3337 3.54167 12.3333C3.02639 12.3333 2.52317 12.2678 2.032 12.1367C1.54083 12.0057 1.07183 11.8087 0.625 11.5458C1.52917 11.0208 2.24142 10.3111 2.76175 9.41663C3.28208 8.52218 3.54206 7.54996 3.54167 6.49996C3.54167 5.44996 3.2815 4.47774 2.76117 3.58329C2.24083 2.68885 1.52878 1.97913 0.625 1.45413C1.07222 1.19163 1.54142 0.994848 2.03258 0.863793C2.52375 0.732737 3.02678 0.667015 3.54167 0.666626Z"
                    fill="#888888"
                  />
                </svg>
                <p className="folio_top_bar_and_navigation_child_guests">
                  {folioBookings &&
                    folioBookings?.items?.length > 0 &&
                    folioBookings?.items[0]?.no_nights}
                </p>
              </div>
            </div>
          </div>
          <div
            className="folio_top_bar_left_item folio_top_bar_and_navigation_child"
            style={{ width: "15%", borderRight: "0.5px solid #333333" }}
          >
            <p className="folio_top_title">Rooms</p>
            <div className="flex flex_gap_10">
              <div className="flex align_items_center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 12 9"
                  fill="none"
                >
                  <path
                    d="M11.2498 3.78829V2.16663C11.2498 1.20413 10.4623 0.416626 9.49984 0.416626H7.1665C6.71734 0.416626 6.309 0.591626 5.99984 0.871626C5.69067 0.591626 5.28234 0.416626 4.83317 0.416626H2.49984C1.53734 0.416626 0.749837 1.20413 0.749837 2.16663V3.78829C0.394004 4.10913 0.166504 4.56996 0.166504 5.08329V8.58329H1.33317V7.41663H10.6665V8.58329H11.8332V5.08329C11.8332 4.56996 11.6057 4.10913 11.2498 3.78829ZM7.1665 1.58329H9.49984C9.82067 1.58329 10.0832 1.84579 10.0832 2.16663V3.33329H6.58317V2.16663C6.58317 1.84579 6.84567 1.58329 7.1665 1.58329ZM1.9165 2.16663C1.9165 1.84579 2.179 1.58329 2.49984 1.58329H4.83317C5.154 1.58329 5.4165 1.84579 5.4165 2.16663V3.33329H1.9165V2.16663Z"
                    fill="#888888"
                  />
                </svg>
                <p className="folio_top_bar_and_navigation_child_guests">
                  {folioBookings?.items?.reduce(
                    (acc, item) => Number(acc) + Number(item?.no_of_rooms),
                    0
                  )}
                </p>
              </div>
            </div>
          </div>
          <div
            className="folio_top_bar_left_item folio_top_bar_and_navigation_child"
            style={{ width: "15%", borderRight: "0.5px solid #333333" }}
          >
            <p className="folio_top_title">Booking ID</p>
            <div className="flex flex_gap_10">
              <div className="flex align_items_center">
                <p className="folio_top_bar_and_navigation_child_guests">
                  {folioData?.booking?.unique_booking_id}
                </p>
              </div>
            </div>
          </div>
          <div
            className="folio_top_bar_right folio_top_bar_and_navigation_child"
            style={{ width: "10%" }}
          >
            <span>
              {/* <UnderscoreRemoveCapitalize text={folioData?.booking?.status  === 'checked_in' ? 'arrived' : folioData?.booking?.status } />  */}
              <BookingStatusUpdate status={folioData?.booking?.status} />
            </span>
          </div>
        </div>
      </div>
      <div className="folio_top_nav flex justify_content_between">
        <div
          style={{ display: "flex", zIndex: "1" }}
          className="footer_inner_lists folio_top_nav_list"
        >
          <ul className="flex flex_gap_10">
            {/* {folio_inner_lists.map((item, index) => (
              <React.Fragment key={index}>
                <li style={{ listStyle: "none" }}>
                  <Link
                    to={item.link}
                    className={`inner_list_item ${
                      location.pathname === item.link ? "active" : ""
                    }`}
                    key={index}
                  >
                    {item.name}
                  </Link>
                </li>
              </React.Fragment>
            ))} */}
            {folio_inner_lists.map((item, index) => {
              const hasPermission =
                !item.check_permission ||
                (item.permission &&
                  item.permission.some((perm) => permissions.includes(perm)));

              return (
                hasPermission && (
                  <li style={{ listStyle: "none" }} key={index}>
                    <Link
                      to={item.link}
                      className={`inner_list_item ${
                        location.pathname === item.link ? "active" : ""
                      }`}
                    >
                      {item.name}
                    </Link>
                  </li>
                )
              );
            })}
          </ul>
        </div>
        <div className="folio_summery_top_right flex align_items_center flex_gap_10">
          {showFolioSelect === false ? null : (
            <DashboardFilter
              maxWidth="150px"
              values={folioFilterOptions}
              onSelect={handleSelect}
              selectedValue={selectedDateFilterValue}
            />
          )}

          {showFolioFeature && (
            <div className="show_folio_feature_popup" ref={clickOutSideRef}>
              {/* <button>Edit Folio</button> */}
              <button onClick={() => createNewFolioPopup()}>
                Create New Folio
              </button>
              <button onClick={() => mergeNewFolioPopup()}>Merge Folio</button>
              <button onClick={() => splitChargesAddPopup()}>
                Split Charges
              </button>
              <button onClick={() => splitPaymentsAddPopup()}>
                Split Payments
              </button>
            </div>
          )}

          <button
            className="show_feature_btn flex justify_content_center align_items_center"
            onClick={() => setShowFolioFeature(!showFolioFeature)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="15"
              viewBox="0 0 4 15"
              fill="none"
            >
              <g clipPath="url(#clip0_4588_1651)">
                <path
                  d="M2.06458 3.12916C2.92867 3.12916 3.62916 2.42867 3.62916 1.56458C3.62916 0.700486 2.92867 0 2.06458 0C1.20049 0 0.5 0.700486 0.5 1.56458C0.5 2.42867 1.20049 3.12916 2.06458 3.12916Z"
                  fill="#3968ED"
                />
                <path
                  d="M2.06458 9.06458C2.92867 9.06458 3.62916 8.3641 3.62916 7.5C3.62916 6.63591 2.92867 5.93542 2.06458 5.93542C1.20049 5.93542 0.5 6.63591 0.5 7.5C0.5 8.3641 1.20049 9.06458 2.06458 9.06458Z"
                  fill="#3968ED"
                />
                <path
                  d="M2.06458 15.0026C2.92867 15.0026 3.62916 14.3021 3.62916 13.438C3.62916 12.5739 2.92867 11.8734 2.06458 11.8734C1.20049 11.8734 0.5 12.5739 0.5 13.438C0.5 14.3021 1.20049 15.0026 2.06458 15.0026Z"
                  fill="#3968ED"
                />
              </g>
              <defs>
                <clipPath id="clip0_4588_1651">
                  <rect
                    width="3.12653"
                    height="15"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>

      {addChargesPopup && (
        <div className="bg-backdrop">
          <form
            onSubmit={handleSubmitCreateFolio}
            style={{ width: "1000px" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="add-ota-model-container-heading">Add Charges</div>
              <div
                onClick={() => setAddChargesPopup(!addChargesPopup)}
                style={{ cursor: "pointer" }}
              >
                <Icon source={CancelMajor} color="base" />
              </div>
            </div>
            <div className="form-container-div-full flex flex_gap_10">
              <div className="form-container-div" style={{ width: "20%" }}>
                <VerticalInputSelectCustomCopy
                  options={serviceOptions}
                  titleName="Service"
                  name="charge_type"
                  value={addChargesData.charge_type}
                  onChange={setAddChargesHandleChange}
                />
              </div>
              <div className="form-container-div" style={{ width: "20%" }}>
                <VerticalInputFieldCustom
                  required={true}
                  titleName="Description"
                  name="description"
                  type="text"
                  value={addChargesData.description}
                  onChange={setAddChargesHandleChange}
                />
              </div>
              <div className="form-container-div" style={{ width: "20%" }}>
                <VerticalInputFieldCustom
                  required={true}
                  titleName="Rate"
                  name="rate"
                  type="number"
                  value={addChargesData.rate}
                  onChange={setAddChargesHandleChange}
                />
              </div>
              <div className="form-container-div" style={{ width: "20%" }}>
                <VerticalInputFieldCustom
                  required={true}
                  titleName="Qty"
                  name="quantity"
                  type="number"
                  value={addChargesData.quantity}
                  onChange={setAddChargesHandleChange}
                />
              </div>
            </div>

            <div className="form-container-div-full flex flex_gap_10">
              <div className="form-container-div" style={{ width: "20%" }}>
                <VerticalInputFieldCustom
                  required={true}
                  titleName="Subtotal"
                  name="sub_total"
                  disabled={true}
                  type="number"
                  value={addChargesData.subtotal}
                  onChange={setAddChargesHandleChange}
                />
              </div>
              <div className="form-container-div" style={{ width: "20%" }}>
                <VerticalInputFieldCustom
                  required={true}
                  titleName="Discount%"
                  name="discount_rate"
                  type="number"
                  value={addChargesData.discount_rate}
                  onChange={setAddChargesHandleChange}
                />
              </div>

              <div className="form-container-div" style={{ width: "20%" }}>
                <VerticalInputSelectCustomCopy
                  options={gstTypeOptions}
                  required={true}
                  titleName="GST Type"
                  name="gst_type"
                  value={addChargesData.gst_type}
                  onChange={setAddChargesHandleChange}
                />
              </div>
              <div className="form-container-div" style={{ width: "20%" }}>
                <VerticalInputSelectCustomCopy
                  required={true}
                  options={gstOptions}
                  titleName="GST%"
                  name="tax_rate"
                  value={addChargesData.tax_rate}
                  onChange={setAddChargesHandleChange}
                />
              </div>
            </div>

            <div>
              Subtotal :{" "}
              {addChargesData.gst_type === "inclusive" ? (
                <HandleNaN
                  value={addChargesData.subtotal - addChargesData.tax_amount}
                />
              ) : (
                <HandleNaN value={addChargesData.subtotal} />
              )}
            </div>

            <div>
              Tax Amount : <HandleNaN value={addChargesData.tax_amount} />
            </div>
            <div className="form-container-div-full flex flex_gap_10">
              <div className="form-container-div" style={{ width: "20%" }}>
                <VerticalInputFieldCustom
                  required={true}
                  titleName="Total Amount"
                  name="net_total"
                  type="number"
                  disabled={true}
                  value={
                    addChargesData.gst_type === "inclusive"
                      ? addChargesData.net_total - addChargesData.tax_amount
                      : addChargesData.net_total
                  }
                  onChange={setAddChargesHandleChange}
                />
              </div>
            </div>

            <div className="flex flex_gap_10">
              <BgThemeButton children={"Add"} type={"submit"} />
              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => setAddChargesPopup(!addChargesPopup)}
              />
            </div>
          </form>
        </div>
      )}

      {createNewFolio && (
        <div className="bg-backdrop">
          <form
            onSubmit={(e) => handleCreateNewFolio(e)}
            style={{ width: "360px" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="add-ota-model-container-heading">
                Create New Folio
              </div>
              <div
                onClick={() => setCreateNewFolio(!createNewFolio)}
                style={{ cursor: "pointer" }}
              >
                <Icon source={CancelMajor} color="base" />
              </div>
            </div>

            <div className="form-container-div-half flex_direction_col">
              <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  options={roomNumberArray}
                  titleName="Room No"
                  name="room_no"
                  value={addNewFolioData.room_no}
                  onChange={setAddFolioHandleChange}
                />
              </div>
              <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  options={guestsArray}
                  titleName="Guest Name"
                  name="guest_id"
                  value={addNewFolioData.guest_id}
                  onChange={setAddFolioHandleChange}
                />
              </div>
            </div>

            <div className="flex">
              <BgThemeButton
                style={{ width: "100%" }}
                children={"Create"}
                type={"submit"}
              />
              {/* <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => setCreateNewFolio(!createNewFolio)}
              /> */}
            </div>
          </form>
        </div>
      )}

      {/* split folio */}
      {transferCharges && (
        <div className="bg-backdrop">
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ width: "880px", padding: "0" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
                padding: "20px 20px 0px ",
              }}
            >
              <div className="add-ota-model-container-heading">
                Transfer Charges
              </div>
              <div
                className="pointer"
                onClick={() => {
                  setTransferCharges(false);
                  setTable1Data([]);
                  setTable2Data([]);
                  setLeftFolioId("");
                  setRightFolioId("");
                  setSelectedDateFilterValueLeft("Select Option");
                  setSelectedDateFilterValueRight("Select Option");
                }}
              >
                <Icon source={CancelMajor} color="base" />
              </div>
            </div>
            <div className="transfer_charges_table_parent">
              <div className="transfer_charges_table1">
                <DashboardFilter
                  values={folioFilterOptions}
                  onSelect={handleSelectFolioLeft}
                  selectedValue={selectedDateFilterValueLeft}
                />
                {table1Data?.length > 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <LegacyCard>
                      <IndexTable
                        //  resourceName={resourceName}
                        //  itemCount={currentOrders.length}
                        //  selectedItemsCount={
                        //    allResourcesSelected ? "All" : selectedResources.length
                        //  }
                        //  onSelectionChange={handleSelectionChange}
                        itemCount={table1Data.length}
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources.length
                        }
                        resourceName={resourceName}
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Services" },
                          { title: "Total Amt." },
                        ]}
                      >
                        {rowMarkupTable1}
                      </IndexTable>
                    </LegacyCard>
                  </div>
                ) : (
                  <NoDataFoundSmall />
                )}
              </div>
              <div
                className="transfer_charges_table_mid"
                onClick={() => handleSubmitTransferFolio(selectedResources)}
              >
                <svg
                  width="32"
                  height="30"
                  viewBox="0 0 32 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="30" rx="4" fill="#3968ED" />
                  <path
                    d="M10 9.5L15.5 15L10 20.5"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M16.5 9.5L22 15L16.5 20.5"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                </svg>
              </div>
              <div className="transfer_charges_table2">
                <DashboardFilter
                  values={filteredFolioOptions}
                  onSelect={handleSelectFolioRight}
                  selectedValue={selectedDateFilterValueRight}
                />
                {table2Data.length > 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <LegacyCard>
                      <IndexTable
                        itemCount={table2Data.length}
                        resourceName={resourceName}
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Services" },
                          { title: "Total Amt." },
                        ]}
                        selectable={false}
                      >
                        {rowMarkupTable2}
                      </IndexTable>
                    </LegacyCard>
                  </div>
                ) : (
                  <NoDataFoundSmall />
                )}
              </div>
            </div>
          </form>
        </div>
      )}

      {transferPayments && (
        <div className="bg-backdrop">
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ width: "880px", padding: "0" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
                padding: "20px 20px 0px ",
              }}
            >
              <div className="add-ota-model-container-heading">
                Transfer Payments
              </div>
              <div
                className="pointer"
                onClick={() => {
                  setTransferPayments(false);
                  setTable1DataPayments([]);
                  setTable2DataPayments([]);
                  setLeftFolioId("");
                  setRightFolioId("");
                  setSelectedDateFilterValueLeft("Select Option");
                  setSelectedDateFilterValueRight("Select Option");
                }}
              >
                <Icon source={CancelMajor} color="base" />
              </div>
            </div>
            <div className="transfer_charges_table_parent">
              <div className="transfer_charges_table1">
                <DashboardFilter
                  values={folioFilterOptions}
                  onSelect={handleSelectFolioLeft}
                  selectedValue={selectedDateFilterValueLeft}
                />
                {table1DataPayments?.length > 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <LegacyCard>
                      <IndexTable
                        itemCount={table1DataPayments.length}
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources.length
                        }
                        resourceName={resourceName}
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Type" },
                          { title: "Date" },
                          { title: "Total Amt." },
                        ]}
                      >
                        {rowMarkupTable1Payments}
                      </IndexTable>
                    </LegacyCard>
                  </div>
                ) : (
                  <NoDataFoundSmall />
                )}
              </div>
              <div
                className="transfer_charges_table_mid"
                onClick={() =>
                  handleSubmitPaymentTransferFolio(selectedResources)
                }
              >
                <svg
                  width="32"
                  height="30"
                  viewBox="0 0 32 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="30" rx="4" fill="#3968ED" />
                  <path
                    d="M10 9.5L15.5 15L10 20.5"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M16.5 9.5L22 15L16.5 20.5"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                </svg>
              </div>
              <div className="transfer_charges_table2">
                <DashboardFilter
                  values={filteredFolioOptions}
                  onSelect={handleSelectFolioRight}
                  selectedValue={selectedDateFilterValueRight}
                />
                {table2DataPayments.length > 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <LegacyCard>
                      <IndexTable
                        itemCount={table2DataPayments.length}
                        resourceName={resourceName}
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Type" },
                          { title: "Date" },
                          { title: "Total Amt." },
                        ]}
                        selectable={false}
                      >
                        {rowMarkupTable2Payments}
                      </IndexTable>
                    </LegacyCard>
                  </div>
                ) : (
                  <NoDataFoundSmall />
                )}
              </div>
            </div>
          </form>
        </div>
      )}

      {mergeFolio && (
        <div className="bg-backdrop">
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ width: "350px", padding: "0" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // marginBottom: "15px",
                padding: "20px 20px 0px ",
              }}
            >
              <div className="add-ota-model-container-heading">Merge Folio</div>
              <div
                className="pointer"
                onClick={() => {
                  setMergeFolio(false);
                  setTransferPayments(false);
                  setTable1DataPayments([]);
                  setTable2DataPayments([]);
                  setLeftFolioId("");
                  setRightFolioId("");
                  setSelectedDateFilterValueLeft("Select Option");
                  setSelectedDateFilterValueRight("Select Option");
                }}
              >
                <Icon source={CancelMajor} color="base" />
              </div>
            </div>
            <div
              className="transfer_charges_table1_merge_folio"
              style={{ width: "100%" }}
            >
              <div
              // style={{ marginLeft: "20px", marginRight: "20px" }}
              >
                <div style={{ marginTop: "10px" }}>
                  <VerticalInputSelectCustomCopy
                    required={true}
                    options={allFolioArrayOptions}
                    name="room_bed_type"
                    focused={false}
                    titleName="Merge Folio To"
                    onChange={(e) => handleSelectFolioFilter(e.target.value)}
                    autoComplete="off"
                    value={selectedFolioFilter}
                  />
                </div>
              </div>

              {allFolioArrayFilters?.length > 0 ? (
                <div style={{ marginTop: "20px" }}>
                  <LegacyCard>
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={allFolioArrayFilters.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      onSelectionChange={handleSelectionChange}
                      headings={[{ title: "Folio ID" }, { title: "Folio No" }]}
                    >
                      {rowMarkupAllFolios}
                    </IndexTable>
                  </LegacyCard>

                  <div style={{ margin: "20px" }}>
                    <BgThemeButton
                      style={{ width: "100%" }}
                      onClick={handleFolioMerge}
                    >
                      Merge
                    </BgThemeButton>
                  </div>
                </div>
              ) : (
                <NoDataFoundSmall />
              )}
            </div>
            {/* <div className="transfer_charges_table_parent">
              <div className="transfer_charges_table1">
                <DashboardFilter
                  values={folioFilterOptions}
                  onSelect={handleSelectFolioLeft}
                  selectedValue={selectedDateFilterValueLeft}
                />
                {table1DataPayments?.length > 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <LegacyCard>
                      <IndexTable
                        itemCount={table1DataPayments.length}
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources.length
                        }
                        resourceName={resourceName}
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Type" },
                          { title: "Date" },
                          { title: "Total Amt." },
                        ]}
                      >
                        {rowMarkupTable1Payments}
                      </IndexTable>
                    </LegacyCard>
                  </div>
                ) : (
                  <NoDataFoundSmall />
                )}
              </div>
              <div
                className="transfer_charges_table_mid"
                onClick={() =>
                  handleSubmitPaymentTransferFolio(selectedResources)
                }
              >
                <svg
                  width="32"
                  height="30"
                  viewBox="0 0 32 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="30" rx="4" fill="#3968ED" />
                  <path
                    d="M10 9.5L15.5 15L10 20.5"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M16.5 9.5L22 15L16.5 20.5"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                </svg>
              </div>
              <div className="transfer_charges_table2">
                <DashboardFilter
                  values={filteredFolioOptions}
                  onSelect={handleSelectFolioRight}
                  selectedValue={selectedDateFilterValueRight}
                />
                {table2DataPayments.length > 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <LegacyCard>
                      <IndexTable
                        itemCount={table2DataPayments.length}
                        resourceName={resourceName}
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Type" },
                          { title: "Date" },
                          { title: "Total Amt." },
                        ]}
                        selectable={false}
                      >
                        {rowMarkupTable2Payments}
                      </IndexTable>
                    </LegacyCard>
                  </div>
                ) : (
                  <NoDataFoundSmall />
                )}
              </div>
            </div> */}
          </form>
        </div>
      )}
    </>
  );
};
export default FolioTopBarandNavigation;

import React from "react";
import Pagination from "../../NewCustomComp/pagination/Pagination";
import { SvgCancel } from "../../../assets/svgIcons";
import styles from "./voidBookings.module.scss";
import UnderscoreRemoveCapitalize from "../UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import NoDataFound from "../NoDataFound";
import NoDataFoundSmall from "../NoDataFoundSmall";

const VoidBookings = ({ data, setVoidPopup, clearAllFilters }) => {
  const handlePopupClose = () => {
    clearAllFilters([]);
    setVoidPopup(false);
  };
  const handlePageChange = (page) => {};
  return (
    <div className="global_popup_container_background">
      <div className="global_popup_container" style={{ width: "80%" }}>
        <div className="flex justify_content_between align_items_center mb-10">
          <h3 className="add-ota-model-container-heading">Void Bookings</h3>
          <span onClick={handlePopupClose} className="pointer">
            <SvgCancel color={"#333"} height={"20px"} width={"20px"} />
          </span>
        </div>

        <div>
          {data && data.data && data.data.length > 0 ? (
            <>
              <table className={styles.void_bookings_table}>
                <thead>
                  <tr>
                    <th>ID</th>

                    <th>Type</th>
                    <th>Duplicate Booking ID</th>
                    <th>Original Booking ID</th>
                    <th>Remarks</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>

                      <td>{item.type.replaceAll("_", " ")}</td>
                      <td>{item.duplicate_booking_id || "N/A"}</td>
                      <td>{item.original_booking_id || "N/A"}</td>
                      <td>{item.remarks || "N/A"}</td>
                      <td>{item.created_by || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <NoDataFoundSmall />
          )}
        </div>

        <div className="mt-10">
          <Pagination
            totalPages={data.current_page}
            currentPage={data.current_page}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default VoidBookings;

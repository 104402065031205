import React from "react";
const BookingPriceBreakup = ({ data }) => {
  return (
    <div>
      <div
        className="boking_details_popup_overview mt-10"
      >
        <h3>Price Breakup</h3>
      </div>

      <div className="booking_details price_breakup d_flex">
        <div className="booking_details_col">
          <div className="total_amt">
            <h3 style={{ color: "red" }}>Subtotal</h3>
            <p style={{ color: "red" }}>{data?.taxable_amount || "N/A"}</p>
          </div>
        </div>
        <div className="booking_details_col">
          <div className="total_amt">
            <h3 style={{ color: "#333" }}>Tax Amt.</h3>
            <p style={{ color: "#333" }}>{data?.total_tax_amount || "N/A"}</p>
          </div>
        </div>
        <div className="booking_details_col">
          <div className="total_amt">
            <h3>Total Amt.</h3>
            <p>{data?.grand_total || "N/A"}</p>
          </div>
        </div>
        <div className="booking_details_col">
          <div className="paid_amt">
            <h3>Paid Amt.</h3>
            <p>{data?.paid_amount || "N/A"}</p>
          </div>
        </div>
        <div className="booking_details_col">
          <div className="due_amt">
            <h3>Due Amt.</h3>
            <p>{data?.balance_amount || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPriceBreakup;
 // functions for calculating the subtotal and taxAmount and grand total for Exclusive
  export function calculateSubtotalForExclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    discountAmount
  ) {
    if (nights === 0) {
      return 0;
    }
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;
    return total;
  }
  export function taxAmountForExclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    taxRate,
    discountAmount
  ) {
    if (nights === 0) {
      return 0;
    }
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;
    const taxAmount = (total * taxRate) / 100;
    return taxAmount;
  }
  export function grandTotalForExclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    taxRate,
    discountAmount
  ) {
    if (nights === 0) {
      return 0;
    }
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;
    const taxAmount = (total * taxRate) / 100;
    return total + taxAmount;
  }
  //----------------------------------------------------------------------------

  // --------functions for calculating the subtotal and taxAmount and grand total for inclusive

  export function calculateSubtotalForInclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    taxRate,
    discountAmount
  ) {
    if (nights === 0) {
      return 0;
    }
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;
    const subTotal = (total * 100) / (100 + taxRate);
    return subTotal;
  }
  export function taxAmountForInclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    taxRate,
    discountAmount
  ) {
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;
    const taxAmount = (total * taxRate) / (100 + taxRate);

    return taxAmount;
  }
  export function grandTotalForInclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    discountAmount
  ) {
    if (nights === 0) {
      return 0;
    }
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;

    return total;
  }


  export const globalCalculationFunction  = ( roomRate , taxType , noOfRooms , noOfNights ) => {
     let taxRate = "";
     if( roomRate  >= 7500 ){
      taxRate = 18;
     } else {
      taxRate = 12;
     }

     switch (taxType) {
       case "inclusive":
         return {

         }

       case "exclusive":
        return{

        }
     }

     return {
       taxRate : taxRate

     }
    
  }
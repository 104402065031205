import { useState, useEffect } from "react";
import "./bookingstatusfilter.css";
import { CheckWithoutBgUnderlineBtn } from "../../UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import VoidBookings from "../BookingDetails/VoidBookings";

const BookingStatusFilter = ({
  title,
  bookingFIlterData,
  getSelectedBookingFiltersFromChild,
  clearAllFilters,
  property_id,
  userToken,
  
}) => {
  const [isPopupOpen, setVoidPopup] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const getVoidBookingListing = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/void/bookings/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        console.log(data);
        setVoidPopup(true)
        setData(data.data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error updating discount:", error);
      showErrorToast("Error updating discount");
    }
  };

  useEffect(() => {
    const element = document.querySelector(".booking-choice-full-parent-container-left");

    if (isPopupOpen) {
      // Remove sticky style when popup is open
      element.style.position = "static";
    } else {
      // Reapply sticky style when popup is closed
      element.style.position = "sticky";
      element.style.top = "70px";
      element.style.height = "100%";
    }
  }, [isPopupOpen]);

  useEffect(() => {
    if (selectedCheckboxes.includes("void")) {
      return;
    } else {
      getSelectedBookingFiltersFromChild(selectedCheckboxes);
    }
  }, [selectedCheckboxes, getSelectedBookingFiltersFromChild]);

  const handleCheckboxChange = (value) => {
    if (value === "void") {
      if (selectedCheckboxes.includes("void")) {
        setSelectedCheckboxes([]);
        setVoidPopup(false)
      } else {
        setSelectedCheckboxes([value]);
        getVoidBookingListing();
      }

      return;
    }
    if (selectedCheckboxes.includes(value)) {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== value)
      );
    } else {
      setSelectedCheckboxes([
        ...selectedCheckboxes.filter((item) => item !== "void"),
        value,
      ]);
    }
  };

  const clearAllHandler = () => {
    setSelectedCheckboxes([]);
  };

  return (
    <>
      <div
        className="booking_status_parent"
        style={{
          width: "100%",
          padding: "15px",
          borderBottom: "0.5px solid #E5E5E5",
        }}
      >
        <div className="booking_status_content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              width: "100%",
            }}
          >
            <div className="booking_filter_title">{title}</div>
            <CheckWithoutBgUnderlineBtn
              style={{ fontSize: "11px" }}
              onClick={() => setSelectedCheckboxes([])}
            >
              Clear All
            </CheckWithoutBgUnderlineBtn>
          </div>
          {bookingFIlterData.map((item, index) => (
            <div key={index}>
              <label className="checkbox_label">
                <input
                  type="checkbox"
                  value={item.value}
                  checked={selectedCheckboxes.includes(item.value)}
                  onChange={() => handleCheckboxChange(item.value)}
                />
                {item.label}
              </label>
            </div>
          ))}
        </div>
      </div>
     {
      isPopupOpen && <VoidBookings data={data}  setVoidPopup={setVoidPopup} clearAllFilters={clearAllHandler}/>
     }
    </>
  );
};
export default BookingStatusFilter;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BgOutlineThemeButton, BgThemeButton } from "../../../UI/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import VerticalInputFieldCustom from "../../VerticalInputFieldCustom.jsx";
import {
  grandTotalForInclusive,
  grandTotalForExclusive,
  taxAmountForInclusive,
  taxAmountForExclusive,
  calculateSubtotalForInclusive,
  calculateSubtotalForExclusive,
} from "../calculationFunctions/CalculationFunctions.jsx";
import { calculateTotal } from "../calculationFunctions/CalculateKeyTotalFromArrayOfObjects";
import { getDecryptedData } from "../../../../utils/encryptStorage.js";

const ModifyReservationConfirmPaxChange = ({
  bookingData,
  addOnCost,
  reasons,
  setReasons,
  setShowModifyPopup,
  handleReasonChange,
  getBookingData,
  fetchBookingDetails,
  handlePopupClose
}) => {
  const extractPrices = (paxes) => {
    let childPrice = 0;
    let extraAdultPrice = 0;
  
    // Check if paxes is an array before iterating over it
    if (Array.isArray(paxes)) {
      paxes.forEach((pax) => {
        // Use adhoc_price if available; otherwise, use price
        const price = parseFloat(pax.adhoc_price) || parseFloat(pax.price);
        
        if (pax.pax_type === "child") {
          childPrice += price;
        } else if (pax.pax_type === "adult") {
          extraAdultPrice += price;
        }
      });
    } else {
      console.error("Invalid paxes data:", paxes);
    }
  
    return { childPrice, extraAdultPrice };
  };
  const [loader,setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const navigate = useNavigate();

  const [paxes, setPaxes] = useState([]);

  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData?.items?.map((item) => ({
        booking_id: item.id,
        room_id: item.room_id,
        rate_plan_id: item.rate_plan_id,
        adults: item.adults,
        children: item.children,
        no_child: item.no_child,
        no_adult: item.no_adult,
        tax_type: item.tax_type,
        tax_rate: item.tax_rate,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        room_rate_per_night: item.room_rate,
        category: item.room_type_name,
        extraAdult: item.extra_adult,
        paxes: item.paxes,
        newExtraAdult: "",
        newExtraAdultCost: "",
        discount_amount: item.discount_amount,
        booking_item_id: item.id,
      }));

      setPaxes(newEditedRoomCategory);
    }
  }, [bookingData]);

  const handleModifyPaxExtraAdult = (e, index, item) => {
    const { name, value } = e.target;

    // if (item?.limits?.extra_bedding === "disabled") {
    //     showErrorToast("Extra Bedding not allowed for this room category");
    //     return;
    // }

    // const backendMaxOccupancy = item?.limits?.max_adult * +item.no_of_rooms;
    // const frontEndMaxOccupancy = +item.no_adult + +item.no_child;
    // const maxExtraAdultsAllowed = backendMaxOccupancy - frontEndMaxOccupancy;

    // if (+value > +maxExtraAdultsAllowed) {
    //     showErrorToast("Not allowed, please increase the number of rooms or reduce the number of extra adults");
    //     return;
    // }

    if (+value > item?.limits?.allowedExtraAdult * +item.no_of_rooms) {
      showErrorToast("Not allowed, please increase the number of rooms");
      return;
    }

    setPaxes((prevPaxes) => {
      const updatedPaxes = [...prevPaxes];
      updatedPaxes[index] = {
        ...updatedPaxes[index],
        [name]: value,
      };
      return updatedPaxes;
    });

    setShowPaxCost(false);
    setPaxTotal(0);
  };

  const handleModifyPaxExtraAdultCost = (e, index) => {
    const { name, value } = e.target;
    setPaxes((prevPaxes) => {
      const updatedPaxes = [...prevPaxes];
      updatedPaxes[index] = {
        ...updatedPaxes[index],
        [name]: value,
      };
      return updatedPaxes;
    });
    setShowPaxCost(false);
    setPaxTotal(0);
    return; // Exit the function early
  };
  const [showPaxCost, setShowPaxCost] = useState(false);
  const [paxTotal, setPaxTotal] = useState(0);
  const [enabledPaxes, setEnabledPaxes] = useState([]);

  const handlePaxCalculation = () => {
    const keysToCheck = ["newExtraAdult", "newExtraAdultCost"];
    if (checkEmptyValuesInObjects(paxes, keysToCheck, enabledPaxes)) {
      return;
    }
   

    const room = paxes.map((item) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      const taxType = item.tax_type;
      const taxRate = item.tax_rate;
      const ratePerNight = item.room_rate_per_night;
      const extraAdult = item.newExtraAdult ? item.newExtraAdult : 1;

      const extraAdultCost = item.newExtraAdult
        ? item.newExtraAdultCost
        : extraAdultPrice;

      return {
        BookingItemId: item.booking_item_id,
        RoomID: item.room_id,
        RatePlanID: item.rate_plan_id,
        baseAdult: item.no_adult,
        Child: item.no_child,
        extraAdult: item.newExtraAdult ? item.newExtraAdult : item.extraAdult,
        Rate: ratePerNight,
        TaxRate: item.tax_rate,
        discountAmount: item.discount_amount,
        NetTotal:
          taxType === "inclusive"
            ? grandTotalForInclusive(
                +ratePerNight,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +item.discount_amount
              )
            : grandTotalForExclusive(
                +ratePerNight,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +item.discount_amount
              ),
        TaxAmount:
          taxType === "inclusive"
            ? taxAmountForInclusive(
                +ratePerNight,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +item.discount_amount
              )
            : taxAmountForExclusive(
                +ratePerNight,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +item.discount_amount
              ),
        TaxbleTotal:
          taxType === "inclusive"
            ? calculateSubtotalForInclusive(
                +ratePerNight,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +item.discount_amount
              )
            : calculateSubtotalForExclusive(
                +ratePerNight,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +item.discount_amount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "update_adult",
      bookingNetTotal: +calculateTotal(room, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(room, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(room, "TaxAmount") + +addOnCost.taxAmount,
      Room: room,
    };
    setPaxTotal(requestBody.bookingNetTotal);
    setShowPaxCost(true);
  };

  const handlePaxCalculationSubmit = async () => {
    if (reasons.paxDetailReason === "") {
      showErrorToast("Please enter a reason for changing pax details");
      return;
    }
   
    const room = paxes.map((item) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      const taxType = item.tax_type;
      const taxRate = item.tax_rate;
      const ratePerNight = item.room_rate_per_night;
      const extraAdult = item.newExtraAdult ? item.newExtraAdult : 1;

      const extraAdultCost = item.newExtraAdult
        ? item.newExtraAdultCost
        : extraAdultPrice;

      return {
        BookingItemId: item.booking_item_id,
        RoomID: item.room_id,
        RatePlanID: item.rate_plan_id,
        RoomDiscount: item.room_discount,
        baseAdult: item.no_adult,
        Child: item.no_child,
        extraAdult: item.newExtraAdult ? item.newExtraAdult : item.extraAdult,
        Rate: ratePerNight,
        TaxRate: item.tax_rate,
        RoomDiscount: item.discount_amount,
        NetTotal:
          taxType === "inclusive"
            ? grandTotalForInclusive(
                +ratePerNight,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +item.discount_amount
              )
            : grandTotalForExclusive(
                +ratePerNight,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +item.discount_amount
              ),
        TaxAmount:
          taxType === "inclusive"
            ? taxAmountForInclusive(
                +ratePerNight,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +item.discount_amount
              )
            : taxAmountForExclusive(
                +ratePerNight,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +item.discount_amount
              ),
        TaxbleTotal:
          taxType === "inclusive"
            ? calculateSubtotalForInclusive(
                +ratePerNight,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +item.discount_amount
              )
            : calculateSubtotalForExclusive(
                +ratePerNight,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +item.discount_amount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "update_adult",
      bookingNetTotal: +calculateTotal(room, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(room, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(room, "TaxAmount") + +addOnCost.taxAmount,
      bookingDiscountAmount:
        +bookingData.total_discount_amount + +addOnCost.discountAmount,
      bookingDueAmount:
        +(+calculateTotal(room, "NetTotal") + +addOnCost.NetTotal) -
        +bookingData.paid_amount,
      paidAmount: bookingData.paid_amount,
      Room: room,
      requestReason: reasons.paxDetailReason,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modify/adult/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Guest Details updated Successfully");
      setShowPaxCost(false);
      setPaxTotal(0);
      fetchBookingDetails();
      getBookingData(bookingData.unique_booking_id);
      setReasons({})
      handlePopupClose();
      setLoader(false);
      return data;
    } catch (error) {
      console.error(error);
      setLoader(false);
      showErrorToast(error.message); // Pass the error message to showErrorToast
      return null;
    }
  };

  const handlePaxChangeDisableEnable = async (e, index, room_id) => {
    const checked = e.target.checked;
    if (checked) {
      try {
        setLoader(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${bookingData.check_in}&check_out=${bookingData.check_out}&room_id=${room_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setPaxes((prevPaxes) => {
          const updatedPaxes = [...prevPaxes];
          updatedPaxes[index] = {
            ...prevPaxes[index],
            limits: data,
          };
          return updatedPaxes;
        });

        setLoader(false);

        if (data.message === "Unauthorised.") {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        setLoader(false);
      }

      setEnabledPaxes((prevState) => [...prevState, index]);
    } else {
      setEnabledPaxes((prevState) =>
        prevState.filter((item) => item !== index)
      );
      setPaxes((prevPaxes) => {
        const updatedPaxes = [...prevPaxes];
        updatedPaxes[index] = {
          ...prevPaxes[index],
          limits: null,
          newExtraAdult: "",
          newExtraAdultCost: "",
        };
        return updatedPaxes;
      });
    }
    setPaxTotal(0);
    setShowPaxCost(false);
  };

 

  function checkEmptyValuesInObjects(data, keysToCheck, enabledPaxes) {
    const invalidObjects = data.filter((obj, index) => {
      // Check if the current index is included in enabledPaxes array
      if (enabledPaxes.includes(index)) {
        return keysToCheck.some((key) => obj[key] === "");
      }
      return false;
    });

    if (invalidObjects.length > 0) {
      const invalidKeys = invalidObjects.flatMap((obj) =>
        keysToCheck.filter((key) => obj[key] === "")
      );
      showErrorToast("Please fill in all required fields");
      return true;
    }
    return false;
  }

 
  return (
    <div>
      <from>
        <table
          className="modifyReservationPopupTable"
          style={{ borderSpacing: "10px", width: "80%" }}
        >
          <thead>
            <tr>
              <th />
              <th>Room Category</th>
              <th>Extra Adults</th>
              <th>Change To</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            {paxes.map((item, index) => (
              <tr key={index}>
                <td
                  style={{
                    verticalAlign: "bottom",
                    paddingBottom: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                    onChange={(e) =>
                      handlePaxChangeDisableEnable(e, index, item.room_id)
                    }
                    checked={enabledPaxes.includes(index)}
                  />
                </td>
                <td>
                  <VerticalInputFieldCustom
                    value={item?.category?.toUpperCase()}
                    disabled={true}
                  />
                </td>
                <td>
                  <VerticalInputFieldCustom
                    value={item.extraAdult}
                    disabled={true}
                  />
                </td>
                <td>
                  <VerticalInputFieldCustom
                    value={item.newExtraAdult}
                    type={"number"}
                    name="newExtraAdult"
                    disabled={!enabledPaxes.includes(index)}
                    onChange={(e) => handleModifyPaxExtraAdult(e, index, item)}
                  />
                </td>
                <td>
                  <VerticalInputFieldCustom
                    name="newExtraAdultCost"
                    value={item.newExtraAdultCost}
                    type={"number"}
                    disabled={!enabledPaxes.includes(index)}
                    onChange={(e) => handleModifyPaxExtraAdultCost(e, index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="modify_reservation_reason_container">
          <VerticalInputFieldCustom
            titleName={"Reason to Modify*"}
            name="paxDetailReason"
            // guestDetailsReason
            value={reasons.paxDetailReason}
            onChange={handleReasonChange}
          />

          <div className="modify_reservation_reason_container flex flex_gap_10 align_items_center">
            <label>New Total:</label>

            <VerticalInputFieldCustom value={paxTotal} disabled={true} />

            <div>Previous Total : {bookingData?.grand_total}</div>
          </div>
        </div>

        <div className="flex flex_gap_10">
          <BgThemeButton
            children={!showPaxCost ? "Calculate" : "Modify"}
            onClick={
              !showPaxCost ? handlePaxCalculation : handlePaxCalculationSubmit
            }
            loader={loader}
          />
          <BgOutlineThemeButton
            children={"Cancel"}
            onClick={handlePopupClose}
          />
        </div>
      </from>
    </div>
  );
};

export default ModifyReservationConfirmPaxChange;

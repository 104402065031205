import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BgOutlineThemeButton, BgThemeButton } from "../../../UI/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import VerticalInputFieldCustom from "../../VerticalInputFieldCustom.jsx";
import {
  grandTotalForInclusive,
  grandTotalForExclusive,
  taxAmountForInclusive,
  taxAmountForExclusive,
  calculateSubtotalForInclusive,
  calculateSubtotalForExclusive,
} from "../calculationFunctions/CalculationFunctions.jsx";
import {
  calculateTotal,
  calculateNumberOfNights,
} from "../calculationFunctions/CalculateKeyTotalFromArrayOfObjects";
import { getDecryptedData } from "../../../../utils/encryptStorage.js";

const ModifyReservationArrivalsAmendStay = ({
  bookingData,
  addOnCost,
  reasons,
  setReasons,
  setShowModifyPopup,
  handleReasonChange,
  getBookingData,
  propertyCurrentDate,
  formatDateMMDDYYYY,
  formatDate,
  calculateToDatePlus,
  handlePopupClose,
  chargesData
}) => {
  const extractPrices = (paxes) => {
    let childPrice = 0;
    let extraAdultPrice = 0;
  
    // Check if paxes is an array before iterating over it
    if (Array.isArray(paxes)) {
      paxes.forEach((pax) => {
        // Use adhoc_price if available; otherwise, use price
        const price = parseFloat(pax.adhoc_price) || parseFloat(pax.price);
        
        if (pax.pax_type === "child") {
          childPrice += price;
        } else if (pax.pax_type === "adult") {
          extraAdultPrice += price;
        }
      });
    } else {
      console.error("Invalid paxes data:", paxes);
    }
  
    return { childPrice, extraAdultPrice };
  };
  const [loader, setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const navigate = useNavigate();
  const [amendStayData, setAmentStayData] = useState([]);
  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData.items.map((item) => ({
        room_category: item.room_type_name,
        changed_room_category: "",
        room_id: item.room_id,
        rate_plan_id: item.rate_plan_id,
        meal_plan: item.room_plan,
        room_rate_per_night: item.room_rate,
        ad_Hoc: item.ad_Hoc || "",
        room_rate: [],
        paxes: item.paxes,
        tax_type: item.tax_type,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        booking_id: item.id,
        discount_rate: item.discount_rate,
        id: item.rate_plan_id,
        new_room_rate: "",
        old_tax_amount: item.tax_amount,
        old_discount_amount: item.discount_amount,
        old_grand_total: item.room_grand_total,
        old_sub_total: item.room_total_amount,
        booking_item_id: item.id,
      }));



      setAmentStayData(newEditedRoomCategory);
      setActiveIndex(Array(newEditedRoomCategory.length).fill(false));
      localStorage.setItem(
        "amendStayData",
        JSON.stringify(newEditedRoomCategory)
      );
    }
  }, [bookingData]);


  const [amendStayDiscount, setAmendStayDiscount] = useState(0);
  const handleAmendStayDiscountChange = (event) => {
    setAmendStayDiscount(event.target.value);
    setShowCheckOutCalculations(false);
    setAmendStayTotal(0);
  };
  useEffect(() => {
    setAmendStayDiscount(bookingData.total_discount_amount);
  }, [bookingData.total_discount_amount]);

  //-----early check in check out
  const [showCheckOutCalculations, setShowCheckOutCalculations] =
    useState(false);
  const [amendStayTotal, setAmendStayTotal] = useState(0);

  const [earlyCheckInDate, setEarlyCheckInDate] = useState("");

  const handleEarlyCheckOutChange = (e) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);
    const selectedDate = new Date(e.target.value);
    const checkInDate = new Date(bookingData.check_in);
    const checkOutDate = new Date(bookingData.check_out);

    if (selectedDate < checkInDate || selectedDate > checkOutDate) {
      showErrorToast(
        "Please select a date between booking check-in and check-out dates."
      );
      setEarlyCheckInDate("");
    } else {
      setEarlyCheckInDate(selectedDate);
    }
  };

  const [extraCharges, setExtraCharges] = useState(0);

  const handleEarlyCheckOutCalculate = (e) => {
    if (!earlyCheckInDate) {
      showErrorToast("Please select early check-out date");
      return;
    }
    

    const newNights = calculateNumberOfNights(
      new Date(propertyCurrentDate),
      new Date(earlyCheckInDate)
    );

   

    const roomsNew = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const totalDiscountAmount =
        (+item.old_discount_amount / +item.no_of_nights) * +newNights;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;
      return {
        BookingItemId: item.booking_item_id,
        RoomID: item.room_id,

        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        DiscountAmount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    function mergeRoomArrays(roomsNew) { 
      return roomsNew.map((roomNew, index) => {
        return {
          ...roomNew,
          RoomDiscount:  +roomNew.RoomDiscount,
          NetTotal:  +roomNew.NetTotal,
          TaxAmount: +roomNew.TaxAmount,
          TaxbleTotal: +roomNew.TaxbleTotal,
        };
      });
    }

    const mergedRooms = mergeRoomArrays(roomsNew);

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      folioId: bookingData.folioId,
      requestType: "early_checkout",
      CheckInDate: bookingData.check_out,
      CheckOutDate: formatDate(earlyCheckInDate),
      Room: mergedRooms,
      requestReason: reasons.amendStayReason,
      extraCharges: extraCharges,
      requestReason: reasons.changeMealPlanReason,
      bookingNetTotal:
      calculateTotal(mergedRooms, "NetTotal") +
      addOnCost.NetTotal +
      chargesData.grandTotal,
      bookingTaxableAmount:
      calculateTotal(mergedRooms, "TaxbleTotal") +
      addOnCost.taxAmount +
      chargesData.subTotal,
      bookingTaxAmount:
      calculateTotal(mergedRooms, "TaxAmount") +
      addOnCost.taxAmount +
      chargesData.taxAmount,
      bookingDiscountAmount:
      calculateTotal(mergedRooms, "RoomDiscount") +
      addOnCost.discountAmount +
      (chargesData.discountAmount + chargesData.tacAmount),
     
      bookingDueAmount:
      +(
        +calculateTotal(mergedRooms, "NetTotal") +
        +addOnCost.NetTotal +
        chargesData.grandTotal
      ) - +bookingData.paid_amount,
     
    };

    setAmendStayTotal(requestBody.bookingNetTotal);
    setShowCheckOutCalculations(true);
  };
 
  const handleEarlyCheckOutSubmit = async (e) => {
    if (reasons.amendStayReason === "") {
      showErrorToast("Please enter the reason for early checkout");
      return;
    }

    // this function will perform the addition of all the child paxes and adult paxes so no need to multiply with no of child and no of adults
    if (!earlyCheckInDate) {
      showErrorToast("Please select early check-out date");
      return;
    }
    const oldNights = calculateNumberOfNights(
      new Date(bookingData.check_in),
      new Date(propertyCurrentDate) // This is the current date
    );

   

    const newNights = calculateNumberOfNights(
      new Date(propertyCurrentDate),
      new Date(earlyCheckInDate)
    );

   

    const roomsNew = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const totalDiscountAmount =
        (+item.old_discount_amount / +item.no_of_nights) * +newNights;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;

      return {
        BookingItemId: item.booking_item_id,
        RoomID: item.room_id,
        NumberOfNights: +newNights + +oldNights,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        RoomDiscount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    function mergeRoomArrays(roomsNew) {
      

      return roomsNew.map((roomNew, index) => {
       

        return {
          ...roomNew,
          RoomDiscount:  +roomNew.RoomDiscount,
          NetTotal: +roomNew.NetTotal,
          TaxAmount:  +roomNew.TaxAmount,
          TaxbleTotal: +roomNew.TaxbleTotal,
        };
      });
    }

    const mergedRooms = mergeRoomArrays( roomsNew);

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      folioId: bookingData.folioId,
      requestReason: reasons.amendStayReason,
      requestType: "early_checkout",
      CheckInDate: bookingData.check_out,
      CheckOutDate: formatDate(earlyCheckInDate),
      extraCharges: extraCharges,
      Room: mergedRooms,
      bookingNetTotal:
      calculateTotal(mergedRooms, "NetTotal") +
      addOnCost.NetTotal +
      chargesData.grandTotal,
      bookingTaxableAmount:
      calculateTotal(mergedRooms, "TaxbleTotal") +
      addOnCost.taxAmount +
      chargesData.subTotal,
      bookingTaxAmount:
      calculateTotal(mergedRooms, "TaxAmount") +
      addOnCost.taxAmount +
      chargesData.taxAmount,
      bookingDiscountAmount:
      calculateTotal(mergedRooms, "RoomDiscount") +
      addOnCost.discountAmount +
      (chargesData.discountAmount + chargesData.tacAmount),
     
      bookingDueAmount:
      +(
        +calculateTotal(mergedRooms, "NetTotal") +
        +addOnCost.NetTotal +
        chargesData.grandTotal
      ) - +bookingData.paid_amount,
      
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.description || data.error || "Unknown error");
      }

      showSuccessToast("Stay has been reduced successfully");
      setAmendStayTotal(0);
      setShowCheckOutCalculations(false);
      // fetchBookingDetails();
      handlePopupClose();
      setEarlyCheckInDate("");
      setReasons({});
      getBookingData(bookingData.unique_booking_id);
      setLoader(false);

      return data;
    } catch (error) {
      showErrorToast(error.message || error.description);
      setLoader(false);
      return null;
    }
  };

  // -----------late check out----------------------
  const [showExtendStay, setShowExtendStay] = useState(false);
  const [lateCheckOutDate, setLateCheckOutDate] = useState("");

  const handleLateCheckOutChange = async (e) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);

    const selectedDate = new Date(e.target.value);
    const checkOutDate = new Date(bookingData.check_out);

    if (selectedDate < checkOutDate) {
      showErrorToast(
        "Please select a date later than the current checkout date"
      );
      setLateCheckOutDate("");
    } else {
      const requestBody = {
        reservationId: bookingData.unique_booking_id,
        requestType: "check_availability",
        CheckInDate: formatDate(selectedDate),
        CheckOutDate: formatDate(checkOutDate),
        requestReason: "late_checkout",
        Room: bookingData.items.map((room) => ({
          BookingItemId: room.id,
          RoomID: room.room_id,
          NumberOfRooms: room.no_of_rooms,
        })),
      };

      try {
        setLoader(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        const data = await response.json();

        if (data.success) {
          showSuccessToast("Selected date is available for late checkout");
          setLateCheckOutDate(selectedDate);
        } else {
          showErrorToast("Selected date is not available for late checkout");
          setLateCheckOutDate("");
        }
        setLoader(false);
      } catch (error) {
        // Handle any network or server errors
        console.error("Error while checking availability:", error);
        showErrorToast(
          "An error occurred while checking availability. Please try again later."
        );
        setLateCheckOutDate("");
        setLoader(false);
      }
    }
  };

  const handleLateCheckOutCalculate = (e) => {
    if (!lateCheckOutDate) {
      showErrorToast("Please select a date for late checkout");
      return;
    }
    const oldNights = calculateNumberOfNights(
      new Date(bookingData.check_in),
      new Date(propertyCurrentDate) // This is the current date
    );
    const newNights = calculateNumberOfNights(
      new Date(propertyCurrentDate),
      new Date(lateCheckOutDate)
    );

   

    const roomsNew = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const totalDiscountAmount =
        (+item.old_discount_amount / +item.no_of_nights) * +newNights;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;

      return {
        BookingItemId: item.booking_item_id,
        RoomID: item.room_id,
        NumberOfNights: +newNights + +oldNights,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        DiscountAmount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    function mergeRoomArrays(roomsNew) {
      

      return roomsNew.map((roomNew, index) => {
        return {
          ...roomNew,
          RoomDiscount:  +roomNew.RoomDiscount,
          NetTotal:  +roomNew.NetTotal,
          TaxAmount: +roomNew.TaxAmount,
          TaxbleTotal: +roomNew.TaxbleTotal,
        };
      });
    }

    const mergedRooms = mergeRoomArrays(roomsNew);

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      folioId: bookingData.folioId,
      requestType: "extend_stay",
      CheckInDate: bookingData.check_out,
      CheckOutDate: formatDate(lateCheckOutDate),
      Room: mergedRooms,
      bookingNetTotal:
      calculateTotal(mergedRooms, "NetTotal") +
      addOnCost.NetTotal +
      chargesData.grandTotal,
      bookingTaxableAmount:
      calculateTotal(mergedRooms, "TaxbleTotal") +
      addOnCost.taxAmount +
      chargesData.subTotal,
      bookingTaxAmount:
      calculateTotal(mergedRooms, "TaxAmount") +
      addOnCost.taxAmount +
      chargesData.taxAmount,
      bookingDiscountAmount:
      calculateTotal(mergedRooms, "RoomDiscount") +
      addOnCost.discountAmount +
      (chargesData.discountAmount + chargesData.tacAmount),
      bookingDueAmount:
      +(
        +calculateTotal(mergedRooms, "NetTotal") +
        +addOnCost.NetTotal +
        chargesData.grandTotal
      ) - +bookingData.paid_amount,
    };

    setAmendStayTotal(requestBody.bookingNetTotal);
    setShowCheckOutCalculations(true);
  };

  const handleLateCheckOutSubmit = async (e) => {
    if (!lateCheckOutDate) {
      showErrorToast("Please select a date for late checkout");
      return;
    }
    if (reasons.amendStayReason === "") {
      showErrorToast("Please enter the reason for late checkout");
      return;
    }
    if (!lateCheckOutDate) {
      showErrorToast("Please select the late checkout date");
      return;
    }
   

    const oldNights = calculateNumberOfNights(
      new Date(bookingData.check_in),
      new Date(propertyCurrentDate) // This is the current date
    );

    const newNights = calculateNumberOfNights(
      new Date(propertyCurrentDate),
      new Date(lateCheckOutDate)
    );

   

    const roomsNew = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const totalDiscountAmount =
        (+item.old_discount_amount / +item.no_of_nights) * +newNights;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;

      return {
        BookingItemId: item.booking_item_id,
        RoomID: item.room_id,
        NumberOfNights: +newNights + +oldNights,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        RoomDiscount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    function mergeRoomArrays(roomsNew) {
      
      return roomsNew.map((roomNew, index) => {
        return {
          ...roomNew,
          RoomDiscount:  +roomNew.RoomDiscount,
          NetTotal:  +roomNew.NetTotal,
          TaxAmount: +roomNew.TaxAmount,
          TaxbleTotal:  +roomNew.TaxbleTotal,
        };
      });
    }

    const mergedRooms = mergeRoomArrays(roomsNew);

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      folioId: bookingData.folioId,
      requestReason: reasons.amendStayReason,
      requestType: "extend_stay",
      CheckInDate: bookingData.check_out,
      CheckOutDate: formatDate(lateCheckOutDate),
      Room: mergedRooms,
      bookingNetTotal:
      calculateTotal(mergedRooms, "NetTotal") +
      addOnCost.NetTotal +
      chargesData.grandTotal,
      bookingTaxableAmount:
      calculateTotal(mergedRooms, "TaxbleTotal") +
      addOnCost.taxAmount +
      chargesData.subTotal,
      bookingTaxAmount:
      calculateTotal(mergedRooms, "TaxAmount") +
      addOnCost.taxAmount +
      chargesData.taxAmount,
      bookingDiscountAmount:
      calculateTotal(mergedRooms, "RoomDiscount") +
      addOnCost.discountAmount +
      (chargesData.discountAmount + chargesData.tacAmount),
     
      bookingDueAmount:
      +(
        +calculateTotal(mergedRooms, "NetTotal") +
        +addOnCost.NetTotal +
        chargesData.grandTotal
      ) - +bookingData.paid_amount,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.description || data.error || "Unknown error");
      }

      showSuccessToast("Stay has been extended successfully");
      setAmendStayTotal(0);
      setShowCheckOutCalculations(false);
      handlePopupClose();
      // fetchBookingDetails();
      setLateCheckOutDate("");
      setReasons({});
      getBookingData(bookingData.unique_booking_id);

      return data;
    } catch (error) {
      showErrorToast(error.message || error.description || "Unknown error");
      return null;
    }
  };

  const [activeIndex, setActiveIndex] = useState([]);

  const handleAmendStayCheckboxChange = (e, roomIndex) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);
    const checked = e.target.checked;
    if (checked) {
      setActiveIndex((prev) => [...prev, roomIndex]);
    } else {
      setActiveIndex((prev) => prev.filter((index) => index !== roomIndex));
      const storedData = JSON.parse(localStorage.getItem("amendStayData"));

      setAmentStayData((prevEditMealPlans) => {
        const updatedData = [...prevEditMealPlans];
        updatedData[roomIndex] = storedData[roomIndex];
        return updatedData;
      });
    }
  };
  const handleAmendStayRateChange = (e, roomIndex) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);

    const { name, value } = e.target;
    setAmentStayData((prev) => {
      const updatedData = [...prev];
      updatedData[roomIndex] = {
        ...updatedData[roomIndex],
        [name]: value,
      };
      return updatedData;
    });
  };
  const handleAmendStayDiscountChangeSingle = (e, roomIndex) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);
    const { name, value } = e.target;
    setAmentStayData((prev) => {
      const updatedData = [...prev];
      updatedData[roomIndex] = {
        ...updatedData[roomIndex],
        [name]: value,
      };
      return updatedData;
    });
  };
  return (
    <>
      <div>
        <table
          className="modifyReservationPopupTable"
          style={{
            width: "60%",
            borderSpacing: "10px 5px",
          }}
        >
          <thead>
            <tr>
              <th>
                <label
                  className="flex flex_gap_5"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    checked={!showExtendStay}
                    onChange={() => {
                      setShowExtendStay(!showExtendStay);
                      setShowCheckOutCalculations(false);
                      setAmendStayTotal(0);
                      setLateCheckOutDate("");
                      setAmendStayDiscount(0);
                      setEarlyCheckInDate("");
                    }}
                    style={{ marginRight: "5px" }}
                  />{" "}
                  <span> Early Check-out</span>
                </label>
              </th>
              <th>
                <label
                  className="flex flex_gap_5"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    checked={showExtendStay}
                    onChange={() => {
                      setShowExtendStay(!showExtendStay);
                      setShowCheckOutCalculations(false);
                      setAmendStayTotal(0);
                      setLateCheckOutDate("");
                      setEarlyCheckInDate("");
                      setAmendStayDiscount(0);
                    }}
                    style={{ marginRight: "5px" }}
                  />
                  <span> Extend Stay</span>
                </label>
              </th>
              <th />
            </tr>
            <tr>
              <th>Check-Out Date</th>
              <th>Change to</th>

              {/* <th>Discount Amount</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <VerticalInputFieldCustom
                  value={formatDateMMDDYYYY(new Date(bookingData.check_out))}
                  disabled={true}
                />
              </td>

              <td>
                {showExtendStay ? (
                  <>
                    <div>
                      <div className="Polaris-connected">
                        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                          <div className="Polaris-TextField">
                            <input
                              className={"Polaris-TextField__Input"}
                              type="date"
                              style={{ cursor: "pointer" }}
                              value={
                                lateCheckOutDate
                                  ? formatDate(lateCheckOutDate)
                                  : ""
                              }
                              onChange={handleLateCheckOutChange}
                              min={formatDate(
                                new Date(
                                  calculateToDatePlus(bookingData.check_out, 1)
                                )
                              )}
                            />
                            <div
                              className={"Polaris-TextField__Backdrop"}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div className="Polaris-connected">
                        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                          <div className="Polaris-TextField">
                            <input
                              className={"Polaris-TextField__Input"}
                              type="date"
                              style={{ cursor: "pointer" }}
                              value={
                                earlyCheckInDate
                                  ? formatDate(new Date(earlyCheckInDate))
                                  : ""
                              }
                              onChange={handleEarlyCheckOutChange}
                              max={formatDate(new Date(bookingData.check_out))}
                              // min={formatDate(
                              //   new Date(
                              //     bookingData.check_in
                              //   )
                              // )}
                            />
                            <div
                              className={"Polaris-TextField__Backdrop"}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
        <hr style={{ margin: "10px 0" }} />
        <div className="flex" style={{ alignItems: "center", gap: "20px" }}>
          <table
            className="modifyReservationPopupTable"
            style={{ width: "70%" }}
          >
            <thead>
              <tr>
                <th />
                <th>Room Category</th>
                <th>Rate/Night</th>
                <th>Disc. Amt</th>
              </tr>
            </thead>
            <tbody>
              {amendStayData?.map((item, roomIndex) => {
                return (
                  <tr key={roomIndex}>
                    <td>
                      <input
                        type="checkbox"
                        value={""}
                        onChange={(e) => {
                          handleAmendStayCheckboxChange(e, roomIndex);
                        }}
                        checked={activeIndex.includes(roomIndex)}
                      />
                    </td>
                    <td>
                      <VerticalInputFieldCustom
                        value={item?.room_category}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <VerticalInputFieldCustom
                        value={item?.room_rate_per_night}
                        name="room_rate_per_night"
                        onChange={(e) => {
                          handleAmendStayRateChange(e, roomIndex);
                        }}
                        disabled={!activeIndex.includes(roomIndex)}
                      />
                    </td>
                    <td>
                      <VerticalInputFieldCustom
                        value={item?.old_discount_amount}
                        name={"old_discount_amount"}
                        onChange={(e) => {
                          handleAmendStayDiscountChangeSingle(e, roomIndex);
                        }}
                        // disabled={
                        //   !activeIndex.includes(roomIndex)
                        // }
                        disabled={true}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {!showExtendStay && (
            <div style={{ width: "150px" }}>
              <VerticalInputFieldCustom
                titleName={"Extra Charges"}
                type={"number"}
                value={extraCharges}
                onChange={(e) => {
                  setExtraCharges(e.target.value);
                  setShowCheckOutCalculations(false);
                  setAmendStayTotal(0);
                }}
              />
            </div>
          )}
        </div>

        <div className="modify_reservation_reason_container">
          <VerticalInputFieldCustom
            titleName={"Reason to Modify*"}
            name="amendStayReason"
            onChange={handleReasonChange}
          />

          <div className="modify_reservation_reason_container flex flex_gap_10 align_items_center">
            <label>New Total:</label>
            <VerticalInputFieldCustom disabled={true} value={amendStayTotal} />

            <label>Previous Total:</label>
            <p>{bookingData.grand_total}</p>
          </div>
        </div>
        <div className="flex flex_gap_10">
          {!showExtendStay ? (
            // button For early checkout
            <BgThemeButton
              children={!showCheckOutCalculations ? "Calculate" : "Modify"}
              onClick={
                !showCheckOutCalculations
                  ? handleEarlyCheckOutCalculate
                  : handleEarlyCheckOutSubmit
              }
              loader={loader}
            />
          ) : (
            //  button Foe early checkout
            <BgThemeButton
              children={!showCheckOutCalculations ? "Calculate" : "Modify"}
              onClick={
                !showCheckOutCalculations
                  ? handleLateCheckOutCalculate
                  : handleLateCheckOutSubmit
              }
              loader={loader}
            />
          )}
          <BgOutlineThemeButton
            children={"Cancel"}
            onClick={handlePopupClose}
          />
        </div>
      </div>
    </>
  );
};

export default ModifyReservationArrivalsAmendStay;

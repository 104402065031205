import React from "react";
import NavTabs from "../Components/NewCustomComp/NavTabs/NavTabs";
import { Outlet } from "react-router-dom";

const HotelierKot = () => {
  const kotTabs = [
    {
      to: "create-kot-manager",
      label: "Create KOT",
    },
    {
      to: "kotlisting",
      label: "KOT List",
    },
    {
      to: "kot-room-table-view",
      label: "Rooms / Tables",
    },
    {
      to: "menus",
      label: "Food Menu",
    },
  ];
  return (
    <div className="main_container">
      <NavTabs mainData={kotTabs} />

      <Outlet />
    </div>
  );
};

export default HotelierKot;

import React, { useState } from "react";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../NewCustomComp/Buttons/Buttons";
import { PrimaryInput } from "../../NewCustomComp/Inputs/Inputs";
import { SelectBasicWithSelectTag } from "../../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { SvgCancel } from "../../../assets/svgIcons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";

const AddCustomerPopup = ({ show, setShow, property_id }) => {
  const [formData, setFormData] = useState({
    guest_name: "",
    guest_email: "",
    guest_phone: "",
    guest_city: "",
    guest_state: "",
    guest_country: "",
    guest_pincode: "",
    guest_address: "",
    identity_proof_type: "",
    identity_proof_no: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/store/guest/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        showSuccessToast("Customer added successfully!");
        handlePopupClose();
      } else {
        showErrorToast("Failed to add customer");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while adding the customer");
    }
  };

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    { label: "Other", value: "other" },
  ];

  const handlePopupClose = () => {
    setShow(false);
    setFormData({
      guest_name: "",
      guest_email: "",
      guest_phone: "",
      guest_city: "",
      guest_state: "",
      guest_country: "",
      guest_pincode: "",
      guest_address: "",
      identity_proof_type: "",
      identity_proof_no: "",
    });
    setErrors({});
  };

  return (
    <div className="p-10 w-100">
      <div className="popup_heading flex justify_content_between">
        <span>Add Customer</span>
        <span onClick={handlePopupClose}>
          <SvgCancel />
        </span>
      </div>

      <hr className="mt-10 mb-10" />
      <div>
        <PrimaryInput
          label="Name"
          value={formData.guest_name}
          onChange={(e) => handleChange("guest_name", e.target.value)}
          error={errors.guest_name}
          size={"medium"}
          required={true}
          errorText={errors.guest_name}
        />
      </div>
      <div className="flex flex_gap_10 mt-10">
        <div className="w-50">
          <PrimaryInput
            label="Phone"
            value={formData.guest_phone}
            onChange={(e) => handleChange("guest_phone", e.target.value)}
            error={errors.guest_phone}
            size={"medium"}
            required={true}
            errorText={errors.guest_phone}
          />
        </div>

        <div className="w-50">
          <PrimaryInput
            label="Email"
            value={formData.guest_email}
            onChange={(e) => handleChange("guest_email", e.target.value)}
            error={errors.guest_email}
            size={"medium"}
            required={true}
            errorText={errors.guest_email}
          />
        </div>
      </div>
      <div className="w-100 mt-10">
        <PrimaryInput
          label="Address"
          value={formData.guest_address}
          onChange={(e) => handleChange("guest_address", e.target.value)}
          error={errors.guest_address}
          size={"medium"}
          required={true}
          errorText={errors.guest_address}
        />
      </div>
      <div className="flex flex_gap_10 mt-10">
        <div className="w-50">
          <PrimaryInput
            label="State"
            value={formData.guest_state}
            onChange={(e) => handleChange("guest_state", e.target.value)}
            error={errors.guest_state}
            size={"medium"}
            required={true}
            errorText={errors.guest_state}
          />
        </div>
        <div className="w-50">
          <PrimaryInput
            label="City"
            value={formData.guest_city}
            onChange={(e) => handleChange("guest_city", e.target.value)}
            error={errors.guest_city}
            size={"medium"}
            required={true}
            errorText={errors.guest_city}
          />
        </div>
      </div>
      <div className="flex flex_gap_10 mt-10">
        <div className="w-50">
          <PrimaryInput
            label="Country"
            value={formData.guest_country}
            onChange={(e) => handleChange("guest_country", e.target.value)}
            error={errors.guest_country}
            size={"medium"}
            required={true}
            errorText={errors.guest_country}
          />
        </div>
        <div className="w-50">
          <PrimaryInput
            label="ZIP Code"
            value={formData.guest_pincode}
            onChange={(e) => handleChange("guest_pincode", e.target.value)}
            error={errors.guest_pincode}
            size={"medium"}
            required={true}
            errorText={errors.guest_pincode}
          />
        </div>
      </div>
      <div className="flex flex_gap_10 mt-10">
        <div className="w-50">
          <SelectBasicWithSelectTag
            label="Identity Proof Type"
            value={formData.identity_proof_type}
            onChange={(e) =>
              handleChange("identity_proof_type", e.target.value)
            }
            error={errors.identity_proof_type}
            size={"medium"}
            options={proofOptions}
            required={true}
            errorText={errors.identity_proof_type}
          />
        </div>
        <div className="w-50">
          <PrimaryInput
            label="Identity Proof No"
            value={formData.identity_proof_no}
            onChange={(e) => handleChange("identity_proof_no", e.target.value)}
            error={errors.identity_proof_no}
            size={"medium"}
            required={true}
            errorText={errors.identity_proof_no}
          />
        </div>
      </div>
      <div className="mt-10 flex flex_gap_10">
        <PrimaryButton title="Add" onClick={handleSubmit} size={"medium"} />
        <SecondaryButton
          title="Cancel"
          onClick={handlePopupClose}
          size={"medium"}
        />
      </div>
    </div>
  );
};

export default AddCustomerPopup;

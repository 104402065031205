import React from "react";

import "./CreateMessageNewPage.css";
import {
  BgOutlineThemeButton,
  BgOutlineThemeHoldButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CancelMajor } from "@shopify/polaris-icons";

// import { showErrorToast, showSuccessful } from "../../../assets/toastUtils";
import { useNavigate } from "react-router-dom";
// import CreateBookingTableComponent from "./createBookingTableComponent/CreateBookingTableComponent";
// import CreateBookingTableComponentTwo from "./CreateBookingTableComponenTwo/CreateBookingTableComponenTwo";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import {
  formatIndianCurrency,
  useCurrentTime,
  getCurrentPropertyDateHandler,
} from "../../../utils/utils";

import DateRangePickerNew from "../CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
import CreateBookingSelectBox from "../CreateBookingNewPage/CreateBookingSelectBox/CreateBookingSelectBox";
import CreateMessageInputBox from "./CreateMessageInputBox";
import { useParams } from "react-router-dom";
import Loader from "../../UI/Loader/Loader";
import CreateMessageCard from "./CreateMessageCard";
import { Icon } from "@shopify/polaris";
import CreateBookingSelectBoxOptionDisabled from "../CreateBookingNewPage/createBookingSelectBoxOptionDisabled/CreateBookingSelectBoxOptionDisabled";
import CreateMessageCardNew from "./CreateMessageCardNew";
import {
  showErrorCustomToast,
  showSuccessfullToast,
} from "../CustomToastify/ToastFunctions";
import { useToast } from "../CustomToastify/ToastContext";
import { getDecryptedData } from "../../../utils/encryptStorage";

const CreateMessageNewPage = ({
  dontShowCreateMessage,
  name,
  email,
  sender_type,
  agent_id,
  communication_type,
  customer_id,
  phone,
  gst,
}) => {
  const { leadId } = useParams();

  console.log(agent_id, "testtstts");
  console.log(customer_id, "testtstts");

  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const toastRef = useToast();

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const currentTimeToBackend = useCurrentTime("24");

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  //..date range ....
  const currentDate = new Date().toISOString().split("T")[0];
  const [loader, setLoader] = useState(false);

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");

  const dataFromChild = (from_date, to_date) => {
    setCheckIn(from_date);
    setCheckOut(to_date);
    setBookingData((prevData) => ({
      ...prevData,
      check_in: from_date,
      check_out: to_date,
    }));
  };

  const BookingTypeOptions = [
    { room_name: "Single", value: "single" },
    { room_name: "Multiple", value: "multiple" },
  ];

  // calculate the number of nights from check in check out

  const [bookingData, setBookingData] = useState({
    booking_type: "",
    available_rooms: "",
    extra_adult_allowed: "",
    check_out: null,
    check_in: null,
    no_of_nights: "",
    no_of_adults: "",
    extra_adults: "",
    no_of_child: "",
    no_of_rooms: "",
    room_category: "",
    room_id: "",
    booking_source: "",
    agent_id: "",
    discount_type: "",
    tax_type: "exclusive",
    tac_discount: "",
    selected_discount: "",
    room_plans: "",
    ota_source: "",
  });

  console.log(bookingData, "bookingDataaaa");

  useEffect(() => {
    const calculateNumberOfNights = () => {
      const checkIn = new Date(bookingData.check_in);
      const checkOut = new Date(bookingData.check_out);
      const timeDifference = checkOut.getTime() - checkIn.getTime();
      const numberOfNights = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return numberOfNights;
    };

    const numberOfNightsForBooking = calculateNumberOfNights();
    setBookingData((prevData) => ({
      ...prevData,
      no_of_nights: numberOfNightsForBooking,
    }));
  }, [bookingData.check_in, bookingData.check_out, checkIn, checkOut]);

  //handle booking change
  // const handleBookingTypeChange = (event) => {
  //   setBookingData({
  //     ...bookingData,
  //     booking_type: event.target.value,
  //   });
  // };

  const handleBookingTypeChange = (event) => {
    setErrors((prev) => ({ ...prev, booking_type: false }));
    const selectedBookingType = event.target.value;
    setBookingData({
      ...bookingData,
      booking_type: selectedBookingType,
    });
  };

  //   ................Room Category Amd fetching room plans.....................

  const [RoomCategoryOptions, setRoomCategoryOptions] = useState("");

  const fetchRoomTypeLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setRoomCategoryOptions(data?.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRoomPlans = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${bookingData.room_id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setBookingData((prev) => ({
        ...prev,
        room_plans: data?.roomRates,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchRoomTypeLists();
      fetchRoomPlans();
    }
  }, [property_id]);
  useEffect(() => {
    if (bookingData.room_id && property_id) {
      fetchRoomPlans();
    }
  }, [bookingData.room_id, property_id]);

  // console.log(bookingData, "bookingdata")

  // const handleRoomCategory = (e) => {
  //   setErrors((prev) => ({ ...prev, room_category: false }));
  //   const id = RoomCategoryOptions.find(
  //     (item) => item?.room_name === e.target.value
  //   ).id;
  //   setBookingData({
  //     ...bookingData,
  //     room_category: e.target.value,
  //     room_id: id,
  //   });
  // };

  const handleRoomCategory = async (e) => {
    if (e.target.value === "") {
      setBookingData((perv) => ({
        ...perv,
        room_category: "",
        limits: "",
      }));
      setAvailableRooms("");

      return;
    }
    setBookingData((prev) => ({
      ...prev,
      no_of_rooms: "",
      no_of_adults: "",
      no_of_child: "",
      extra_adults: "",
    }));

    setErrors((prev) => ({ ...prev, room_category: false }));
    const id = RoomCategoryOptions.find(
      (item) => item?.room_name === e.target.value
    )?.id;
    const roomCategory = e.target.value;
    // api  for fetching the room plans

    const checkIn = bookingData.check_in;
    const checkOut = bookingData.check_out;
    // check availability api
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}&room_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      const blockedRooms = data?.available_rooms_dates?.filter(
        (item) => item?.blocked !== 0
      );
      if (blockedRooms?.length > 0) {
        showErrorCustomToast(
          toastRef,
          `Room is blocked for selected dates :  ${blockedRooms
            .map((room) => room.date)
            .join(", ")}      `
        );
        return;
      } else {
        setAvailableRooms(data);
        setBookingData((prev) => ({
          ...prev,
          limits: data,
        }));

        //  fetch room plans
        fetchRoomRates(id, roomCategory);
      }

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRoomRates = async (id, roomCategory) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }
      const data = await response.json();

      if (data.success === true) {
        setBookingData((prev) => ({
          ...prev,
          room_plans: data?.roomRates,
          room_category: roomCategory,
          room_id: id,
        }));
      }
    } catch (error) {
      console.error("Error fetching room plans:", error);
      showErrorCustomToast(
        toastRef,
        "something went wrong please try again..."
      );
    }
  };

  //iframe code

  const [iframeContent, setIframeContent] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  const [downloadUrl, setDownloadUrl] = useState("");

  const handleSubmitPreviewVoucher = async (
    event,
    template,
    statuses,
    vouchertitle,
    booking_id
  ) => {
    try {
      // console.log(booking_id);
      // console.log(bookingDataArray, leadId, "0000000");

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v2/lead/preview/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            data: bookingDataArray,
            lead_id: leadId,
            property_id: property_id?.id,
            name: name,
            email: email,
            type: sender_type,
            communication_type: communication_type,
            customer_id: customer_id,
            phone: phone,
            gst: gst,
            existingUser: agent_id,
          }),
        }
      );

      const data = await response.text();
      if (data) {
        setIframeContent(data);
        setShowIframePopup(true);
        setIframeSrc(null);
        // setShowBillPreview(true);
        // setIsLoading(false);
        // printIframe("receipt");
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorCustomToast(
        toastRef,
        "An error occurred while processing your request."
      );
    }
  };

  //fetch room type on handle category change
  const [availableRooms, setAvailableRooms] = useState("");

  const [iframeContents, setIframeContents] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const fetchRoomType = async () => {
    const roomId = bookingData.room_id;
    const checkIn = bookingData.check_in;
    const checkOut = bookingData.check_out;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}&room_id=${roomId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAvailableRooms(data);
      setBookingData((prev) => ({
        ...prev,
        limits: data,
      }));

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const Popup = ({ content, onClose }) => {
    return (
      <div className="popup">
        <div className="popup-content">
          <button className="close-btn" onClick={onClose}>
            Close
          </button>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      bookingData?.room_id &&
      bookingData.check_in &&
      bookingData.check_out &&
      property_id
    ) {
      fetchRoomType();
    }
  }, [
    bookingData?.room_id,
    bookingData.check_in,
    bookingData.check_out,
    property_id,
    userToken,
  ]);

  const handleInputChange = (e) => {
    const extraAdultAllowed = availableRooms?.allowedExtraAdult;
    let maxAdultAllowed = availableRooms?.max_adult;
    let baseAdultAllowed = availableRooms?.base_adult;
    const extraBeddingAllowed =
      availableRooms?.extra_bedding_allowed == "enabled" ? true : false;

    const { name, value } = e.target;

    setBookingData({
      ...bookingData,
      [name]: value,
    });
  };

  // booking submit handler
  const BookingSourceOptions = [
    { room_name: "Walk In Booking", value: "walk in booking" },
    { room_name: "Front Desk", value: "front desk" },
    { room_name: "Sales and Marketing", value: "sales and marketing" },
    { room_name: "Agent (B2B) Partner", value: "agent" },
    { room_name: "OTA", value: "ota" },
  ];

  const handleBookingSourceChange = (e) => {
    setBookingData({
      ...bookingData,
      booking_source: e.target.value,
    });
  };

  //booking agent

  const [agentList, setAgentList] = useState([]);

  const fetchAgentList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAgentList(data.data);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (bookingData.booking_source === "Agent (B2B) Partner" && property_id) {
      fetchAgentList();
    }
  }, [bookingData.booking_source, property_id]);

  // Tac discount
  const fetchTacDiscount = async () => {
    const agentId = bookingData.agent_id;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/tac/discount/${agentId}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setBookingData({
        ...bookingData,
        tac_discount: data.tac_discount,
      });
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (bookingData.agent_id && property_id) {
      fetchTacDiscount();
    }
  }, [bookingData.agent_id, property_id]);

  // for discount percentage
  const [discountOptions, setDiscountOptions] = useState([]);
  const fetchDiscountOptions = async () => {
    const checkIn = bookingData.check_in;
    const checkOut = bookingData.check_out;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/discount/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setDiscountOptions(data.discount);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching discount options:", error);
    }
  };
  // useEffect(() => {
  //   if (bookingData.check_in && bookingData.check_out && property_id) {
  //     fetchDiscountOptions();
  //   }
  // }, [bookingData.check_in, bookingData.check_out, property_id]);

  const [discount, setDiscount] = useState("");

  const handleDiscountTypeChange = (e) => {
    const discountValue = e.target.value;

    if (+discountValue < 0) {
      showErrorCustomToast(toastRef, "Discount percentage cannot be negative");
      return;
    }
  
 
  
    if (+discountValue >= 100) {
      showErrorCustomToast(toastRef, "Discount percentage should be less than 100%");
      return;
    }

    if (+discountValue >= 100) {
      showErrorCustomToast(
        toastRef,
        "Discount percentage should be less than 100%"
      );
      return;
    }

    setBookingData({
      ...bookingData,
      discount_type: discountValue,
    });
  };
  const [discountValue, setDiscountValue] = useState("");

  const handleDiscountValueChange = (e) => {
    const value = e.target.value;
    setDiscountValue(value);
  };

  const [bookingDataArray, setBookingDataArray] = useState([]);
  console.log(bookingDataArray, "bookingDataArrayss");

  //booking card delete
  // const handleDeleteBookingCard = (index) => {
  //   console.log(availableRooms.available_rooms,"uguigu dfddddddddddddddddddddddddi")
  //   console.log(bookingDataArray[index].no_of_rooms ,"availabel rooms")
  //    const totalRooms = +availableRooms.available_rooms + +bookingDataArray[index].no_of_rooms;

  //    console.log(totalRooms ,"bcbweifbwiuivbriuvbiebirbiervberivbrvibib")

  // //  setBookingDataArray((prevData) => prevData.filter((_, i) => i !== index));
  //  setAvailableRooms( (prev)=>(
  //  { ...prev,
  //   available_rooms: totalRooms}
  //  )

  //  );

  // };
  const handleDeleteBookingCard = (index) => {
    const totalRooms =
      +availableRooms.available_rooms + +bookingDataArray[index].no_of_rooms;
    setAvailableRooms((prev) => ({
      ...prev,
      available_rooms: totalRooms,
    }));
    setTimeout(() => {
      setBookingDataArray((prev) => prev.filter((_, i) => i !== index));
    }, 500);
    setShowDiscountSelect(false);
  };

  const isDisabled = () => {
    if (
      bookingData.booking_type === "" ||
      bookingData.no_of_nights === "" ||
      bookingData.no_of_rooms === "" ||
      bookingData.room_category === ""
    ) {
      showErrorCustomToast(toastRef, "Please fill in all required fields.");
      if (bookingData.booking_type === "") {
        setErrors((prev) => ({ ...prev, booking_type: true }));
      }
      if (bookingData.no_of_nights === 0) {
        setErrors((prev) => ({ ...prev, no_of_nights: true }));
      }
      if (bookingData.room_category === "") {
        setErrors((prev) => ({ ...prev, room_category: true }));
      }
      if (bookingData.booking_source === "") {
        setErrors((prev) => ({ ...prev, booking_source: true }));
      }
      if (bookingData.no_of_rooms === "") {
        setErrors((prev) => ({ ...prev, no_of_rooms: true }));
      }
      if (bookingData.no_of_adults === "") {
        setErrors((prev) => ({ ...prev, no_of_adults: true }));
      }
      return true;
    }

    if (
      bookingData.booking_source === "Agent (B2B) Partner" &&
      bookingData.agent_id === ""
    ) {
      setErrors((prev) => ({ ...prev, agent_id: true }));
      showErrorCustomToast(toastRef, "Please Select Agent for B2B bookings.");
      return true;
    }

    if (bookingData.booking_source === "OTA" && bookingData.ota_source === "") {
      setErrors((prev) => ({ ...prev, ota_source: true }));
      showErrorCustomToast(
        toastRef,
        "Please provide the OTA Source for OTA bookings."
      );
      return true;
    }
    if (
      bookingData.booking_source === "Sales and Marketing" &&
      bookingData.agent_name === ""
    ) {
      setErrors((prev) => ({ ...prev, agent_name: true }));
      showErrorCustomToast(
        toastRef,
        "Please provide the Agent Name for Sales and Marketing bookings."
      );
      return true;
    }

    return false;
  };

  // ota sources
  const otaTypeOptions = [
    { label: "MakeMyTrip", value: "makemytrip" },
    { label: "GoIbibo", value: "goibibo" },
    { label: "Expedia", value: "expedia" },
    { label: "EaseMyTrip", value: "easemytrip" },
    { label: "ClearTrip", value: "cleartrip" },
    { label: "HappyEasyGo", value: "happyeasygo" },
    { label: "Travelguru", value: "travelguru" },
    { label: "Yatra", value: "yatra" },
  ];

  const [errors, setErrors] = useState({
    booking_type: false,
    no_of_nights: false,
    no_of_rooms: false,
    room_category: false,
    booking_source: false,
    agent_id: false,
    no_of_adults: false,
    discount_type: false,
    agent_id: false,
    agent_name: false,
    ota_source: false,
    agent_name: false,
    extra_adults: false,
    no_of_child: false,
    guest_name: false,
  });

  const handleClickOnAddButton = () => {
    if (isDisabled()) {
      return;
    }

    const hasSameRoomCategory = bookingDataArray.some(
      (item) => item.room_category === bookingData.room_category
    );

    if (hasSameRoomCategory) {
      showErrorCustomToast(
        toastRef,
        "Same room category cannot be added twice"
      );
      return;
    }

    setBookingDataArray((prevData) => [...prevData, bookingData]);
    setIsDataReceived(true);
    setAvailableRooms({});
    setTimeout(() => {
      setBookingData((prevData) => ({
        ...prevData,
        no_of_rooms: "",
        no_of_adults: "",
        extra_adults: "",
        extra_adult: "",
        no_of_child: "",
        room_category: "",
      }));
    }, 500);
  };

  // ncn
  console.log(bookingData);

  const [isDataReceived, setIsDataReceived] = useState(false);
  // console.log(JSON.stringify(bookingDataArray) +  "getDataFromChildatamessage")
  const getDataFromChild = (index, data) => {
    setBookingDataArray((prevData) => {
      const newData = prevData.map((item, i) => {
        return i === index ? data : item;
      });
      setIsDataReceived(true);
      return newData;
    });
  };

  function calculateTotal(array, property) {
    return array.reduce((total, item) => {
      const value = parseFloat(item[property]) || 0; // Convert to number, default to 0 if NaN
      return total + value;
    }, 0);
  }

  const [guestDetails, setGuestDetails] = useState({
    salutation: "",
    name: "",
    email: "",
    phone: "",
    remarks: "",
  });

  // calculating the total of the booking arrays and saving them

  const sumSubTotal = calculateTotal(bookingDataArray, "subTotal") || 0;
  const sumGrandTotal = calculateTotal(bookingDataArray, "grandTotal");
  const sumDiscountAmount =
    calculateTotal(bookingDataArray, "discountAmount") || 0;

  const sumTotalRoomRate =
    calculateTotal(bookingDataArray, "total_room_rate") || 0;
  const sumTaxAmount = calculateTotal(bookingDataArray, "taxAmount") || 0;

  // handle confirm booking button

  const isConfirmDisabled = () => {
    if (
      guestDetails.salutation === "" ||
      guestDetails.name === ""
      // guestDetails.email === "" ||
      // guestDetails.phone === ""
    ) {
      return true;
    }
  };

  // on click of hold button show the hold modal

  const [showHoldPopup, setShowHoldPopup] = useState(false);

  const timeArray = [
    { label: "select time", value: "" },
    { label: "12:00 AM", value: "00:00" },
    { label: "01:00 AM", value: "01:00" },
    { label: "02:00 AM", value: "02:00" },
    { label: "03:00 AM", value: "03:00" },
    { label: "04:00 AM", value: "04:00" },
    { label: "05:00 AM", value: "05:00" },
    { label: "06:00 AM", value: "06:00" },
    { label: "07:00 AM", value: "07:00" },
    { label: "08:00 AM", value: "08:00" },
    { label: "09:00 AM", value: "09:00" },
    { label: "10:00 AM", value: "10:00" },
    { label: "11:00 AM", value: "11:00" },
    { label: "12:00 PM", value: "12:00" },
    { label: "01:00 PM", value: "13:00" },
    { label: "02:00 PM", value: "14:00" },
    { label: "03:00 PM", value: "15:00" },
    { label: "04:00 PM", value: "16:00" },
    { label: "05:00 PM", value: "17:00" },
    { label: "06:00 PM", value: "18:00" },
    { label: "07:00 PM", value: "19:00" },
    { label: "08:00 PM", value: "20:00" },
    { label: "09:00 PM", value: "21:00" },
    { label: "10:00 PM", value: "22:00" },
    { label: "11:00 PM", value: "23:00" },
  ];

  const [holdBookingData, setHoldBookingData] = useState("");

  const holdBookingDataChange = (e) => {
    // console.log(holdBookingData + "holdBookingData");
    // setHoldBookingData({
    //   ...holdBookingData,
    //   [e.target.name]: e.target.value,
    // });
    setShowHoldPopup(false);
  };

  // function wrote  for showing the date format according to indian standard
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "dd/mm/yyyy";
    } else {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  };

  console.log(bookingData, "bookingData");
  const checkKeyValidity = (dataArray, keyName) => {
    for (const item of dataArray) {
      // Check if the key is empty or equal to 0
      if (!item[keyName] || item[keyName] === 0) {
        showErrorCustomToast(
          toastRef,
          `Invalid value for ${keyName.replace(/_/g, " ")}`
        );
        return true; // Stop further processing if an invalid value is found
      }
    }
  };

  //handle assign  button click in popup

  const handleAssignButtonClick = () => {
    // if (isConfirmDisabled()) {
    // showErrorCustomToast(toastRef,"Please fill required Guest details.");
    //   return
    // }

    if (bookingDataArray.length === 0) {
      showErrorCustomToast(toastRef, "Please add booking details");
      return;
    }

    if (checkKeyValidity(bookingDataArray, "no_of_adults")) {
      return;
    }
    if (checkKeyValidity(bookingDataArray, "no_of_rooms")) {
      return;
    }

    console.log(bookingDataArray, "bookingdatarayaya");

    // Check child age for each booking pax
    for (const booking of bookingDataArray) {
      for (const pax of booking.booking_paxes) {
        if (pax.pax_type === "child" && !pax.child_age) {
          showErrorCustomToast(toastRef, "Please enter child's age.");
          return;
        }
      }
    }

    if (isAssignButtonValid()) {
      const roomInputData = bookingDataArray.map((item) => ({
        room_id: item.room_id || "",
        room_type_name: item.room_category || "",
        rate_plan_id: item.room_rate_details?.rate_plan_id || "",
        room_plan: item.room_rate_details?.rate_plan_type || "",
        no_of_rooms: item.no_of_rooms || "",
        no_nights: item.no_of_nights || "",
        room_rate: item.room_rate_new,
        extra_adult: item.extra_adults,
        no_adult: item.no_of_adults || 0,
        no_child: item.no_of_child || 0,
        discount_rate: item?.discount_type || 0,
        discount_amount: item?.discountAmount || 0,
        tax_rate: item.tax_rate || 0,
        tax_amount: item.taxAmount || 0,
        room_total_amount: item.subTotal || 0,
        booking_paxs: item.booking_paxes,
        room_grand_total: item.grandTotal,
        add_hoc_rate: item?.add_hoc_rate || 0,
      }));

      const postData = {
        holding_time: holdBookingData,
        booking_type: bookingData.booking_type,
        status: "confirmed",
        source: "front_desk",
        check_in: bookingData.check_in,
        check_out: bookingData.check_out,
        gst_type: bookingData.tax_type || "",
        grand_total: sumGrandTotal,
        taxable_amount: sumSubTotal,
        total_tax_amount: sumTaxAmount,
        total_discount_amount: sumDiscountAmount,
        roomInputData: roomInputData,
        name: name,
        email: email,
        type: sender_type,
        communication_type: communication_type,
        customer_id: customer_id,
        phone: phone,
        gst: gst,
        existingUser: agent_id,
      };

      const url =
        communication_type === "rates_and_availability"
          ? `${process.env.REACT_APP_BASE_URL}/api/v2/send/rate/message/${property_id.id}`
          : `${process.env.REACT_APP_BASE_URL}/api/v2/add/message/${leadId}/${property_id.id}`;

      try {
        setLoader(true);
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(postData),
        })
          .then((response) => {
            if (!response.ok) {
              showErrorCustomToast(toastRef, "Error while creating Message");
              throw new Error("Error while creating Message");
            }
            return response.json();
          })
          .then((data) => {
            if (data.success) {
              showSuccessfullToast(toastRef, "Message Created successfully");
              setShowHoldPopup(false);
              setLoader(false);
              navigate("/leads");
            } else {
              setLoader(false);
              showErrorCustomToast(toastRef, "Error while creating Message");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setLoader(false);
          });
      } catch (error) {
        console.error("Error:", error);
        setLoader(false);
      }

      setShowHoldPopup(false);
    } else {
      showErrorCustomToast(toastRef, "Please select hold date and time");
      setLoader(false);
    }
  };

  // array for tracking the show adhoc room rates form child to show the discount
  const [adhocArray, setAdhocArray] = useState([]);

  const getAdhocArrayFromChild = (data, index) => {
    setAdhocArray((prev) => {
      const newArray = [...prev];
      newArray[index] = data;
      return newArray;
    });
  };

  const isAssignButtonValid = () => {
    if (holdBookingData.hold_date !== "" && holdBookingData.hold_time !== "") {
      return true;
    } else {
      return false;
    }
  };

  const [addOnsPaymentData, setAddOnsPaymentData] = useState({
    addOn: [],
    payments: [],
  });
  // const getAddonsData = (data) => {
  //   setAddOnsPaymentData((prev) => {
  //     return {
  //       ...prev,
  //       addOn: data,
  //     };
  //   });
  // };
  // const getPaymentData = (data) => {
  //   setAddOnsPaymentData((prev) => {
  //     return {
  //       ...prev,
  //       payments: data,
  //     };
  //   });
  // };

  // const [singleLeadData, setSingleLeadData] = useState(null);

  // useEffect(() => {
  //   const getSingleBookingDataHandler = (leadId) => {
  //     // setLoader(true);

  //     fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v2/lead/detail/${leadId}/${property_id?.id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.success) {
  //           setSingleLeadData(data?.leads);

  //           setLoader(false);
  //         } else {
  //           setLoader(false);
  //         showErrorCustomToast(toastRef,data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         console.error("Error:", error);
  //         //showErrorCustomToast(toastRef,toastRef,"An error occurred while processing your request.");
  //       });
  //   };

  //   getSingleBookingDataHandler(leadId);
  // }, []);

  // console.log(JSON.stringify(singleLeadData) + "singleLeadData");

  const sumPaidAmount =
    calculateTotal(addOnsPaymentData.payments, "amount") || 0;

  // add ons cost

  const sumSubtotalAddons =
    calculateTotal(addOnsPaymentData.addOn, "price") || 0;
  const sumDiscountAmountAddons =
    calculateTotal(addOnsPaymentData.addOn, "discount_amount") || 0;
  const sumGrandTotalAddons =
    calculateTotal(addOnsPaymentData.addOn, "total") || 0;

  const sumTaxAmountAddons =
    calculateTotal(addOnsPaymentData.addOn, "tax_amount") || 0;

  // new handler for room change
  const handleNumberOfRoomsChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_rooms: false }));
      return;
    }

    const selectedRooms = +value;
    const prevAvailableRooms = availableRooms?.available_rooms;

    if (selectedRooms <= 0) {
      showErrorCustomToast(toastRef, "Please enter a valid number of rooms");
      setErrors((prev) => ({ ...prev, no_of_rooms: true }));
      return;
    }

    if (selectedRooms > prevAvailableRooms) {
      showErrorCustomToast(toastRef, "Cannot exceed the available rooms limit");
      setErrors((prev) => ({ ...prev, no_of_rooms: true }));
      return;
    }

    // Update the booking data with the new value
    setBookingData({
      ...bookingData,
      [name]: value,
    });
    setErrors((prev) => ({ ...prev, no_of_rooms: false }));
  };

  // handle number of adult
  const handleNumberOfAdultChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_adults: false }));
      return;
    }
    if (isNaN(value) || +value <= 0) {
      showErrorCustomToast(toastRef, "Please enter a valid number of adults");
      setErrors((prev) => ({ ...prev, no_of_adults: true }));
      return;
    }

    if (+value > availableRooms?.base_adult * bookingData.no_of_rooms) {
      showErrorCustomToast(
        toastRef,
        "The number of adults cannot be greater than the base occupancy of a room"
      );
      setErrors((prev) => ({ ...prev, no_of_adults: true }));
      return;
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_adults: false }));
    }
  };

  //handle number of extra adult change
  const handleNumberOfExtraAdultChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, extra_adult: false }));
      return;
    }
    if (isNaN(value) || +value <= 0) {
      showErrorCustomToast(toastRef, "Please enter a valid number of adults");
      setErrors((prev) => ({ ...prev, extra_adult: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, extra_adult: false }));
      }, 5000);
      return;
    }

    if (availableRooms.extra_bedding !== "enabled") {
      showErrorCustomToast(toastRef, "Extra Bedding not allowed for this room");
      setErrors((prev) => ({ ...prev, extra_adult: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, extra_adult: false }));
      }, 5000);

      setBookingData({
        ...bookingData,
        [name]: 0,
      });
      return; // Return early if extra bedding is not allowed
    }
    if (bookingData.no_of_child !== "") {
      const backendMaxOccupancy =
        availableRooms?.max_adult * +bookingData.no_of_rooms;
      const frontEndMaxOccupancy =
        +bookingData.no_of_adults + +bookingData.no_of_child;
      const maxExtraAdultsAllowed =
        (backendMaxOccupancy - frontEndMaxOccupancy + 1) *
        bookingData.no_of_rooms;
      if (+value > +maxExtraAdultsAllowed) {
        showErrorCustomToast(
          toastRef,
          "Max occupancy reached! please increase number of rooms"
        );
        setBookingData({
          ...bookingData,
          [name]: "", // Clear the input value
        });
        return;
      }
    }

    if (+value > availableRooms?.allowedExtraAdult * bookingData.no_of_rooms) {
      showErrorCustomToast(
        toastRef,
        "Value cannot exceed the available extra adult per room"
      );
      setErrors((prev) => ({ ...prev, extra_adult: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, extra_adult: false }));
      }, 5000);
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, extra_adult: false }));
    }
  };

  //handle number of child change

  const handleNumberOfChildChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_child: false }));
      return;
    }
    if (isNaN(value) || +value < 0) {
      showErrorCustomToast(toastRef, "Please enter a valid number of children");
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_child: false }));
      }, 5000);
      setErrors((prev) => ({ ...prev, no_of_child: true }));
      return;
    }

    const childAllowed = availableRooms?.max_child;

    const backendMaxOccupancy =
      availableRooms?.max_adult * +bookingData.no_of_rooms;
    const frontEndMaxOccupancy =
      +bookingData.no_of_adults + +bookingData.extra_adults;
    const maxChildAllowed =
      (backendMaxOccupancy - frontEndMaxOccupancy + 1) *
      bookingData.no_of_rooms;
    if (+value > +maxChildAllowed) {
      showErrorCustomToast(
        toastRef,
        "Max occupancy reached! please increase number of rooms"
      );
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
      return;
    }

    if (+value > childAllowed * +bookingData.no_of_rooms) {
      showErrorCustomToast(
        toastRef,
        "Value cannot exceed the available child limit"
      );
      setErrors((prev) => ({ ...prev, no_of_child: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_child: false }));
      }, 5000);
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
      return;
    }

    setBookingData({
      ...bookingData,
      [name]: value,
    });
    setErrors((prev) => ({ ...prev, no_of_child: false }));
  };

  const handleGuestDetails = (e) => {
    setGuestDetails({
      ...guestDetails,
      [e.target.name]: e.target.value,
    });
  };

  // salutations

  const [showSalutationPopup, setShowSalutationPopup] = useState(false);

  const [salutations, setSalutations] = useState([]);

  const fetchSalutations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        // console.log(data.data, "SALUTATIONS");
        setSalutations(data.data);
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
  };

  useEffect(() => {
    fetchSalutations();
  }, []);

  const handleSalutationChange = (e) => {
    const { name, value } = e.target;

    if (value === "other") {
      setShowSalutationPopup(true);
    } else {
      setGuestDetails({
        ...guestDetails,
        salutation: e.target.value,
      });
    }
  };

  const [popUpSalutation, setPopUpSalutation] = useState("");

  const handlePopupAddButtonClick = async (e) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/add`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            name: popUpSalutation,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setPopUpSalutation(false);
        setPopUpSalutation("");
        fetchSalutations();
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
    setGuestDetails({
      ...guestDetails,
      salutation: popUpSalutation,
    });
    setShowSalutationPopup(false);
  };

  const handleConfirmButtonClick = () => {
    if (isConfirmDisabled()) {
      showErrorCustomToast(toastRef, "Please fill required Guest Name.");
      return;
    }
    if (bookingDataArray.length === 0) {
      showErrorCustomToast(toastRef, "Please add booking details");
      return;
    }
    const roomInputData = bookingDataArray.map((item) => ({
      room_id: item.room_id || "",
      room_type_name: item.room_category || "",
      room_plan_id: item.room_details.rate_plan_id || "",
      room_plan: item.room_details?.rate_plan_type || "",
      no_of_rooms: item.no_of_rooms || "",
      no_nights: item.no_of_nights || "",
      room_rate: item.room_rate_new,
      no_adult: item.no_of_adults || 0,
      no_child: item.no_of_child || 0,
      discount_rate: item?.discount_type?.discount_amount || 0,
      discount_percent: item?.discount_type || 0,
      discount_amount: item?.discountAmount || 0,
      tax_rate: item.tax_rate || 0,
      tax_amount: item.taxAmount || 0,
      room_total_amount: item.subTotal || 0,
      booking_paxs: item.booking_paxes,
      room_grand_total: item.grandTotal,
    }));

    const addOns = addOnsPaymentData.addOn;

    const paymentData = addOnsPaymentData.payments;

    const postData = {
      status: "confirmed",
      source: bookingData.booking_source,
      check_in: bookingData.check_in,
      check_out: bookingData.check_out,
      date: currentDate,
      agent_id: bookingData.agent_id,
      guest_name: guestDetails.salutation + " " + guestDetails.name,
      guest_email: guestDetails.email,
      guest_phone: guestDetails.phone,
      pincode: guestDetails.pincode || "",
      guest_city: guestDetails.city || "",
      guest_state: guestDetails.state || "",
      guest_address: guestDetails.address || "",
      gst_type: bookingData.tax_type || "",
      grand_total: sumGrandTotal,
      taxable_amount: sumSubTotal,
      total_tax_amount: sumTaxAmount,
      total_discount_amount: sumDiscountAmount,
      roomInputData: roomInputData,
      addOns: addOns,
      payments: paymentData,
    };

    // navigate("/bookings/createbooking/edit")

    // Check child age for each booking pax
    for (const booking of bookingDataArray) {
      for (const pax of booking.booking_paxes) {
        if (pax.pax_type === "child" && !pax.child_age) {
          showErrorCustomToast(toastRef, "Please enter child's age.");
          return;
        }
      }
    }

    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/store/new/bookings/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...postData,
            check_in: checkIn,
            check_out: checkOut,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            showErrorCustomToast(toastRef, "Error while creating booking");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success === true) {
            navigate("/bookings");
            showSuccessfullToast(toastRef, "Booking Created Successfully");

            // navigate(`/bookings/createbooking/edit/${data.booking_id}`);
          } else {
            showErrorCustomToast(toastRef, "Error while creating booking");
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function calculateDiscountAmountForBookingPaxes(bookingData) {
    let totalDiscountAmount = 0;
    // Loop through each booking in the array
    bookingData?.forEach((booking) => {
      // Loop through each booking pax in the booking
      booking?.booking_paxes?.forEach((pax) => {
        // Add the discount_amount of the booking pax to the totalDiscountAmount
        totalDiscountAmount += pax?.discount_amount;
      });
    });
    return totalDiscountAmount;
  }

  // console.log(bookingDataArray, "bookingDataArray");
  const [showDiscountSelect, setShowDiscountSelect] = useState(true);

  return (
    <>
      <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
        {!dontShowCreateMessage && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={handleGoBack}
            >
              <svg
                className="back-btn"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <rect
                  x="29.75"
                  y="29.75"
                  width="29.5"
                  height="29.5"
                  rx="5.75"
                  transform="rotate(180 29.75 29.75)"
                  stroke="#666666"
                  strokeWidth="0.5"
                />
                <line x1="21" y1="15.043" x2="9" y2="15.043" stroke="#666666" />
                <path
                  d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                  stroke="#666666"
                  strokeWidth="1.2"
                />
              </svg>
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                paddingLeft: "15px",
              }}
            >
              Create Message
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          margin: "10px 0",
          justifyContent: "space-between",
        }}
      >
        <div className="create_booking_page_left_booking_card_container">
          {/* add on and  payment section */}

          {/* <CreateBookingTableComponent
            heading="Add Ons"
            TableHeaders={["Name", "Amt", "Dic. %", "Tax Rate", "Total Amt."]}
            getAddonsData={getAddonsData}
          />

          <CreateBookingTableComponentTwo
            heading="Payments"
            TableHeaders={[
              "Payment Type",
              "Payment Mode",
              "Remarks",
              "Paid Amount",
            ]}
            getPaymentData={getPaymentData}
          /> */}

          {/* guest details */}

          <div className="create_booking_primary_guest_details_container_wrapper">
            {/* <div className="create_booking_primary_guest_details_container">
              <h4>Primary Guest Details</h4>
              <div className="create_booking_primary_guest_details">
                <div className="create_booking_primary_guest_details_input_container">
                  <label className="standard-select">
                    Name <span style={{ color: "red" }}>*</span> <br />
                  </label>
                  <div className="flex gap_5">
                    <select
                      id="salutation"
                      name="salutation"
                      value={guestDetails.salutation}
                      onChange={handleSalutationChange}
                      className="create_booking_primary_guest_details_input"
                      style={{ width: "50px", marginRight: "5px" }}
                    >
                      <option value="">Select...</option>
                      {salutations?.map((salutation, index) => (
                        <option key={index} value={salutation.name}>
                          {salutation.name}
                        </option>
                      ))}
                      <option value={"other"}>Other</option>
                    </select>

                    <input
                      type="text"
                      required
                      className="create_booking_primary_guest_details_input"
                      name="name"
                      value={guestDetails.name}
                      onChange={handleGuestDetails}
                    />
                  </div>
                </div>
                <div className="create_booking_primary_guest_details_input_container">
                  <label className="standard-select">Phone </label> <br />
                  <input
                    type="number"
                    required
                    className="create_booking_primary_guest_details_input"
                    value={guestDetails.phone}
                    name="phone"
                    onChange={handleGuestDetails}
                  />
                </div>

                <div className="create_booking_primary_guest_details_input_container">
                  <label className="standard-select">Email </label> <br />
                  <input
                    type="email"
                    className="create_booking_primary_guest_details_input"
                    value={guestDetails.email}
                    name="email"
                    onChange={handleGuestDetails}
                  />
                </div>
              </div>
            </div>
            <div className="create_booking_primary_guest_details_container">
              <h4>Remarks</h4>
              <div className="create_booking_primary_guest_details">
                <textarea
                  type="text"
                  required
                  className="create_booking_primary_guest_details_input_remarks"
                  name="remarks"
                  value={guestDetails.remarks}
                  onChange={handleGuestDetails}
                  style={{ width: "100%" }}
                />
              </div>
            </div> */}

            <div
              className="create_booking_page_main "
              style={{ borderBottom: "1px solid #ccc" }}
            >
              <div
                className="create_booking_page_top lms_create_booking_page_top flex align_center"
                style={{ padding: "10px 0px" }}
              >
                <div className="flex align_center ">
                  <CreateBookingSelectBox
                    label="Rate Type"
                    value={bookingData.booking_type}
                    onChange={handleBookingTypeChange}
                    options={BookingTypeOptions}
                    disabled={bookingDataArray.length > 0}
                    error={errors.booking_type}
                  />

                  <div
                    className="create_booking_selectBox_container"
                    onClick={() => setErrors({ no_of_nights: false })}
                  >
                    <DateRangePickerNew
                      dataFromChild={dataFromChild}
                      // defaultDate={`${calculateToDate(currentDate)}:${currentDate}`}
                      minDate={new Date(propertyCurrentDate)}
                      disabled={
                        bookingDataArray.length > 0 ||
                        bookingData.room_category !== ""
                      }
                      error={errors.no_of_nights}
                    />
                  </div>

                  {/* room category */}
                  <CreateBookingSelectBoxOptionDisabled
                    disabled={
                      bookingData.check_in && bookingData.check_out
                        ? false
                        : true
                    }
                    label="Room Category"
                    value={bookingData.room_category}
                    onChange={handleRoomCategory}
                    options={RoomCategoryOptions}
                    error={errors.room_category}
                    bookingDataArray={bookingDataArray}
                  />
                  {/* <CreateBookingSelectBox
                    disabled={
                      bookingData.check_in && bookingData.check_out
                        ? false
                        : true
                    }
                    label="Room Category"
                    value={bookingData.room_category}
                    onChange={handleRoomCategory}
                    options={RoomCategoryOptions}
                  /> */}
                  {/* <CreateMessageInputBox
              alignment="vertical"
              value={bookingData.no_of_nights}
              label="No. of Nights"
              onChange={handleInputChange}
              name="no_of_nights"
            /> */}
                  {/* <div style={{ width: "60px" }} className=" vertical">
              <label className="Create_booking_label">No. of Nights</label>
              <br />
              <input
                defaultValue={bookingData.no_of_nights}
                className="createBookingInputAndSelectTagsSmall"
                disabled
              />
            </div> */}
                  <CreateMessageInputBox
                    alignment="vertical"
                    label="No. of Rooms"
                    onChange={handleNumberOfRoomsChange}
                    name="no_of_rooms"
                    value={bookingData.no_of_rooms}
                    error={errors.no_of_rooms}
                    disabled={bookingData.room_category === ""}
                  />
                  <CreateMessageInputBox
                    alignment="vertical"
                    label="No. of Adults"
                    onChange={handleNumberOfAdultChange}
                    name="no_of_adults"
                    value={bookingData.no_of_adults}
                    error={errors.no_of_adults}
                    disabled={bookingData.room_category === ""}
                  />
                  <CreateMessageInputBox
                    alignment="vertical"
                    label="Extra Adults"
                    onChange={handleNumberOfExtraAdultChange}
                    name="extra_adults"
                    value={bookingData.extra_adults}
                    error={errors.extra_adults}
                    disabled={bookingData.room_category === ""}
                  />
                  <CreateMessageInputBox
                    alignment="vertical"
                    label="No. of Child"
                    // onChange={handleInputChange}
                    name="no_of_child"
                    onChange={handleNumberOfChildChange}
                    value={bookingData.no_of_child}
                    error={errors.no_of_child}
                    disabled={bookingData.room_category === ""}
                  />

                  <div>
                    <WithoutBgButtonBlue
                      style={{ background: "none", marginTop: "27px" }}
                      onClick={handleClickOnAddButton}
                      type={"submit"}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_7483_1851)">
                            <path
                              d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                              stroke="#3968ED"
                            />
                            <path
                              d="M10.5873 7H3.41406"
                              stroke="#3968ED"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M7 3.41344L7 10.5867"
                              stroke="#3968ED"
                              stroke-miterlimit="10"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7483_1851">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      }
                      // children={"Add "}
                    />
                  </div>
                </div>
                <div>
                  {availableRooms.available_rooms !== undefined && (
                    <p
                      className="avail_room"
                      style={{
                        fontSize: "12px",
                        backgroundColor:
                          availableRooms.available_rooms === 0
                            ? "rgb(255 0 0 / 18%)"
                            : "",
                        color:
                          availableRooms.available_rooms === 0 ? "red" : "",
                      }}
                    >
                      {availableRooms.available_rooms === 0
                        ? "Rooms not available"
                        : `Available Rooms: ${availableRooms.available_rooms}`}
                    </p>
                  )}

                  {(availableRooms.base_adult !== undefined ||
                    availableRooms.base_adult === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Base Occupancy: {availableRooms.base_adult}
                    </p>
                  )}

                  {(availableRooms.max_adult !== undefined ||
                    availableRooms.max_adult === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Max. Occupancy: {availableRooms.max_adult}
                    </p>
                  )}

                  {(availableRooms.allowedExtraAdult !== undefined ||
                    availableRooms.allowedExtraAdult === "") && (
                    <p
                      style={{
                        fontSize: "10px",
                        color: availableRooms.allowedExtraAdult
                          ? "green"
                          : "red",
                      }}
                    >
                      Extra Adult Allowed:{" "}
                      {availableRooms.allowedExtraAdult
                        ? `Enabled (${availableRooms.allowedExtraAdult})`
                        : "Disabled"}
                    </p>
                  )}

                  {(availableRooms.max_child !== undefined ||
                    availableRooms.max_child === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Child Allowed: {availableRooms.max_child}
                    </p>
                  )}
                  {/* {(availableRooms.base_adult !== undefined ||
                    availableRooms.base_adult === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Base Adult: {availableRooms.base_adult}
                    </p>
                  )} */}

                  {/* {(availableRooms.max_adult !== undefined ||
                    availableRooms.max_adult === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Max Adult: {availableRooms.max_adult}
                    </p>
                  )} */}

                  {(availableRooms.allowedExtraAdult !== undefined ||
                    availableRooms.allowedExtraAdult === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Extra Adult Allowed:{" "}
                      {availableRooms.allowedExtraAdult
                        ? "Enabled"
                        : "Disabled"}
                    </p>
                  )}

                  {/* {(availableRooms.max_child !== undefined ||
                    availableRooms.max_child === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Child Allowed: {availableRooms.max_child}
                    </p>
                  )} */}
                  <></>
                </div>
              </div>
              <div className="create_booking_page_mids flex gap align_center">
                {/* <CreateBookingSelectBox
                  label="Booking Source"
                  value={bookingData?.booking_source}
                  onChange={handleBookingSourceChange}
                  options={BookingSourceOptions}
                  disabled={bookingDataArray.length > 0}
                /> */}

                {bookingData.booking_source === "Agent (B2B) Partner" && (
                  <label>
                    Select Agent <br />
                    <select
                      onChange={handleInputChange}
                      name="agent_id"
                      className="createBookingInputAndSelectTags"
                    >
                      <option>Select</option>
                      {agentList.map((item, index) => {
                        return (
                          <option key={index} value={item.agent_information}>
                            {item.agent_name}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                )}
                {bookingData.booking_source === "OTA" && (
                  <label>
                    Select OTA <br />
                    <select
                      onChange={handleInputChange}
                      name="ota_source"
                      className="createBookingInputAndSelectTags"
                      value={bookingData.ota_source}
                    >
                      <option>Select</option>
                      {otaTypeOptions.map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                )}
                {bookingData.agent_id !== "" && (
                  <div>
                    <label>TAC Disc. %</label> <br />
                    <input
                      type="number"
                      name="tac_discount"
                      disabled
                      defaultValue={bookingData?.tac_discount}
                      className="createBookingInputAndSelectTags"
                    />
                  </div>
                )}
              </div>
            </div>

            {bookingDataArray.length > 0 && (
              <>
                {bookingDataArray.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      {/* <CreateMessageCard
                        index={index}
                        data={data}
                        discountValue={discountValue}
                        RoomCategoryOptions={RoomCategoryOptions}
                        handleDeleteBookingCard={handleDeleteBookingCard}
                        allowedRooms={+availableRooms.available_rooms}
                        allowedChild={+availableRooms.max_child}
                        getDataFromChild={getDataFromChild}
                        availableRooms={availableRooms}
                        setAvailableRooms={setAvailableRooms}
                        tax_type={bookingData.tax_type}
                        discount_type={bookingData.discount_type}
                        showDiscountSelect={showDiscountSelect}
                        setShowDiscountSelect={setShowDiscountSelect}
                      /> */}

                      {/* create message card testing  */}
                      <CreateMessageCardNew
                        index={index}
                        singleBookingData={data}
                        setBookingDataArray={setBookingDataArray}
                        // bookingDataArray={bookingDataArray}
                        handleDeleteBookingCard={handleDeleteBookingCard}
                        discount_type={bookingData.discount_type}
                        tax_type={bookingData.tax_type}
                        getAdhocArrayFromChild={getAdhocArrayFromChild}
                        adhocArray={adhocArray}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </div>
        </div>
        {showPopup && (
          <Popup content={iframeContent} onClose={() => setShowPopup(false)} />
        )}
        {/* salutations popup */}

        {showSalutationPopup && (
          <>
            <div className="global_popup_container_background">
              <div className="global_popup_container ">
                <div className="GoBackButtonCustom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => setShowSalutationPopup(false)}
                  >
                    <rect
                      x="29.75"
                      y="29.75"
                      width="29.5"
                      height="29.5"
                      rx="5.75"
                      transform="rotate(180 29.75 29.75)"
                      stroke="#666666"
                      strokeWidth="0.5"
                    ></rect>
                    <line
                      x1="21"
                      y1="15.043"
                      x2="9"
                      y2="15.043"
                      stroke="#666666"
                    ></line>
                    <path
                      d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                      stroke="#666666"
                      strokeWidth="1.2"
                    ></path>
                  </svg>
                  <span>Add </span>
                </div>

                <VerticalInputFieldCustom
                  titleName={"Salutation"}
                  name={"name"}
                  type={"text"}
                  value={popUpSalutation}
                  onChange={(e) => setPopUpSalutation(e.target.value)}
                />

                <BgThemeButton
                  children={"Add"}
                  onClick={handlePopupAddButtonClick}
                  style={{ margin: "10px 0" }}
                />
              </div>
            </div>
          </>
        )}

        {/* total amount Card   */}

        <div className="create_booking_page_total_amount_card">
          {/* setting the discount type and tax type changing these will change the total amount */}
          {/* this select tag is sending id to handler and it sae discount type object in BookingData object */}
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              gap: "10px",
              flexWrap: "wrap",
              padding: "20px",
              bordera: "0.5px solid #333",
            }}
          >
            <div>
              <>
                {/* <label>Disc Type</label> <br />
                  <select
                    onChange={handleDiscountTypeChange}
                    name="discount_amount"
                    className="createBookingInputAndSelectTags"
                    style={{ marginTop: "5px", width: "150px" }}
                  >
                    <option>Select</option>
                    {discountOptions &&
                      // discountOptions.length > 0 &&
                      discountOptions?.map((item, index) => {
                        return (
                          <option key={index} value={item.discount_amount}>
                            {item.discount_name} {item.discount_amount}
                          </option>
                        );
                      })}
                  </select> */}

                {/* <CreateMessageInputBox
                    alignment="vertical"
                    label="Disc %"
                    name="discountPercent" 
                    onChange={handleDiscountTypeChange}
                  /> */}
   <div className="gap_7 flex" style={{ flexDirection: "column" }}>
  <label className="Create_booking_label">Disc %</label>
  <input
    style={{
      padding: "6px 20px 6px 10px",
      width: "50%",
      borderRadius: "4px",
      border: "1px solid #ddd",
      outline: "none",
    }}
    onChange={handleDiscountTypeChange}
    name="discount_type"
    type="number"
    value={bookingData.discount_type}
  ></input>
</div>
              </>
            </div>

            {/* <div>
              <label className="Create_booking_label">Tax</label>
              <div className="flex gap_7">
                <label className="Create_booking_label flex gap_7">
                  <input
                    type="radio"
                    name="tax_type"
                    value="inclusive"
                    checked={bookingData.tax_type === "inclusive"}
                    onChange={handleInputChange}
                  ></input>
                  Inclusive
                </label>
                <label className="Create_booking_label flex gap_7">
                  <input
                    type="radio"
                    name="tax_type"
                    value={"exclusive"}
                    checked={bookingData.tax_type === "exclusive"}
                    onChange={handleInputChange}
                  ></input>
                  Exclusive
                </label>
              </div>
            </div> */}
          </div>

          {/* <div style={{ padding: "20px", position: "relative" }}>
            <div className="crate_booking_page_total_amount_heading">
              <span>Subtotal</span>
              <span>
                {formatIndianCurrency(+sumSubTotal + +sumSubtotalAddons)}{" "}
              </span>
            </div>
            <div className="crate_booking_page_total_amount_heading">
              <span>Discount Amt.</span>
              <span>{formatIndianCurrency(+sumDiscountAmount)}</span>
            </div>

            <div className="crate_booking_page_total_amount_heading">
              <span>Tax Amt.</span>
              <span>
                {formatIndianCurrency(+sumTaxAmount + +sumTaxAmountAddons)}{" "}
              </span>
            </div>
            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#3968ED" }}
            >
              <span>Grand Total</span>
              <span>
                {formatIndianCurrency(+sumGrandTotal + +sumGrandTotalAddons)}
              </span>
            </div>

            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#15AA12" }}
            >
              <h4>Paid Amt.</h4>
              <h3>{formatIndianCurrency(sumPaidAmount)}</h3>
            </div>
            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#BD941B" }}
            >
              <h4>Due Amt.</h4>
              <h3>
                {formatIndianCurrency(
                  +sumGrandTotal + +sumGrandTotalAddons - sumPaidAmount
                )}
              </h3>
            </div>
          </div> */}

          {bookingData.booking_type === "Single" && (
            <div style={{ padding: "20px", position: "relative" }}>
              <div className="crate_booking_page_total_amount_heading">
                <span>Sub Total</span>
                <span>
                  {formatIndianCurrency(+sumSubTotal + +sumSubtotalAddons)}
                </span>
              </div>
              <div className="crate_booking_page_total_amount_heading">
                <span>Discount Amt.</span>
                <span>
                  {formatIndianCurrency(
                    +sumDiscountAmount + +sumDiscountAmountAddons
                  )}
                </span>
              </div>
              <div className="crate_booking_page_total_amount_heading">
                <span>Tax Amt.</span>
                <span>
                  {formatIndianCurrency(+sumTaxAmount + +sumTaxAmountAddons)}
                </span>
              </div>
              <div
                className="crate_booking_page_total_amount_heading"
                style={{ color: "#3968ED" }}
              >
                <span>Grand Total</span>
                <span>
                  {formatIndianCurrency(+sumGrandTotal + +sumGrandTotalAddons)}
                </span>
              </div>
              {/* <div
                className="crate_booking_page_total_amount_heading"
                style={{ color: "#15AA12" }}
              >
                <h4>Paid Amt.</h4>
                <h3>{formatIndianCurrency(sumPaidAmount)}</h3>
              </div> */}
              {/* <div
                className="crate_booking_page_total_amount_heading"
                style={{ color: "#BD941B" }}
              >
                <h4>Due Amt.</h4>
                <h3>
                  {formatIndianCurrency(
                    +sumGrandTotal + +sumGrandTotalAddons - sumPaidAmount
                  )}
                </h3>
              </div> */}
            </div>
          )}

          <div
            className="flex gap_7"
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <BgOutlineThemeHoldButton
              onClick={() => setShowHoldPopup(true)}
              // type={"submit"}
              children={"Hold Time"}
            />
            <BgThemeButton
              style={{ width: "100%" }}
              loader={loader}
              // onClick={handleConfirmButtonClick}
              onClick={handleAssignButtonClick}
              type={"submit"}
              children={"Send Rates & Availabilty"}
            />
            {isDataReceived && (
              <WithoutBgButtonBlue
                style={{ width: "100%", background: "none" }}
                onClick={(e) => handleSubmitPreviewVoucher()}
                type={"submit"}
              >
                Preview
              </WithoutBgButtonBlue>
            )}

            {/* <BgThemeButton
              style={{ background: "#BD941B" }}
              onClick={() => setShowHoldPopup(true)}
            >
              Hold
            </BgThemeButton> */}
          </div>

          {iframeContent && showIframePopup && (
            <>
              <div className="bg-backdrop">
                <section
                  className="communication_iframe_container"
                  style={{ width: "795px", overflow: "hidden" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                      padding: "0 25px",
                    }}
                  >
                    <div
                      className="add-ota-model-container-heading"
                      style={{ display: "flex", gap: "10px" }}
                    ></div>
                    <div
                      onClick={() => setShowIframePopup(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>
                  {/* <iframe
                title="Preview"
                src={iframeSrc}
                width="100%"
                height="100%"
              /> */}
                  <iframe
                    srcDoc={iframeContent}
                    id="receipt"
                    // style={{ display: "none" }}
                    title="Receipt"
                  />
                  {/* <PreviewCommunicationVoucher uniquebookingid={bookingId} /> */}
                </section>
              </div>
            </>
          )}

          {showHoldPopup && (
            <>
              <div
                className="createBookingTableNewPopupContainer"
                onClick={() => setShowHoldPopup(false)}
              >
                <div
                  className="createBookingTableNewPopupContent"
                  onClick={(e) => e.stopPropagation()}
                  style={{ width: "400px" }}
                >
                  <h3 style={{ textAlign: "center" }}>Hold Time</h3>

                  <div className="d_flex gap_7" style={{ margin: "15px 0" }}>
                    <div style={{ width: "100%" }}>
                      <label
                        style={{
                          textAlign: "start",
                          fontSize: "12px",
                          color: "#333333",
                        }}
                      >
                        Select Hours
                      </label>{" "}
                      <br />
                      {/* <select
                        className="addEvent-input"
                        name="hold_time"
                        value={holdBookingData.hold_time}
                        onChange={holdBookingDataChange}
                      >
                        {timeArray.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}
                      {/* <VerticalInputFieldCustom
                        format={"HH:mm:ss"}
                        type="time"
                        step="1"
                        onChange={(e)=>setHoldBookingData(e.target.value)}
                        name="hold_time"
                        placeholder="Select time"
                        value={holdBookingData.hold_time}
                      /> */}
                      <input
                        className="addEvent-input"
                        format={"HH:mm:ss"}
                        type="time"
                        width={100}
                        style={{ cursor: "pointer" }}
                        step="1"
                        onChange={(e) => setHoldBookingData(e.target.value)}
                        name="hold_time"
                        placeholder="Select Hours"
                        value={holdBookingData.hold_time}
                      />
                    </div>
                  </div>

                  <div className="createBookingTableNewPopupFooter">
                    <BgThemeButton
                      // type={"submit"}
                      children={"Set Time"}
                      style={{ background: "#BD941B", width: "48%" }}
                      onClick={holdBookingDataChange}
                    />
                    <BgOutlineThemeButton
                      //  type={"submit"}
                      children={"Discard"}
                      onClick={() => setShowHoldPopup(false)}
                      style={{ width: "48%" }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default CreateMessageNewPage;

import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Page,
} from "@shopify/polaris";

import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BgThemeButton } from "../UI/Buttons";

import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import NoDataFound from "../CustomComponents/NoDataFound";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import { getDecryptedData } from "../../utils/encryptStorage";

const StoreRequestedItems = () => {
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [items, setItems] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const navigate = useNavigate();

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
    useState(false);

  const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
    useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const [issues, setIssues] = useState({
    issue_to: "",
    issue_by: "",
    issue_date: "",
    details: [],
  });

  const [itemDetails, setItemDetails] = useState([
    {
      purchased_item_id: "",
      item_price: "",
      quantity: "",
      max_qty: "",
    },
  ]);

  const [errors, setErrors] = useState(Array(itemDetails.length).fill(""));
  const [hasQuantityError, setHasQuantityError] = useState(false);

  const handleAddStockChange = (e) => {
    const { name, value } = e.target;
    setIssues((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    setIssues((prevValue) => ({
      ...prevValue,
      details: itemDetails,
    }));
  }, [itemDetails]);

  // const handleChangePurchaseItem = (event, index) => {
  //   const selectedId = parseInt(event.target.value);
  //   const selectedItem = purchases?.find((item) => item.id === selectedId);

  //   if (selectedItem) {
  //     const updatedItemDetails = [...itemDetails];

  //     updatedItemDetails[index] = {
  //       ...updatedItemDetails[index],
  //       purchased_item_id: selectedId,
  //       item_price: selectedItem.item_price,
  //       max_qty: selectedItem.item_qty,
  //     };

  //     setItemDetails(updatedItemDetails);
  //   } else {
  //     const updatedItemDetails = [...itemDetails];
  //     updatedItemDetails[index] = {
  //       ...updatedItemDetails[index],
  //       purchased_item_id: "",
  //       max_qty: "",
  //     };
  //     setItemDetails(updatedItemDetails);
  //   }
  // };

  const handleChangePurchaseItem = (event, index) => {
    const selectedId = parseInt(event.target.value);
    const selectedItem = purchases?.find((item) => item.id === selectedId);

    const updatedItemDetails = [...itemDetails];
    const existingIndex = updatedItemDetails.findIndex(
      (item) => item.purchased_item_id === selectedId
    );

    if (existingIndex !== -1) {
      showErrorToast("Item already selected");
      return; // Stop further execution if item already exists
    }

    if (selectedItem) {
      // Item is not already selected, add it to the array
      updatedItemDetails[index] = {
        ...updatedItemDetails[index],
        purchased_item_id: selectedId,
        item_price: selectedItem.item_price,
        max_qty: selectedItem.item_qty < 0 ? 0 : selectedItem.item_qty,
        remaining_qty: selectedItem.item_qty < 0 ? 0 : selectedItem.item_qty,
        quantity: 0,
      };
    } else {
      // No item selected, reset the details
      updatedItemDetails[index] = {
        ...updatedItemDetails[index],
        purchased_item_id: "",
        max_qty: "",
      };
    }

    setItemDetails(updatedItemDetails);
  };

  const handleTableInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItemDetails = [...itemDetails];
    updatedItemDetails[index][name] = value;

    setItemDetails(updatedItemDetails);
  };

  const handleQuantityChange = (event, index) => {
    const newItems = [...itemDetails];
    const inputValue = event.target.value;
    const maxQty = newItems[index].max_qty;

    let remaining_qty = maxQty - parseInt(inputValue).toFixed(2);

    if (remaining_qty < 0) {
      remaining_qty = 0;
    }

    if (parseInt(inputValue) > maxQty) {
      const newErrors = [...errors];
      setHasQuantityError(true);
      newErrors[index] = "Quantity cannot exceed the maximum.";
      setErrors(newErrors);
    } else {
      const newErrors = [...errors];
      newErrors[index] = ""; // Clear the error message if the quantity is within the limit.
      setErrors(newErrors);
      setHasQuantityError(false);
    }

    newItems[index].quantity = inputValue;
    newItems[index].remaining_qty = remaining_qty;
    setItemDetails(newItems);
  };

  const handleRemoveStock = (index) => {
    const updatedItemDetails = [...itemDetails];
    updatedItemDetails.splice(index, 1);
    setItemDetails(updatedItemDetails);
  };

  const handleAddStock = () => {
    setItemDetails([
      ...itemDetails,
      {
        purchased_item_id: "",
        item_price: "",
        quantity: "",
        max_qty: "",
      },
    ]);
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/requested/items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchStaff = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/get-user/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setStaffs(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchItems = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setItems(data.Item);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchPurchases = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/purchased/items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setPurchases(data.purchase_Items);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const rowMarkup =  orders?.data?.map(
    ({
      id,
      quantity,
      unit,
      request_by,
      index,
      item_department,
      item_category,
      request_date,
    }) => (
      <IndexTable.Row
        id={id}
        key={index}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {(
            <TextDateFormatter date={request_date} as={"div"} showYear={true} />
          ) || request_date}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {<UnderscoreRemoveCapitalize text={item_department} />}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {<UnderscoreRemoveCapitalize text={item_category} />}
        </IndexTable.Cell>
        <IndexTable.Cell>{request_by}</IndexTable.Cell>
        <IndexTable.Cell>
          {+quantity} {unit}
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const handleSubmitAddIssueItem = async (e) => {
    e.preventDefault();
    if (hasQuantityError) {
      showErrorToast("Please correct quantity errors before submitting.");
      return;
    }
    if (issues.details.length <= 0) {
      showErrorToast("Please add atleast one item");
      return;
    }

    const hasInvalidItems = issues.details.some(
      (item) => item.max_qty <= 0 || item.quantity <= 0
    );
    if (hasInvalidItems) {
      showErrorToast("Quantity cannot be zero or negative.");
      return;
    }

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/issues/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...issues }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        clearSelection();
        showSuccessToast(data.message);
        setIssues({
          issue_to: "",
          issue_by: "",
          issue_date: "",
          details: [],
        });
        setItemDetails([
          {
            purchased_item_id: "",
            item_price: "",
            quantity: "",
            max_qty: "",
          },
        ]);
        setShowAddItemModelMenuActive(false);
        setLoader(false);
        // window.location.reload()
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      // fetchStaff(property_id?.id);
      // fetchItems(property_id?.id);
      // fetchPurchases(property_id?.id);
    }
  }, [property_id]);

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/purchased/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const removeItemConfirm = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/requested/items/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIds),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowModelMenuActive(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleUpdateStock = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/purchased/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...issues }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        clearSelection();
        showSuccessToast(data.message);
        setIssues({
          category_id: "",
          puchased_item_id: "",
          issue_to: "",
          issue_by: "",
          // quantity: "",
        });
        setShowEditModelMenuActive(false);
        // window.location.reload()
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  return (
    <>
      {loader ? (
        <LoaderSmall />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div>
                <GoBackButtonCustom
                  buttonNextText={"Requested Items"}
                  onClick={() => navigate("/hotelier/purchase")}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <div>
                  <div style={{ textDecoration: "none" }}>
                    {/* <BgThemeButton
                      onClick={() =>
                      [setShowAddItemModelMenuActive(
                        !showAddItemModelMenuActive
                      ),
                      fetchStaff(property_id?.id) , fetchItems(property_id?.id)]
                        
                      }
                      children={"Add Issue Item"}
                    /> */}
                  </div>
                </div>
              </div>
            </div>

            <LegacyCard>
              {orders && orders?.data &&  orders?.data?.length > 0 ? (
                <IndexTable
                  resourceName={resourceName}
                  itemCount={orders.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "Requested Date" },
                    // { title: "Purchased Item Name" },
                    { title: "Department" },
                    { title: "Item Category" },
                    { title: "Requested By" },
                    { title: "Quantity" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              ) : (
                <NoDataFound />
              )}

              {/* Single select */}
              {showLowerMenuSingleSelect && (
                <div className=" room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        gap: "20px",
                      }}
                    >
                      <Button plain destructive onClick={showModelMenu}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>

                      {/* <BgThemeButton
                                            children={"Edit"}
                                            onClick={() => setShowEditModelMenuActive(!showEditModelMenuActive)}
                                        /> */}
                    </div>
                  </div>
                </div>
              )}

              {/* Multiple select */}
              {showLowerMenuMultipleSelect && (
                <div className=" room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button plain destructive onClick={showModelMenu}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </LegacyCard>
          </div>

          {/* Add Item Button Click */}
          {showAddItemModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="stock-add-model-container">
                  <form onSubmit={(e) => handleSubmitAddIssueItem(e)}>
                    <div>
                      {/* <Page
                        backAction={{
                          content: "Products",
                          url: `/hotelier/purchase/issueitems`,
                        }}
                        title="Add Issue Items"
                        compactTitle
                      ></Page> */}

                      <GoBackButtonCustom
                        buttonNextText={`Add Issue Items`}
                        onClick={() => setShowAddItemModelMenuActive(false)}
                      />
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div style={{ marginBottom: "4px" }}>
                          Issued To
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <select
                          style={{
                            width: "100%",
                            minHeight: "2.25rem",
                            border: "1px solid rgba(171, 177, 186, 1)",
                            borderRadius: "3px",
                          }}
                          required={true}
                          name="issue_to"
                          onChange={handleAddStockChange}
                          value={issues.issue_to}
                        >
                          <option value="">Select Option</option>
                          {staffs?.map((x, index) => (
                            <option key={index} value={x.name}>
                              {x.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          titleName={"Issue Date"}
                          type="date"
                          value={issues.issue_date}
                          name="issue_date"
                          className="addStaffInputs"
                          onChange={handleAddStockChange}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="addStockTable">
                      <div className="addStockTableHeader">
                        <div style={{ width: "25%" }}>Purchased Item Name</div>
                        <div style={{ width: "8%" }}>Purchase ID</div>
                        <div style={{ width: "15%" }}>Item Price</div>
                        <div style={{ width: "15%" }}>Qty</div>
                        <div style={{ width: "10%" }}>Stock</div>
                        <div style={{ width: "10%" }}>Max Qty</div>
                        <div style={{ width: "10%" }}></div>
                      </div>

                      {itemDetails?.map((_, index) => (
                        <div className="addStockTableContent" key={index}>
                          <div style={{ width: "25%" }}>
                            <select
                              style={{
                                width: "100%",
                                minHeight: "2.25rem",
                                border: "1px solid rgba(171, 177, 186, 1)",
                                borderRadius: "3px",
                              }}
                              required={true}
                              name="purchased_item_id"
                              onChange={(e) =>
                                handleChangePurchaseItem(e, index)
                              }
                              value={itemDetails[index].purchased_item_id}
                            >
                              <option value="">Select Option</option>
                              {purchases?.map((x, index) => (
                                <option key={index} value={x.id}>
                                  {x.item_name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div style={{ width: "8%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              value={itemDetails[index].purchased_item_id}
                              disabled={true}
                            />
                          </div>
                          <div style={{ width: "15%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              value={itemDetails[index].item_price}
                              className="addStockTablePrice"
                              name="item_price"
                              required={"true"}
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "15%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              value={itemDetails[index].quantity}
                              className="addStockTableTotalPrice"
                              name="quantity"
                              required={"true"}
                              onChange={(e) => handleQuantityChange(e, index)}
                            />
                            <span style={{ color: "red" }}>
                              {errors[index]}
                            </span>
                          </div>

                          <div style={{ width: "10%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              className="addStockTableTotalPrice"
                              name={`max_qty_${index}`}
                              value={itemDetails[index]?.remaining_qty}
                              disabled={"disabled"}
                            />
                          </div>
                          <div style={{ width: "10%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              className="addStockTableTotalPrice"
                              name={`max_qty_${index}`}
                              value={itemDetails[index]?.max_qty}
                              disabled={"disabled"}
                            />
                          </div>

                          <div style={{ width: "10%" }}>
                            <button
                              onClick={handleAddStock}
                              className="addStockButton"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_622_2184)">
                                  <path
                                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                    stroke="#3968ED"
                                  />
                                  <path
                                    d="M10.5868 7H3.41357"
                                    stroke="#3968ED"
                                    strokeMiterlimit="10"
                                  />
                                  <path
                                    d="M7 3.41344L7 10.5867"
                                    stroke="#3968ED"
                                    strokeMiterlimit="10"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_622_2184">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              {/* Add More */}
                            </button>

                            {index > 0 && (
                              <button
                                onClick={() => handleRemoveStock(index)}
                                className="addStockButton"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.5 3H12.75C13.1642 3 13.5 3.33579 13.5 3.75L13.5 4.5H1.5L1.50002 3.75C1.50002 3.33579 1.83581 3 2.25002 3H4.50002V1.125C4.50002 0.50368 5.0037 0 5.62502 0H9.37502C9.99634 0 10.5 0.50368 10.5 1.125V3ZM6.00002 1.5V3H9.00002V1.5H6.00002Z"
                                    fill="#888888"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2.25002 6H12.75V13.875C12.75 14.4963 12.2463 15 11.625 15H3.37502C2.7537 15 2.25002 14.4963 2.25002 13.875V6ZM5.25 8.25H3.75V12.75H5.25V8.25ZM8.25 8.25H6.75V12.75H8.25V8.25ZM9.75 8.25H11.25V12.75H9.75V8.25Z"
                                    fill="#888888"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        children={"Add"}
                        type={"submit"}
                        // onClick={}
                      />
                      <Button
                        onClick={() => setShowAddItemModelMenuActive(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </section>
              </div>
            </>
          )}

          {/* Edit Button Click */}
          {showEditModelMenuActive && (
            <>
              <div className="bg-backdrop"></div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={removeItemConfirm} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default StoreRequestedItems;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { to, Outlet, useLocation } from "react-router-dom";
import NavTabs from "../NewCustomComp/NavTabs/NavTabs";

const SettingsContent = () => {
  const activeApp = localStorage.getItem("activeApp");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const property_id = useSelector((state) => state.property.value);
  const location = useLocation();

  useEffect(() => {
    const selectedProperty = JSON.parse(
      localStorage.getItem("selectedProperty")
    );
    setSelectedProperty(selectedProperty);
  }, [property_id?.id]);

  const booking_engine_tabs = [
    {
      label: "Profile",
      to: "profile",
    },

    {
      label: "Brand",
      to: "brand",
    },
    {
      title: "Flexi-Pay",
      link: "flexi-pay",
    },
  ];

  const channel_manager_tabs = [
    {
      label: "Profile",
      to: "profile",
    },

    {
      label: "Brand",
      to: "brand",
    },
  ];

  const pma_tabs = [
    {
      label: "Profile",
      to: "profile",
    },
    // {
    //   label: "Location",
    //   to: "location",
    // },
    {
      label: "Brand",
      to: "brand",
    },
    {
      label: "Distance",
      to: "distance",
    },
    {
      label: "Tax",
      to: "tax",
    },
    {
      label: "Staffs",
      to: "staffs",
    },
    // {
    //   label: "Roles & Permissions",
    //   to: "roles-and-permission",
    // },
    {
      label: "Activity Logs",
      to: "activity-logs",
    },
    {
      label: "OTA Mapping",
      to: "ota-mapping",
    },
    {
      label: "Folio Series",
      to: "folio-series",
    },
    {
      label: "Payment Gateways",
      to: "folio-gateways",
    },
    {
      label: "Privacy Policy",
      to: "privacy-policy",
    },
    {
      label: "Domains",
      to: "property-domain",
    },
    {
      label: "Add-Ons",
      to: "add-ons",
    },
    {
      label: "Flexi-Pay",
      to: "flexi-pay",
    },
    {
      label: "Bill Templates",
      to: "billing-templates",
    },
  ];
  const tabs =
    activeApp === "booking_engine"
      ? booking_engine_tabs
      : activeApp === "channel_manager"
      ? channel_manager_tabs
      : pma_tabs;

  const activeTab = tabs.findIndex((tab) => location.pathname.includes(tab.to));

  return (
    <div className="main_container">
      <NavTabs mainData={tabs} />
      <div style={{ width: "70%", margin: "20px auto" }}>
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsContent;

import React, { useState, useEffect } from "react";
import { popupStyles } from "./styles";
import { BgThemeButton, BgOutlineThemeButton } from "../../UI/Buttons";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import SearchComponent from "../SearchComponent/SearchComponent";
import SelectDateRange from "../SelectDateRange/SelectDateRange";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import LoaderSmall from "../../UI/Loader/LoaderSmall";
import "./styles.css";
import { getDecryptedData } from "../../../utils/encryptStorage";

const HoldRoomsAgentsPopup = ({ closePopup, propertyCurrentDate,fetchHoldRoomsListing,handleDelete }) => {
  
  const userToken = getDecryptedData('encryptToken');
  const [holdRoomPopup, setHoldRoomPopup] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState({});
  const handleHoldRoomsButtonClick = () => {
    setHoldRoomPopup(true);
  };
  const [selectedAgent, setSelectedAgent] = useState("contracted");
  const handleRadioAgentChange = (agentType) => {
    setSelectedAgent(agentType);
    setRoomData([]);
    setSelectedRoomCategories([]);
    setAvailableRooms({});
    setDateRange({
      from_date: "",
      to_date: "",
    });
    setCheckedCategories({});
  };

  const [dateRange, setDateRange] = useState({
    from_date: "",
    to_date: "",
  });

  const handleDateChange = (form_date, to_date) => {
    setDateRange({
      from_date: form_date,
      to_date: to_date,
    });
  };

  const [selectedRelease, setSelectedRelease] = useState("auto_release");

  const handleReleaseRadioChange = (name) => {
    setSelectedRelease(name);
    setDataForBackend((perv) => ({ ...perv, release_type: name }));
    setGeneralAgents((perv) => ({ ...perv, release_type: name }));
  };

  const property_id = useSelector((state) => state.property.value);

  // ==== room category  =======
  const [roomCategories, setRoomCategories] = useState();
  const [agents, setAgents] = useState([]);
  const [dataForBackend, setDataForBackend] = useState({
    reference_id: "",
    reference_person_name: "",
    reference_person_phone: "",
    hold_for: "Event",
    hold_type: "Temporary",
    release_type: "auto_release",
  });

  const getSelectedItem = (data) => {
    setDataForBackend({
      reference_id: data.agent_information,
      reference_person_name: data.agent_name,
      reference_person_phone: data.agent_phone,
      hold_for: "Event",
      hold_type: "Temporary",
    });
  };

  const [loader, setLoader] = useState(true);

  const fetchRoomCategories = async () => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };

  const fetchAgents = async () => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };

  useEffect(() => {
    if (property_id?.id) {
      Promise.all([fetchRoomCategories(), fetchAgents()])
        .then(([roomCategoriesData, agentsData]) => {
          if (roomCategoriesData.message === "Unauthorised.") {
            // navigate("/");
          } else {
            setRoomCategories(roomCategoriesData?.rooms);
          }

          setAgents(agentsData?.data ? agentsData?.data : []);
          setLoader(false);
        })
        .catch((error) => console.error(error));
    }
  }, [property_id?.id]);

  const [selectedRoomCategories, setSelectedRoomCategories] = useState([]);
  const [availableRooms, setAvailableRooms] = useState({});
  const [roomData, setRoomData] = useState([]);

  const checkedRoomCategories = (roomName, id) => {
    if (dateRange.from_date === "" || dateRange.to_date === "") {
      showErrorToast("Please select the Date Range");
      return;
    }

    if (selectedAgent === "contracted" && !dataForBackend.reference_id) {
      showErrorToast("Please select the Agent");
      return;
    }
    if (selectedAgent === "general" && !generalAgents.agent_name) {
      showErrorToast("Please enter company name");
      return;
    }

    if (selectedRoomCategories.includes(roomName)) {
      setSelectedRoomCategories(
        selectedRoomCategories.filter((item) => item !== roomName)
      );
      setRoomData(roomData.filter((item) => item.room_id !== id));
    } else {
      setSelectedRoomCategories([...selectedRoomCategories, roomName]);
      checkAvailability(id, roomName);
    }
  };

  const checkAvailability = async (id, roomName) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${dateRange.from_date}&check_out=${dateRange.to_date}&room_id=${id}`,
        // `${process.env.REACT_APP_BASE_URL}/api/v1/inventories/edit/${id}/${dateRange.from_date}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAvailableRooms((prev) => ({
        ...prev,
        [roomName]: data.available_rooms,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleHoldRoomsChange = (roomName, id, value) => {
    const validValue = Math.min(value, availableRooms[roomName] || 0);
    setCheckedCategories((prev) => ({
      ...prev,
      [`${roomName}Input`]: validValue,
    }));

    setRoomData((prev) => {
      const existingCategory = prev.find((item) => item.room_id === id);
      if (existingCategory) {
        return prev.map((item) =>
          item.room_id === id ? { ...item, hold_room: validValue } : item
        );
      }
      return [
        ...prev,
        { room_id: id, hold_room: validValue, room_name: roomName },
      ];
    });
  };
  const [releaseRooms, setReleaseRooms] = useState([
    { release: "D-7", amount: "" },
    { release: "D-5", amount: "" },
    { release: "D-3", amount: "" },
  ]);
  const releaseOptionsPercent = [
    { label: "0%", value: "0" },
    { label: "25%", value: "25" },
    { label: "50%", value: "50" },
    { label: "75%", value: "75" },
    { label: "100%", value: "100" },
  ];
  const calculateTotal = () => {
    return releaseRooms.reduce((total, room) => {
      return total + (parseInt(room.amount) || 0);
    }, 0);
  };
  const handleSelectChange = (index, value) => {
    const updatedReleaseRooms = [...releaseRooms];
    updatedReleaseRooms[index].amount = value;
    setReleaseRooms(updatedReleaseRooms);
  };

  const currentTotal = calculateTotal();

  const handleContractedAgentSubmit = async () => {
    let bodyData = {};

    if (selectedAgent === "contracted" && selectedRelease === "auto_release") {
      
      bodyData = {
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
        requestType: "hold_rooms",
        reference_type: selectedAgent,
        reference_id: dataForBackend.reference_id,
        reference_person_name: dataForBackend.reference_person_name,
        reference_person_phone: dataForBackend.reference_person_phone,
        release_type: selectedRelease,
        release_label: releaseRooms.map((room) => room.release),
        release_percent: releaseRooms.map((room) => room.amount || 0),
        room_categories: roomData.map((room) => ({
          room_id: room.room_id,
          hold_rooms: room.hold_room,
          room_name: room.room_name,
        })),
      };
    } else if (
      selectedAgent === "contracted" &&
      selectedRelease === "manual_release"
    ) {
      console.log("called 2");
      bodyData = {
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
        requestType: "hold_rooms",
        reference_type: selectedAgent,
        reference_id: dataForBackend.reference_id,
        reference_person_name: dataForBackend.reference_person_name,
        reference_person_phone: dataForBackend.reference_person_phone,
        release_type: selectedRelease,
        // release_label: releaseRooms.map((room) => room.release),
        // release_percent: releaseRooms.map((room) => room.amount || 0),
        room_categories: roomData.map((room) => ({
          room_id: room.room_id,
          hold_rooms: room.hold_room,
          room_name: room.room_name,
        })),
      };
    } else if (
      selectedAgent === "general" &&
      selectedRelease === "auto_release"
    ) {
      bodyData = {
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
        requestType: "hold_rooms",
        reference_type: selectedAgent,
        // reference_id: "",
        reference_person_name: generalAgents.agent_name,
        reference_person_phone: generalAgents.agent_phone,
        release_type: selectedRelease,
        release_label: releaseRooms.map((room) => room.release),
        release_percent: releaseRooms.map((room) => room.amount || 0),
        room_categories: roomData.map((room) => ({
          room_id: room.room_id,
          hold_rooms: room.hold_room,
          room_name: room.room_name,
        })),
      };
    } else if (
      selectedAgent === "general" &&
      selectedRelease === "manual_release"
    ) {
      bodyData = {
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
        requestType: "hold_rooms",
        reference_type: selectedAgent,
        reference_person_name: generalAgents.agent_name,
        reference_person_phone: generalAgents.agent_phone,
        release_type: selectedRelease,
        room_categories: roomData.map((room) => ({
          room_id: room.room_id,
          hold_rooms: room.hold_room,
          room_name: room.room_name,
        })),
      };
    }

   

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/hold/room/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(bodyData),
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        // navigate("/")
      }
      if(response.ok || data.success === true){
        showSuccessToast("hold rooms added successfully")
        closePopup()
        fetchHoldRoomsListing("dayTypeFilter=+90")
      }else{
        showErrorToast("something went wrong ...!")
      }
      
    } catch (error) {
      console.log(error);
      showErrorToast("something went wrong ...!")
    }
  };

  // === general agents=======
  const [generalAgents, setGeneralAgents] = useState({
    hold_type: "general",
    release_type: "auto_release",
    agent_name: "",
    agent_phone: "",
  });

  const handleGeneralAgentChange = (e) => {
    setGeneralAgents((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  // console.log(generalAgents, "generalAgents")

  return (
    <div style={popupStyles.container}>
      <div style={popupStyles.containerChild}>
        <h4 style={popupStyles.popupHeadings}>Hold Rooms</h4>
        <div className="flex flex_gap_15">
          <label className="flex flex_gap_5 align_items_center pointer">
            <input
              name="agentType"
              type="radio"
              checked={selectedAgent === "contracted"}
              onClick={() => handleRadioAgentChange("contracted")}
            />
            <span> Contracted Agent</span>
          </label>
          <label className="flex flex_gap_5 align_items_center pointer">
            <input
              name="agentType"
              type="radio"
              checked={selectedAgent === "general"}
              onClick={() => handleRadioAgentChange("general")}
            />
            <span>General Agent</span>
          </label>
        </div>

        <div>
          {selectedAgent === "contracted" ? (
            <>
              <table style={popupStyles.table}>
                <thead>
                  <tr>
                    <th style={{ paddingRight: "5px" }}>Select Agent</th>
                    <th style={{ paddingLeft: "5px" }}>Select Date Range</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingRight: "5px" }}>
                      <SearchComponent
                        searchArray={agents}
                        keyName={"agent_name"}
                        searchFilterBy={"agent_name"}
                        getSelectedItem={getSelectedItem}
                      />
                    </td>
                    <td style={{ paddingLeft: "5px" }}>
                      <SelectDateRange
                        dataFromChild={handleDateChange}
                        minDate={new Date(propertyCurrentDate)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table style={popupStyles.tableBig}>
                <thead>
                  <tr>
                    <th style={{ paddingRight: "5px" }}>Company Name</th>
                    <th style={{ padding: "0 5px" }}>Phone </th>
                    <th style={{ paddingLeft: "5px" }}>Select Date Range</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingRight: "5px" }}>
                      <VerticalInputFieldCustom
                        placeholder="Enter Company Name"
                        name={"agent_name"}
                        onChange={handleGeneralAgentChange}
                      />
                    </td>
                    <td style={{ padding: "0 5px" }}>
                      <VerticalInputFieldCustom
                        type={"number"}
                        name={"agent_phone"}
                        onChange={handleGeneralAgentChange}
                      />
                    </td>
                    <td style={{ paddingLeft: "5px" }}>
                      <SelectDateRange
                        dataFromChild={handleDateChange}
                        minDate={new Date(propertyCurrentDate)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
        {/* ===========room Categories=========== */}
        <div>
          <h4 style={popupStyles.categoryHeading}>Select Room Categories</h4>
          {loader ? (
            // <Loader />
            <LoaderSmall />
          ) : (
            <>
              {roomCategories?.map((item, index) => (
                <div
                  key={index}
                  style={{ height: "40px" }}
                  className="flex justify_content_between align_items_center"
                >
                  <div>
                    <label className="flex flex_gap_10 pointer">
                      <input
                        type="checkbox"
                        checked={selectedRoomCategories.includes(
                          item.room_name
                        )}
                        onChange={() =>
                          checkedRoomCategories(item.room_name, item.id)
                        }
                      />{" "}
                      <span>{item.room_name}</span>
                    </label>
                  </div>
                  <div>
                    {selectedRoomCategories.includes(item.room_name) && (
                      <div className="flex flex_gap_15">
                        <div className="flex flex_gap_5 align_items_center">
                          <span>Avl. Rooms</span>
                          <VerticalInputFieldCustom
                            disabled={true}
                            style={popupStyles.smallInput}
                            value={availableRooms[item.room_name]}
                          />
                        </div>

                        <div className="flex flex_gap_5 align_items_center">
                          <span>Hold Rooms</span>
                          <VerticalInputFieldCustom
                            style={popupStyles.smallInput}
                            type="text"
                            value={
                              checkedCategories[`${item.room_name}Input`] || ""
                            }
                            onChange={(e) =>
                              handleHoldRoomsChange(
                                item.room_name,
                                item.id,
                                e.target.value
                              )
                            }
                            // readOnly={!availableRooms[item.room_name]}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <hr />
      <div style={popupStyles.containerChild}>
        <div className="flex justify_content_between">
          <div className="flex flex_gap_15">
            <label className="flex flex_gap_5 align_items_center pointer">
              <input
                name="releaseRooms"
                type="radio"
                checked={selectedRelease === "auto_release"}
                onClick={() => handleReleaseRadioChange("auto_release")}
              />
              <span>Auto Release</span>
            </label>
            <label className="flex flex_gap_5 align_items_center pointer">
              <input
                name="releaseRooms"
                type="radio"
                checked={selectedRelease === "manual_release"}
                onClick={() => handleReleaseRadioChange("manual_release")}
              />
              <span>Manual Release</span>
            </label>
          </div>
          {
            selectedRelease === "auto_release" &&
              // <div>

              releaseRooms.map((releaseRoom, index) => (
                <div
                  key={releaseRoom.release}
                  className="flex flex_gap_5 align_items_center"
                >
                  <label style={{ fontWeight: "600", fontSize: "12px" }}>
                    {releaseRoom.release}
                  </label>
                  {/* <VerticalInputSelectCustomCopy
                    options={releaseOptionsPercent}
                    style={{ width: "60px", minHeight: "25px" }}
                    value={releaseRoom.amount}
                    onChange={(e) => handleSelectChange(index, e.target.value)}
                  /> */}
                  <select
                    key={index}
                    value={releaseRoom.amount}
                    style={popupStyles.agent_hold_rooms_popup_select}
                    className="agent_hold_rooms_popup_select"
                    onChange={(e) => handleSelectChange(index, e.target.value)}
                  >
                    {releaseOptionsPercent.map((item, optionIndex) => {
                      const optionValue = parseInt(item.value);
                      const isDisabled =
                        currentTotal -
                          (parseInt(releaseRoom.amount) || 0) +
                          optionValue >
                        100;

                      return (
                        <option
                          key={optionIndex}
                          value={item.value}
                          disabled={isDisabled}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ))
            // </div>
          }
        </div>

        <div className="flex flex_gap_10" style={{ marginTop: "20px" }}>
          <BgThemeButton
            children={"Add"}
            onClick={handleContractedAgentSubmit}
          />
          <BgOutlineThemeButton
            children={"Cancel"}
            onClick={() => closePopup()}
          />
        </div>
      </div>
    </div>
  );
};

export default HoldRoomsAgentsPopup;

import React from "react";
import Purchase from "../Components/Purchase";
import FbKotList from "../Components/FbKotList";
import TableKoT from "../Components/Dashboard/FoodDashboard/FandB-KoT/TableKoT";
import OrderListFAndBDashBoard from "../Components/Dashboard/FoodDashboard/OrderListFAndBDashBoard/OrderListFAndBDashBoard";

const CreateKotNew = () => {
  return (
    <>
      <div className="mt-10">
        <TableKoT />
      </div>
    </>
  );
};

export default CreateKotNew;

import React, { useState, useEffect } from "react";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "../../UI/Buttons";
import {
  calculateTotalArrayObjectKey,
  formatIndianCurrency,
} from "../../../utils/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { PrimaryInput } from "../../NewCustomComp/Inputs/Inputs";
import { SelectBasicWithSelectTag } from "../../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { SvgCancel, SvgMinus, SvgPlus } from "../../../assets/svgIcons";
import { calculateTotal } from "../ModifyReservationPopup/calculationFunctions/CalculateKeyTotalFromArrayOfObjects";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../NewCustomComp/Buttons/Buttons";
import UnderscoreRemoveCapitalize from "../UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";

const AddMultiplePaymentsPopup = ({
  propertyCurrentDate,
  folios,
  setShow,
  balanceAmount,
  checkout,
  bookingId,
  uniqueBookingId,
  refreshPageApi,
}) => {
  
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData("encryptToken");
  const [agentLists, setAgentLists] = useState([]);

  const fetchAgentLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAgentLists(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const agentListOptions = [
    {
      label: "Select option",
      value: "",
    },
    ...agentLists?.map((agent) => ({
      label: agent?.company_name,
      value: agent?.agent_information,
    })),
  ];

  const folioOptions = [
    { label: "Select Option", value: "" },

    ...folios?.map((folio) => ({
      label: folio?.folio_no,
      value: folio?.id,
    })),
  ];

  const currentDate = new Date().toISOString().split("T")[0];

  const [addChargesData, setAddChargesData] = useState({
    date: propertyCurrentDate,
    booking_id: "",
    folio_id: folios ? folios[0]?.id : "",
    property_id: "",
    notes: "",
    notes: "",
    payment_mode: "",
    payment_type: "",
    amount: "",
    company_name: "",
  });

  const [chargesDataArray, setChargesDataArray] = useState([]);
  const [agentCreditLimit, setAgentCreditLimit] = useState("");
  const handleAddCharges = () => {
    // Validation messages
    if (!addChargesData.date) {
      showErrorToast("Please select a date.");
      return;
    }

    if (
      !addChargesData.amount ||
      isNaN(addChargesData.amount) ||
      +addChargesData.amount <= 0
    ) {
      showErrorToast("Please enter a valid amount greater than 0.");
      return;
    }

    if (!addChargesData.notes.trim()) {
      showErrorToast("Notes are required.");
      return;
    }

    if (!addChargesData.payment_type) {
      showErrorToast("Please select a payment type.");
      return;
    }

    if (
      addChargesData.payment_type === "cash" &&
      !addChargesData.payment_mode
    ) {
      showErrorToast("Please select a payment mode.");
      return;
    }

    if (
      (addChargesData.payment_type === "city_ledger" ||
        addChargesData.payment_type === "advance_payment") &&
      !addChargesData.company_name
    ) {
      showErrorToast(
        "Company name is required for City Ledger or Advance Payment."
      );
      return;
    }

    // Validate agent credit limit for specific payment types
    if (
      (addChargesData.payment_type === "city_ledger" ||
        addChargesData.payment_type === "advance_payment") &&
      +addChargesData.amount > agentCreditLimit
    ) {
      showErrorToast(
        `The amount exceeds the agent's credit limit of ${agentCreditLimit}.`
      );
      return;
    }

    if (addChargesData.payment_type === "city_ledger") {
      // Calculate total existing charges for the given company_name
      const existingChargesTotal = chargesDataArray
        .filter((charge) => charge.company_name === addChargesData.company_name)
        .reduce((total, charge) => total + +charge.amount, 0);

      // Check if the new charge exceeds the credit limit
      if (existingChargesTotal + +addChargesData.amount > agentCreditLimit) {
        showErrorToast(
          `The total amount exceeds the agent's credit limit of ${agentCreditLimit}.`
        );
        return;
      }
    }

    // Remove empty keys from addChargesData
    const filteredAddChargesData = Object.fromEntries(
      Object.entries(addChargesData).filter(([key, value]) => value !== "")
    );

    // If all validations pass, add the filtered data
    setChargesDataArray([...chargesDataArray, filteredAddChargesData]);
    setAddChargesData({
      date: currentDate,
      booking_id: "",
      folio_id: folios ? folios[0]?.id : "",
      property_id: "",
      notes: "",
      payment_mode: "",
      payment_type: "",
      amount: "",
      company_name: "",
    });
  };

  const paymentModeOptions = [
    { label: "Select Option", value: "" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
  ];

  const setAddChargesHandleChange = (event) => {
    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
      amount: "",
    });

    if (event.target.name === "company_name") {
      const id = +event.target.value;
      const singleAgent = agentLists.find(
        (agent) => +agent.agent_information === +id
      )?.credit;
      setAgentCreditLimit(
        calculateTotalArrayObjectKey(singleAgent, "balance_amount")
      );
    }
  };

  const handleChargesAmountChange = (event) => {
    const value = event.target.value;
    setAddChargesData({
      ...addChargesData,
      amount: value,
    });
  };

  const handlePaymentTypeChange = (event) => {
    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
      company_name: "",
      amount: "",
    });
  };

  useEffect(() => {
    if (property_id) {
      fetchAgentLists();
    }
  }, [property_id]);

  const handleSubmitPayments = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      const raw = JSON.stringify({
        booking_id: bookingId,
        property_id: property_id?.id,
        folio_id: addChargesData.folio_id,
        date: addChargesData.date,
        payments: chargesDataArray.map((charge) => {
          if (charge.payment_type === "city_ledger") {
            return {
              ...charge,
              payment_mode: agentLists.find(
                (item) => +item.agent_information === +charge.company_name
              )?.company_name,
            };
          } else {
            return charge;
          }
        }),
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log( JSON.parse(raw) ,"hiiii");     

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/handle/payments/${property_id?.id}`,
        requestOptions
      );

      if (!response.ok) {
        // Handle HTTP errors
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json(); // Parse JSON response
      refreshPageApi(uniqueBookingId);
      closeModal();
      showSuccessToast(result.message);
    } catch (error) {
      console.error("Error during payment submission:", error.message);
    }
  };

  const handleRemoveCharges = (index) => {
    setChargesDataArray((prevData) => prevData.filter((_, i) => i !== index));
  };

  const closeModal = () => {
    setShow(false);
    setAddChargesData({
      date: propertyCurrentDate,
      booking_id: "",
      folio_id: folios ? folios[0]?.id : "",
      property_id: "",
      notes: "",
      payment_mode: "",
      payment_type: "",
      amount: "",
      company_name: "",
    });
    setChargesDataArray([]);
  };

  const paymentTypeOptions = [
    { label: "Select Option", value: "" },
    { label: "Cash / Advance Payment", value: "cash" },
    { label: "City Ledger", value: "city_ledger" },
  ];

  return (
    <div className="bg-backdrop">
      <div style={{ width: "700px" }} className="stock-add-model-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div className="add-ota-model-container-heading">Add Payments</div>
          <div onClick={() => setShow(false)} style={{ cursor: "pointer" }}>
            <SvgCancel width={"20"} height={"20"} color={"#D9D9D9"} />
          </div>
        </div>
        <div className="form-container-div-half">
          <div className="form-container-div">
            <PrimaryInput
              size={"medium"}
              min={propertyCurrentDate}
              required={true}
              focused={false}
              name="date"
              type="date"
              label="Date"
              onChange={setAddChargesHandleChange}
              autoComplete="off"
              value={addChargesData.date || currentDate}
              disabled={chargesDataArray.length > 0 ? true : false}
            />
          </div>
          <div className="form-container-div">
            <SelectBasicWithSelectTag
              size={"medium"}
              options={paymentTypeOptions}
              type="text"
              required={true}
              focused={false}
              name="payment_type"
              label="Payment Type"
              onChange={handlePaymentTypeChange}
              autoComplete="off"
              value={addChargesData.payment_type}
            />
          </div>
          <div className="form-container-div">
            <SelectBasicWithSelectTag
              disabled={addChargesData.payment_type ? false : true}
              options={
                addChargesData.payment_type === "cash"
                  ? paymentModeOptions
                  : chargesDataArray.length > 0
                  ? agentListOptions?.filter((option) => {
                      if (!option.value) return true;
                      return chargesDataArray.some(
                        (charge) => +charge.company_name === +option.value
                      );
                    })
                  : agentListOptions
              }
              type="text"
              required={true}
              focused={false}
              name={
                addChargesData.payment_type === "cash"
                  ? "payment_mode"
                  : "company_name"
              }
              label={
                addChargesData.payment_type === "city_ledger" ||
                addChargesData.payment_type === "advance_payment"
                  ? "Company Name"
                  : "Payment Mode"
              }
              onChange={setAddChargesHandleChange}
              autoComplete="off"
              value={
                addChargesData.payment_type === "cash"
                  ? addChargesData.payment_mode
                  : addChargesData.company_name
              }
              size={"medium"}
            />
          </div>
        </div>
        <div className="form-container-div-half">
          <div className="form-container-div">
            <SelectBasicWithSelectTag
              options={folioOptions}
              size={"medium"}
              required={true}
              focused={false}
              name="folio_id"
              label="Folio"
              onChange={setAddChargesHandleChange}
              autoComplete="off"
              value={addChargesData.folio_id}
              disabled={chargesDataArray.length > 0 ? true : false}
            />
          </div>
          <div className="form-container-div">
            <PrimaryInput
              size={"medium"}
              required={true}
              focused={false}
              name="notes"
              label="Notes"
              onChange={setAddChargesHandleChange}
              autoComplete="off"
              value={addChargesData.notes}
            />
          </div>
          <div className="form-container-div">
            <PrimaryInput
              type="number"
              required={true}
              focused={false}
              disabled={false}
              name="amount"
              label="Amount"
              onChange={handleChargesAmountChange}
              autoComplete="off"
              value={addChargesData.amount}
              size={"medium"}
            />
          </div>
          <div
            className="form-container-div flex align_items_end"
            style={{ width: "20px" }}
          >
            <span className="pointer" onClick={handleAddCharges}>
              <SvgPlus />
            </span>
          </div>
        </div>

        {chargesDataArray.length > 0 && (
          <table className="w-100 mt-5 mb-5">
            <thead>
              <tr className="text-align-left">
                <th className="pt-5 pb-5">Sr. No</th>
                <th className="pt-5 pb-5">Payment Type</th>
                <th className="pt-5 pb-5">Payment Mode</th>
                <th className="pt-5 pb-5">Amount</th>
                <th className="pt-5 pb-5"></th>
              </tr>
            </thead>
            <tbody>
              {chargesDataArray.length > 0 &&
                chargesDataArray.map((item, index) => (
                  <tr key={index}>
                    <td className="pt-5 pb-5">{index + 1}.</td>
                    <td className="pt-5 pb-5">
                      {item?.payment_type?.replaceAll("_", " ")}
                    </td>
                    <td className="pt-5 pb-5">
                      {item?.payment_type === "cash"
                        ? item?.payment_mode?.replaceAll("_", " ")
                        : "agent"}
                    </td>
                    <td className="pt-5 pb-5">
                      {formatIndianCurrency(item.amount)}
                    </td>

                    <td>
                      <span
                        className="pointer"
                        onClick={() => handleRemoveCharges(index)}
                      >
                        <SvgMinus color="red" />
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}

        <div className="flex flex_gap_10 mt-10 mb-10">
          {agentCreditLimit && (
            <div className="form-container-div-full">
              <>
                <b>Balance Credit Limit : </b>
                <span> {formatIndianCurrency(agentCreditLimit)}</span>
              </>
            </div>
          )}

          <div className="flex">
            <b>Pending Balance : </b>
            <span>
              {" "}
              {calculateTotal(chargesDataArray, "amount")
                ? formatIndianCurrency(
                    balanceAmount - calculateTotal(chargesDataArray, "amount")
                  )
                : formatIndianCurrency(balanceAmount)}
            </span>
          </div>
        </div>

        <div className="flex flex_gap_10">
          {checkout ? (
            <PrimaryButton
              size="medium"
              type="button"
              title={"Add"}
              disabled={
                Number(balanceAmount) ===
                calculateTotal(chargesDataArray, "amount")
                  ? false
                  : true
              }
              loader={loader}
              onClick={handleSubmitPayments}
            />
          ) : (
            <PrimaryButton
              size="medium"
              type="button"
              title={"Add"}
              disabled={
                !calculateTotal(chargesDataArray, "amount") ||
                calculateTotal(chargesDataArray, "amount") >
                  Number(balanceAmount)
              }
              loader={loader}
              onClick={handleSubmitPayments}
            />
          )}
          <SecondaryButton
            size="medium"
            type="button"
            title={"Cancel"}
            onClick={closeModal}
          />
        </div>
      </div>
    </div>
  );
};
export default AddMultiplePaymentsPopup;

import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import { Link, useLocation } from "react-router-dom";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomCssComponentTableText from "../../CustomComponents/CustomCssComponentTableText";
import StatusUpdate from "../../CustomComponents/StatusUpdate";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import SidebarInnerLists from "../../CustomComponents/SidebarInnerLists";
// import {
//   other_select_nav_items_inventory,
//   rooms_inner_lists,
// } from "../../assets/constant";
import {
  other_select_nav_items_inventory,
  rooms_inner_lists,
} from "../../../assets/constant";
import Loader from "../../UI/Loader/Loader";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import VerticalInputFieldCustom from "../../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../../CustomComponents/VerticalInputSelectCustomCopy";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import NoDataFound from "../../CustomComponents/NoDataFound";

import UnderscoreRemoveCapitalize from "../../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import BookingDetailSliderNew from "../../CustomComponents/BookingDetails/BookingDetailsSliderNew";
import RoomKotDetailsSlider from "../../CustomComponents/BookingDetails/RoomKotDetailsSlider";
import { getDecryptedData } from "../../../utils/encryptStorage";

const KotRoomTableView = () => {
  const navigate = useNavigate();
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const userToken = getDecryptedData('encryptToken');
  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [editRooms, setEditRooms] = useState(false);

  const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [openKotDetailsSlider, setOpenKotDetailsSlider] = useState(false);
  const [newOrderRoom, setNewOrderRoom] = useState(false);
  const [kotList, setKotList] = useState(false);

  const [roomsData, setRoomsData] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);

  const fetchRoomsData = async (propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      setRoomsData(data.rooms);
      // setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [orders, setOrders] = useState([]);
  // fetch data
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const sortMethods = {
    none: { method: (a, b) => null },
    ascendingRooms: { method: undefined },
    sortedRooms: {
      method: (a, b) => {
        const nameComparison = a?.room_name?.localeCompare(b?.room_name);

        if (nameComparison !== 0) {
          return nameComparison;
        }
        return a?.room_no - b?.room_no;
      },
    },
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders =
    orders && orders.length > 0
      ? orders
          .sort(sortMethods["sortedRooms"].method)
          .slice(startIndex, endIndex)
      : [];

  const fetchInfo = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/table_view/fetch/${propertyId}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   property_id: property_id,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  // const chargesForDate = () => {Object.keys(viewFolioBillsData.charges).map((date) => {
  const [fetchedRoomType, setFetchedRoomType] = useState([]);

  const fetchRoomType = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoomType(data.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  const [rooms, setRooms] = useState([]);

  const fetchRooms = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/room_view/fetch/${propertyId}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   property_id: property_id,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setRooms(data.room_list);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchRooms(property_id?.id);
      fetchRoomsData(property_id?.id);
    }
  }, [property_id]);

  const roomsOptions = [
    ...roomsData.flatMap(
      (room) =>
        room.assigned_rooms_no
          .filter((assignedRoom) => assignedRoom.status === "occupied")
          .map((room) => ({ label: room.room_no, value: room.room_no })) || []
    ),
  ];

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else {
      setAddItemData({
        room_name: "",
        room_no: "",
        floor_no: "",
        status: "clean",
        room_id: fetchedRoomType ? fetchedRoomType[0]?.id : "",
        property_id: property_id?.id,
      });
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const getOneData = async (id) => {
    setEditId(id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit_table/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        table_no: data.tabledata.table_no,
        status: data.tabledata.status,
      };
      setCategoriesData(updatedData);
      setEditRooms(true);
    } catch (error) {
      console.error(error);
    }
  };
  const getRoomKots = async (id) => {
    setEditId(id);
    try {
      const response = await fetch(
        // /room/kot/fetch/{room_no}/{property_id}
        `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/room/kot/fetch/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setKotList(data?.data);

        setOpenKotDetailsSlider(true);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const rowMarkup = currentOrders?.map(
    ({ id, room_no, room_name, floor_no, status, index }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={room_no}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={room_name}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={floor_no}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <StatusUpdate status={status}></StatusUpdate>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const roomTypeOptions = fetchedRoomType?.map((roomType) => {
    return { label: roomType.room_name, value: roomType.id };
  });

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    service: "",
    description: "",
    rate: "",
    quantity: "",
    total_amount: "",
    sub_total: "",
    discount: "",
    gst_type: "",
    gst: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };
  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);

          const totalPages = Math.ceil(orders.length / itemsPerPage);
          if (currentPage > totalPages) {
            handlePageChange(totalPages);
          }
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [editId, setEditId] = useState(null);

  const updateFunction = async (id) => {
    if (categoriesData.table_no === "") {
      showErrorToast("Please Fill the Table No. ...!!!");
      return;
    }
    if (categoriesData.status === "") {
      showErrorToast("Please Select the Table Status ...!!!");
      return;
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update_table/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify(categoriesData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast(data.message);
        setEditRooms(false);
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    clearSelection();
  };

  // ...............................
  const tabs = [
    {
      title: "Table View",
      link: "table-view",
    },
    {
      title: "Room View",
      link: "room-view",
    },
  ];
  const location = useLocation();
  const activeTab = tabs.findIndex((tab) =>
    location.pathname.includes(tab.link)
  );

  const statusOptions = [
    { label: "Select Option", value: "" },
    { label: "Occupied", value: "occupied" },
    { label: "Vacant", value: "vacant" },
  ];
  const [categoriesData, setCategoriesData] = useState({
    table_no: "",
    status: "",
  });

  const setAddItemDataHandleChangeTables = (event) => {
    setCategoriesData({
      ...categoriesData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitAddTable = async (event) => {
    event.preventDefault();
    if (categoriesData.table_no === "") {
      showErrorToast("Please Fill the Table No. ...!!!");
      return;
    }
    if (categoriesData.status === "") {
      showErrorToast("Please Select the Table Status ...!!!");
      return;
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create_table/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...categoriesData,
            property_id: property_id?.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        setShowLowerMenuMultipleSelect(false);
        // clearSelection();
        showSuccessToast(data.message);
        setCategoriesData({
          table_no: "",
          status: "",
        });
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
        setLoader(false);
        // setShowAddCategoriesLowerMenuActive(false);
        // return;
      } else {
        showErrorToast(data.message);
        setLoader(false);
        // setShowAddCategoriesLowerMenuActive(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
      showErrorToast("An error occurred while processing your request.");
      // setShowAddCategoriesLowerMenuActive(false);
    }
  };


  return (
    <div className="mt-10">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="">
            {/* <div
              style={{
                margin: "2px",
                display: "flex",
                gap: "10px",
                background: "white",
                padding: "10px",
                marginBottom: "30px",
              }}
            >
              <BgThemeButton
                icon={
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 2.825C2 2.36937 2.36937 2 2.825 2H12.175C12.6306 2 13 2.36937 13 2.825V6.125C13 6.58064 12.6306 6.95 12.175 6.95H8.93611C8.48048 6.95 8.11111 6.58064 8.11111 6.125V4.81111C8.11111 4.4736 7.83751 4.2 7.5 4.2C7.16249 4.2 6.88889 4.4736 6.88889 4.81111V6.125C6.88889 6.58064 6.51952 6.95 6.06389 6.95H2.825C2.36937 6.95 2 6.58064 2 6.125V2.825Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.85 4.75C5.85 3.83873 6.58873 3.1 7.5 3.1C8.41127 3.1 9.15 3.83873 9.15 4.75V7.16117C9.15 7.28262 9.22966 7.38968 9.34598 7.42458L10.828 7.86918C11.4796 8.06465 11.8903 8.70721 11.7941 9.38064L11.4457 11.8195C11.3489 12.4968 10.7688 13 10.0845 13H5.82786C5.29154 13 4.80417 12.6882 4.57942 12.2012L3.44634 9.74621C3.3065 9.44321 3.27865 9.08528 3.38768 8.76863C3.50265 8.43474 3.78294 8.13136 4.22126 8.06874C4.79052 7.9874 5.33019 8.174 5.85 8.51934V4.75ZM7.5 4.2C7.19624 4.2 6.95 4.44624 6.95 4.75V9.03609C6.95 9.80061 6.06524 10.0928 5.56023 9.67465C5.03915 9.24315 4.68535 9.13147 4.42226 9.15262C4.42099 9.16334 4.42042 9.17647 4.42141 9.1918C4.42342 9.22306 4.43147 9.25571 4.4451 9.28525L5.57817 11.7402C5.62312 11.8376 5.7206 11.9 5.82786 11.9H10.0845C10.2213 11.9 10.3374 11.7994 10.3567 11.6639L10.7051 9.22508C10.7244 9.09039 10.6422 8.96188 10.5119 8.92279L9.0299 8.47819C8.44829 8.30371 8.05 7.76839 8.05 7.16117V4.75C8.05 4.44624 7.80376 4.2 7.5 4.2Z"
                      fill="white"
                    />
                  </svg>
                }
                onClick={() => navigate("/kot/create-kot-manager")}
                children={"KOT"}
                style={{ height: "30px" }}
              />
            
              <BgOutlineThemeButton
                icon={
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.9115 7.27807C11.638 4.08549 9.18887 2.63066 6.74082 2.75763C4.32096 2.88315 2.0378 4.55138 1.07 7.30112L1 7.5L1.07 7.69888C2.0378 10.4486 4.32096 12.1169 6.74082 12.2424C9.18887 12.3693 11.638 10.9145 12.9115 7.72193L13 7.5L12.9115 7.27807ZM6.99511 10.4878C8.64066 10.4878 9.97463 9.15013 9.97463 7.5C9.97463 5.84988 8.64066 4.51218 6.99511 4.51218C5.34957 4.51218 4.0156 5.84988 4.0156 7.5C4.0156 9.15013 5.34957 10.4878 6.99511 10.4878Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M8.78282 7.5C8.78282 8.49008 7.98244 9.29269 6.99511 9.29269C6.00779 9.29269 5.2074 8.49008 5.2074 7.5C5.2074 6.50993 6.00779 5.70731 6.99511 5.70731C7.98244 5.70731 8.78282 6.50993 8.78282 7.5Z"
                      fill="#3968ED"
                    />
                  </svg>
                }
                onClick={() => navigate("/kot-room-table-view")}
                children={"Table/Room"}
                style={{ height: "30px" }}
              />
              <BgThemeButton
                icon={
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.699219 1.24995C0.699219 0.670052 1.16932 0.199951 1.74922 0.199951H12.2492C12.8291 0.199951 13.2992 0.670052 13.2992 1.24995V11.75C13.2992 12.3299 12.8291 12.8 12.2492 12.8H1.74922C1.16932 12.8 0.699219 12.3299 0.699219 11.75V1.24995ZM5.59922 2.99995H11.1992V4.39995H5.59922V2.99995ZM11.1992 5.79995H5.59922V7.19995H11.1992V5.79995ZM5.59922 8.59995H11.1992V9.99995H5.59922V8.59995ZM3.49922 4.39995C3.88582 4.39995 4.19922 4.08655 4.19922 3.69995C4.19922 3.31335 3.88582 2.99995 3.49922 2.99995C3.11262 2.99995 2.79922 3.31335 2.79922 3.69995C2.79922 4.08655 3.11262 4.39995 3.49922 4.39995ZM4.19922 6.49995C4.19922 6.88655 3.88582 7.19995 3.49922 7.19995C3.11262 7.19995 2.79922 6.88655 2.79922 6.49995C2.79922 6.11335 3.11262 5.79995 3.49922 5.79995C3.88582 5.79995 4.19922 6.11335 4.19922 6.49995ZM3.49922 9.99995C3.88582 9.99995 4.19922 9.68655 4.19922 9.29995C4.19922 8.91335 3.88582 8.59995 3.49922 8.59995C3.11262 8.59995 2.79922 8.91335 2.79922 9.29995C2.79922 9.68655 3.11262 9.99995 3.49922 9.99995Z"
                      fill="white"
                    />
                  </svg>
                }
                onClick={() => navigate("/hotelier/menus")}
                children={"Menu"}
                style={{ height: "30px" }}
              />
            </div> */}
            <div
              className="custom_tab_container"
           
            >
              <div className="custom_tab_header">
              
              </div>
            </div>

            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              onClick={() => [setShowLowerMenuSingleSelect(true)]}
            >
              <GoBackButtonCustom showSvg={false} buttonNextText={"Table"} />

              <WithoutBgButtonBlue
                onClick={() => setShowLowerMenuMultipleSelect(true)}
                children={"Add"}
              />
            </div>

            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "10px 20px",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {orders &&
                  orders?.map((room) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                           
                            paddingTop: "0",
                           
                          }}
                        >
                          <div
                            onClick={() => getOneData(room.id, room.id)}
                            style={{
                              width: "80px",
                              height: "45px",
                            
                              backgroundColor:
                                room.status === "occupied"
                                  ? "#BD941B"
                                  : "rgba(21, 170, 18, 1)",
                              color: "white",
                              fontSize: "10px",
                              fontWeight: "500",
                              borderRadius: "4px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                             
                            }}
                            className="hoverOnRoom"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                width: "100%",
                                height: "100%",
                                lineHeight: "12px",
                                textAlign: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                            >
                              <p>{room.table_no}</p>
                              <p>{room.net_total}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
              onClick={() => [setShowLowerMenuSingleSelect(true)]}
            >
              <GoBackButtonCustom showSvg={false} buttonNextText={"Rooms"} />

              <div></div>
            </div>

            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <div>
                {rooms &&
                  rooms?.map((room) => {
                    return (
                      <>
                        <div style={{ padding: "10px 20px" }}>
                          {room.room_name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            padding: "10px 20px",
                            paddingTop: "0",
                            borderBottom: "0.5px solid #CCC",
                            flexWrap: "wrap",
                          }}
                        >
                          {room?.Rooms?.map((singleRoom) => {
                            return (
                              <div
                                key={singleRoom?.room_no}
                                onClick={() =>
                                  getRoomKots(
                                    singleRoom.room_no,
                                    singleRoom.room_no
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  width: "80px",
                                  height: "35px",
                                  backgroundColor: "#BD941B",
                                  color: "white",
                                  fontSize: "10px",
                                  fontWeight: "500",
                                  borderRadius: "4px",

                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "100%",
                                    lineHeight: "12px",
                                    textAlign: "center",
                                  }}
                                >
                                  <p>{singleRoom.room_no}</p>
                                  <p>{singleRoom.balance_amount}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Edit Button Click */}
          {editRooms && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Edit Table
                    </div>
                    <div
                      onClick={() => setEditRooms(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="Table No."
                        required={true}
                        name="table_no"
                        className="textfield"
                        onChange={(e) => setAddItemDataHandleChangeTables(e)}
                        focused={false}
                        type={"number"}
                        value={categoriesData.table_no}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        titleName="Status"
                        name="status"
                        value={categoriesData.status}
                        onChange={(e) => setAddItemDataHandleChangeTables(e)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => updateFunction(editId)}
                        type="submit"
                        children={"Update"}
                      />
                      <Button onClick={() => setEditRooms(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {showLowerMenuMultipleSelect && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => handleSubmitAddTable(e)}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Add Table
                    </div>
                    <div
                      onClick={() => setShowLowerMenuMultipleSelect(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="Table No."
                        required={true}
                        name="table_no"
                        className="textfield"
                        onChange={(e) => setAddItemDataHandleChangeTables(e)}
                        focused={false}
                        type={"number"}
                        value={categoriesData.table_no}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        titleName="Status"
                        name="status"
                        value={categoriesData.status}
                        onChange={(e) => setAddItemDataHandleChangeTables(e)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton type="submit" children={"Add"} loader={loader}/>
                      <Button
                        onClick={() => setShowLowerMenuMultipleSelect(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}

          {openKotDetailsSlider && (
            <RoomKotDetailsSlider
              kotList={kotList}
              // chargesDetails={chargesDetails}
              // masterFolioDetails={masterFolioDetails}
              setShowBookingDetails={setOpenKotDetailsSlider}
            />
          )}

          {newOrderRoom && (
            <>
              <div className="bg-backdrop">
                <div
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "702px" }}
                  className="stock-add-model-container"
                >
                  <div className="area_list_parent">
                    <div
                      className="add-ota-model-container-heading"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <div>Rooms</div>
                      <div>
                        {/* {folioId && (
                          <>
                            <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              {" "}
                              <strong>Folio No: </strong>
                              {folioId?.folio_no}
                            </span>
                            {"  "}||{" "}
                            <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              {" "}
                              <strong>Guest Name :</strong>{" "}
                              {folioId && folioId?.guest_name}
                            </span>
                            {"  "}||{" "}
                            <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              {" "}
                              <strong>Phone No. :</strong>{" "}
                              {folioId && folioId?.guest_phone}
                            </span>
                          </>
                        )} */}
                      </div>
                    </div>
                    <div
                      className="flex flex_gap_10"
                      style={{ flexWrap: "wrap" }}
                    >
                      {roomsData && roomsData.length > 0 ? (
                        roomsOptions.map((data) => (
                          <div
                            className="area_container flex justify_content_between align_items_center"
                            key={data.id}
                            // onClick={() =>
                            //   handleRoomClick(data.value, data.label)
                            // }
                            style={{
                              backgroundColor: selectedRooms.includes(
                                data.value
                              )
                                ? "rgba(189, 148, 27, 1)"
                                : "rgba(21, 170, 18, 1)",
                            }}
                          >
                            {data.label}
                          </div>
                        ))
                      ) : (
                        <>No Available Rooms</>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={() => [
                          setNewOrderRoom(false),
                          // setNewOrderPopup(false),
                        ]}
                        // disabled={folioId ? false : true}
                        type="submit"
                        children={"Next"}
                      />
                      <Button
                        onClick={() => [
                          setNewOrderRoom(false),
                          // setSelectedTables([]),
                          // setSelectedRooms([]),
                          // setNewOrderPopup(false),
                          // setSelectedRoomsLabels([]),
                          // setSelectedTablesLabels([]),
                        ]}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default KotRoomTableView;

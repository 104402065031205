import React from "react";

import "./SentMessageNewPage.css";
import {
  BgOutlineSendAgainButton,
  BgOutlineThemeButton,
  BgOutlineThemeHoldButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CancelMajor } from "@shopify/polaris-icons";

import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useNavigate } from "react-router-dom";
// import CreateBookingTableComponent from "./createBookingTableComponent/CreateBookingTableComponent";
// import CreateBookingTableComponentTwo from "./CreateBookingTableComponenTwo/CreateBookingTableComponenTwo";

import { useParams } from "react-router-dom";

import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import { formatIndianCurrency } from "../../../utils/utils";
// import CreateBookingCard from "../CreateBookingNewPage/createBookingCard/CreateBookingCard";
import DateRangePickerNew from "../CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
import CreateBookingSelectBox from "../CreateBookingNewPage/CreateBookingSelectBox/CreateBookingSelectBox";
import CreateMessageInputBox from "../CreateMessageNewPage/CreateMessageInputBox";
import SentMessageCard from "./SentMessageCard/SentMessageCard";

import Loader from "../../UI/Loader/Loader";
import NoDataFound from "../NoDataFound";
import { Icon } from "@shopify/polaris";
import { getDecryptedData } from "../../../utils/encryptStorage";

const SentMessageNewPage = () => {
  const { messageId } = useParams();

  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  //..date range ....
  const currentDate = new Date().toISOString().split("T")[0];
  const [loader, setLoader] = useState(true);

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");

  const [messageState, setMessageState] = useState({
    subtotal: "",
    discount_amount: "",
    taxAmt: "",
    grandTotal: "",
    total_discount_amount: "",
  });

  const dataFromChild = (from_date, to_date) => {
    setCheckIn(from_date);
    setCheckOut(to_date);
    setBookingData((prevData) => ({
      ...prevData,
      check_in: from_date,
      check_out: to_date,
    }));
  };

  const BookingTypeOptions = [
    { room_name: "Single", value: "single" },
    { room_name: "Multiple", value: "multiple" },
  ];

  // calculate the number of nights from check in check out

  const [bookingData, setBookingData] = useState({
    booking_type: "",
    available_rooms: "",
    extra_adult_allowed: "",
    check_out: "",
    check_in: "",
    no_of_nights: "",
    no_of_adults: "",
    extra_adults: "",
    no_of_child: "",
    no_of_rooms: "",
    room_category: "",
    room_id: "",
    booking_source: "",
    agent_id: "",
    discount: "",
    tax_type: "inclusive",
    tac_discount: "",
    selected_discount: "",
    room_plans: "",
    ota_source: "",
  });

  useEffect(() => {
    const calculateNumberOfNights = () => {
      const checkIn = new Date(bookingData.check_in);
      const checkOut = new Date(bookingData.check_out);
      const timeDifference = checkOut.getTime() - checkIn.getTime();
      const numberOfNights = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return numberOfNights;
    };

    const numberOfNightsForBooking = calculateNumberOfNights();
    setBookingData((prevData) => ({
      ...prevData,
      no_of_nights: numberOfNightsForBooking,
    }));
  }, [bookingData.check_in, bookingData.check_out, checkIn, checkOut]);

  //handle booking change
  // const handleBookingTypeChange = (event) => {
  //   setBookingData({
  //     ...bookingData,
  //     booking_type: event.target.value,
  //   });
  // };

  const handleBookingTypeChange = (event) => {
    const selectedBookingType = event.target.value;
    setBookingData({
      ...bookingData,
      booking_type: selectedBookingType,
    });
  };

  //   ................Room Category Amd fetching room plans.....................

  const [RoomCategoryOptions, setRoomCategoryOptions] = useState("");
  const [MessageData, setMessageData] = useState({
    booking_type: "",
    available_rooms: "",
    extra_adult_allowed: "",
    check_out: "",
    check_in: "",
    no_of_nights: "",
    no_of_adults: "",
    extra_adults: "",
    no_of_child: "",
    no_of_rooms: "",
    room_category: "",
    room_id: "",
    booking_source: "",
    agent_id: "",
    discount: "",
    tax_type: "inclusive",
    tac_discount: "",
    selected_discount: "",
    room_plans: "",
    ota_source: "",
  });

  const fetchRoomTypeLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setRoomCategoryOptions(data?.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRoomPlans = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${bookingData.room_id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setBookingData({
        ...bookingData,
        room_plans: data.roomRates,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchRoomTypeLists();
    }
  }, [property_id]);
  useEffect(() => {
    if (bookingData.room_id && property_id) {
      fetchRoomPlans();
    }
  }, [bookingData.room_id, property_id]);

  const handleRoomCategory = (e) => {
    const id = RoomCategoryOptions.find(
      (item) => item?.room_name === e.target.value
    ).id;
    setBookingData({
      ...bookingData,
      room_category: e.target.value,
      room_id: id,
    });
  };

  //fetch message data

  const fetchMessageData = (propertyId) => {
    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v2/message/detail/${messageId}/${propertyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Here 'data' is defined and can be used
          setMessageData(data);
          setLoader(false);
          setMessageState({
            subtotal: data?.messages?.taxable_amount,
            discount_amount: data?.messages?.total_discount_amount,
            taxAmt: data?.messages?.total_tax_amount,
            grandTotal: data?.messages?.grand_total,
            total_discount_amount: data?.messages?.total_discount_amount, // Corrected key name
          });

          setBookingData((prev) => ({
            ...prev,
            discount_type: data.messages.lead_communication_items[0]?.discount_rate, // Ensure to handle possible undefined

          }));
        })
        .catch((error) => {
          setLoader(false);
          showErrorToast(error.message); // Show error message from the caught error
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };





  useEffect(() => {
    if (property_id) {
      fetchMessageData(property_id.id);
    }
  }, [property_id]);

  // iframe content


  const [iframeContent, setIframeContent] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  const [downloadUrl, setDownloadUrl] = useState("");

  const handleSubmitPreviewVoucher = async (

  ) => {
    try {
      const lead_id = MessageData?.messages?.lead_id;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v2/lead/preview/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            sent_data: [MessageData?.messages],
            lead_id: lead_id,
            property_id: property_id?.id
          }),
        }
      );

      const data = await response.text();
      if (data) {
        setIframeContent(data);
        setShowIframePopup(true);
        setIframeSrc(null);

      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  //fetch room type on handle category change
  const [availableRooms, setAvailableRooms] = useState("");

  const fetchRoomType = async () => {
    const roomId = bookingData.room_id;
    const checkIn = bookingData.check_in;
    const checkOut = bookingData.check_out;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}&room_id=${roomId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      // console.log(data + "availablerooms");
      setAvailableRooms(data);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      MessageData?.room_id &&
      MessageData?.check_in &&
      MessageData?.check_out &&
      property_id
    ) {
      fetchRoomType();
    }
  }, [
    bookingData?.room_id,
    bookingData.check_in,
    bookingData.check_out,
    property_id,
    userToken,
  ]);

  // useEffect(() => {
  //   if (
  //     bookingData?.room_id &&
  //     bookingData.check_in &&
  //     bookingData.check_out &&
  //     property_id
  //   ) {
  //     fetchRoomType();
  //   }
  // }, [
  //   bookingData?.room_id,
  //   bookingData.check_in,
  //   bookingData.check_out,
  //   property_id,
  //   userToken,
  // ]);

  const handleInputChange = (e) => {
    const extraAdultAllowed = availableRooms?.allowedExtraAdult;
    let maxAdultAllowed = availableRooms?.max_adult;
    let baseAdultAllowed = availableRooms?.base_adult;
    const extraBeddingAllowed =
      availableRooms?.extra_bedding_allowed == "enabled" ? true : false;

    const { name, value } = e.target;

    setBookingData({
      ...bookingData,
      [name]: value,
    });
  };

  // booking submit handler
  const BookingSourceOptions = [
    { room_name: "Walk In Booking", value: "walk in booking" },
    { room_name: "Front Desk", value: "front desk" },
    { room_name: "Sales and Marketing", value: "sales and marketing" },
    { room_name: "Agent (B2B) Partner", value: "agent" },
    { room_name: "OTA", value: "ota" },
  ];

  const handleBookingSourceChange = (e) => {
    setBookingData({
      ...bookingData,
      booking_source: e.target.value,
    });
  };

  //booking agent

  const [agentList, setAgentList] = useState([]);

  const fetchAgentList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAgentList(data.data);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (bookingData.booking_source === "Agent (B2B) Partner" && property_id) {
      fetchAgentList();
    }
  }, [bookingData.booking_source, property_id]);

  // Tac discount
  const fetchTacDiscount = async () => {
    const agentId = bookingData.agent_id;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/tac/discount/${agentId}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setBookingData({
        ...bookingData,
        tac_discount: data.tac_discount,
      });
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (bookingData.agent_id && property_id) {
      fetchTacDiscount();
    }
  }, [bookingData.agent_id, property_id]);

  // for discount percentage
  const [discountOptions, setDiscountOptions] = useState([]);
  const fetchDiscountOptions = async () => {
    const checkIn = bookingData.check_in;
    const checkOut = bookingData.check_out;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/discount/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setDiscountOptions(data.discount);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching discount options:", error);
    }
  };
  useEffect(() => {
    if (bookingData.check_in && bookingData.check_out && property_id) {
      fetchDiscountOptions();
    }
  }, [bookingData.check_in, bookingData.check_out, property_id]);

  // discount type
  // const handleDiscountTypeChange = (e) => {
  //   const discountValue = e.target.value;
  //   console.log(discountValue);

  //   console.log(+discountValue,"ppp");
  //   console.log(discountValue);

  //   if (+discountValue >=100) {
  //     // Display a warning message or handle the input validation as needed
  //     console.log("Discount value must be between 0 and 99");
  //     return;
  //   }

  //   console.log(discountValue);
  //   // if(discountValue.length > 2) {
  //   //   showErrorToast("pp")
  //   //   return;
  //   // }

  //   setBookingData({
  //     ...bookingData,
  //     discount_type: discountValue,
  //   });
  // };

  const handleDiscountTypeChange = (e) => {
    const discountValue = e.target.value;

    if (+discountValue < 0) {
      showErrorToast("Discount percentage cannot be negative");
      return;
    }

    if (+discountValue >= 100) {
      showErrorToast("Discount percentage should be less than 100%");
      return;
    }

    setBookingData({
      ...bookingData,
      discount_type: discountValue,
    });
  };

  // setting up the available rooms and extra adults in booking data object

  // handle click on Add button save booking data in Booking array
  const [bookingDataArray, setBookingDataArray] = useState([]);

  //booking card delete
  // const handleDeleteBookingCard = (index) => {
  //   console.log(availableRooms.available_rooms,"uguigu dfddddddddddddddddddddddddi")
  //   console.log(bookingDataArray[index].no_of_rooms ,"availabel rooms")
  //    const totalRooms = +availableRooms.available_rooms + +bookingDataArray[index].no_of_rooms;

  //    console.log(totalRooms ,"bcbweifbwiuivbriuvbiebirbiervberivbrvibib")

  // //  setBookingDataArray((prevData) => prevData.filter((_, i) => i !== index));
  //  setAvailableRooms( (prev)=>(
  //  { ...prev,
  //   available_rooms: totalRooms}
  //  )

  //  );

  // };
  const handleDeleteBookingCard = (index) => {
    const totalRooms =
      +availableRooms.available_rooms + +bookingDataArray[index].no_of_rooms;
    setAvailableRooms((prev) => ({
      ...prev,
      available_rooms: totalRooms,
    }));
    setTimeout(() => {
      setBookingDataArray((prev) => prev.filter((_, i) => i !== index));
    }, 500);
    setShowDiscountSelect(false);
  };

  // ota sources
  const otaTypeOptions = [
    { label: "MakeMyTrip", value: "makemytrip" },
    { label: "GoIbibo", value: "goibibo" },
    { label: "Expedia", value: "expedia" },
    { label: "EaseMyTrip", value: "easemytrip" },
    { label: "ClearTrip", value: "cleartrip" },
    { label: "HappyEasyGo", value: "happyeasygo" },
    { label: "Travelguru", value: "travelguru" },
    { label: "Yatra", value: "yatra" },
  ];

  const handleClickOnAddButton = () => {
    // if (isDisabled()) {
    //   return; // Exit early if any condition is met
    // }
    const prevAvailableRooms = availableRooms.available_rooms;

    const hasSingleBooking = bookingDataArray.some(
      (item) => item.booking_type === "Single"
    );

    if (hasSingleBooking) {
      showErrorToast("Only one booking can be created at a time");
    } else {
      setBookingDataArray((prevData) => [...prevData, bookingData]);
      setAvailableRooms((...prevData) => ({
        ...prevData,
        available_rooms: +prevAvailableRooms - +bookingData.no_of_rooms,
      }));
      // setBookingData({
      //   booking_type: "",
      //   available_rooms: "",
      //   extra_adult_allowed: "",
      //   check_out: null,
      //   check_in: null,
      //   no_of_nights: "",
      //   no_of_adults: "",
      //   extra_adults: "",
      //   no_of_child: "",
      //   no_of_rooms: "",
      //   room_category: "",
      //   room_id: "",
      //   booking_source: "",
      //   agent_id: "",
      //   discount: "",
      //   tax_type: "exclusive",
      //   tac_discount: "",
      //   selected_discount: "",
      //   room_plans: "",
      //   ota_source: "",
      // });
    }
  };
  // ncn

  const isDisabled = () => {
    if (
      bookingData.booking_type === "" ||
      bookingData.no_of_nights === "" ||
      bookingData.no_of_rooms === "" ||
      bookingData.room_category === ""
      // bookingData.booking_source === ""
    ) {
      showErrorToast("Please fill in all required fields.");
      return true;
    }

    if (
      bookingData.booking_source === "Agent (B2B) Partner" &&
      bookingData.agent_id === ""
    ) {
      showErrorToast("Please Select Agent for B2B bookings.");
      return true;
    }

    if (
      bookingData.booking_source === "OTA" &&
      bookingData.ota_source === "" &&
      bookingData.tac_discount === ""
    ) {
      showErrorToast("Please provide the OTA Source for OTA bookings.");
      return true;
    }
    if (+bookingData.no_of_rooms > +availableRooms.available_rooms) {
      showErrorToast("No of rooms cannot be greater than available rooms");
      return true;
    }

    if (+bookingData.no_of_child > +availableRooms.max_child) {
      showErrorToast("child cannot exceed the max child");
      return true;
    }

    return false;
  };

  const [isDataReceived, setIsDataReceived] = useState(false);

  const getDataFromChild = (index, data) => {

    // Update bookingDataArray state
    setBookingDataArray((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = data;
      // console.log(updatedData, "updatedData");
      return updatedData;
    });
    setIsDataReceived(true);
  };

  // console.log(JSON.stringify(bookingDataArray) + " bookingDataArray");

  // const getDataFromChild = (index, data) => {
  //   console.log((JSON.stringify(data)) +  "getDataFromChildata")
  //   // setBookingDataArray(data)

  //   setBookingDataArray((prevData) => {
  //     return prevData.map((item, i) => {
  //       if (i === index) {
  //         return data;
  //       } else {
  //         return item;
  //       }
  //     });
  //   });
  // };

  // console.log(bookingDataArray, "bkarray");

  // function calculateTotal(array, property) {
  //   return array.reduce((total, item) => {
  //     const value = parseFloat(item[property]) || 0; // Convert to number, default to 0 if NaN
  //     return total + value;
  //   }, 0);
  // }

  function calculateTotal(array, property) {
    // Check if array is null or undefined, and if it's an array
    if (array && Array.isArray(array)) {
      return array.reduce((total, item) => {
        const value = parseFloat(item[property]) || 0; // Convert to number, default to 0 if NaN
        return total + value;
      }, 0);
    } else {
      // Handle cases where bookingDataArray is not an array
      console.error("Booking data is not an array or is null/undefined.");
      return 0; // Return default value or handle accordingly
    }
  }

  const [guestDetails, setGuestDetails] = useState({
    salutation: "",
    name: "",
    email: "",
    phone: "",
    remarks: "",
  });

  // calculating the total of the booking arrays and saving them

  const sumSubTotal = calculateTotal(bookingDataArray, "subTotal") || 0;
  const sumGrandTotal = calculateTotal(bookingDataArray, "grandTotal" || 0);
  const sumdiscount_amount =
    calculateTotal(bookingDataArray, "discount_amount") || 0;
  const sumTotalRoomRate =
    calculateTotal(bookingDataArray, "total_room_rate") || 0;
  const sumTaxAmount = calculateTotal(bookingDataArray, "taxAmount") || 0;

  // handle confirm booking button

  const isConfirmDisabled = () => {
    if (
      guestDetails.salutation === "" ||
      guestDetails.name === ""
      // guestDetails.email === "" ||
      // guestDetails.phone === ""
    ) {
      return true;
    }
  };

  // on click of hold button show the hold modal

  // console.log(MessageData, "MessageData")

  const [showHoldPopup, setShowHoldPopup] = useState(false);
  const [bookingDataSingle, setBookingDataSingle] = useState(null);

  const toggleHoldPopup = (index) => {
    // console.log(data, 'dataa');
    // // console.log(data);

    // // Create a new variable with a different name to avoid conflicts with state variable
    // const newBookingDataSingle = {
    //   status: "confirmed",
    //   source: MessageData?.messages?.source,
    //   gst_type: MessageData?.messages?.gst_type,
    //   check_in: MessageData?.messages?.check_in,
    //   check_out: MessageData?.messages?.check_out,
    //   grand_total: data?.room_grand_total,
    //   taxable_amount: data?.tax_amount,
    //   total_tax_amount: MessageData?.messages?.total_tax_amount,
    //   total_discount_amount: MessageData?.messages?.total_discount_amount,

    //   roomInputData: [data],
    // };

    // setBookingDataSingle(newBookingDataSingle);
    setMultiplleIndex(index)

    setShowHoldPopup(!showHoldPopup);
  };


  // console.log(bookingDataSingle, "bookingDataSingle");

  const timeArray = [
    { label: "select time", value: "" },
    { label: "12:00 AM", value: "00:00" },
    { label: "01:00 AM", value: "01:00" },
    { label: "02:00 AM", value: "02:00" },
    { label: "03:00 AM", value: "03:00" },
    { label: "04:00 AM", value: "04:00" },
    { label: "05:00 AM", value: "05:00" },
    { label: "06:00 AM", value: "06:00" },
    { label: "07:00 AM", value: "07:00" },
    { label: "08:00 AM", value: "08:00" },
    { label: "09:00 AM", value: "09:00" },
    { label: "10:00 AM", value: "10:00" },
    { label: "11:00 AM", value: "11:00" },
    { label: "12:00 PM", value: "12:00" },
    { label: "01:00 PM", value: "13:00" },
    { label: "02:00 PM", value: "14:00" },
    { label: "03:00 PM", value: "15:00" },
    { label: "04:00 PM", value: "16:00" },
    { label: "05:00 PM", value: "17:00" },
    { label: "06:00 PM", value: "18:00" },
    { label: "07:00 PM", value: "19:00" },
    { label: "08:00 PM", value: "20:00" },
    { label: "09:00 PM", value: "21:00" },
    { label: "10:00 PM", value: "22:00" },
    { label: "11:00 PM", value: "23:00" },
  ];

  const [holdBookingData, setHoldBookingData] = useState("");

  const holdBookingDataChange = (e) => {
    // console.log(holdBookingData + "holdBookingData");
    // setHoldBookingData({
    //   ...holdBookingData,
    //   [e.target.name]: e.target.value,
    // });
    setShowHoldPopup(false);
  };

  // function wrote  for showing the date format according to indian standard
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "dd/mm/yyyy";
    } else {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  };




  const handleAssignButtonClick = () => {
    if (isAssignButtonValid()) {
      setLoader(true);
      // if (isConfirmDisabled()) {
      //   showErrorToast("Please fill required Guest details.");
      //   return;
      // }

      // console.log(bookingDataArray, "bookingDataArrayyy");

      const roomInputData = bookingDataArray.map((item) => ({
        room_id: item.room_id || "",
        room_type_name: item.room_type_name || "",
        rate_plan_id: item.rate_plan_id || "",
        room_plan: item.room_plan || "",
        no_of_rooms: item.no_of_rooms || "",
        no_nights: item.no_nights || "",
        room_rate: item.room_rate,
        no_adult: item.no_adult || 0,
        no_child: item.no_child || 0,
        extra_adult: item.extra_adult || 0,
        discount_rate: item.discount_type || 0,
        discount_percent: item?.discount_type?.discount_amount || 0,
        discount_amount: item.discount_amount || 0,
        tax_rate: item.tax_rate || 0,
        tax_amount: item?.taxAmount || 0,
        room_total_amount: item.room_total_amount || 0,
        booking_paxs: item.booking_paxs,
        room_grand_total: item.grandTotal,
        room_sub_total: item.subTotal,

        isSelected: "false",
      }));

      const addOns = addOnsPaymentData.addOn;

      const paymentData = addOnsPaymentData.payments;

      const postData = {
        holding_time: MessageData.holding_time,
        booking_type: MessageData.booking_type,
        status: "confirmed",
        source: "Front Desk",
        check_in: MessageData.check_in,
        check_out: MessageData.check_out,
        // date: currentDate,
        // agent_id: bookingData.agent_id,
        // guest_name: guestDetails.salutation + " " + guestDetails.name,
        // guest_email: guestDetails.email,
        // guest_phone: guestDetails.phone,
        // pincode: guestDetails.pincode || "",
        // guest_city: guestDetails.city || "",
        // guest_state: guestDetails.state || "",
        // guest_address: guestDetails.address || "",
        gst_type: MessageData.gst_type || "exclusive",
        grand_total: sumGrandTotal,
        taxable_amount: sumSubTotal,
        total_tax_amount: sumTaxAmount,
        total_discount_amount: sumdiscount_amount,
        roomInputData: roomInputData,
        // addOns: addOns,
        // payments: paymentData,
      };


      try {
        fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v2/message/update/${messageId}/${property_id.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(postData),
          }
        )
          .then((response) => {
            if (!response.ok) {
              showErrorToast("Error while Updating Message");
            }
            return response.json();
          })
          .then((data) => {
            setLoader(false);
            if (data.success === true) {
              showSuccessToast("Message Updated  successfully");
              setShowHoldPopup(false);
              fetchMessageData(property_id.id);
            } else {
              setLoader(false);
              showErrorToast("Error while Updating Message");
            }
          });
      } catch (error) {
        setLoader(false);
        console.error("Error:", error);
      }

      setShowHoldPopup(false);
    } else {
      showErrorToast("Please select hold date and time");
    }
  };

  // convert to booking

  const handleConvertToBooking = () => {
    if (isAssignButtonValid()) {
      const roomInputData = bookingDataArray.map((item) => ({
        room_id: item.room_id || "",
        room_type_name: item.room_type_name || "",
        rate_plan_id: item.rate_plan_id || "",
        room_plan: item.room_plan || "",
        no_of_rooms: item.no_of_rooms || "",
        no_nights: item.no_nights || "",
        room_rate: item.room_rate_new,
        no_adult: item.no_adult || 0,
        no_child: item.no_child || 0,
        extra_adult: item.extra_adult || 0,
        discount_rate: item?.discount_rate || 0,
        discount_percent: item?.discount_type?.discount_amount || 0,
        discount_amount: item?.discount_amount || 0,
        tax_rate: item.tax_rate || 0,
        tax_amount: item.taxAmount || 0,
        room_total_amount: item.room_total_amount || 0,
        booking_paxes: item?.booking_paxs,
        room_grand_total: item.grandTotal,
        room_sub_total: item.subTotal,
      }));

      const postData = {
        holding_time: MessageData?.messages?.holding_time,
        booking_type: MessageData?.messages?.booking_type,
        status: "confirmed",
        source: "Front Desk",
        check_in: MessageData?.messages?.check_in,
        check_out: MessageData?.messages?.check_out,
        date: currentDate,
        // agent_id: bookingData.agent_id,
        // guest_name: guestDetails.salutation + " " + guestDetails.name,
        // guest_email: guestDetails.email,
        // guest_phone: guestDetails.phone,
        // pincode: guestDetails.pincode || "",
        // guest_city: guestDetails.city || "",
        // guest_state: guestDetails.state || "",
        // guest_address: guestDetails.address || "",
        gst_type: MessageData?.messages?.gst_type || "exclusive",
        grand_total: sumGrandTotal,
        taxable_amount: sumSubTotal,
        total_tax_amount: sumTaxAmount,
        total_discount_amount: sumdiscount_amount,
        roomInputData: roomInputData,
        // addOns: addOns,
        // payments: paymentData,
      };

      try {
        fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v2/convert/to/booking/${messageId}/${property_id.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(postData),
          }
        )
          .then((response) => {
            if (!response.ok) {
              showErrorToast("Error while Converting Booking");
            }
            return response.json();
          })
          .then((data) => {

            if (data.success === true) {
              showSuccessToast("Booking Converted  successfully");

              setShowHoldPopup(false);
              // window.location.reload();
              navigate("/bookings");
            } else {
              showErrorToast("Error while Converting Booking ");
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }

      setShowHoldPopup(false);
    } else {
      showErrorToast("Please select hold date and time");
    }
  };

  const [multipleIndex, setMultiplleIndex] = useState("")

  const handleConvertToBookingMultiple = () => {
    // alert("hi")
    if (isAssignButtonValid()) {
      // if (isConfirmDisabled()) {
      //   showErrorToast("Please fill required Guest details.");
      //   return;
      // }

      const addOns = addOnsPaymentData.addOn;

      const paymentData = addOnsPaymentData.payments;

      const roomInputData = bookingDataArray.map((item) => ({
        room_id: item.room_id || "",
        room_type_name: item.room_type_name || "",
        rate_plan_id: item.rate_plan_id || "",
        room_plan: item.room_plan || "",
        no_of_rooms: item.no_of_rooms || "",
        no_nights: item.no_nights || "",
        room_rate: item.room_rate_new,
        no_adult: item.no_adult || 0,
        no_child: item.no_child || 0,
        extra_adult: item.extra_adult || 0,
        discount_rate: item?.discount_rate || 0,
        discount_percent: item?.discount_type?.discount_amount || 0,
        discount_amount: item?.discount_amount || 0,
        tax_rate: item.tax_rate || 0,
        tax_amount: item.taxAmount || 0,
        room_total_amount: item.room_total_amount || 0,
        booking_paxes: item?.booking_paxs,
        room_grand_total: item.grandTotal,
        room_sub_total: item.subTotal,
      }))[+multipleIndex];

      const postData = {
        status: "confirmed",
        source: "Front Desk",
        check_in: MessageData?.messages?.check_in,
        check_out: MessageData?.messages?.check_out,
        date: currentDate,
        gst_type: MessageData.gst_type || "exclusive",
        grand_total: sumGrandTotal,
        taxable_amount: sumSubTotal,
        total_tax_amount: sumTaxAmount,
        total_discount_amount: sumdiscount_amount,
        roomInputData: roomInputData,

      };

      try {
        fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v2/convert/to/booking/${messageId}/${property_id.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(postData),
          }
        )
          .then((response) => {
            if (!response.ok) {
              showErrorToast("Error while Converting Booking");
            }
            return response.json();
          })
          .then((data) => {
            if (data.success === true) {
              showSuccessToast("Booking Converted  successfully");

              setShowHoldPopup(false);
              navigate("/bookings");
              // window.location.reload();
            } else {
              showErrorToast("Error while Converting Booking ");
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }

      setShowHoldPopup(false);
    } else {
      showErrorToast("Please select hold date and time");
    }
  };

  const isAssignButtonValid = () => {
    if (holdBookingData.hold_date !== "" && holdBookingData.hold_time !== "") {
      return true;
    } else {
      return false;
    }
  };

  const [addOnsPaymentData, setAddOnsPaymentData] = useState({
    addOn: [],
    payments: [],
  });
  // const getAddonsData = (data) => {
  //   setAddOnsPaymentData((prev) => {
  //     return {
  //       ...prev,
  //       addOn: data,
  //     };
  //   });
  // };
  // const getPaymentData = (data) => {
  //   setAddOnsPaymentData((prev) => {
  //     return {
  //       ...prev,
  //       payments: data,
  //     };
  //   });
  // };

  // const [singleLeadData, setSingleLeadData] = useState(null);

  // useEffect(() => {
  //   const getSingleBookingDataHandler = (messageId) => {
  //     // setLoader(true);

  //     fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v2/lead/detail/${messageId}/${property_id?.id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.success) {
  //           setSingleLeadData(data?.leads);

  //           setLoader(false);
  //         } else {
  //           setLoader(false);

  //         }
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         console.error("Error:", error);
  //         // showErrorToast("An error occurred while processing your request.");
  //       });
  //   };

  //   getSingleBookingDataHandler(messageId);
  // }, []);


  const sumPaidAmount =
    calculateTotal(addOnsPaymentData.payments, "amount") || 0;

  // add ons cost

  const sumSubtotalAddons =
    calculateTotal(addOnsPaymentData.addOn, "price") || 0;
  const sumdiscount_amountAddons =
    calculateTotal(addOnsPaymentData.addOn, "discount_amount") || 0;
  const sumGrandTotalAddons =
    calculateTotal(addOnsPaymentData.addOn, "total") || 0;

  const sumTaxAmountAddons =
    calculateTotal(addOnsPaymentData.addOn, "tax_amount") || 0;

  // new handler for room change
  const handleNumberOfRoomsChange = (e) => {
    const { name, value } = e.target;
    const selectedRooms = +value;
    const prevAvailableRooms = availableRooms?.available_rooms;

    if (selectedRooms > prevAvailableRooms) {
      showErrorToast("cannot exceed the available rooms limit");
      return;
    }
    setBookingData({
      ...bookingData,
      [name]: value,
    });
    // setAvailableRooms({
    //   ...availableRooms,
    //   available_rooms: prevAvailableRooms - selectedRooms,
    // });
  };

  // handle number of adult
  const handleNumberOfAdultChange = (e) => {
    const { name, value } = e.target;
    if (+value > availableRooms?.base_adult * bookingData.no_of_rooms) {
      showErrorToast(
        "The number of adults cannot be greater than the base occupancy of a room"
      );
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
    }
  };

  //handle number of extra adult change
  const handleNumberOfExtraAdultChange = (e) => {
    const { name, value } = e.target;
    if (availableRooms.extra_bedding !== "enabled") {
      showErrorToast("Extra Bedding not allowed for this room");
      setBookingData({
        ...bookingData,
        [name]: 0,
      });
      return; // Return early if extra bedding is not allowed
    }

    if (+value > availableRooms?.allowedExtraAdult * bookingData.no_of_rooms) {
      showErrorToast("Value cannot exceed the available extra adult per room");
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
    }
  };

  //handle number of child change

  const handleNumberOfChildChange = (e) => {
    const { name, value } = e.target;
    const childAllowed = availableRooms?.max_child;

    if (+value > childAllowed * bookingData.no_of_rooms) {
      showErrorToast("Value cannot exceed the available extra adult limit");
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
    }
  };

  const handleGuestDetails = (e) => {
    setGuestDetails({
      ...guestDetails,
      [e.target.name]: e.target.value,
    });
  };

  // salutations

  const [showSalutationPopup, setShowSalutationPopup] = useState(false);

  const [salutations, setSalutations] = useState([]);

  const fetchSalutations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSalutations(data.data);
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
  };

  useEffect(() => {
    fetchSalutations();
  }, []);

  const handleSalutationChange = (e) => {
    const { name, value } = e.target;

    if (value === "other") {
      setShowSalutationPopup(true);
    } else {
      setGuestDetails({
        ...guestDetails,
        salutation: e.target.value,
      });
    }
  };

  const [popUpSalutation, setPopUpSalutation] = useState("");

  const handlePopupAddButtonClick = async (e) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/add`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            name: popUpSalutation,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setPopUpSalutation(false);
        setPopUpSalutation("");
        fetchSalutations();
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
    setGuestDetails({
      ...guestDetails,
      salutation: popUpSalutation,
    });
    setShowSalutationPopup(false);
  };

  const handleConfirmButtonClick = () => {
    if (isConfirmDisabled()) {
      showErrorToast("Please fill required Guest Name.");
      return;
    }
    if (bookingDataArray.length === 0) {
      showErrorToast("Please add booking details");
      return;
    }
    const roomInputData = bookingDataArray.map((item) => ({
      room_id: item.room_id || "",
      room_type_name: item.room_type_name || "",
      rate_plan_id: item.rate_plan_id || "",
      room_plan: item.room_plan || "",
      no_of_rooms: item.no_of_rooms || "",
      no_nights: item.no_nights || "",
      room_rate: item.room_rate_new,
      no_adult: item.no_adult || 0,
      no_child: item.no_child || 0,
      extra_adult: item.extra_adult || 0,
      discount_rate: item?.discount_rate || 0,
      discount_percent: item?.discount_type?.discount_amount || 0,
      discount_amount: item?.discount_amount || 0,
      tax_rate: item.tax_rate || 0,
      tax_amount: item.tax_amount || 0,
      room_total_amount: item.room_total_amount || 0,
      booking_paxs: item.booking_paxes,
      room_grand_total: item.room_grand_total,
    }));

    const addOns = addOnsPaymentData.addOn;

    const paymentData = addOnsPaymentData.payments;

    const postData = {
      status: "confirmed",
      source: bookingData.booking_source,
      check_in: bookingData.check_in,
      check_out: bookingData.check_out,
      date: currentDate,
      agent_id: bookingData.agent_id,
      guest_name: guestDetails.salutation + " " + guestDetails.name,
      guest_email: guestDetails.email,
      guest_phone: guestDetails.phone,
      pincode: guestDetails.pincode || "",
      guest_city: guestDetails.city || "",
      guest_state: guestDetails.state || "",
      guest_address: guestDetails.address || "",
      gst_type: bookingData.tax_type || "",
      grand_total: sumGrandTotal,
      taxable_amount: sumSubTotal,
      total_tax_amount: sumTaxAmount,
      total_discount_amount: sumdiscount_amount,
      roomInputData: roomInputData,
      addOns: addOns,
      payments: paymentData,
    };

    // console.log(postData + "postData");

    // navigate("/bookings/createbooking/edit")

    // Check child age for each booking pax
    for (const booking of bookingDataArray) {
      for (const pax of booking.booking_paxes) {
        if (pax.pax_type === "child" && !pax.child_age) {
          showErrorToast("Please enter child's age.");
          return;
        }
      }
    }

    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/store/new/bookings/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...postData,
            check_in: checkIn,
            check_out: checkOut,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            showErrorToast("Error while creating booking");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success === true) {
            navigate("/bookings");
            showSuccessToast("Booking Created Successfully");

            // navigate(`/bookings/createbooking/edit/${data.booking_id}`);
          } else {
            showErrorToast("Error while creating booking");
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function calculatediscount_amountForBookingPaxes(bookingData) {
    let totaldiscount_amount = 0;
    // Loop through each booking in the array
    bookingData?.forEach((booking) => {
      // Loop through each booking pax in the booking
      booking?.booking_paxes?.forEach((pax) => {
        // Add the discount_amount of the booking pax to the totaldiscount_amount
        totaldiscount_amount += pax?.discount_amount;
      });
    });
    return totaldiscount_amount;
  }

  // console.log(bookingDataArray, "bookingDataArray");
  const [showDiscountSelect, setShowDiscountSelect] = useState(true);

  return (
    <>

      {loader ? (
        <Loader />
      ) : (

        <>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={handleGoBack}
            >
              <svg
                className="back-btn"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <rect
                  x="29.75"
                  y="29.75"
                  width="29.5"
                  height="29.5"
                  rx="5.75"
                  transform="rotate(180 29.75 29.75)"
                  stroke="#666666"
                  strokeWidth="0.5"
                />
                <line x1="21" y1="15.043" x2="9" y2="15.043" stroke="#666666" />
                <path
                  d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                  stroke="#666666"
                  strokeWidth="1.2"
                />
              </svg>
            </div>
            <h4 style={{ fontWeight: "bold", fontSize: "18px" }}>Sent Message</h4>
          </div>

          <div
            style={{
              display: "flex",
              margin: "10px 0",
              justifyContent: "space-between",
            }}
          >
            <div className="create_booking_page_left_booking_card_container">
              {/* add on and  payment section */}

             

              {/* guest details */}

              <div className="create_booking_primary_guest_details_container_wrapper">
                {/* <div className="create_booking_primary_guest_details_container">
              <h4>Primary Guest Details</h4>
              <div className="create_booking_primary_guest_details">
                <div className="create_booking_primary_guest_details_input_container">
                  <label className="standard-select">
                    Name <span style={{ color: "red" }}>*</span> <br />
                  </label>
                  <div className="flex gap_5">
                    <select
                      id="salutation"
                      name="salutation"
                      value={guestDetails.salutation}
                      onChange={handleSalutationChange}
                      className="create_booking_primary_guest_details_input"
                      style={{ width: "50px", marginRight: "5px" }}
                    >
                      <option value="">Select...</option>
                      {salutations?.map((salutation, index) => (
                        <option key={index} value={salutation.name}>
                          {salutation.name}
                        </option>
                      ))}
                      <option value={"other"}>Other</option>
                    </select>

                    <input
                      type="text"
                      required
                      className="create_booking_primary_guest_details_input"
                      name="name"
                      value={guestDetails.name}
                      onChange={handleGuestDetails}
                    />
                  </div>
                </div>
                <div className="create_booking_primary_guest_details_input_container">
                  <label className="standard-select">Phone </label> <br />
                  <input
                    type="number"
                    required
                    className="create_booking_primary_guest_details_input"
                    value={guestDetails.phone}
                    name="phone"
                    onChange={handleGuestDetails}
                  />
                </div>

                <div className="create_booking_primary_guest_details_input_container">
                  <label className="standard-select">Email </label> <br />
                  <input
                    type="email"
                    className="create_booking_primary_guest_details_input"
                    value={guestDetails.email}
                    name="email"
                    onChange={handleGuestDetails}
                  />
                </div>
              </div>
            </div>
            <div className="create_booking_primary_guest_details_container">
              <h4>Remarks</h4>
              <div className="create_booking_primary_guest_details">
                <textarea
                  type="text"
                  required
                  className="create_booking_primary_guest_details_input_remarks"
                  name="remarks"
                  value={guestDetails.remarks}
                  onChange={handleGuestDetails}
                  style={{ width: "100%" }}
                />
              </div>
            </div> */}

                <div
                  className="create_booking_page_main"
                  style={{
                    position: "relative",
                    padding: "0",
                    backgroundColor: "#ffffff00",
                  }}
                >
                  <div
                    className="create_booking_page_top smsg_create_booking_page_top flex align_center"
                    style={{ borderTop: "none" }}
                  >
                    <div
                      className="flex  nodata_found_container w-100  "
                      style={{
                        flexDirection: "column",
                        width: "100",
                        justifyContent: "start",
                      }}
                    >
                      {/* <CreateBookingSelectBox
                    label="Rate Type"
                    value={bookingData.booking_type}
                    onChange={handleBookingTypeChange}
                    options={BookingTypeOptions}
                    disabled={bookingDataArray.length > 0}
                  /> */}

                      <div className="create_booking_selectBox_container">
                        {/* <DateRangePickerNew
                      dataFromChild={dataFromChild}
                      // defaultDate={`${calculateToDate(currentDate)}:${currentDate}`}
                      minDate={new Date(currentDate)}
                      disabled={bookingDataArray.length > 0}
                    /> */}
                      </div>

                      {/* room category */}
                      {/* <CreateBookingSelectBox
                    disabled={
                      bookingData.check_in && bookingData.check_out
                        ? false
                        : true
                    }
                    label="Room Category"
                    value={bookingData.room_category}
                    onChange={handleRoomCategory}
                    options={RoomCategoryOptions}
                  /> */}
                      {/* <CreateMessageInputBox
              alignment="vertical"
              value={bookingData.no_of_nights}
              label="No. of Nights"
              onChange={handleInputChange}
              name="no_of_nights"
            /> */}
                      {/* <div style={{ width: "60px" }} className=" vertical">
              <label className="Create_booking_label">No. of Nights</label>
              <br />
              <input
                defaultValue={bookingData.no_of_nights}
                className="createBookingInputAndSelectTagsSmall"
                disabled
              />
            </div> */}
                      {/* <CreateMessageInputBox
                    alignment="vertical"
                    label="No. of Rooms"
                    onChange={handleNumberOfRoomsChange}
                    name="no_of_rooms"
                    value={bookingData.no_of_rooms}
                  />
                  <CreateMessageInputBox
                    alignment="vertical"
                    label="No. of Adults"
                    onChange={handleNumberOfAdultChange}
                    name="no_of_adults"
                    value={bookingData.no_of_adults}
                  />
                  <CreateMessageInputBox
                    alignment="vertical"
                    label="Extra Adults"
                    onChange={handleNumberOfExtraAdultChange}
                    name="extra_adults"
                    value={bookingData.extra_adults}
                  />
                  <CreateMessageInputBox
                    alignment="vertical"
                    label="No. of Child"
                    // onChange={handleInputChange}
                    name="no_of_child"
                    onChange={handleNumberOfChildChange}
                    value={bookingData.no_of_child}
                  /> */}

                      {/* <SentMessageCard
                        index={index}
                        data={data}
                        handleDeleteBookingCard={handleDeleteBookingCard}
                        allowedRooms={+availableRooms.available_rooms}
                        allowedChild={+availableRooms.max_child}
                        getDataFromChild={getDataFromChild}
                        availableRooms={availableRooms}
                        setAvailableRooms={setAvailableRooms}
                        tax_type={bookingData.tax_type}
                        discount_type={bookingData.discount_type}
                        showDiscountSelect={showDiscountSelect}
                        setShowDiscountSelect={setShowDiscountSelect}
                      /> */}

                      <>
                        {MessageData &&
                          MessageData?.messages?.lead_communication_items?.length >
                          0 ? (
                          <>
                            {MessageData?.messages?.lead_communication_items?.map(
                              (data, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <SentMessageCard
                                      BookingType={
                                        MessageData?.messages?.booking_type
                                      }
                                      data={data}
                                      toggleHoldPopup={toggleHoldPopup}
                                      index={index}
                                      checkIn={MessageData?.messages?.check_in}
                                      checkOut={MessageData?.messages?.check_out}
                                      handleDeleteBookingCard={
                                        handleDeleteBookingCard
                                      }
                                      allowedRooms={+availableRooms.available_rooms}
                                      allowedChild={+availableRooms.max_child}
                                      getDataFromChild={getDataFromChild}
                                      availableRooms={availableRooms}
                                      setAvailableRooms={setAvailableRooms}
                                      tax_type={data.tax_type}
                                      discount_type={bookingData.discount_type}
                                      showDiscountSelect={showDiscountSelect}
                                      setShowDiscountSelect={setShowDiscountSelect}
                                    />
                                  </React.Fragment>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "75vw",
                              alignItems: "center",
                            }}
                          >
                            <NoDataFound />
                          </div>
                        )}
                      </>

                      {/* {MessageData?.messages?.lead_communication_items?.map(
                    (data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <SentMessageCard
                            data={data}
                            index={index}
                            handleDeleteBookingCard={handleDeleteBookingCard}
                            allowedRooms={+availableRooms.available_rooms}
                            allowedChild={+availableRooms.max_child}
                            getDataFromChild={getDataFromChild}
                            availableRooms={availableRooms}
                            setAvailableRooms={setAvailableRooms}
                            tax_type={data.tax_type}
                            discount_type={"percent"}
                            showDiscountSelect={showDiscountSelect}
                            setShowDiscountSelect={setShowDiscountSelect}
                          />
                        </React.Fragment>
                      );
                    }
                  )} */}

                      {/* <div>
                    <WithoutBgButtonBlue
                      style={{ background: "none", marginTop: "27px" }}
                      onClick={handleClickOnAddButton}
                      type={"submit"}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_7483_1851)">
                            <path
                              d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                              stroke="#3968ED"
                            />
                            <path
                              d="M10.5873 7H3.41406"
                              stroke="#3968ED"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M7 3.41344L7 10.5867"
                              stroke="#3968ED"
                              stroke-miterlimit="10"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7483_1851">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      }
                      children={"Add "}
                    />
                  </div> */}
                    </div>
                    {/* <div>
                  {(availableRooms.available_rooms !== undefined ||
                    availableRooms.available_rooms === "") && (
                    <p
                      className="avail_room"
                      style={{
                        fontSize: "12px",
                        backgroundColor:
                          availableRooms.available_rooms === 0
                            ? "rgb(255 0 0 / 18%)"
                            : "",
                        color:
                          availableRooms.available_rooms === 0 ? "red" : "",
                      }}
                    >
                      Available Rooms:{" "}
                      {availableRooms.available_rooms || "rooms not available"}
                    </p>
                  )}
                  {/* {(availableRooms.base_adult !== undefined ||
                    availableRooms.base_adult === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Base Adult: {availableRooms.base_adult}
                    </p>
                  )} */}

                    {/* {(availableRooms.max_adult !== undefined ||
                    availableRooms.max_adult === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Max Adult: {availableRooms.max_adult}
                    </p>
                  )} */}

                    {(availableRooms.allowedExtraAdult !== undefined ||
                      availableRooms.allowedExtraAdult === "") && (
                        <p style={{ fontSize: "10px" }}>
                          Extra Adult Allowed:{" "}
                          {availableRooms.allowedExtraAdult ? "Enabled" : "Disabled"}
                        </p>
                      )}

                    {/* {(availableRooms.max_child !== undefined ||
                    availableRooms.max_child === "") && (
                    <p style={{ fontSize: "10px" }}>
                      Child Allowed: {availableRooms.max_child}
                    </p>
                  )} */}
                    <></>
                    {/* </div> */}
                  </div>
                  <div
                    className="create_booking_page_mid flex gap align_center"
                    style={{ display: "none" }}
                  >
                    hi
                    {/* <CreateBookingSelectBox
                  label="Booking Source"
                  value={bookingData?.booking_source}
                  onChange={handleBookingSourceChange}
                  options={BookingSourceOptions}
                  disabled={bookingDataArray.length > 0}
                /> */}
                    {bookingData.booking_source === "Agent (B2B) Partner" && (
                      <label>
                        Select Agent <br />
                        <select
                          onChange={handleInputChange}
                          name="agent_id"
                          className="createBookingInputAndSelectTags"
                        >
                          <option>Select</option>
                          {agentList.map((item, index) => {
                            return (
                              <option key={index} value={item.agent_information}>
                                {item.agent_name}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    )}
                    {bookingData.booking_source === "OTA" && (
                      <label>
                        Select OTA <br />
                        <select
                          onChange={handleInputChange}
                          name="ota_source"
                          className="createBookingInputAndSelectTags"
                          value={bookingData.ota_source}
                        >
                          <option>Select</option>
                          {otaTypeOptions.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    )}
                    {bookingData.agent_id !== "" && (
                      <div>
                        <label>TAC Disc. %</label> <br />
                        <input
                          type="number"
                          name="tac_discount"
                          disabled
                          defaultValue={bookingData?.tac_discount}
                          className="createBookingInputAndSelectTags"
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* {bookingDataArray.length > 0 && (
              <>
                {bookingDataArray.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <SentMessageCard
                        index={index}
                        data={data}
                        handleDeleteBookingCard={handleDeleteBookingCard}
                        allowedRooms={+availableRooms.available_rooms}
                        allowedChild={+availableRooms.max_child}
                        getDataFromChild={getDataFromChild}
                        availableRooms={availableRooms}
                        setAvailableRooms={setAvailableRooms}
                        tax_type={bookingData.tax_type}
                        discount_type={bookingData.discount_type}
                        showDiscountSelect={showDiscountSelect}
                        setShowDiscountSelect={setShowDiscountSelect}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            )} */}
              </div>
            </div>

            {/* salutations popup */}

            {showSalutationPopup && (
              <>
                <div className="global_popup_container_background">
                  <div className="global_popup_container ">
                    <div className="GoBackButtonCustom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => setShowSalutationPopup(false)}
                      >
                        <rect
                          x="29.75"
                          y="29.75"
                          width="29.5"
                          height="29.5"
                          rx="5.75"
                          transform="rotate(180 29.75 29.75)"
                          stroke="#666666"
                          strokeWidth="0.5"
                        ></rect>
                        <line
                          x1="21"
                          y1="15.043"
                          x2="9"
                          y2="15.043"
                          stroke="#666666"
                        ></line>
                        <path
                          d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                          stroke="#666666"
                          strokeWidth="1.2"
                        ></path>
                      </svg>
                      <span>Add </span>
                    </div>

                    <VerticalInputFieldCustom
                      titleName={"Salutation"}
                      name={"name"}
                      type={"text"}
                      value={popUpSalutation}
                      onChange={(e) => setPopUpSalutation(e.target.value)}
                    />

                    <BgThemeButton
                      children={"Add"}
                      onClick={handlePopupAddButtonClick}
                      style={{ margin: "10px 0" }}
                    />
                  </div>
                </div>
              </>
            )}

            {/* total amount Card   */}

            <div className="create_booking_page_total_amount_card">
              {/* setting the discount type and tax type changing these will change the total amount */}
              {/* this select tag is sending id to handler and it sae discount type object in BookingData object */}
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  gap: "10px",
                  flexWrap: "wrap",
                  padding: "20px",
                  borderBottom: "0.5px solid #333",
                }}
              >
                <div>
                  {showDiscountSelect && (
                    <>
                      {/* <label>Disc Type</label> <br />
                  <select
                    onChange={handleDiscountTypeChange}
                    name="discount_id"
                    className="createBookingInputAndSelectTags"
                    style={{ marginTop: "5px", width: "150px" }}
                  >
                    <option>Select</option>
                    {discountOptions &&
                      // discountOptions.length > 0 &&
                      discountOptions?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.discount_name} {item.discount_amount}
                          </option>
                        );
                      })}
                  </select> */}
                      {/* 
                  
<CreateMessageInputBox
                    alignment="vertical"
                    label="Disc %"
                    // onChange={handleInputChange}
                    name="no_of_child"
                    onChange={handleDiscountTypeChange}
                    // value={discount_percent}
                  /> */}

                      {/* <CreateMessageInputBox
                    alignment="vertical"
                    label="Disc %"
                    name="discount_type"
                         placeholder="Enter discount value (0-99)"
                    onChange={handleDiscountTypeChange}
                  /> */}
                      <div
                        className="gap_7 flex"
                        style={{ flexDirection: "column" }}
                      >
                        <label className="Create_booking_label">Disc %</label>
                        <input
                          style={{
                            padding: "6px 20px 6px 10px",
                            width: "50%",
                            borderRadius: "4px",
                            border: "1px solid #ddd",
                            outline: "none",
                          }}
                          onChange={handleDiscountTypeChange}
                          name="discount_type"
                          type="number"
                          value={bookingData.discount_type}
                        ></input>
                      </div>

                    </>
                  )}
                </div>

                {/* <div>
              <label className="Create_booking_label">Tax</label>
              <div className="flex gap_7">
                <label className="Create_booking_label flex gap_7">
                  <input
                    type="radio"
                    name="tax_type"
                    value="inclusive"
                    checked={bookingData.tax_type === "inclusive"}
                    onChange={handleInputChange}
                  ></input>
                  Inclusive
                </label>
                <label className="Create_booking_label flex gap_7">
                  <input
                    type="radio"
                    name="tax_type"
                    value={"exclusive"}
                    checked={bookingData.tax_type === "exclusive"}
                    onChange={handleInputChange}
                  ></input>
                  Exclusive
                </label>
              </div>
            </div> */}
              </div>

              {/* <div style={{ padding: "20px", position: "relative" }}>
            <div className="crate_booking_page_total_amount_heading">
              <span>Subtotal</span>
              <span>
                {formatIndianCurrency(+sumSubTotal + +sumSubtotalAddons)}{" "}
              </span>
            </div>
            <div className="crate_booking_page_total_amount_heading">
              <span>Discount Amt.</span>
              <span>{formatIndianCurrency(+sumdiscount_amount)}</span>
            </div>

            <div className="crate_booking_page_total_amount_heading">
              <span>Tax Amt.</span>
              <span>
                {formatIndianCurrency(+sumTaxAmount + +sumTaxAmountAddons)}{" "}
              </span>
            </div>
            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#3968ED" }}
            >
              <span>Grand Total</span>
              <span>
                {formatIndianCurrency(+sumGrandTotal + +sumGrandTotalAddons)}
              </span>
            </div>

            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#15AA12" }}
            >
              <h4>Paid Amt.</h4>
              <h3>{formatIndianCurrency(sumPaidAmount)}</h3>
            </div>
            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#BD941B" }}
            >
              <h4>Due Amt.</h4>
              <h3>
                {formatIndianCurrency(
                  +sumGrandTotal + +sumGrandTotalAddons - sumPaidAmount
                )}
              </h3>
            </div>
          </div> */}

              {MessageData?.messages?.booking_type === "Single" && (
                <div style={{ padding: "20px", position: "relative" }}>
                  <div className="crate_booking_page_total_amount_heading">
                    <span>Subtotal</span>
                    <span>
                      {formatIndianCurrency(
                        +sumSubTotal + +sumSubtotalAddons || messageState.subtotal
                      )}

                      {/* <span>{formatIndianCurrency(messageState.subtotal)}</span> */}
                    </span>
                  </div>
                  <div className="crate_booking_page_total_amount_heading">
                    <span>Discount Amt.</span>
                    <span>
                      {formatIndianCurrency(
                        +sumdiscount_amount + +sumdiscount_amountAddons
                        // ||  MessageData?.messages?.total_discount_amount
                      )}
                    </span>
                    {/* <span>{formatIndianCurrency(messageState.discountAmt)}</span> */}
                  </div>
                  <div className="crate_booking_page_total_amount_heading">
                    <span>Tax Amt.</span>
                    <span>
                      {formatIndianCurrency(
                        +sumTaxAmount + +sumTaxAmountAddons || messageState.taxAmt
                      )}
                    </span>
                  </div>
                  <div
                    className="crate_booking_page_total_amount_heading"
                    style={{ color: "#3968ED" }}
                  >
                    <span>Grand Total</span>
                    <span>
                      {formatIndianCurrency(
                        +sumGrandTotal + +sumGrandTotalAddons ||
                        messageState.grandTotal
                      )}
                    </span>
                  </div>
                  {/* <div
                className="crate_booking_page_total_amount_heading"
                style={{ color: "#15AA12" }}
              >
                <h4>Paid Amt.</h4>
                <h3>{formatIndianCurrency(sumPaidAmount)}</h3>
              </div> */}
                  {/* <div
                className="crate_booking_page_total_amount_heading"
                style={{ color: "#BD941B" }}
              >
                <h4>Due Amt.</h4>
                <h3>
                  {formatIndianCurrency(
                    +sumGrandTotal + +sumGrandTotalAddons - sumPaidAmount
                  )}
                </h3>
              </div> */}
                </div>
              )}

              <div
                className="flex gap_7"
                style={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <BgOutlineSendAgainButton
                  // type={"submit"}
                  onClick={handleAssignButtonClick}
                  children={"Send Again"}
                />
                {MessageData?.messages?.booking_type === "Single" ? (
                  <BgThemeButton
                    style={{ width: "100%" }}
                    loader={loader}
                    // onClick={handleConfirmButtonClick}
                    onClick={() => setShowHoldPopup(true)}
                    type={"submit"}
                    children={"Convert To Booking"}
                  />
                ) : null}

                {isDataReceived && (
                  <WithoutBgButtonBlue
                    style={{ width: "100%", background: "none" }}
                    onClick={(e) => handleSubmitPreviewVoucher()}
                    type={"submit"}
                  >
                    Preview
                  </WithoutBgButtonBlue>
                )}

                {/* <BgThemeButton
              style={{ background: "#BD941B" }}
              onClick={() => setShowHoldPopup(true)}
            >
              Hold
            </BgThemeButton> */}
              </div>

              {iframeContent && showIframePopup && (
                <>
                  <div className="bg-backdrop">
                    <section
                      className="communication_iframe_container"
                      style={{ width: "795px", overflow: "hidden" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                          padding: "0 25px",
                        }}
                      >
                        <div
                          className="add-ota-model-container-heading"
                          style={{ display: "flex", gap: "10px" }}
                        >



                        </div>
                        <div
                          onClick={() => setShowIframePopup(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>
                      {/* <iframe
                title="Preview"
                src={iframeSrc}
                width="100%"
                height="100%"
              /> */}
                      <iframe
                        srcDoc={iframeContent}
                        id="receipt"
                        // style={{ display: "none" }}
                        title="Receipt"
                      />
                      {/* <PreviewCommunicationVoucher uniquebookingid={bookingId} /> */}
                    </section>
                  </div>
                </>
              )}

              {showHoldPopup && (
                <>
                  <div
                    className="createBookingTableNewPopupContainer"
                    onClick={() => setShowHoldPopup(false)}
                  >
                    <div
                      className="createBookingTableNewPopupContent"
                      onClick={(e) => e.stopPropagation()}
                      style={{ width: "400px" }}
                    >
                      <h3 style={{ textAlign: "center" }}>Are you Sure ?</h3>

                      <div className="d_flex" style={{ margin: "15px 0" }}>
                        <div
                          className="d_flex align_center"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "15px",
                            fontWeight: 600,
                            color: "#333333",
                            padding: "0px 72px",
                          }}
                        >
                          <label style={{ textAlign: "center" }}>
                            You Want This Message to Convert Booking
                          </label>{" "}
                          <br />
                          {/* <select
                        className="addEvent-input"
                        name="hold_time"
                        value={holdBookingData.hold_time}
                        onChange={holdBookingDataChange}
                      >
                        {timeArray.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}
                          {/* <VerticalInputFieldCustom
                        format={"HH:mm:ss"}
                        type="time"
                        step="1"
                        onChange={(e)=>setHoldBookingData(e.target.value)}
                        name="hold_time"
                        placeholder="Select time"
                        value={holdBookingData.hold_time}
                      /> */}
                        </div>
                      </div>

                      <div className="createBookingTableNewPopupFooter">
                        <BgThemeButton
                          // type={"submit"}
                          children={"Yes Convert"}
                          style={{ width: "50%" }}
                          onClick={
                            MessageData?.messages?.booking_type === "Single"
                              ? handleConvertToBooking
                              : handleConvertToBookingMultiple
                          }
                        />
                        <BgOutlineThemeButton
                          //  type={"submit"}
                          children={"Discard"}
                          onClick={() => setShowHoldPopup(false)}
                          style={{ width: "50%" }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>

      )}


    </>
  );
};
export default SentMessageNewPage;

import React, { useEffect, useState } from "react";
import { getDecryptedData } from "../../utils/encryptStorage";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import Loader from "../UI/Loader/Loader";

const BillingTemplates = () => {
  const property_id = useSelector((state) => state.property.value);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateContent, setSelectedTemplateContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const userToken = getDecryptedData("encryptToken"); // Replace with the actual user token

  // Fetch templates from the API
  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/email/template`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch templates");
      }
      const data = await response.json();
      setTemplates(data.data || []); // Assuming `templates` is the key in the response
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      setLoading(false);
    }
  };

  // Select template and preview content
  const selectTemplate = async (template) => {
    setSelectedTemplateContent(template.content || "");
    const raw = JSON.stringify({
      template_id: template.id,
    });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/save/property/template/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: raw,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to select template");
      }
      const data = await response.json();
      fetchTemplates()
      setSelectedTemplateContent(data.content || "");
      showSuccessToast(data.message); // Assuming `content` holds the HTML string
    } catch (error) {
      console.error("Error selecting template:", error);
      showErrorToast("Failed to load template content");
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div style={{ width: "800px", margin: "0 auto" }} className="mt-10">
      <div className="room-type-heading mb-10">Billing Templates</div>
      {loading ? (
       <Loader/>
      ) : (
        <ul className="templates-list " style={{ listStyle: "none" }}>
          {
            templates.map((template, index) => {
              const isActive = template.property_dynamic_template.some(
                (dynamicTemplate) => dynamicTemplate.property_id === property_id?.id
              );
            
              return (
                <li
                  key={template.id}
                  
                  className={`template-item ${isActive ? "active" : ""}`}
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    marginBottom: "5px",
                    border: "1px solid #ddd",
                    backgroundColor: isActive ? "#3968ed" : "#fff",
                    color: isActive ? "#fff" : "#000",
                  }}
                  onClick={() => selectTemplate(template)}
                >
                  {template.name || `Template ${index + 1}`}
                </li>
              )
            })
          }
        </ul>
      )}

      {/* {selectedTemplateContent && (
        <div
          className="template-preview"
          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ccc",
            backgroundColor: "#f9f9f9",
          }}
        >
          <h3>Template Preview</h3>
          <div
            dangerouslySetInnerHTML={{ __html: selectedTemplateContent }}
            style={{ overflow: "auto", maxHeight: "400px" }}
          ></div>
        </div>
      )} */}
    </div>
  );
};

export default BillingTemplates;

import { useEffect, useState } from "react";
import styles from "../Inputs/Inputs.module.scss";

export const Textarea = ({
  label,
  name,
  onChange = () => {},
  value,
  type,
  defaultValue,
  placeholder,
  required,
  min,
  max,
  onKeyDown,
  disabled,
  inputId,
  maxLength,
  onBlur,
  style,
  size,
  error,
  errorText = "lkjhgfdsdfhj",
}) => {
  const [internalError, setInternalError] = useState(true);
  const [charCount, setCharCount] = useState(0); // Count characters instead of words

  const handleChange = (e) => {
    let currentValue = e.target.value;

    // If the input exceeds the max length, slice it
    if (currentValue.length > max) {
      currentValue = currentValue.slice(0, max);
    }

    setCharCount(currentValue.length); // Update character count

    if (typeof onChange === "function") {
      e.target.value = currentValue; // Set the sliced value back to the event
      onChange(e); // Propagate changes to parent component
    }
  };

  useEffect(() => {
    if (charCount > max) {
      setInternalError(true); // Set error state if char count exceeds max
    } else {
      setInternalError(false); // Clear error if within limits
    }
  }, [charCount, max]);

  const hasError = internalError || error;

  return (
    <div className={styles.primary_input_container}>
      {label ? (
        <div
          className={`${styles.label} ${
            size === "small"
              ? styles.small
              : size === "medium"
              ? styles.medium
              : styles.large
          } `}
          style={{width:"fit-content"}}
        >
          {label}
        
          <sup style={{ color: "red" }}>{required === true ? " *" : ""} </sup>
        </div>
      ) : null}
      <div
        style={{ position: "relative" }}
        className={styles.primary_input_div}
      >
        <textarea
          on
          style={{
            ...style,
            borderColor: error ? "#ED3939" : "",
            resize:"none",
            width:"100%",
            height:"100%"
          }}
          onBlur={onBlur}
          onWheel={(e) => e.target.blur()}
          maxLength={maxLength}
          id={inputId}
          disabled={disabled}
          onKeyDown={onKeyDown}
          required={required}
          defaultValue={defaultValue}
          autoComplete="off"
          className={`${styles.primary_input} ${styles[size]} ${
            error ? styles.error : ""
          } ${disabled ? styles.disabled : ""}`}
          name={name}
          onChange={handleChange || ""}
          value={value}
          type={type}
          placeholder={placeholder}
          min={min}
          max={max}
        
        ></textarea>
        {/* Display character count */}
        <div className={styles.char_count}>
          {max > 0 ? (
            <>
              {charCount} /{" "}
              {(min !== undefined || max !== undefined) && (
                <div className={styles.range_info}>
                  {min !== undefined && <span> {min}</span>}
                  {max !== undefined && <span> {max}</span>}
                </div>
              )}
            </>
          ) : null}
        </div>

        {/* Display min/max if provided */}
        {/* {(min !== undefined || max !== undefined) && (
                    <div className={styles.range_info}>
                        {min !== undefined && <span>Min: {min}</span>}
                        {max !== undefined && <span>Max: {max}</span>}
                    </div>
                )} */}
      </div>

      {error && errorText ? (
        <>
          <span className={styles.error_text}>{errorText}</span>
        </>
      ) : null}
    </div>
  );
};

import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  Select,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteMajor } from "@shopify/polaris-icons";
import { BgOutlineThemeButton, BgThemeButton } from "./Buttons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import Loader from "./Loader/Loader";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { getDecryptedData } from "../../utils/encryptStorage";

const AddItems = ({
  setShowAddUnitsMenuActive,
  setShowAddDepartmentMenuActive,
  setShowAddModelMenuActive,
  setShowAddItemsActive,
  fetchInfo,
}) => {
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const taxSlabOptions = [
    { label: "5%", value: "5" },
    { label: "10%", value: "10" },
    { label: "18%", value: "18" },
    { label: "25%", value: "25" },
  ];

  const [itemsData, setItemsData] = useState({
    item_name: "",
    unit_name: "",
    unit_id: "",
    category_name: "",
    category_id: "",
    hsn_code: "",
    department_name: "",
    department_id: "",
    tax_slab: "",
  });

  const fetchUnits = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unit/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setUnits(data.Unit);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/category/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setCategories(data.category);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchDepartment = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/department/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setDepartments(data.Department);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchUnits(property_id?.id);
      fetchCategories(property_id?.id);
      fetchDepartment(property_id?.id);
    }
  }, [property_id]);

  const handleUnitChange = (event) => {
    setItemsData({
      ...itemsData,
      unit_id: event.target.value,
      unit_name: event.target.options[event.target.selectedIndex].text,
    });
  };

  const handleCategoryChange = (event) => {
    setItemsData({
      ...itemsData,
      category_id: event.target.value,
      category_name: event.target.options[event.target.selectedIndex].text,
    });
  };

  const handleDepartmentChange = (event) => {
    setItemsData({
      ...itemsData,
      department_id: event.target.value,
      department_name: event.target.options[event.target.selectedIndex].text,
    });
  };

  const handleInputChange = (event) => {
    setItemsData({
      ...itemsData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitAddItem = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/items/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...itemsData }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo && fetchInfo(property_id?.id);
        setShowAddItemsActive(false);
        // clearSelection();
        showSuccessToast(data.message);
        setItemsData({
          item_name: "",
          unit_name: "",
          category_name: "",
          hsn_code: "",
          department_name: "",
          tax_slab: "",
        });
        // window.location.reload();
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="bg-backdrop">
        <section className="agent-add-categories-model-container">
          <LegacyCard>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 10px 0 10px",
              }}
            >
              <GoBackButtonCustom
                buttonNextText={`Add Items`}
                onClick={() => setShowAddItemsActive(false)}
              />
              <div>
                {/* <BgThemeButton
                                onClick={() => [
                                    setShowAddCategoriesLowerMenuActive(
                                        !showAddCategoriesLowerMenuActive
                                    ),
                                    setShowLowerMenuMultipleSelect(false),
                                    setShowLowerMenuSingleSelect(false),
                                ]}
                                children={"Add Units"}
                            /> */}
              </div>
            </div>
            <div
              style={{
                padding: "10px",
              }}
            >
              <form
                onSubmit={(e) => e.preventDefault()}
                style={{
                  width: "100%",
                }}
              >
                <div className="edit-rooms-form-wrapper-container">
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name={"item_name"}
                        className="textfield"
                        focused={false}
                        titleName="Item Name"
                        onChange={handleInputChange}
                        autoComplete="off"
                        value={itemsData.item_name}
                      />
                    </div>

                    <div className="form-container-div">
                      <div style={{ marginBottom: "4px" }}>
                        Unit
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <select
                        style={{
                          width: "100%",
                          minHeight: "2.25rem",
                          border: "1px solid rgba(171, 177, 186, 1)",
                          borderRadius: "3px",
                        }}
                        required="true"
                        name="unit_id"
                        onChange={handleUnitChange}
                      >
                        <option value="">Select Option</option>
                        {units?.map((x) => {
                          return (
                            <>
                              <option key={x.id} value={x.id}>
                                {x.units_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      <input
                        type="hidden"
                        name="unit_name"
                        value={itemsData.unit_name}
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <div style={{ marginBottom: "4px" }}>
                        Category Item
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <select
                        style={{
                          width: "100%",
                          minHeight: "2.25rem",
                          border: "1px solid rgba(171, 177, 186, 1)",
                          borderRadius: "3px",
                        }}
                        required={true}
                        name="category_id"
                        onChange={handleCategoryChange}
                      >
                        <option value="">Select Option</option>
                        {categories?.map((x) => {
                          return (
                            <option
                              key={x.id}
                              value={x.id}
                              data-name={x.category_name}
                            >
                              {x.category_name}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        type="hidden"
                        name="category_name"
                        value={itemsData.category_name}
                      />
                    </div>
                    <div className="form-container-div">
                      <div style={{ marginBottom: "4px" }}>
                        Department
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <select
                        style={{
                          width: "100%",
                          minHeight: "2.25rem",
                          border: "1px solid rgba(171, 177, 186, 1)",
                          borderRadius: "3px",
                        }}
                        required="true"
                        name="department_id"
                        onChange={handleDepartmentChange}
                      >
                        <option value="">Select Option</option>
                        {departments?.map((x) => {
                          return (
                            <>
                              <option key={x.id} value={x.id}>
                                {x.department_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      <input
                        type="hidden"
                        name="department_name"
                        value={itemsData.department_name}
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name="hsn_code"
                        className="textfield"
                        titleName="HSN Code"
                        focused={false}
                        onChange={handleInputChange}
                        autoComplete="off"
                        value={itemsData.hsn_code}
                      />
                    </div>
                    <div className="form-container-div">
                      <div style={{ marginBottom: "4px" }}>
                        Tax Slab
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <select
                        style={{
                          width: "100%",
                          minHeight: "2.25rem",
                          border: "1px solid rgba(171, 177, 186, 1)",
                          borderRadius: "3px",
                        }}
                        required="true"
                        name="tax_slab"
                        onChange={handleInputChange}
                      >
                        <option value="">Select Option</option>
                        {taxSlabOptions?.map((x) => {
                          return (
                            <>
                              <option key={x.label} value={x.value}>
                                {x.label}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    color: "#3968ED",
                    display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <BgThemeButton
                    children={"Add"}
                    type="submit"
                    loader={loader}
                    onClick={(e) => handleSubmitAddItem(e)}
                  />
                  <BgOutlineThemeButton
                    onClick={() => setShowAddItemsActive(false)}
                    children={"Cancel"}
                  />
                </div>
              </form>
            </div>
          </LegacyCard>
        </section>
      </div>
    </>
  );
};

export default AddItems;

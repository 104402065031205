import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDecryptedData } from "../../utils/encryptStorage";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import styles from "./singleAgentDetails.module.scss";
import {
  PrimaryButton,
  SecondaryButton,
} from "../NewCustomComp/Buttons/Buttons";
import {
  SvgDecrementBtn,
  SvgDelete,
  SvgEdit,
  SvgIncrementBtn,
} from "../../assets/svgIcons";
import {
  getCurrentPropertyDateHandler,
  getDateByMonth,
} from "../../utils/utils";
import EditAgentPopup from "./EditAgentPopup";
import ConfirmPopup from "../NewCustomComp/confirmPopup/ConfirmPopup";
import SliderComponentSkeleton from "../NewCustomComp/SliderComponentSkelon/SliderComponentSkeleton";
import SingleAgentBookings from "../CustomComponents/SingleAgentDetailsPageSilderComponents/SingleAgentBookings";
import InventorySliderComponent from "../CustomComponents/BookingSoldSlider/InventorySliderComponent";
import { BgOutlineThemeButton, WithoutBgButtonBlue } from "../UI/Buttons";
import AgentCityLedgerReport from "../UI/AgentCityLedgerReport";

const SingleAgentDetails = () => {
  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  const { ID } = useParams();
  const navigate = useNavigate();
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData("encryptToken");
  const [data, setData] = useState("");
  const fetchInfo = async (propertyId, agentId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent-info/${agentId}/${propertyId}`;

    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        setData(data.data);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (property_id?.id && ID) {
      fetchInfo(property_id?.id, ID);
    }
  }, [property_id, ID]);

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  // edit agent

  const [addAgentData, setAddAgentData] = useState({
    agent_name: "",
    agent_email: "",
    agent_phone: "",
    agent_category: "",
    company_name: "",
    business_type: "",
    agent_pan: "",
    agent_gst: "",
    settled_from: "",
    credit_amount: "",
    balance: "",
    agent_description: "",
    assigned_properties: "",

    max_hold_time: "",
    advance_amount: "",
    total_credit_amount: "",
    total_advance_amount: "",
    agent_information: "",
    balance_amount: "",
    temporary_booking: "",
    used_amount: "",
    // settle_from: "",

    // assigned_properties:"]", "2"
  });

  const [selectedTags, setSelectedTags] = useState([]);
  const [editAgent, setEditAgent] = useState(false);
  const fetchSingleAgentData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/edit/${id}/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        const updatedData = {
          agent_name: data?.data?.agentInfo.agent_name,
          agent_email: data?.data?.agentInfo.agent_email,
          agent_phone: data?.data?.agentInfo.agent_phone,
          agent_category: data?.data?.agent_category,
          company_name: data?.data?.company_name,
          business_type: data?.data?.business_type,
          agent_pan: data?.data?.agent_pan,
          agent_gst: data?.data?.agent_gst,
          settled_from: data?.data?.settled_from,
          credit_amount: data?.data?.credit_amount,
          balance: data?.data?.balance,
          agent_description: data?.data?.agent_description,
          assigned_properties: data?.data?.assignedProperties,
          max_hold_time: data?.data?.max_hold_time,
          total_credit_amount: data?.data?.total_credit_amount,
          total_advance_amount: data?.data?.total_advance_amount,
          agent_information: data?.data?.agent_information,
          used_amount: data?.data?.total_used_credit,
          balance_amount: data?.data?.total_balance_credit,
          temporary_booking: data?.data?.temporary_booking,
        };

        setAddAgentData(updatedData);
        setSelectedTags(data?.data?.assignedProperties);
        setEditAgent(true);
        const assignedProperties = data?.data?.assignedProperties;
        // setSelectedTags(assignedProperties ? assignedProperties : []);

        // setAddItemData(updatedData);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = () => {
    fetchSingleAgentData(ID);
  };

  // delete agent
  const [deletePopup, setDeletePopup] = useState(false);

  const handleDeleteAgent = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/delete/${ID}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ids: [ID],
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        // fetchInfo(agentId);
        navigate(-1);
        setDeletePopup(false);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  // total bookings slider
  const [bookingDetailsSlider, setBookingDetailsSlider] = useState(false);
  const [sliderData, setSliderData] = useState({});

  const handleOpenBookingDetails = async (id, name) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent-booking/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            id: id,
          }),
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }

      if (data.success) {
        setSliderData(data.data);
        setBookingDetailsSlider(true);
      }
    } catch (error) {
      // setLoader(false);
      console.error(error);
    }
  };

  // pagination

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate the data for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData =
    data &&
    data?.agentLogs &&
    data?.agentLogs?.slice(startIndex, startIndex + itemsPerPage);
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled =
    startIndex + itemsPerPage >= data && data?.agentLogs?.length;

  // Handle page navigation
  const handleNextPage = () => {
    if (startIndex + itemsPerPage < data?.agentLogs.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // hold sold  slider
  const [totalBookingData, setTotalBookingData] = useState({});
  const [showTotalBookings, setShowTotalBookings] = useState(false);

  const fetchSoldRoomsData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent/inventory/sold/hold/details/${propertyCurrentDate}/${ID}/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json().then((data) => {
        if (data.success) {
          setTotalBookingData(data);
          setShowTotalBookings(true);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };


  const [showCityLedger, setShowCityLedger] = useState(false);

  return (
    <div className="main_container">
      <div className="w-60 mx-auto">
        <div className="flex flex_gap_10 align_items_center">
          <GoBackButtonCustom onClick={() => navigate(-1)} />
          <div className="room-type-heading  ">{data.company_name}</div>

          <div
            className="flex flex_gap_10 justify_content_end "
            style={{ margin: "0  0 0 auto" }}
          >
            <WithoutBgButtonBlue children={"City Ledger Report"} onClick={() => navigate(`/agents/city/Ledger/${ID}`)} />
            <SecondaryButton size={"medium"} title={"Adv. Payments"} onClick={() => navigate(`/agents/${ID}/advance-payments`)} />
            <PrimaryButton size={"medium"} title={"Credit Payments"} onClick={() => navigate(`/credit/${ID}`)} />
          </div>
        </div>

        <div className="flex flex_gap_10">
          <div className="w-60">
            <div
              className={`bg-white ${styles.container} mt-10 flex justify_content_between`}
            >
              <span className="flex flex_direction_col flex_gap_5">
                <span className={styles.bold}>{data?.total_bookings || 0}</span>
                <span
                  className={`${styles.amountsText} ${styles.anchor}`}
                  onClick={() => handleOpenBookingDetails(ID)}
                >
                  Total Bookings
                </span>
              </span>
              <span className="flex flex_direction_col flex_gap_5">
                <span className={styles.bold}>
                  {data?.total_hold_rooms || 0}
                </span>
                <span
                  className={`${styles.amountsText} ${styles.anchor}`}
                  onClick={fetchSoldRoomsData}
                >
                  Hold Rooms
                </span>
              </span>

              <span className="flex flex_direction_col flex_gap_5">
                <span className={styles.bold}>{data?.total_contract || 0}</span>
                <span
                  className={`${styles.amountsText} ${styles.anchor}`}
                  onClick={fetchSoldRoomsData}
                >
                  Contracts
                </span>
              </span>

              <span className="flex flex_direction_col flex_gap_5">
                <span className={styles.bold} style={{ fontSize: "16px" }}>
                  ₹{data?.total_spend || 0}
                </span>
                <span className={styles.amountsText}> Total Assets</span>
              </span>
            </div>

            <div className={`bg-white ${styles.container} mt-10 `}>
              <div
                className={`${styles.heading} mb-10 flex justify_content_between`}
              >
                <span>Credits</span>

                <span onClick={() => navigate(`/credit/${ID}`)} className={styles.details}>Details → </span>
              </div>
              <div className="flex justify_content_between">
                <span className="flex flex_direction_col flex_gap_5">
                  <span className={styles.bold}>
                    ₹{data.total_credit_amount}
                  </span>
                  <span className={styles.amountsText}>Credit Amt.</span>
                </span>
                <span className="flex flex_direction_col flex_gap_5">
                  <span className={styles.bold}>₹{data.total_used_credit}</span>
                  <span className={styles.amountsText}>Used Amt.</span>
                </span>
                <span className="flex flex_direction_col flex_gap_5">
                  <span className={`${styles.bold} text-right`}>
                    ₹{data.total_balance_credit}
                  </span>
                  <span className={`${styles.amountsText} text-right`}>
                    {" "}
                    Balance Amt.
                  </span>
                </span>
              </div>
            </div>

            <div className={`bg-white ${styles.container} mt-10`}>
              <div
                className={`${styles.heading} mb-10 flex justify_content_between`}
              >
                <span>Credits Finance Logs</span>
                <span className="flex">
                  <span className="pointer" onClick={handlePrevPage}>
                    <SvgDecrementBtn
                      btnColor={isPrevDisabled ? "#e0e0e0" : ""}
                    />
                  </span>
                  <span className="pointer" onClick={handleNextPage}>
                    <SvgIncrementBtn
                      btnColor={isNextDisabled ? "#e0e0e0" : ""}
                    />
                  </span>
                </span>
              </div>
              <div>
                {currentData &&
                  currentData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        border: "1px solid #e0e0e0",
                        borderRadius: "6px",
                        padding: "10px",
                      }}
                      className="flex justify_content_between mt-10"
                    >
                      <div className="flex flex_direction_col flex_gap_5">
                        <span className={styles.bold}>
                          {getDateByMonth(item?.date)}
                        </span>
                        <span className={styles.amountsText}>Date</span>
                      </div>

                      <div className="flex flex_direction_col flex_gap_5">
                        <span className={styles.bold}>
                          ₹{item?.balance_amount || 0}
                        </span>
                        <span className={styles.amountsText}>Balance Amt.</span>
                      </div>

                      <div className="flex flex_direction_col flex_gap_5">
                        <span className={styles.bold}>
                          ₹{item?.new_amount || 0}
                        </span>
                        <span className={styles.amountsText}>New Amt.</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="w-40">
            <div className={`bg-white ${styles.container} mt-10  `}>
              <div className={`${styles.heading} mb-10`}>
                <span>Contact Information</span>
              </div>
              <div className={styles.bold}>
                {data?.agentInfo?.agent_phone || "NA"}
              </div>
              <div className={styles.bold}>
                {data?.agentInfo?.agent_email || "NA"}
              </div>
              <hr className="mt-10 mb-10" />
              <div className={`${styles.heading} mb-10`}>
                <span>Business Information</span>
              </div>
              <div>
                <div className={styles.amountsText}>Agent Category</div>
                <div className={styles.bold}>
                  {data?.agent_category || "NA"}
                </div>
              </div>
              <div className="mt-10">
                <div className={styles.amountsText}>Contact Person</div>
                <div className={styles.bold}>
                  {data?.agentInfo?.agent_name || "NA"}
                </div>
              </div>
              <div className="mt-10">
                <div className={styles.amountsText}>GST No.</div>
                <div className={styles.bold}>{data?.agent_gst || "NA"}</div>
              </div>

              <div className="flex flex_gap_10">
                <SecondaryButton
                  size={"medium"}
                  isIconLeft={true}
                  icon={<SvgEdit />}
                  title={"Edit"}
                  style={{ width: "50%" }}
                  onClick={handleEditClick}
                />
                <SecondaryButton
                  size={"medium"}
                  isIconLeft={true}
                  icon={<SvgDelete />}
                  title={"Delete"}
                  style={{ borderColor: "red", color: "red", width: "50%" }}
                  onClick={() => setDeletePopup(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {editAgent && (
        <EditAgentPopup
          show={editAgent}
          setShow={setEditAgent}
          addAgentData={addAgentData}
          setAddAgentData={setAddAgentData}
          userToken={userToken}
          fetchInfo={fetchInfo}
          property={property_id}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      )}

      {deletePopup && (
        <>
          <ConfirmPopup
            heading={"Are You Sure ?"}
            subHeading={"Do You Want To Delete"}
            showPopup={deletePopup}
            setShowPopup={setDeletePopup}
            handleConfirmClick={handleDeleteAgent}
          />
        </>
      )}

      {/* bookings details  */}

      {bookingDetailsSlider && (
        <SliderComponentSkeleton
          show={bookingDetailsSlider}
          setShow={setBookingDetailsSlider}
          smallTitle={`<span  style="font-weight: 600;">Bookings</span>`}
          title={sliderData.customer_name}
        >
          <SingleAgentBookings data={sliderData} />
        </SliderComponentSkeleton>
      )}

      {/* end  bookings details  */}

      {showTotalBookings && totalBookingData && (
        <>
          <SliderComponentSkeleton
            show={showTotalBookings}
            setShow={setShowTotalBookings}
            title={data?.agentInfo?.agent_name}
          >
            <InventorySliderComponent
              totalBookingData={totalBookingData}
              setShowTotalBookings={setShowTotalBookings}
              selectedDate={"selectedDate"}
              fetchSoldRoomsData={fetchSoldRoomsData}
              fetchInfo={fetchInfo}
              visibleFilters={[2, 3]}
            />
          </SliderComponentSkeleton>
        </>
      )}
      {
        showCityLedger && (
        <div className="global_popup_container_background">

          <div className="global_popup_container" style={{width:"80%" ,height:"80%",overflow:"auto"}}>
            <div>

            </div>
          <AgentCityLedgerReport hideSideBar={true} />
          </div>
        </div>
        )
      }
    </div>
  );
};

export default SingleAgentDetails;

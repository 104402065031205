import React, { useCallback, useEffect, useState } from "react";
import { BgThemeButton } from "../UI/Buttons";
import { Button, Icon } from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import CustomToggleButton from "../UI/CustomToggleButton";

const EditAgentPopup = ({
  show,
  setShow,
  addAgentData,
  setAddAgentData,
  property,
  userToken,
  fetchInfo,
  selectedTags,
  setSelectedTags
}) => {
  const fetchPropertiesData = async () => {
    try {
      // /api/v1/user-profile
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user-profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setAllProperties(data.data.properties);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPropertiesData();
  }, []);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [open8, setOpen8] = useState(true);
  const [allProperties, setAllProperties] = useState([]);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);

  const [addCategories, setAddCategories] = useState([]);
  const fetchAgentCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/categories/${property?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAddCategories(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property?.id) {
      fetchAgentCategories(property?.id);
    }
  }, [property?.id]);
  const handleChangeAgentData = (event) => {
    setAddAgentData({
      ...addAgentData,
      [event.target.name]: event.target.value,
    });
  };

  const addCategoriesArray = [
    { label: "Select Option" },
    ...addCategories.map((category) => {
      return { label: category.agent_cat_name, value: category.id };
    }),
  ];

  const addAssignHotel = (e) => {
    const foundProperty = allProperties.find(
      (property) => property.property_name === e.target.value
    );
    if (foundProperty) {
      const isPropertyExists = selectedTags.some(
        (property) => property.id === foundProperty.id
      );

      if (!isPropertyExists) {
        const newProperty = {
          id: foundProperty.id,
          name: foundProperty.property_name,
        };

        setSelectedTags((prevState) => [...prevState, newProperty]);
      } else {
        showErrorToast("Already Exists");
      }
    }
  };

  const removePropertyHandler = (tag) => {
    setSelectedTags((previousTags) =>
      previousTags.filter((previousTag) => previousTag?.id !== tag)
    );
  };
  const [temporaryBooking, setTemporaryBooking] = useState("");
  const [showMaxHold, setShowMaxHold] = useState(false);

  const temporaryBookingsHandle = useCallback(
    (data) => {
      setTemporaryBooking(data === "enabled" ? 1 : 0);
      if (temporaryBooking === 1) {
        setShowMaxHold(true);
      } else {
        setShowMaxHold(false);
      }
    },
    [showMaxHold, temporaryBooking, addAgentData.max_hold_time]
  );

  const handleUpdateAgents = (event) => {
    event.preventDefault();
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agents/update/${addAgentData.agent_information}/${property?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addAgentData,
          account_id: property?.account_id,
          assigned_properties: selectedTags,
          credit_amount: addAgentData.total_credit_amount,
          temporary_booking: temporaryBooking,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setLoader(false);
          navigate("/agents");
          fetchInfo(property?.id);
          showSuccessToast("Agent Updated Successfully");
          setShow(!show);
        } else {
          showErrorToast("Something Went Wrong.. Please try again...");
          setLoader(false);
        }
      });
  };

  return (
    <div>
      <div className="bg-backdrop">
        <section className="room-rate-plan-edit-model-container">
          <h4>Edit Agent</h4>
          <div className="edit-rooms-form-container">
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  type={"text"}
                  required={true}
                  name={"agent_name"}
                  className="textfield"
                  focused={false}
                  titleName="Name"
                  onChange={handleChangeAgentData}
                  autoComplete="off"
                  value={addAgentData.agent_name}
                />
              </div>
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  type={"number"}
                  required={true}
                  name={"agent_phone"}
                  className="textfield"
                  focused={false}
                  titleName="Phone"
                  onChange={handleChangeAgentData}
                  autoComplete="off"
                  value={addAgentData.agent_phone}
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  type={"email"}
                  required={true}
                  name={"agent_email"}
                  className="textfield"
                  focused={false}
                  titleName="Email"
                  onChange={handleChangeAgentData}
                  autoComplete="off"
                  value={addAgentData.agent_email}
                />
              </div>

              <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  options={addCategoriesArray}
                  type={"text"}
                  required={true}
                  name={"agent_category"}
                  className="textfield"
                  focused={false}
                  titleName="Agent Category"
                  onChange={handleChangeAgentData}
                  autoComplete="off"
                  value={addAgentData.agent_category}
                />
              </div>

              {/* <div className="form-container-div">
                        <VerticalInputFieldCustom
                          type={"number"}
                          required={true}
                          name={"max_hold_time"}
                          className="textfield"
                          focused={false}
                          titleName="Booking Holding Time"
                          autoComplete="off"
                          onChange={handleChangeAgentData}
                          value={addAgentData.max_hold_time || "N/A"}
                        />
                      </div> */}
            </div>

            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  // type={"number"}
                  required={true}
                  name={"total_credit_amount"}
                  className="textfield"
                  focused={false}
                  titleName="Total Credit Limit"
                  onChange={handleChangeAgentData}
                  autoComplete="off"
                  value={addAgentData.total_credit_amount || 0}
                />
              </div>
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  // type={"number"}
                  required={true}
                  name={"agent_gst"}
                  className="textfield"
                  focused={false}
                  titleName="GST"
                  onChange={handleChangeAgentData}
                  autoComplete="off"
                  value={addAgentData.agent_gst}
                />
              </div>

              <div className="form-container-div">
                <VerticalInputFieldCustom
                  disabled={true}
                  type={"number"}
                  required={true}
                  name={"total_advance_amount"}
                  className="textfield"
                  focused={false}
                  titleName="Total Advance Payment"
                  onChange={handleChangeAgentData}
                  autoComplete="off"
                  value={addAgentData.total_advance_amount || 0}
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <div
                  onClick={handleToggle8}
                  aria-expanded={open8}
                  aria-controls="basic-collapsible"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: "700",
                    fontSize: "14px",
                  }}
                >
                  <div>Assign Hotel</div>
                </div>

                <div className="edit-rooms-form-container">
                  <div className="form-container-div">
                    <div style={{ marginBottom: "4px" }}>
                      Select Property
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <select
                      style={{
                        width: "100%",
                        minHeight: "2.25rem",
                        border: "1px solid rgba(171, 177, 186, 1)",
                        borderRadius: "3px",
                      }}
                      onChange={(e) => addAssignHotel(e)}
                    >
                      <option value="">Select Option</option>
                      {allProperties?.map((x) => {
                        return (
                          <option
                            id={x.property_id}
                            key={x.property_id}
                            value={x.property_name}
                          >
                            {x.property_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedTags &&
                      selectedTags?.length > 0 &&
                      selectedTags?.map((tag) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#f0f0f0",
                              padding: "5px",
                              borderRadius: "5px",
                              margin: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "12px",
                            }}
                          >
                            {tag?.name}
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => removePropertyHandler(tag.id)}
                            >
                              <Icon source={CancelMajor} />
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="form-container-div">
                <div style={{ marginBottom: "7px" }}>
                  <CustomToggleButton
                    positive={"Enable"}
                    negative={"Disable"}
                    byDefaultEnable={
                      addAgentData.temporary_booking === 1 ? true : false
                    }
                    func={temporaryBookingsHandle}
                    title={"Temporary Bookings"}
                  />
                </div>
                {showMaxHold && (
                  <div className="form-container-div-full">
                    <VerticalInputFieldCustom
                      required={true}
                      name={"max_hold_time"}
                      className="textfield"
                      focused={false}
                      titleName="Max Hold Time"
                      onChange={handleChangeAgentData}
                      autoComplete="off"
                      value={addAgentData.max_hold_time}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <BgThemeButton onClick={handleUpdateAgents} loader={loader}>
              Update
            </BgThemeButton>
            <Button onClick={() => setShow(false)}>Discard</Button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EditAgentPopup;

import React from "react";

const VerticalInputSelectCustomPermissions = ({
  titleName,
  name,
  onChange,
  value,
  type,
  options,
  optionOnclick,
  required,
  disabled,
  style,
  error,
}) => {
  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  return (
    <div>
      <div style={{ marginBottom: "0.25rem" }}>
        <label htmlFor="standard-select">
          {titleName}
          {required ? <span style={{ color: "red" }}> *</span> : ""}
        </label>
      </div>
      <div className="select">
        <select
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          className="select-custom"
          style={{
            width: "100%",
            minHeight: "2.25rem",
            backgroundColor: error ? "#FFEAEA" : "",
            border: error ? "1px solid red" : "0.0625rem solid #888",
            borderRadius: "0.25rem",
            paddingLeft: "5px",
            ...style,
          }}
          required={required}
        >
          {options?.map((option, index) => {
            const isAccessible =
              Array.isArray(option?.permission)
                ? option.permission.some((perm) => permissions.includes(perm))
                : permissions.includes(option?.permission);

            return (
              (option?.label === "Select Option" || isAccessible) && (
                <option
                  key={index}
                  value={option?.value}
                  onClick={optionOnclick}
                >
                  {option?.label}
                </option>
              )
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default VerticalInputSelectCustomPermissions;